import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { getFileService } from '../../../api/cognito/file.service';
import { updateApplication } from '../../../api/patch/application.patch';
import { uploadInsurance } from '../../../api/patch/vehicle.patch';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { CAR_HIRE_NATIONAL } from '../../../consts';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { UploaderInput } from '../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../uiComponents/text/text';
import { Checkbox, CheckboxLabel } from '../../../uiComponents/uiControls/checkbox/checkbox';
import { ErrorType, handleAPIError } from '../../../utils/handleAPIError';
import { ALLOWED_MIME_TYPES, fileNameTimeStampFormat, getBlob, renderNotification } from '../../../utils/utils';
import { validateFutureDateFormat } from '../../../utils/validations';
import { SectionProps } from '../order/order';
import { Insurance } from '../order/order.styles';
import { SectionHeader } from './sectionHeader';

interface ProofOfInsurance {
  insurance_document: File | Blob;
  expiry_date: string;
}

export const InsuranceSection = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [ottoInsuranceChecked, setOttoInsuranceChecked] = useState<boolean>(progress?.is_otto_insured ?? false);
  const [insuranceUrl, setInsuranceUrl] = useState<URL | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ProofOfInsurance>();
  const vehicleId: string = progress?.vehicle_id;
  const applicationId: string = progress?.application_id;
  const fileService = getFileService();

  const onSubmit = async (submitValues: ProofOfInsurance) => {
    const { expiry_date, insurance_document } = submitValues;
    let ext: string | undefined = ALLOWED_MIME_TYPES[insurance_document ? insurance_document.type : ''];
    if (!ext) {
      ext = insurance_document ? (insurance_document as File).name.split('.').pop() : undefined;
    }

    const fileName = 'INSURANCE';

    let fileWithExt = fileName;
    if (ext) {
      fileWithExt = `${fileWithExt}.${ext}`;
    }
    const fileNameWithTimeStamp = fileNameTimeStampFormat(fileWithExt);
    const path = `DOCUMENTS/VEHICLE/${vehicleId}/${fileName}/${fileNameWithTimeStamp}`;
    setSubmitting(true);

    let s3_url;
    try {
      await fileService.uploadFileToStorage({
        path,
        data: insurance_document,
      });
      s3_url = path;
    } catch (error: any) {
      renderNotification('error', 'Error', `${error.message}.`, true);
      setSubmitting(false);
      return;
    }

    const payload = {
      application_id: vehicleId,
      insurance_type: 'ALTERNATIVE-INSURANCE',
      document_type: fileName,
      s3_url,
      expiry_date,
    };

    uploadInsurance(vehicleId, payload).then(() => {
      setSubmitting(false);
      checkProgress?.();
      setIsCollapsed(true);
    });
  };

  const onOttoInsuranceCheck = useCallback(
    (checked: boolean) => {
      updateApplication(applicationId, { is_otto_insured: checked }).then(() => {
        checkProgress?.();
      });
    },
    [applicationId, checkProgress]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (progress?.insurance_expiry != null) {
          setValue('expiry_date', progress?.insurance_expiry);
          setValue(
            'expiry_date',
            progress?.insurance_expiry ? format(new Date(progress?.insurance_expiry), 'yyyy-MM-dd') : ''
          );
          if (progress?.insurance_s3_url) {
            const blob = getBlob(progress?.insurance_s3_url);
            setValue('insurance_document', await blob);
          }
        }
      } catch (err) {
        handleAPIError(err as ErrorType);
      }
    };
    fetchData();
  }, [progress, setValue]);

  useEffect(() => {
    const fetchInsuranceUrl = async () => {
      if (progress?.insurance_s3_url) {
        try {
          const linkToStorageFile = await fileService.getFileUrlFromStorage({ path: progress?.insurance_s3_url });
          setInsuranceUrl(linkToStorageFile);
        } catch (error: any) {
          renderNotification('error', 'Error', `${error.message}`, true);
        }
      }
    };

    fetchInsuranceUrl();
  }, [progress?.insurance_s3_url, fileService]);

  const insuranceDoc = watch('insurance_document');
  const expiry = watch('expiry_date');

  return (
    <CollapsiblePanel
      styled={{ marginTop: 16 }}
      header={
        <SectionHeader
          title="Insurance & PCO"
          isComplete={isComplete}
          completedText="Completed"
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div>
        <SectionHeader title="Insurance & PCO" isComplete={isComplete} isLocked={isLocked} expanded />
        {progress?.application_type === CAR_HIRE_NATIONAL && (
          <Insurance gap={8} itemsY="center">
            <Checkbox
              name="otto_insurance"
              defaultChecked={progress?.is_otto_insured ?? false}
              onCheck={(checked: boolean) => {
                onOttoInsuranceCheck(checked);
                setOttoInsuranceChecked(checked);
              }}
            />
            <CheckboxLabel label="Otto insurance included" />
          </Insurance>
        )}
        {!ottoInsuranceChecked && (
          <>
            <Text styled={{ marginTop: 24 }} block variant="body6" color={PRIMARY_PURPLE} weight={300}>
              Please make sure its comprenhensive and compliant.
            </Text>
            <FlexLayout styled={{ marginTop: 24 }} itemsX="start" itemsY="center" gap={50}>
              <FlexLayout itemsX="start" gap={16} vertical>
                <UploaderInput
                  label="Proof of insurance"
                  control={control as unknown as Control<FieldValues>}
                  name="insurance_document"
                  required
                  error={errors?.insurance_document}
                />
                {errors?.insurance_document && (
                  <Text variant="body8" color={STATUS_RED} weight={300} block>
                    {errors?.insurance_document?.message ?? ''}
                  </Text>
                )}
                {insuranceUrl && (
                  <a
                    style={{ color: 'blue !important' }}
                    target="_blank"
                    href={insuranceUrl.href}
                    rel="noopener noreferrer"
                  >
                    View current insurance
                  </a>
                )}
              </FlexLayout>
              <TextField
                type="date"
                {...register('expiry_date', {
                  required: 'Insurance expiry date is a required field',
                  validate: (v: string) => validateFutureDateFormat(v),
                })}
                error={errors?.expiry_date}
                required
                label="Expiry date"
              />
            </FlexLayout>
            <FlexLayout styled={{ marginTop: 24 }} itemsX="end" gap={16}>
              <PrimaryButton
                disabled={!insuranceDoc || !expiry || !!errors?.expiry_date}
                isProcessing={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </PrimaryButton>
            </FlexLayout>
          </>
        )}
      </div>
    </CollapsiblePanel>
  );
};
