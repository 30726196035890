import React, { useCallback, useEffect } from 'react';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { Table } from '../../../../uiComponents/table/table';
import { driverInvoiceColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { TableTextCell } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { CustomerInvoicesItem } from '../../../../models/driver';
import { format } from 'date-fns';

export const DriverInvoices = ({ invoices }: { invoices: CustomerInvoicesItem[] }) => {
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetInvoicesResponse = useCallback(
    (invoices: CustomerInvoicesItem[]) => {
      const invoiceRows = invoices?.map((invoice: CustomerInvoicesItem) => {
        return {
          rowData: { data: invoice },
          cells: [
            <TableTextCell
              value={invoice?.invoice_date ? format(new Date(invoice.invoice_date), 'dd MMM yyyy') : '-'}
            />,
            <TableTextCell value={invoice?.invoice_amount} />,
            <TableTextCell value={invoice?.line_items[0]?.product_name} />,
            <TableTextCell value={invoice?.status} />,
            <TableTextCell value={invoice?.id} />,
          ],
        };
      });
      setTableData(invoiceRows);
      setTotalRows(invoiceRows.length);
    },
    [setTotalRows, setTableData]
  );

  useEffect(() => {
    handleGetInvoicesResponse(invoices);
  }, [setTableFilters, handleGetInvoicesResponse, invoices]);

  return (
    <Table
      variant="compact"
      tableTheme="purple"
      embedded
      onColumnHeaderClick={(_) => {}}
      sortAscending={sortAscending}
      columns={driverInvoiceColumns}
      rows={tableData}
      totalRows={totalRows}
      rowsPerPage={numRowsPerPage}
      currentPageNumber={pageNumber}
      sortingColumn={sortingColumn}
      filters={[]}
      filterQuery={filterQuery}
      goToPage={(pageNumber: number) => {
        goToPageNumber(pageNumber);
      }}
      onNumRowsPerPageChange={(value: number) => {
        setNumRowsPerPage(value);
        goToPageNumber(0);
      }}
    />
  );
};
