import { css, styled } from 'styled-components';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GRAY_2, PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_RED, PRIMARY_WHITE } from '../../../../common/styles/Colors';

export const Card = styled.div<{ $inArrears?: boolean }>`
  background-color: ${PRIMARY_PURPLE};
  border-radius: 16px;
  color: ${PRIMARY_WHITE};
  font-weight: 300;
  padding: 32px;
  width: 440px;

  h1 {
    font-weight: 800;
  }

  .active-link {
    cursor: pointer;
    text-decoration: underline;
  }

  ${(props) => {
    if (props.$inArrears) {
      return css`
        box-shadow: 0 0 0 8px ${STATUS_RED} inset;
      `;
    }
    return null;
  }}
`;

export const DvlaInputDiv = styled.div`
  position: relative;
  padding-bottom: 16px;
`;

export const OrdwayCustomerLink = styled.a`
  color: ${PRIMARY_WHITE};
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;

  &:visited {
    color: ${PRIMARY_WHITE};
  }
`;

export const SearchButton = styled(FlexLayout)`
  position: absolute;
  right: 8px;
  top: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;

export const StatusBarBG = styled.div`
  position: relative;
  margin-top: 2px;
  background-color: ${GRAY_2};
  width: 120px;
  height: 20px;
  border-radius: 100px;
`;

export const StatusBar = styled.div<{ $progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  border-radius: 100px;
  width: ${(props) => `${props?.$progress.toFixed(2)}%`};
  background-color: ${PRIMARY_GREEN};
`;
