export const ACCOUNT = '/app/account';
export const AFTERCARE = '/app/account/aftercare';
export const AFTERCARE_LIST = '/app/account/aftercare/list';
export const AFTERCARE_UPCOMING = '/app/account/aftercare/upcoming';
export const AFTERCARE_CONTACT_LIST = '/app/account/aftercare/contact-list';
export const AFTERCARE_REVIEWS = '/app/account/aftercare/review';
export const ADDITIONAL_CHARGES = '/app/account/additional-charges';
export const CONGESTIONS_CHARGES = '/app/account/additional-charges/congestion-charges';
export const EXCESS_MILEAGE = '/app/account/additional-charges/excess-mileage';
export const ADDITIONAL_DETAILS = '/app/account/additional-details';
export const ADMIN = '/app/admin';
export const AGREEMENTS = '/app/account/agreements';
export const AGREEMENT_MIGRATION = '/app/migration/agreements/:id';
export const ASSESSMENTS = '/app/sales/assessments';
export const BRANCHES = '/app/admin/branches';
export const CITIES = '/app/admin/cities';
export const COURTESY_ORDER = '/app/sales/courtesy';
export const CREATE_ORDER = '/app/sales/order/create';
export const CONDITION_REPORT = '/app/condition-report/';
export const CONTRACTS = '/app/admin/contracts';
export const CREATE_CONTRACT = '/app/admin/contracts/create';
export const EDIT_CONTRACT = '/app/admin/contracts/edit/:id';
export const VIEW_CONTRACT = '/app/admin/contracts/:id';
export const DECLARATION = '/app/sales/assessment/sign-declaration';
export const DASHBOARD = '/app/dashboard';
export const DRIVERS = '/app/account/drivers';
export const DRIVER_MIGRATION = '/app/migration/drivers/:id';
export const EXPORT = '/app/export';
export const FLEET = '/app/fleet';
export const LOGIN = '/login';
export const NEW_DRIVER = '/app/account/new-driver';
export const ORDER = '/app/sales/order';
export const ORDERS = '/app/sales/orders';
export const PHV_AND_MOT = '/app/servicing/phv-and-mot';
export const PROSPECTIVE_DRIVERS = '/app/sales/prospective-drivers';
export const SALES = '/app/sales';
export const SERVICING = '/app/servicing';
export const SERVICING_LIST = '/app/servicing/servicing-list';
export const TRANSFERS = '/app/fleet/transfers';
export const CREATE_TRANSFER = '/app/fleet/transfers/create';
export const EDIT_CONDITION_REPORT = '/app/condition-report/edit/:id';
export const USERS = '/app/admin/users';
export const VEHICLES = '/app/fleet/vehicles';
export const VEHICLE_BATCHES = '/app/fleet/batches';
export const VEHICLE_MIGRATION = '/app/migration/vehicle/:id';
export const VEHICLE_TYPES = '/app/fleet/vehicle-types';
export const INSURANCE = '/app/insurance';
export const POLICIES = '/app/insurance/policies';
export const VEHICLE_MODELS = '/app/fleet/vehicle-model';
export const NEW_VEHICLE_BATCHES = '/app/fleet/vehicle-batches';
export const REPORTS = '/app/reports';
export const EMBEDDED_REPORT = '/app/reports/embedded';
