import { useNavigate } from 'react-router-dom';
import { BsListUl } from 'react-icons/bs';
import { getAllBranches } from '../../../api/get/branch.get';
import { VehicleInfoProps } from '../vehiclePage/vehiclePage';
import { VehicleDetailsCard } from './vehicle.styles';
import { AFTERCARE_STATUS, LIVE_STATUS } from '../../../consts/status';
import { DRIVERS } from '../../../consts/routes';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_WHITE, SECONDARY_PURPLE_30, PRIMARY_GREEN, STATUS_RED } from '../../../common/styles/Colors';
import { HEAVILY_DAMAGED, UNINSURED, WRITE_OFF } from '../../../consts/vehicle';
import { useEffect, useState } from 'react';
import { Branch } from '../../../models/branch';
import { format } from 'date-fns';

export const VehicleInfoCard = ({ vehicle }: VehicleInfoProps) => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<Branch[]>();

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      setBranches(response?.data);
    });
  }, []);
  return (
    <>
      <VehicleDetailsCard $isDamaged={[HEAVILY_DAMAGED, WRITE_OFF, UNINSURED].includes(vehicle.condition)}>
        <div>
          <BsListUl size={48} color={PRIMARY_WHITE} />
        </div>
        <Text variant="h1" weight={800} color={PRIMARY_WHITE} block>
          {`${vehicle?.manufacturer} ${vehicle?.model}`}
        </Text>
        <GridLayout template={2} styled={{ marginTop: 24 }} gap="24px 8px">
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              VRM
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {vehicle?.vrm ?? '-'}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              VIN
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {vehicle?.vin ?? '-'}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              Manufacturer
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {vehicle?.manufacturer}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              Model
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {`${vehicle?.model} ${vehicle?.spec ?? ''}`}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              Colour
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {vehicle?.color}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              Status
            </Text>
            <Tag
              color={
                [LIVE_STATUS, AFTERCARE_STATUS].includes(vehicle?.vehicle_status) ? PRIMARY_GREEN : SECONDARY_PURPLE_30
              }
            >
              {vehicle?.vehicle_status}
            </Tag>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              {vehicle?.driver_id ? 'Driver' : 'Available on'}
            </Text>
            <Text
              variant="body6"
              color={PRIMARY_WHITE}
              weight={300}
              block
              styled={{
                textDecoration: vehicle?.driver_id ? 'underline' : 'none',
                cursor: vehicle?.driver_id ? 'pointer' : 'default',
              }}
              onClick={() => (vehicle?.driver_id ? navigate(`${DRIVERS}/${vehicle?.driver_id}`) : null)}
            >
              {vehicle?.driver_id
                ? vehicle?.driver_name ?? '-'
                : format(new Date(vehicle?.available_on), 'dd MMM yyyy')}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              Mileage
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {vehicle?.mileage?.toString() ?? '-'}
            </Text>
          </div>
          <div>
            <Text variant="body6" weight={500} color={PRIMARY_WHITE} block>
              Physical location
            </Text>
            <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
              {branches?.find((branch) => branch?.branch_id === vehicle?.physical_branch_id)?.branch_name ?? '-'}
            </Text>
          </div>
        </GridLayout>
        {[HEAVILY_DAMAGED, WRITE_OFF, UNINSURED].includes(vehicle.condition) && (
          <FlexLayout styled={{ marginTop: 20 }}>
            <Tag key="damaged" color={STATUS_RED}>
              {vehicle.condition}
            </Tag>
          </FlexLayout>
        )}
      </VehicleDetailsCard>
    </>
  );
};
