import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ORDERS } from '../../../../consts/routes';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Text } from '../../../../uiComponents/text/text';
import { Table } from '../../../../uiComponents/table/table';
import { driverApplicationColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import {
  TableTextCell,
  TableTagCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { getAllApplications } from '../../../../api/get/application.get';
import { Application } from '../../../../models/application';
import { DriverTableProps } from '../../../../models/driver';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { format } from 'date-fns';

interface DriverApplicationsProps extends DriverTableProps {
  tableHeader: string;
}

export const DriverApplications = ({ driverId, tableHeader }: DriverApplicationsProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const handleGetApplicationsResponse = useCallback(
    (count: number, applications: Application[]) => {
      const applicationRows = applications?.map((application: Application) => {
        return {
          rowData: { data: application },
          cells: [
            <TableTextCell value={application?.application_type ?? '-'} />,
            <TableTextCell
              value={application?.created_date ? format(new Date(application?.created_date), 'dd MMM yyyy') : '-'}
            />,
            <TableTextCell
              value={application?.updated_date ? format(new Date(application?.updated_date), 'dd MMM yyyy') : '-'}
            />,
            <TableTagCell tags={[application?.application_status] ?? []} />,
          ],
        };
      });
      setTableData(applicationRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchApplicationList = useCallback(
    (queryString: string) => {
      getAllApplications(queryString).then((response: { count: number; data: Application[] }) => {
        handleGetApplicationsResponse(response.count, response.data);
      });
    },
    [handleGetApplicationsResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString(tableFilters, rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchApplicationList(queryString);
    },
    [fetchApplicationList, setTableData, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('updated_date');
    fetchApplicationList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC&filter=driver_id$eq=${driverId}`);
    setTableFilters([{ columnName: 'driver_id', options: { label: driverId, value: driverId } }]);
  }, [fetchApplicationList, setSortingColumn, setTableFilters, driverId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          {tableHeader}
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          {tableHeader}
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={driverApplicationColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
          }}
          onRowClick={(application: { data: Application }) => {
            navigate(`${ORDERS}/${application?.data?.application_id}`);
          }}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
