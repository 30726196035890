import { Button } from '../../components/ui/button/button';
import { ColumnDef } from '@tanstack/react-table';
import { BsArrowDown, BsArrowUp, BsCircleFill, BsEye, BsPencil } from 'react-icons/bs';
import { Checkbox } from '../../components/ui/checkbox/checkbox';
import { Badge } from '../../components/ui/badge/badge';
import { ContractListItem, ContractStatus } from '../../models/contract';

enum BadgeColor {
  DRAFT = 'blue_light',
  ACTIVE = 'success',
  CANCELLED = 'error',
}

export const columns: ColumnDef<ContractListItem>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
        onCheckedChange={() => {
          if (table.getIsAllPageRowsSelected()) {
            table.toggleAllPageRowsSelected(false);
            return;
          }
          if (!table.getIsAllPageRowsSelected()) {
            table.toggleAllPageRowsSelected(true);
            return;
          }
          if (!table.getIsSomePageRowsSelected()) {
            table.toggleAllPageRowsSelected(true);
            return;
          }
        }}
        aria-label="Select all"
      />
    ),
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected() || table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'id',
    id: 'id',
    header: () => {
      return <div className="px-3.5 text-sm font-extrabold text-utility-gray-900">Contract ID</div>;
    },
    cell: ({ row }) => {
      return <span className="text-sm font-medium text-utility-gray-900">{row.getValue('id')}</span>;
    },
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Name
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'contract_type',
    id: 'contract_type',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Contract Type
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'contract_length',
    id: 'contract_length',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Contract Length
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'location',
    id: 'location',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Location
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'status',
    id: 'status',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Status
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <Badge shape="square" color={BadgeColor[row.getValue('status') as ContractStatus]}>
          <BsCircleFill size="6" />
          {row.getValue('status')}
        </Badge>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: () => {
      return <></>;
    },
    cell: ({ row, table }) => {
      const handleView = () => {
        if (table.options.meta?.viewData) {
          table.options.meta.viewData(row.original);
        }
      };
      const handleEdit = () => {
        if (table.options.meta?.editData) {
          table.options.meta.editData(row.original);
        }
      };

      return (
        <div className="inline-flex gap-x-[4px] items-center justify-end w-[100%]">
          <Button color="secondary" variant="ghost" onClick={handleView}>
            <BsEye className="h-4 w-4" />
          </Button>
          <Button color="secondary" variant="ghost" onClick={handleEdit}>
            <BsPencil className="h-4 w-4" />
          </Button>
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
];
