import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Notification } from '../../../uiComponents/toast/toast';
import { updateBatch } from '../../../api/patch/batches.patch';
import { VehicleBatch } from '../../../models/vehicle';
import { format } from 'date-fns';
interface EditBatchProps {
  values: VehicleBatch;
  onClose: (action: 'cancel' | 'submit') => void;
}

export const EditBatchForm = ({ values, onClose }: EditBatchProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: values,
  });
  const onSubmit = (submitValues: VehicleBatch) => {
    setLoading(true);
    const { available_on } = submitValues;

    const payload = {
      available_on,
    };
    updateBatch(values.id, payload)
      .then(() => {
        setLoading(false);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'The Batch has been successfully updated',
        });
        onClose('submit');
      })
      .catch(() => {
        setLoading(false);
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error updating batch',
        });
      });
  };
  useEffect(() => {
    setValue('available_on', format(new Date(values.available_on), 'yyyy-MM-dd'));
  }, [values, setValue]);
  return (
    <>
      <GridLayout template={1}>
        <TextField
          {...register('available_on', {
            required: 'Available on is a required field',
          })}
          required
          label="Available on"
          type="date"
          error={errors.available_on}
        />
        <FlexLayout itemsX="end" styled={{ marginTop: '20px' }}>
          <SecondaryButton disabled={loading} onClick={() => onClose('cancel')}>
            Cancel
          </SecondaryButton>
          <PrimaryButton isProcessing={loading} styled={{ marginLeft: '10px' }} onClick={handleSubmit(onSubmit)}>
            Submit
          </PrimaryButton>
        </FlexLayout>
      </GridLayout>
    </>
  );
};
