import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsPencil, BsCheck2Circle } from 'react-icons/bs';
import { CreateUpdateDriverTagsForm } from './forms/createUpdateDriverTags';
import { EditReasonForm } from './forms/editReasonForm';
import { TAG_ALL_IN_ORDER, TAG_LOW_RISK, TAG_NO_RISK, TAG_STATUS_RESOLVED } from '../../../../../consts/driver';
import { FINANCE, FULFILLMENT } from '../../../../../consts/department';
import { useTableFilters } from '../../../../../hooks/useTableFilters';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../../uiComponents/table/table';
import { driverTagColumns } from '../../../../../uiComponents/table/tableColumns/tableColumns';
import { TableTextCell, TableTagCell, getQueryString } from '../../../../../uiComponents/table/tableUtils/tableUtils';
import { getDriverTagsByDriverId, getTagTypesByCategory } from '../../../../../api/get/driver.get';
import { DriverTag, DriverTagType } from '../../../../../models/driver';
import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { handleAPIError, ErrorType } from '../../../../../utils/handleAPIError';
import { OptionList } from '../../../../../utils/props';
import { useAppSelector } from '../../../../../store-hooks';
import { format } from 'date-fns';

interface DriverTagsTableProps {
  driverId: string;
  fetch: (driverId: string) => Promise<void>;
}

export const DriverTagsTable = ({ driverId, fetch }: DriverTagsTableProps) => {
  const [isAssignModal, setIsAssignModal] = useState<boolean>(false);
  const [isEditReason, setIsEditReason] = useState<boolean>(false);
  const [isResolveModal, setIsResolveModal] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<DriverTag | null>(null);
  const driverTagOptionsRef = useRef<OptionList[]>([]);
  const pulseUser = useAppSelector((state) => state.pulseUser);

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const isPrivilegedUser: boolean =
    pulseUser?.super_admin || pulseUser?.department === FINANCE || pulseUser?.department === FULFILLMENT;

  const handleGetTagsResponse = useCallback(
    (tags: DriverTag[]) => {
      const negativeTags = tags?.filter(
        (tag: DriverTag) => ![TAG_ALL_IN_ORDER, TAG_NO_RISK, TAG_LOW_RISK].includes(tag.tag_name)
      );
      const tagsRows = negativeTags?.map((tag: DriverTag) => {
        const actionsDisabled: boolean = tag.resolution_status === TAG_STATUS_RESOLVED || !isPrivilegedUser;
        return {
          rowData: { data: tag },
          cells: [
            tag?.tag_name ? <TableTagCell tags={[tag?.tag_name.toUpperCase()]} /> : <TableTextCell value="-" />,
            <TableTextCell value={tag?.created_date ? format(new Date(tag?.created_date), 'dd MMM yyyy') : '-'} />,
            <TableTextCell value={tag?.date_resolved ? format(new Date(tag?.date_resolved), 'dd MMM yyyy') : '-'} />,
            <TableTextCell value={tag.tag_reason ?? '-'} />,
            <TableTextCell value={tag?.created_by ?? '-'} />,
            <TableTextCell value={tag?.resolved_by ?? '-'} />,
            tag?.resolution_status ? <TableTagCell tags={[tag?.resolution_status]} /> : <TableTextCell value="-" />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                disabled={actionsDisabled}
                icon={<BsPencil size={24} color={PRIMARY_PURPLE} />}
                tooltip="Edit reason"
                onClick={() => {
                  setIsEditReason(true);
                  setSelectedTag(tag);
                }}
              />
              <ActionIcon
                disabled={actionsDisabled}
                icon={<BsCheck2Circle size={24} color={PRIMARY_PURPLE} />}
                tooltip="Resolve tag"
                onClick={() => {
                  setIsResolveModal(true);
                  setSelectedTag(tag);
                }}
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(tagsRows);
      setTotalRows(tagsRows?.length);
    },
    [isPrivilegedUser, setTableData, setTotalRows]
  );

  const fetchData = useCallback(
    async (queryString: string) => {
      try {
        const response = await getTagTypesByCategory('DRIVER-TAG');
        if (response) {
          const fetchedTags = response.data.mutliSelectTagTypes;
          driverTagOptionsRef.current = fetchedTags?.map((tag: DriverTagType) => ({
            value: tag.id,
            label: tag.name,
          }));
        }
        if (driverId) {
          getDriverTagsByDriverId(queryString).then((response: { data: DriverTag[] }) => {
            if (response?.data) {
              handleGetTagsResponse(response.data);
            }
          });
        }
      } catch (err) {
        handleAPIError(err as ErrorType);
      }
    },
    [driverId, handleGetTagsResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString(tableFilters, rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchData(queryString);
    },
    [fetchData, setTableData, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('start_date');
    if (driverId) {
      fetchData(`${driverId}`);
    }
  }, [setSortingColumn, setTableFilters, driverId, fetchData]);

  return (
    <>
      <Table
        variant={isPrivilegedUser ? 'standard' : 'compact'}
        header=""
        actionButtonText={isPrivilegedUser ? 'Assign tag' : undefined}
        onActionButtonClick={isPrivilegedUser ? () => setIsAssignModal(true) : undefined}
        tableTheme="purple"
        embedded
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={driverTagColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={[]}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, sortingColumn, sortAscending);
        }}
      />
      <Modal
        styled={{ width: '40%', minWidth: 400 }}
        open={isAssignModal || isResolveModal}
        showClose
        onClose={() => {
          setIsAssignModal(false);
          setIsResolveModal(false);
          setSelectedTag(null);
        }}
      >
        <>
          {isAssignModal && (
            <CreateUpdateDriverTagsForm
              isCreate={true}
              driverId={driverId}
              title="Please assign driver tag(s)"
              dropDownOptions={driverTagOptionsRef.current}
              onFormSubmit={() => {
                setIsAssignModal(false);
                fetchData(`${driverId}`);
                fetch(driverId);
              }}
            />
          )}
          {isResolveModal && selectedTag && (
            <CreateUpdateDriverTagsForm
              driverTagId={selectedTag.driver_tag_id}
              title="Please supply a reason to resolve!"
              onFormSubmit={() => {
                setIsResolveModal(false);
                fetchData(`${driverId}`);
                fetch(driverId);
              }}
            />
          )}
        </>
      </Modal>
      {isEditReason && selectedTag && (
        <Modal
          title="Edit reason"
          styled={{ width: '75vw', minWidth: 400 }}
          open={isEditReason}
          showClose
          onClose={() => {
            setIsEditReason(false);
            setSelectedTag(null);
          }}
        >
          <EditReasonForm
            tag={selectedTag}
            onFormSubmit={() => {
              setIsEditReason(false);
              fetchData(`${driverId}`);
            }}
          />
        </Modal>
      )}
    </>
  );
};
