import React, { useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../../order/order';
import { SectionHeader } from './../sectionHeader';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { CustomerOptionSearch, CustomerOptionSearchForm } from './customerOptionSearchForm';
import { getFilters, TableFilter } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { useGetAllOrderAssignableVehiclesQuery } from '../../../../api/vehicle/vehicleSliceApi';
import { DashDataTable } from '../../../../components/tables/dash-table/dash-table';
import { getCoreRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { columns } from './assignableVehiclesTableColumns/assignableVehiclesTableColumns';
interface ModelProps extends SectionProps {
  onModelSelect: () => void;
}

export const CustomerOptionSection = ({ isComplete, isLocked, progress, checkProgress, onModelSelect }: ModelProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [queryStringState, setQueryStringState] = useState<string>();
  const [filters, setFilters] = React.useState<TableFilter[]>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [hideData, setHideData] = useState<boolean>(false);
  const {
    data: vehiclesList,
    isFetching: isVehiclesListFetching,
    isLoading: isVehiclesListLoading,
  } = useGetAllOrderAssignableVehiclesQuery(
    {
      query: queryStringState,
    },
    {
      skip: !queryStringState,
    }
  );

  const table = useReactTable({
    data: vehiclesList?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
    state: {
      sorting,
    },
  });

  const search = (values: CustomerOptionSearch) => {
    const filters: TableFilter[] = [];
    if (values.plan_type) {
      filters.push({
        columnName: 'contract_type_id',
        options: values.plan_type,
        clause: '$in',
      });
    }
    if (values.contract_length) {
      filters.push({
        columnName: 'contract_length',
        options: values.contract_length,
        clause: '$in',
      });
    }
    if (values.manufacturer) {
      filters.push({
        columnName: 'manufacturer_id',
        options: values.manufacturer,
        clause: '$in',
      });
    }
    if (values.model) {
      filters.push({
        columnName: 'model_id',
        options: values.model,
        clause: '$in',
      });
    }
    if (values.color) {
      filters.push({
        columnName: 'color_id',
        options: values.color,
        clause: '$in',
      });
    }
    if (values.fuel_type) {
      filters.push({
        columnName: 'fuel_type_id',
        options: values.fuel_type,
        clause: '$in',
      });
    }
    setFilters(filters);
  };

  useEffect(() => {
    const queryString = getFilters(filters, sorting[0]?.id, sorting[0]?.desc ? 'DESC' : 'ASC');
    setQueryStringState(queryString);
    if (!queryString) {
      setHideData(true);
    } else {
      setHideData(false);
    }
  }, [filters, sorting]);

  return (
    <>
      <CollapsiblePanel
        styled={{ marginTop: 16 }}
        locked={isLocked}
        header={
          <FlexLayout itemsY="center" gap={8}>
            <SectionHeader
              title="Customer options"
              completedText={`${progress?.manufacturer} ${progress?.model ?? ''}`}
              isComplete={isComplete}
              isLocked={isLocked}
              expanded={false}
            />
          </FlexLayout>
        }
        expanded={!isCollapsed}
        onCollapse={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <div>
          <FlexLayout gap={8} itemsY="center">
            <SectionHeader
              title="Customer options"
              completedText={`${progress?.manufacturer} ${progress?.model ?? ''}`}
              isComplete={isComplete}
              isLocked={isLocked}
              expanded
            />
          </FlexLayout>
          <CustomerOptionSearchForm onSearch={search} />
          {vehiclesList?.data && !hideData && (
            <div className="mt-4">
              <div className="bg-white rounded-t-xl border-[1px] border-utility-gray-200 py-5">
                <DashDataTable.Title
                  rowCount={vehiclesList?.count || 0}
                  data-testid="title"
                  className="max-sm:!pl-4 max-sm:!pt-5"
                >
                  Available vehicles
                </DashDataTable.Title>
                <p className="text-sm font-light text-utility-gray-600 px-6">Please choose one vehicle to assign</p>
              </div>
              <DashDataTable.Table
                table={table}
                columns={columns}
                isLoading={isVehiclesListLoading || isVehiclesListFetching}
              />
            </div>
          )}
        </div>
      </CollapsiblePanel>
    </>
  );
};
