import { apiSlice } from '../../api/baseApi';

type ConditionReportByIdRequest = {
  id: string;
  filters: string;
};

type RemovePhysicalLocationResponse = {
  data: {
    id: string;
  }[];
};

type RemovePhysicalLocationParams = {
  id: string;
};

export const vehicleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehicles2: builder.query({
      query: (queryParams) => `vehicle${queryParams && `?${queryParams}`}`,
    }),
    getConditionReportsByVehicleId: builder.query({
      query: (fetchArgs: ConditionReportByIdRequest) =>
        `condition-report/by/vehicle/${fetchArgs.id}${fetchArgs.filters && `?${fetchArgs.filters}`}`,
    }),
    removePhysicalLocationByVehicleId: builder.mutation<RemovePhysicalLocationResponse, RemovePhysicalLocationParams>({
      query: ({ id }) => ({
        url: `vehicle/remove/physical-location/${id}`,
        method: 'PATCH',
      }),
    }),
    getAllOrderAssignableVehicles: builder.query({
      query: ({ query }) => `vehicle/get-all-assignable-new${query && `?${query}`}`,
    }),
  }),
});

export const {
  useGetAllVehicles2Query,
  useGetConditionReportsByVehicleIdQuery,
  useRemovePhysicalLocationByVehicleIdMutation,
  useGetAllOrderAssignableVehiclesQuery,
} = vehicleApiSlice;
