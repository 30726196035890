import { useLocation } from 'react-router-dom';

export const EmbeddedReport = () => {
  const location = useLocation();
  return (
    <>
      {location.state && (
        <iframe className="w-full h-[calc(100svh-110px)]" src={location.state} title="Report"></iframe>
      )}
    </>
  );
};
