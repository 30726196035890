import React, { Fragment } from 'react';
import { ColoredStatus } from '../../drivers/driver/driverOrdwayDetails/utils/coloredStatus';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Text } from '../../../uiComponents/text/text';
import { OrdwayDetails } from '../../../models/driver';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { OrdwaySubscription, ProductPlan } from '../../../models/ordway';
import { format } from 'date-fns';

interface ConditionReportOrdwayDetailsProps {
  ordwayData?: OrdwayDetails;
  arrears: number;
}
export const ConditionReportOrdwayDetails = ({ ordwayData, arrears }: ConditionReportOrdwayDetailsProps) => {
  const subscriptionAmount: string | undefined = ordwayData?.billing_schedule_items?.unbilled_subscription_amount;
  const activeSubscriptions = ordwayData?.subscriptions?.filter((sub) => sub?.status === 'Active');
  const isInArrears: boolean = arrears > 0;

  return (
    <>
      <div>
        {(() => {
          return (
            <>
              {activeSubscriptions && activeSubscriptions?.length > 0 && (
                <>
                  <FlexLayout itemsY="center" gap={16}>
                    <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                      Active payment plans
                    </Text>
                  </FlexLayout>
                  {isInArrears && (
                    <Text variant="body7" weight={500} color={STATUS_RED}>
                      {`In arrears of £${ordwayData?.arrears ?? ''}`}
                    </Text>
                  )}

                  {activeSubscriptions?.map((activeSubscription: OrdwaySubscription) => (
                    <Fragment key={activeSubscription?.id}>
                      {activeSubscription?.plans?.map((activePlan: ProductPlan) => (
                        <FieldGrid
                          key={activePlan?.plan_id}
                          styled={{ margin: '16px 0' }}
                          numColumns={3}
                          headers={['Payment plan:', 'Total amount:', 'Outstanding amount:']}
                          values={[
                            activePlan?.plan_name || '-',
                            activeSubscription?.tcv ? `£${activeSubscription.tcv}` : '-',
                            `£${subscriptionAmount}` ?? '£0.00',
                          ]}
                        />
                      ))}
                    </Fragment>
                  ))}
                </>
              )}
              <FlexLayout itemsY="center" gap={16}>
                <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                  Account information
                </Text>
              </FlexLayout>
              <FieldGrid
                styled={{ margin: '16px 0' }}
                numColumns={3}
                headers={[
                  'Last invoice date:',
                  'Weekly amount:',
                  'Invoice status:',
                  'Subscription status:',
                  'Weekly additional charges:',
                ]}
                values={[
                  (ordwayData?.latest_invoice?.invoice_date &&
                    format(new Date(ordwayData?.latest_invoice.invoice_date), 'dd MMMM yyyy')) ||
                    '-',
                  `£${ordwayData?.subscriptions[0]?.Weekly_Rental_Less_EV_Assist}` || '0.00',
                  ordwayData?.latest_invoice?.status ? (
                    <ColoredStatus status={ordwayData?.latest_invoice?.status} />
                  ) : (
                    '-'
                  ),
                  <ColoredStatus status={ordwayData?.subscriptions[0]?.status ?? '-'} />,
                  `£${ordwayData?.additional_charges?.total}` || '0.00',
                ]}
              />
            </>
          );
        })()}
      </div>
    </>
  );
};
