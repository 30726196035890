import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// utils
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { APP_CONTEXT } from '../../../utils/context';
import { aftercareContactListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
// api
// models
import { AftercareContact } from '../../../models/aftercare';
// styles
import { PRIMARY_PURPLE, STATUS_ORANGE } from '../../../common/styles/Colors';
// components
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { BsClipboard, BsEye, BsPhoneVibrate } from 'react-icons/bs';
import { Table } from '../../../uiComponents/table/table';
import { Modal } from '../../../uiComponents/modals/modal';
import { AftercareSetupForm } from '../aftercareSetup/aftercareSetup';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { CallbackForm } from './callbackForm/callbackForm';
// contants
import { AFTERCARE_CONTACT_LIST, AGREEMENTS } from '../../../consts/routes';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { aftercareStatusListOptions } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { OptionList } from '../../../utils/props';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useGetAndMergeContactListQuery } from '../../../api/listAndMerge/listAndMergeAftercareContactApiSlice';
import { format } from 'date-fns';

export const ContactList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [activeAftercare, setActiveAftercare] = useState<AftercareContact | null>(null);
  const [isCallbackModalOpen, setIsCallbackModalOpen] = useState<boolean>(false);
  const [isAftercareSetupModalOpen, setIsAftercareSetupModalOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=end_date:ASC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: contactList,
    isLoading: isContactListLoading,
    isFetching: isContactListFetching,
    refetch,
  } = useGetAndMergeContactListQuery({ query: queryStringState, refetch: refetchData });

  const filters: FilterItem[] = [
    {
      name: 'agreement_status',
      element: (
        <DropDownFilter
          name="agreement_status"
          placeholder="Status"
          options={aftercareStatusListOptions}
          multiValues={statusFilter}
          title="Status"
          onChange={(items) => setStatusFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  const handleGetAftercareContactListResponse = useCallback(
    (count: number, data: AftercareContact[]) => {
      const aftercareRows = data?.map((aftercareItem: AftercareContact) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell value={aftercareItem?.driver_mobile_phone} />,
            <TableTextCell
              value={aftercareItem?.end_date ? format(new Date(aftercareItem?.end_date), 'dd MMM yyyy') : '-'}
            />,
            <FlexLayout itemsX="start">
              {aftercareItem.contact_request_exists && (
                <Tag key={`aftercare_agreement_status_${aftercareItem.agreement_id}`} color={STATUS_ORANGE}>
                  Callback requested
                </Tag>
              )}
            </FlexLayout>,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() =>
                  navigate(`${AGREEMENTS}/${aftercareItem.agreement_id}`, { state: AFTERCARE_CONTACT_LIST })
                }
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View agreement page"
              />
              <ActionIcon
                onClick={() => {
                  setActiveAftercare(aftercareItem);
                  setIsAftercareSetupModalOpen(true);
                }}
                icon={<BsClipboard size={24} color={PRIMARY_PURPLE} />}
                tooltip="Aftercare setup"
              />
              <ActionIcon
                disabled={true}
                onClick={() => {
                  setActiveAftercare(aftercareItem);
                  setIsCallbackModalOpen(true);
                }}
                icon={<BsPhoneVibrate size={24} color={PRIMARY_PURPLE} />}
                tooltip="Call back"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, navigate]
  );

  const contactListColumns = useMemo(() => [...aftercareContactListColumns], []);

  useEffect(() => {
    setActiveSideNav('aftercareContactList');
    setPageTitle('Aftercare');
    setSortingColumn('end_date');
  }, [setActiveSideNav, setPageTitle, setSortingColumn]);

  useEffect(() => {
    if (!statusFilter) return;
    setTableFilters([
      { columnName: 'contact_status', options: statusFilter },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, statusFilter]);

  useEffect(() => {
    if (contactList) {
      handleGetAftercareContactListResponse(contactList.count, contactList.data);
    }
  }, [contactList, queryStringState, handleGetAftercareContactListResponse]);

  const onClearClick = useCallback(() => {
    setStatusFilter([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });

    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    goToPageNumber(0);
    onClearClick();
  }, [goToPageNumber, onClearClick, refetch, defaultString, queryStringState]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isContactListLoading || isContactListFetching}
        header="Contact list"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        sortAscending={sortAscending}
        columns={contactListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        filters={filters}
        disableApply={invalidDates}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: AftercareContact }) => {
          navigate(`${AGREEMENTS}/${data.agreement_id}`, { state: AFTERCARE_CONTACT_LIST });
        }}
      />
      {activeAftercare && (
        <>
          <Modal
            open={isAftercareSetupModalOpen}
            title="Aftercare setup"
            showClose
            onClose={() => setIsAftercareSetupModalOpen(false)}
          >
            <AftercareSetupForm
              aftercare={activeAftercare}
              onClose={() => setIsAftercareSetupModalOpen(false)}
              isInEdit={false}
              onSubmit={() => {
                refetchToFirstPage();
              }}
            />
          </Modal>
          <Modal
            title="Callback request"
            open={isCallbackModalOpen}
            showClose
            onClose={() => setIsCallbackModalOpen(false)}
          >
            <CallbackForm
              aftercare={activeAftercare}
              onSubmit={() => refetchToFirstPage()}
              onClose={() => setIsCallbackModalOpen(false)}
            />
          </Modal>
        </>
      )}
    </>
  );
};
