import { useNavigate } from 'react-router-dom';
import { FiShoppingBag } from 'react-icons/fi';
import { NavContainer, Separator, TextLink } from './sideNav.styles';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_WHITE } from '../../../common/styles/Colors';
import {
  AGREEMENTS,
  ASSESSMENTS,
  DRIVERS,
  ORDERS,
  PROSPECTIVE_DRIVERS,
  REPORTS,
  VEHICLES,
} from '../../../consts/routes';

export const SideNav = () => {
  const navigate = useNavigate();

  return (
    <NavContainer>
      <FiShoppingBag size={24} color={PRIMARY_WHITE} />
      <Text color={PRIMARY_WHITE} variant="h4" weight={800} block>
        Sales
      </Text>
      <Separator />
      <TextLink onClick={() => navigate(DRIVERS)} color={PRIMARY_WHITE} weight={300} variant="body6" block>
        Drivers
      </TextLink>
      <TextLink
        onClick={() => navigate(AGREEMENTS)}
        styled={{ padding: '16px 0' }}
        color={PRIMARY_WHITE}
        weight={300}
        variant="body6"
        block
      >
        Agreements
      </TextLink>
      <TextLink onClick={() => navigate(VEHICLES)} color={PRIMARY_WHITE} weight={300} variant="body6" block>
        Vehicles
      </TextLink>
      <Separator />
      <TextLink onClick={() => navigate(REPORTS)} color={PRIMARY_WHITE} weight={300} variant="body6" block>
        Dashboard
      </TextLink>
      <TextLink
        onClick={() => navigate(PROSPECTIVE_DRIVERS)}
        color={PRIMARY_WHITE}
        weight={300}
        variant="body6"
        block
        styled={{ paddingTop: 16 }}
      >
        Prospective drivers
      </TextLink>
      <TextLink
        onClick={() => navigate(ASSESSMENTS)}
        color={PRIMARY_WHITE}
        weight={300}
        variant="body6"
        block
        styled={{ paddingTop: 16 }}
      >
        Assessments
      </TextLink>
      <TextLink
        styled={{ paddingTop: 16 }}
        onClick={() => navigate(ORDERS)}
        color={PRIMARY_WHITE}
        weight={300}
        variant="body6"
        block
      >
        Orders
      </TextLink>
    </NavContainer>
  );
};
