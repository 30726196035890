import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { BsExclamationCircle } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AnswerText,
  CodeName,
  DVLAContainer,
  DvlaInput,
  HowDidYouHearAboutUs,
  InputLabel,
  PanelHeader,
  PenaltyInputContainer,
  SectionContainer,
  SelectedPenaltyCodes,
} from './createNewDriver.styles';
import { DriverDetails } from '../sections/driverDetails';
import { EligibilityCheck } from '../sections/eligibilityCheck';
import { LiabilityQuestionnaire } from '../sections/liabilityQuestionnaire';
import { DvlaInputDiv, SearchButton } from '../../../orders/order/order.styles';
import { getDriverByDVLA } from '../../../../api/get/driver.get';
import { submitInitialDriverDetails } from '../../../../api/post/driver.post';
import { penaltyColors } from '../../../../common/utils';
import { PRIMARY_GREEN, PRIMARY_PURPLE, SECONDARY_PURPLE_30, STATUS_RED } from '../../../../common/styles/Colors';
import { penaltyCodes, signonSources } from '../../../../consts/driver';
import { DRIVERS, PROSPECTIVE_DRIVERS } from '../../../../consts/routes';
import { COMPLETE_STATUS } from '../../../../consts/status';
import { PageContainer } from '../../../../core/coreStyles';
import { CustomerProfile, DriverAssessmentEligibility } from '../../../../models/driver';
import { Questionnaire } from '../../../../models/assessment';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Tag } from '../../../../uiComponents/customComponents/tag/tag';
import { TextInput } from '../../../../uiComponents/inputs/textInput/textInput';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Text } from '../../../../uiComponents/text/text';
import { Checkbox } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { ErrorType, handleAPIError } from '../../../../utils/handleAPIError';
import { dvlaFormat } from '../../../../utils/validations';
import { format } from 'date-fns';

export const CreateNewDriver = () => {
  const location = useLocation();
  const { dvla_no: dvla_no_to_edit } = location?.state ?? {};
  const navigate = useNavigate();
  const [dvlaDetailsRetrieved, setDvlaDetailsRetrieved] = useState<boolean>(false);
  const [isDvlaNoValid, setIsDvlaNoValid] = useState<boolean>(false);
  const [driverLoading, setDriverLoading] = useState<boolean>(false);
  const [defaultEligibilityAnswers, setDefaultEligibilityAnswers] = useState<boolean[] | null | undefined>(null);
  const [defaultLiabilityAnswers, setDefaultLiabilityAnswers] = useState<boolean[] | null | undefined>(null);
  const [driverDetailsExpanded, setDriverDetailsExpanded] = useState<boolean>(false);
  const [eligibilityExpanded, setEligibilityExpanded] = useState<boolean>(false);
  const [liabilityExpanded, setLiabilityExpanded] = useState<boolean>(false);
  const [isExisitingDriverModal, setIsExisitingDriverModal] = useState<boolean>(false);
  const [isDriverProfileStatusComplete, setIsDriverProfileStatusComplete] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const {
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CustomerProfile>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { dvla_no: '' },
  });

  const selectedPenaltyCodes: string[] = watch('penalty_codes') ?? [];
  const isDvlaClean: boolean | undefined = watch('dvla_clean');
  const numPoints: number | '' | undefined = watch('no_dvla_points');
  const dvlaNo: string | undefined = watch('dvla_no');

  const dvlaPenaltySectionCompleted: boolean =
    isDvlaClean || (selectedPenaltyCodes?.length > 0 && numPoints !== '' && numPoints != null);

  const isDriverCreationBlocked = () => {
    const redPenaltiesNo = selectedPenaltyCodes.filter(
      (penaltyCode) => penaltyColors[penaltyCode] === STATUS_RED
    ).length;
    return redPenaltiesNo > 0 || (numPoints as number) >= 7;
  };

  const blockDriverCreation: boolean = isDriverCreationBlocked();

  // Driver details
  const driverId: string | undefined = watch('id');
  const title: string = watch('title') || '';
  const firstName: string = watch('first_name') || '';
  const lastName: string = watch('last_name') || '';
  const dateOfBirth: string = watch('date_of_birth') || '';
  const email: string = watch('email') || '';
  const callingCode: string = watch('no_area_code') || '';
  const mobileNo: string = watch('mobile_phone') || '';
  const addressLine1: string | undefined = watch('address_line_1');
  const postCode: string | undefined = watch('address_post_code');
  const city: string | undefined = watch('address_city') || '';
  const country: string = watch('country_id') || '';
  const ethnicity: string = watch('ethnicity') || '';
  const niNumber: string = watch('national_insurance_number') || '';
  const signonSource: string | undefined = watch('signon_source');

  // Driver details validations
  const dateOfBirthValid: boolean = dateOfBirth !== '' && !errors?.date_of_birth;
  const emailValid: boolean = email !== '';
  const mobileNoValid: boolean = mobileNo !== '';
  const addressLine1Valid: boolean = addressLine1 !== '' && !errors?.address_line_1;
  const niNumberValid: boolean = niNumber !== '' && !errors?.national_insurance_number;

  // Liability
  const insulin: Questionnaire = watch('insulin');
  const glasses: Questionnaire = watch('glasses');
  const epilepsy: Questionnaire = watch('epilepsy');
  const medicalCondtion: Questionnaire = watch('medicalCondition');
  const anxietyDepression: Questionnaire = watch('anxietyDepression');
  const sleepDisorder: Questionnaire = watch('sleepDisorder');
  const involvedAccident: Questionnaire = watch('involvedAccident');
  const motoringOffences: Questionnaire = watch('motoringOffences');
  const criminalConvictions: Questionnaire = watch('criminalConvictions');

  // Eligibility
  const newPcoDriver: Questionnaire = watch('pco_new');
  const driverUnder28over65: Questionnaire = watch('under_28_or_over_65');
  const hasFaultAccident: Questionnaire = watch('fault_accident');
  const moreThan3Points: Questionnaire = watch('more_than_3_points');
  const automaticExperience: Questionnaire = watch('automatic_car');
  const assessmentType: DriverAssessmentEligibility = watch('assessment_type');

  const driverSectionCompleted =
    dvlaDetailsRetrieved &&
    title !== '' &&
    firstName !== '' &&
    lastName !== '' &&
    dateOfBirthValid &&
    emailValid &&
    callingCode &&
    mobileNoValid &&
    addressLine1Valid &&
    postCode !== '' &&
    city !== '' &&
    country &&
    ethnicity !== '' &&
    niNumberValid;

  const eligibilityCompleted: boolean =
    !!newPcoDriver && !!driverUnder28over65 && !!hasFaultAccident && !!moreThan3Points && !!automaticExperience;

  const liabilityCompleted: boolean =
    !!insulin &&
    !!glasses &&
    !!epilepsy &&
    !!medicalCondtion &&
    !!anxietyDepression &&
    !!sleepDisorder &&
    !!involvedAccident &&
    !!motoringOffences &&
    !!criminalConvictions;

  const onlineAssessmentRecommended: boolean =
    !!eligibilityCompleted &&
    newPcoDriver === 'no' &&
    driverUnder28over65 === 'no' &&
    hasFaultAccident === 'no' &&
    moreThan3Points === 'no' &&
    automaticExperience === 'no';

  const practicalAssessmentRecommended: boolean =
    !!eligibilityCompleted &&
    (newPcoDriver === 'yes' ||
      driverUnder28over65 === 'yes' ||
      hasFaultAccident === 'yes' ||
      moreThan3Points === 'yes' ||
      automaticExperience === 'yes');

  const resetFields = useCallback(() => {
    reset({
      driver_id: '',
      title: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      email: '',
      no_area_code: '',
      mobile_phone: '',
      address_line_1: '',
      address_line_2: '',
      address_post_code: '',
      address_city: '',
      country_id: '',
      ethnicity: '',
      national_insurance_number: '',
      signon_source: '',
      no_dvla_points: '',
      dvla_clean: false,
    });
    setDefaultLiabilityAnswers(null);
    setDefaultEligibilityAnswers(null);
  }, [reset, setDefaultLiabilityAnswers, setDefaultEligibilityAnswers]);

  const populateFields = useCallback(
    async (profile: CustomerProfile) => {
      setValue('id', profile?.id);
      setValue('pco_new', profile?.eligibility_questionnaire_responses?.[0] ? 'yes' : 'no');
      setValue('pco_6_months', profile?.eligibility_questionnaire_responses?.[1] ? 'yes' : 'no');
      setValue('under_28_or_over_65', profile?.eligibility_questionnaire_responses?.[2] ? 'yes' : 'no');
      setValue('fault_accident', profile?.eligibility_questionnaire_responses?.[3] ? 'yes' : 'no');
      setValue('more_than_3_points', profile?.eligibility_questionnaire_responses?.[4] ? 'yes' : 'no');
      setValue('automatic_car', profile?.eligibility_questionnaire_responses?.[5] ? 'yes' : 'no');
      setValue('penalty_codes', profile?.penalty_codes);
      setValue('no_dvla_points', profile?.no_dvla_points);
      setValue('dvla_clean', +(profile?.no_dvla_points ?? '-1') === 0 && profile?.penalty_codes?.length == 0);
      setValue('driver_id', profile?.id);
      setValue('title', profile?.title ?? '');
      setValue('first_name', profile?.first_name ?? '');
      setValue('middle_name', profile?.middle_name ?? '');
      setValue('last_name', profile?.last_name ?? '');
      setValue('date_of_birth', profile?.date_of_birth ? format(new Date(profile?.date_of_birth), 'yyyy-MM-dd') : '');
      setValue('email', profile?.email ?? '');
      setValue('no_area_code', profile?.no_area_code ?? '');
      setValue('mobile_phone', profile?.mobile_phone ?? '');
      setValue('address_line_1', profile?.address_line_1 ?? '');
      setValue('address_line_2', profile?.address_line_2 ?? '');
      setValue('address_post_code', profile?.address_post_code ?? '');
      setValue('address_city', profile?.address_city ?? '');
      setValue('country_id', profile?.country_id ?? '');
      setValue('ethnicity', profile?.ethnicity ?? '');
      setValue('national_insurance_number', profile?.national_insurance_number ?? '');
      setValue('signon_source', profile?.signon_source ?? '');
      setDefaultEligibilityAnswers(profile?.eligibility_questionnaire_responses);
      setDefaultLiabilityAnswers(profile?.liability_questionnaire_responses);
      setDvlaDetailsRetrieved(true);
    },
    [setValue]
  );

  const getDriverByDvlaNumber = useCallback(
    (value: string) => {
      setDvlaDetailsRetrieved(false);
      setDriverLoading(true);
      getDriverByDVLA(value).then(
        (response: { data: CustomerProfile; message: undefined } | { message: 'OK'; data: undefined }) => {
          if (response?.data) {
            populateFields(response.data);
            setIsExisitingDriverModal(true);
            setIsDriverProfileStatusComplete(response.data.driver_profile_status === COMPLETE_STATUS);
            setDriverLoading(false);
          } else {
            resetFields();
            setDriverLoading(false);
          }
          setDvlaDetailsRetrieved(true);
        }
      );
    },
    [populateFields, resetFields]
  );

  const onBlurGetDriverByDVLA = async (value: string) => {
    if (!value) {
      return;
    }
    getDriverByDvlaNumber(value);
  };

  useEffect(() => {
    if (dvla_no_to_edit != null) {
      setValue('dvla_no', dvla_no_to_edit);
      setIsDvlaNoValid(true);
      getDriverByDvlaNumber(dvla_no_to_edit);
    }
  }, [dvla_no_to_edit, getDriverByDvlaNumber, setValue]);

  const checkForErrors = () => {
    if (
      errors?.first_name ||
      errors?.last_name ||
      errors?.email ||
      errors?.no_area_code ||
      errors?.mobile_phone ||
      errors?.address_line_1 ||
      errors?.address_post_code ||
      errors?.address_city ||
      errors?.country_id ||
      errors?.ethnicity ||
      errors?.national_insurance_number
    ) {
      setDriverDetailsExpanded(true);
    }

    if (
      errors?.insulin ||
      errors?.glasses ||
      errors?.epilepsy ||
      errors?.medicalCondition ||
      errors?.anxietyDepression ||
      errors?.sleepDisorder ||
      errors?.involvedAccident ||
      errors?.motoringOffences ||
      errors?.criminalConvictions
    ) {
      setLiabilityExpanded(true);
    }

    if (
      errors?.pco_6_months ||
      errors?.pco_new ||
      errors?.under_28_or_over_65 ||
      errors?.fault_accident ||
      errors?.more_than_3_points ||
      errors?.automatic_car ||
      errors?.assessment_type ||
      errors?.notes
    ) {
      setEligibilityExpanded(true);
    }
  };

  const onSubmit = async () => {
    try {
      const payload = {
        ...getValues(),
        penalty_codes: isDvlaClean ? [] : selectedPenaltyCodes,
        no_dvla_points: isDvlaClean ? '' : numPoints,
        address_country: 'United Kingdom',
      };
      setSubmitLoading(true);
      const { data } = await submitInitialDriverDetails(payload);
      data.driver_id
        ? Notification({
            type: 'success',
            title: 'Success',
            message: `Driver has been successfully ${data?.is_driver_new ? 'created' : 'updated'}`,
          })
        : Notification({ type: 'error', title: 'Error', message: 'Failed to create new driver' });
      setSubmitLoading(false);
      navigate(PROSPECTIVE_DRIVERS);
    } catch (err) {
      setSubmitLoading(false);
      handleAPIError(err as ErrorType);
    }
  };

  return (
    <PageContainer>
      <FlexLayout styled={{ marginTop: 16, marginBottom: 32 }} itemsY="start" gap={16}>
        <DVLAContainer>
          <InputLabel $light>DVLA number</InputLabel>
          <DvlaInputDiv>
            <DvlaInput
              placeholder="DVLA number"
              error={errors.dvla_no}
              {...register('dvla_no', {
                required: 'DVLA is a required field.',
                pattern: { value: dvlaFormat, message: 'Must be DVLA format e.g. AAAAA111111BB2CC' },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.toUpperCase();
                  const value: string = e.target.value;
                  const isValid: boolean = dvlaFormat?.test(value);
                  setIsDvlaNoValid(isValid);

                  if (!isValid) {
                    setDvlaDetailsRetrieved(false);
                  }
                },
                onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => {
                  e.target.value = e.target.value.toUpperCase();
                  dvlaFormat.test(e.target.value) && onBlurGetDriverByDVLA(e.target.value);
                },
              })}
              value={dvlaNo}
            />
            {driverLoading ? (
              <Spinner styled={{ position: 'absolute', right: 24, top: 13 }} size={16} color={PRIMARY_PURPLE} />
            ) : (
              <SearchButton itemsX="center" itemsY="center">
                <FiSearch color={PRIMARY_GREEN} size={24} />
              </SearchButton>
            )}
          </DvlaInputDiv>
          <InputLabel $light>DVLA points</InputLabel>
          <PrimaryButton
            isGreen
            onClick={() =>
              window.open('https://www.viewdrivingrecord.service.gov.uk/driving-record/licence-number', '_blank')
            }
          >
            Go to DVLA platform
          </PrimaryButton>
          <PenaltyInputContainer gap={8}>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              options={penaltyCodes}
              placeholder="Penalties"
              name="penalty_codes"
              multiSelect
              multiSelectCount={selectedPenaltyCodes?.length}
              error={errors?.penalty_codes}
              disabled={isDvlaClean}
            />
            <TextInput
              disabled={isDvlaClean}
              styled={{ minWidth: 120, width: 120 }}
              placeholder="Points"
              {...register('no_dvla_points')}
              value={numPoints}
            />
          </PenaltyInputContainer>
          <SelectedPenaltyCodes>
            {selectedPenaltyCodes?.map((code) => (
              <Tag key={code} color={penaltyColors[code]} styled={{ margin: 4 }}>
                <FlexLayout itemsY="center" styled={{ margin: '2px 4px' }}>
                  <BsExclamationCircle size={16} color={penaltyColors[code]} />
                  <CodeName>{code}</CodeName>
                </FlexLayout>
              </Tag>
            ))}
          </SelectedPenaltyCodes>
          <FlexLayout itemsY="center" gap={8} styled={{ marginTop: 16 }}>
            <Checkbox
              {...register('dvla_clean')}
              disabled={blockDriverCreation}
              onCheck={() => {
                setValue('no_dvla_points', undefined);
                setValue('penalty_codes', undefined);
              }}
            />
            <Text variant="body6" weight={500} color={SECONDARY_PURPLE_30}>
              This driver has no penalties, points or offences.
            </Text>
          </FlexLayout>
        </DVLAContainer>
        <SectionContainer
          $disabled={
            !dvlaDetailsRetrieved ||
            !isDvlaNoValid ||
            !dvlaPenaltySectionCompleted ||
            blockDriverCreation ||
            isDriverProfileStatusComplete
          }
        >
          <CollapsiblePanel
            expanded={eligibilityExpanded}
            completed={(eligibilityCompleted && assessmentType != null) || isDriverProfileStatusComplete}
            header={
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                Eligibility check
              </Text>
            }
            onCollapse={(collapsed: boolean) => setEligibilityExpanded(collapsed)}
          >
            <>
              {dvlaDetailsRetrieved && (
                <EligibilityCheck
                  register={register}
                  savedAnswers={defaultEligibilityAnswers}
                  onlineAssessmentRecommended={onlineAssessmentRecommended ?? false}
                  practicalAssessmentRecommended={practicalAssessmentRecommended ?? false}
                  assessmentType={assessmentType}
                  errors={errors}
                />
              )}
            </>
          </CollapsiblePanel>
          <CollapsiblePanel
            expanded={driverDetailsExpanded}
            completed={!!driverSectionCompleted}
            header={
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                Associated driver details
              </Text>
            }
            onCollapse={(collapsed: boolean) => {
              setDriverDetailsExpanded(collapsed);
            }}
            styled={{ marginTop: 16 }}
          >
            <DriverDetails
              errors={errors}
              register={register}
              setError={(name, msg) => setError(name, msg)}
              clearErrors={(name) => clearErrors(name)}
              getValues={() => getValues()}
              setValue={(name, value) => setValue(name, value)}
              control={control}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            styled={{ marginTop: 16 }}
            expanded={liabilityExpanded}
            completed={liabilityCompleted}
            header={
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                Liability questionnaire
              </Text>
            }
            onCollapse={(collapsed: boolean) => {
              setLiabilityExpanded(collapsed);
            }}
          >
            <>
              {dvlaDetailsRetrieved && (
                <LiabilityQuestionnaire register={register} savedAnswers={defaultLiabilityAnswers} errors={errors} />
              )}
            </>
          </CollapsiblePanel>
          <HowDidYouHearAboutUs>
            <PanelHeader>How did you hear about us?</PanelHeader>
            <FlexLayout wrap gap={16} styled={{ marginTop: 40 }}>
              {signonSources?.map((option) => (
                <FlexLayout key={option.value} wrap itemsY="center">
                  <RadioButton
                    id={option.value}
                    {...register('signon_source', {
                      required: 'Please select a sign on source',
                    })}
                    disabled={false}
                    value={option.value}
                  />
                  <AnswerText htmlFor={option.value}>{option.label}</AnswerText>
                </FlexLayout>
              ))}
            </FlexLayout>
            {errors?.signon_source && (
              <Text variant="body8" weight={300} color={STATUS_RED}>
                {errors?.signon_source?.message as string}
              </Text>
            )}
          </HowDidYouHearAboutUs>
          <FlexLayout itemsX="end" styled={{ marginTop: 27, height: 100 }}>
            <PrimaryButton
              styled={{ minWidth: 186, height: 40 }}
              disabled={
                !(
                  driverSectionCompleted &&
                  liabilityCompleted &&
                  eligibilityCompleted &&
                  !!assessmentType &&
                  signonSource != null
                )
              }
              onClick={handleSubmit(onSubmit, checkForErrors)}
            >
              {submitLoading ? <Spinner size={20} color={PRIMARY_PURPLE} /> : 'Submit'}
            </PrimaryButton>
          </FlexLayout>
        </SectionContainer>
      </FlexLayout>
      {isExisitingDriverModal && isDriverProfileStatusComplete && (
        <ConfirmationModal
          title="This driver already exists!"
          isOpen={isExisitingDriverModal}
          onClose={() => setIsExisitingDriverModal(false)}
          onConfirm={() => navigate(`${DRIVERS}/${driverId}`)}
          closeButtonCaption="Cancel"
          confirmButtonCaption={'View driver information page'}
        />
      )}
    </PageContainer>
  );
};
