import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Option } from '../../../../components/ui/select/select';
import {
  useGetContractLengthListQuery,
  useGetContractTypeListQuery,
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
} from '../../../../api/contracts/contractsDetailsApiSlice';
import { SelectInput } from '../../../../components/inputs/select-input/select-input';
import {
  ContractLengthListItem,
  ContractTypeListItem,
  ManufacturerListItem,
  ModelListItem,
} from '../../../../models/contract';
import { Button } from '../../../../components/ui/button/button';
import { useGetColorsListQuery, useGetFuelListQuery } from '../../../../api/vehicleModel/vehicleModelApiSlice';
import { useEffect, useState } from 'react';

const optionSchema = z.object({
  value: z.string().min(1, 'Value is required'),
  label: z.string().min(1, 'Label is required'),
  info: z.string().optional(),
  node: z.any().optional(),
});

const customerOptionsFormSchema = z.object({
  plan_type: z.array(optionSchema).nonempty('Manufacturer is required'),
  contract_length: z.array(optionSchema).optional(),
  manufacturer: z.array(optionSchema).optional(),
  model: z.array(optionSchema).optional(),
  color: z.array(optionSchema).optional(),
  fuel_type: z.array(optionSchema).optional(),
});
export type CustomerOptionsFormType = z.infer<typeof customerOptionsFormSchema>;

export interface CustomerOptionSearch {
  plan_type: Option[] | undefined;
  contract_length: Option[] | undefined;
  manufacturer: Option[] | undefined;
  model: Option[] | undefined;
  color: Option[] | undefined;
  fuel_type: Option[] | undefined;
}

export interface CustomerOptionSearchFormProps {
  defaultValues?: CustomerOptionsFormType;
  onSearch: (values: CustomerOptionSearch) => void;
}

export const CustomerOptionSearchForm = ({ defaultValues, onSearch }: CustomerOptionSearchFormProps) => {
  const { data: contractTypeList } = useGetContractTypeListQuery({});
  const { data: contractLengthList } = useGetContractLengthListQuery({});
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: modelList } = useGetVehicleModelListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: colorsList } = useGetColorsListQuery({});
  const [contractLengthOptions, setContractLengthOptions] = useState<Option[]>([]);
  const [manufacturerOptions, setManufacturerOptions] = useState<Option[]>([]);
  const [vehicleModelOptions, setVehicleModelOptions] = useState<Option[]>([]);
  const [colorsOptions, setColorsOptions] = useState<Option[]>([]);
  const [fuelTypeOptions, setFuelTypeOptions] = useState<Option[]>([]);

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<CustomerOptionsFormType>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: zodResolver(customerOptionsFormSchema),
  });

  const planType = watch('plan_type');
  const manufacturers = watch('manufacturer');
  const model = watch('model');
  const fuelType = watch('fuel_type');
  const color = watch('color');

  useEffect(() => {
    if (contractLengthList?.data) {
      const allLengths: number[] = contractLengthList?.data?.flatMap(
        (item: ContractLengthListItem) => item.contract_lengths
      );
      const distinctLengths = [...new Set(allLengths)]?.map((item: number) => {
        return {
          value: item?.toString(),
          label: item?.toString(),
        };
      });
      setContractLengthOptions(distinctLengths);
    }
    if (manufacturerList?.data) {
      setManufacturerOptions(
        manufacturerList?.data?.map((item: ManufacturerListItem) => {
          return { value: item.id, label: item.name };
        })
      );
    }
    if (modelList?.data) {
      setVehicleModelOptions(
        modelList?.data?.map((item: ModelListItem) => {
          return { value: item.id, label: item.model };
        })
      );
    }
    if (colorsList?.data) {
      setColorsOptions(
        colorsList?.data?.map((item: { id: string; name: string }) => {
          return { value: item.id, label: item.name };
        })
      );
    }
    if (fuelList?.data) {
      setFuelTypeOptions(
        fuelList?.data?.map((item: { id: string; name: string }) => {
          return { value: item.id, label: item.name };
        })
      );
    }
  }, [contractLengthList?.data, manufacturerList?.data, modelList?.data, colorsList?.data, fuelList?.data]);

  useEffect(() => {
    if (contractLengthList?.data && planType && planType.length > 0) {
      const availableContractLenths = contractLengthList?.data.find(
        (item: ContractLengthListItem) => item.contract_type_id === planType[0]?.value
      )?.contract_lengths;

      setContractLengthOptions(
        availableContractLenths?.map((item: number) => {
          return {
            value: item?.toString(),
            label: item?.toString(),
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planType]);

  useEffect(() => {
    if (manufacturerList?.data && modelList?.data && colorsList?.data && fuelList?.data) {
      const selectedManufacturersList = manufacturers?.map((el) => el.value) || [];
      const selectedVehicleModelsList = model?.map((item) => item.value) || [];
      const selectedFuelTypesList = fuelType?.map((item) => item.label) || [];
      const selectedColorsList = color?.map((item) => item.label) || [];
      if (manufacturers && manufacturers.length > 0) {
        const availableVehicleModels = modelList.data?.filter((item: ModelListItem) =>
          selectedManufacturersList.includes(item.manufacturer_id)
        );
        const availableColors = availableVehicleModels.flatMap((item: ModelListItem) => item.exterior_colors);
        const availableFuelTypes = availableVehicleModels.flatMap((item: ModelListItem) => item.fuel_types);
        setVehicleModelOptions(
          availableVehicleModels?.map((item: ModelListItem) => {
            return { value: item.id, label: item.model };
          })
        );
        setColorsOptions(
          colorsList.data
            ?.filter((item: { id: string; name: string }) => availableColors.includes(item.name))
            ?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
        );
        setFuelTypeOptions(
          fuelList.data
            ?.filter((item: { id: string; name: string }) => availableFuelTypes.includes(item.name))
            ?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
        );
      }

      if (model && model.length > 0 && (!manufacturers || manufacturers.length === 0)) {
        const availableVehicleModels = modelList.data?.filter((item: ModelListItem) =>
          selectedVehicleModelsList.includes(item.id)
        );
        const availableManufacturers = manufacturerList?.data?.filter((item: ManufacturerListItem) =>
          availableVehicleModels?.map((item: ModelListItem) => item.manufacturer_id).includes(item.id)
        );
        const availableColors = availableVehicleModels.flatMap((item: ModelListItem) => item.exterior_colors);
        const availableFuelTypes = availableVehicleModels.flatMap((item: ModelListItem) => item.fuel_types);
        setManufacturerOptions(
          availableManufacturers?.map((item: ManufacturerListItem) => {
            return { value: item.id, label: item.name };
          })
        );
        setColorsOptions(
          colorsList.data
            ?.filter((item: { id: string; name: string }) => availableColors.includes(item.name))
            ?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
        );
        setFuelTypeOptions(
          fuelList.data
            ?.filter((item: { id: string; name: string }) => availableFuelTypes.includes(item.name))
            ?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
        );
      }

      if (
        color &&
        color.length > 0 &&
        (!model || model.length === 0) &&
        (!manufacturers || manufacturers.length === 0) &&
        (!fuelType || fuelType.length === 0)
      ) {
        const availableVehicleModels = modelList.data.filter((item: ModelListItem) =>
          item.exterior_colors.some((exterior_color) => selectedColorsList.includes(exterior_color))
        );
        const availableManufacturers = manufacturerList?.data?.filter((item: ManufacturerListItem) =>
          availableVehicleModels?.map((item: ModelListItem) => item.manufacturer_id).includes(item.id)
        );
        const availableFuelTypes = availableVehicleModels.flatMap((item: ModelListItem) => item.fuel_types);
        setManufacturerOptions(
          availableManufacturers?.map((item: ManufacturerListItem) => {
            return { value: item.id, label: item.name };
          })
        );
        setVehicleModelOptions(
          availableVehicleModels?.map((item: ModelListItem) => {
            return { value: item.id, label: item.model };
          })
        );
        setFuelTypeOptions(
          fuelList.data
            ?.filter((item: { id: string; name: string }) => availableFuelTypes.includes(item.name))
            ?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
        );
      }

      if (
        fuelType &&
        fuelType.length > 0 &&
        (!model || model.length === 0) &&
        (!manufacturers || manufacturers.length === 0) &&
        (!color || color.length === 0)
      ) {
        const availableVehicleModels = modelList.data.filter((item: ModelListItem) =>
          item.fuel_types.some((fuelType) => selectedFuelTypesList.includes(fuelType))
        );
        const availableManufacturers = manufacturerList?.data?.filter((item: ManufacturerListItem) =>
          availableVehicleModels?.map((item: ModelListItem) => item.manufacturer_id).includes(item.id)
        );
        const availableColors = availableVehicleModels.flatMap((item: ModelListItem) => item.exterior_colors);
        setManufacturerOptions(
          availableManufacturers?.map((item: ManufacturerListItem) => {
            return { value: item.id, label: item.name };
          })
        );
        setColorsOptions(
          colorsList.data
            ?.filter((item: { id: string; name: string }) => availableColors.includes(item.name))
            ?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
        );
        setVehicleModelOptions(
          availableVehicleModels?.map((item: ModelListItem) => {
            return { value: item.id, label: item.model };
          })
        );
      }

      if (
        (!manufacturers || manufacturers.length === 0) &&
        (!model || model.length === 0) &&
        (!fuelType || fuelType.length === 0) &&
        (!color || color.length === 0)
      ) {
        setManufacturerOptions(
          manufacturerList?.data?.map((item: ManufacturerListItem) => {
            return { value: item.id, label: item.name };
          })
        );
        setVehicleModelOptions(
          modelList.data?.map((item: ModelListItem) => {
            return { value: item.id, label: item.model };
          })
        );
        setColorsOptions(
          colorsList.data?.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })
        );
        setFuelTypeOptions(
          fuelList.data?.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturers, model, fuelType, color]);

  const handleSelectChange = (field: keyof CustomerOptionsFormType) => (option: Option[]) => {
    setValue(field, option, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<CustomerOptionsFormType> = (data) => {
    onSearch({
      plan_type: data?.plan_type,
      contract_length: data?.contract_length,
      manufacturer: data?.manufacturer,
      model: data?.model,
      color: data?.color,
      fuel_type: data?.fuel_type,
    });
  };

  const onReset = () => {
    reset({
      plan_type: [],
      manufacturer: [],
      model: [],
      color: [],
      contract_length: [],
      fuel_type: [],
    });
    onSearch({
      plan_type: undefined,
      contract_length: undefined,
      manufacturer: undefined,
      model: undefined,
      color: undefined,
      fuel_type: undefined,
    });
  };

  return (
    <>
      <div className="pt-8 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4">
        <SelectInput
          data-testid="plan_type"
          label="Plan type"
          placeholder="Please select plan type"
          options={(contractTypeList?.data || [])?.map((item: ContractTypeListItem) => {
            return { value: item.id, label: item.name };
          })}
          value={getValues().plan_type}
          onSelect={handleSelectChange('plan_type')}
          error={errors.plan_type?.message}
          required
        />
        <SelectInput
          data-testid="contract_length"
          label="Contract length"
          placeholder="Please select contract length"
          options={contractLengthOptions}
          value={getValues().contract_length}
          onSelect={handleSelectChange('contract_length')}
          error={errors.contract_length?.message}
          multiple
        />
        <SelectInput
          data-testid="manufacturer"
          label="Manufacturer"
          placeholder="Please select manufacturer"
          options={manufacturerOptions}
          value={getValues().manufacturer}
          onSelect={handleSelectChange('manufacturer')}
          error={errors.manufacturer?.message}
          multiple
        />
        <SelectInput
          data-testid="model"
          label="Model"
          placeholder="Please select model"
          options={vehicleModelOptions}
          value={getValues().model}
          onSelect={handleSelectChange('model')}
          error={errors.model?.message}
          multiple
        />
        <SelectInput
          data-testid="color"
          label="Color"
          placeholder="Please select color"
          options={colorsOptions}
          value={getValues().color}
          onSelect={handleSelectChange('color')}
          error={errors.color?.message}
          multiple
        />
        <SelectInput
          data-testid="fuel_type"
          label="Fuel type"
          placeholder="Please select fuel type"
          options={fuelTypeOptions}
          value={getValues().fuel_type}
          onSelect={handleSelectChange('fuel_type')}
          error={errors.fuel_type?.message}
          multiple
        />
      </div>
      <div className="pt-4 flex justify-end items-center gap-4">
        <Button type="button" data-testid="btn-reset" disabled={!isDirty} color="secondary" onClick={onReset}>
          Reset filters
        </Button>
        <Button type="button" data-testid="btn-search" disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Search
        </Button>
      </div>
    </>
  );
};
