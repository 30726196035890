import React from 'react';
import { Link } from 'react-router-dom';
import { ActionCard, LinkText, StyledButton } from './agreementButtonGroup.styles';
import { isAftercare, isRental, IN_SERVICE_RENTAL } from '../../../../consts/plans';
import { LIVE_STATUS, IN_SERVICING } from '../../../../consts/status';
import { SERVICING_LIST } from '../../../../consts/routes';
import { ButtonDataItem } from '../../../drivers/driver/driverButtons/driverButtonGroup';
import { Text } from '../../../../uiComponents/text/text';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Agreement } from '../../../../models/agreement';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { Aftercare } from '../../../../models/aftercare';

interface AgreementButtonGroupProps {
  agreement: Agreement;
  ordwayDataLoaded: boolean;
  isCompleteProcess: boolean;
  originalAgreement?: Aftercare[];
  onTerminateAgreementClick: () => void;
  onCompleteAgreementClick: () => void;
  setIsSwapModalOpen: (value: boolean) => void;
}

export const AgreementButtonGroup = ({
  agreement,
  ordwayDataLoaded,
  isCompleteProcess,
  originalAgreement,
  onTerminateAgreementClick,
  onCompleteAgreementClick,
  setIsSwapModalOpen,
}: AgreementButtonGroupProps) => {
  const isSwapActionDisabled = (): boolean =>
    agreement?.agreement_status !== LIVE_STATUS ||
    agreement?.has_courtesy_vehicle ||
    !isRental(agreement?.agreement_type);

  const isActionDisabled = (): boolean => {
    return (
      agreement?.agreement_status !== LIVE_STATUS ||
      agreement?.has_in_service_rental ||
      agreement?.has_courtesy_vehicle ||
      agreement?.vehicle_status === IN_SERVICING
    );
  };

  const buttonData: ButtonDataItem[] = [
    {
      label: 'Swap agreement',
      isDisabled: isSwapActionDisabled(),
      onClick: () => setIsSwapModalOpen(true),
    },
    {
      label: 'Complete agreement',
      isDisabled: isActionDisabled() || isRental(agreement?.agreement_type) || !!originalAgreement?.length,
      onClick: onCompleteAgreementClick,
    },
    {
      label: 'Terminate agreement',
      isDisabled: isActionDisabled() || isAftercare(agreement?.agreement_type),
      onClick: onTerminateAgreementClick,
      isRed: true,
    },
  ];

  const getLinkText = (hasInServiceRental: boolean): JSX.Element => {
    return (
      <LinkText>
        Driver has {hasInServiceRental ? 'an in-service rental' : 'a replacement'} vehicle. Please go to the&nbsp;
        <Link to={SERVICING_LIST}>servicing page</Link>
        &nbsp;to check in the vehicle.
      </LinkText>
    );
  };

  const renderInServiceRentalText = () => {
    if (agreement?.has_in_service_rental) {
      if (agreement.agreement_type === IN_SERVICE_RENTAL && ordwayDataLoaded) {
        return (
          <LinkText>
            Terminate in-service rental through&nbsp;
            <Link to={SERVICING_LIST}>the servicing page.</Link>
            &nbsp;
          </LinkText>
        );
      } else if (agreement.agreement_type !== IN_SERVICE_RENTAL) {
        return getLinkText(true);
      }
    }
    return null;
  };

  const renderCourtesyVehicleText = () => {
    if (agreement?.has_courtesy_vehicle) {
      return getLinkText(false);
    }
    return null;
  };

  const renderMainVehicleInServicingText = () => {
    return agreement?.vehicle_status === IN_SERVICING ? (
      <LinkText>
        The main vehicle {agreement?.vrm} is in servicing, finish it through&nbsp;
        <Link to={SERVICING_LIST}>the servicing page to complete/terminate the agreement.</Link>
        &nbsp;
      </LinkText>
    ) : null;
  };

  return (
    <ActionCard>
      <Text variant="h1" color={PRIMARY_PURPLE} weight={800} block>
        Actions
      </Text>
      <FlexLayout styled={{ marginTop: 16 }} itemsX="start" vertical>
        {buttonData.map((button, index) => (
          <StyledButton
            key={index}
            isProcessing={!ordwayDataLoaded || isCompleteProcess}
            disabled={button.isDisabled}
            onClick={button.onClick}
            $isRed={button.isRed}
          >
            {button.label}
          </StyledButton>
        ))}
        {renderMainVehicleInServicingText()}
        {renderInServiceRentalText()}
        {renderCourtesyVehicleText()}
      </FlexLayout>
    </ActionCard>
  );
};
