import { Controller, FieldValues, UseFormReturn, Path, FieldErrors, PathValue } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextFieldLabel } from '../../inputs/textField/textField.styles';
import { Container, ErrorMessage, StyledWrapper } from './datePickerComponent.styles';
import { toDate } from 'date-fns';

interface DatePickerComponentProps<T extends FieldValues> {
  control: UseFormReturn<T>['control'];
  errors?: FieldErrors<T>;
  name: Path<T>;
  label: string;
  rules?: {
    required: boolean;
  };
  disabled?: boolean;
  includedDates?: Date[];
  dayClassName?: ((date: Date) => string | null) | null;
  defaultSelectedDate?: Date;
}

export function DatePickerComponent<T extends FieldValues>({
  control,
  name,
  label,
  rules,
  errors,
  disabled,
  includedDates,
  dayClassName,
  defaultSelectedDate,
}: DatePickerComponentProps<T>): JSX.Element {
  return (
    <Container>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={
          defaultSelectedDate === null
            ? undefined
            : (toDate(defaultSelectedDate as Date) as unknown as PathValue<T, Path<T>>)
        }
        render={({ field: { onChange, value } }) => (
          <StyledWrapper>
            <TextFieldLabel $isRequired={rules?.required}>{label}</TextFieldLabel>
            <DatePicker
              onChange={(date) => onChange(date)}
              selected={value}
              showMonthYearDropdown
              dateFormat="yyyy-MM-dd"
              disabled={disabled}
              popperPlacement="bottom-end"
              highlightDates={includedDates}
              includeDates={includedDates}
              dayClassName={(date) => (dayClassName ? dayClassName(date) : null)}
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [5, 10],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    rootBoundary: 'viewport',
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
              placeholderText="Click to select a date"
            />
          </StyledWrapper>
        )}
      />
      <ErrorMessage>{errors && errors.message}</ErrorMessage>
    </Container>
  );
}
