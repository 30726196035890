import { useCallback, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../order/order';
import { SectionHeader } from './sectionHeader';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30 } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { penaltyCodes as PENALTY_CODES } from '../../../consts/driver';
import { TextInput } from '../../../uiComponents/inputs/textInput/textInput';
import { Checkbox, CheckboxLabel } from '../../../uiComponents/uiControls/checkbox/checkbox';
import { OptionList } from '../../../utils/props';
import { penaltyColors, statusColors } from '../../../common/utils';
import { DVSA_URL } from '../../../consts';
import { updateDriver } from '../../../api/patch/driver.patch';
import { DvlaNumber } from '../order/order.styles';
import { CodeName, SelectedPenaltyCodes } from '../../drivers/newDriver/createNewDriver/createNewDriver.styles';
import { BsExclamationCircle } from 'react-icons/bs';
import { format } from 'date-fns';

export const DvlaCheckSection = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [penaltyCodes, setPenaltyCodes] = useState<OptionList[]>(
    progress?.penalty_codes?.map((penalty: string) => {
      return { value: penalty, label: penalty.toUpperCase() };
    })
  );
  const [points, setPoints] = useState<number | null>(+(progress?.no_dvla_points ?? '0'));
  const [noPenaltyChecked, setNoPenaltyChecked] = useState<boolean>(
    progress?.penalty_codes?.length === 0 && progress?.no_dvla_points === '0'
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const driverId: string = progress?.driver_id;

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    updateDriver(driverId, {
      no_dvla_points: noPenaltyChecked ? 0 : points ?? 0,
      penalty_codes: noPenaltyChecked ? [] : penaltyCodes?.map((code: OptionList) => code?.value),
      dvsa_checked_date: format(new Date(), 'yyyy-MM-dd'),
    })
      .then(() => {
        checkProgress?.();
        setSubmitting(false);
        setIsCollapsed(true);
      })
      .catch(() => setSubmitting(false));
  }, [penaltyCodes, points, noPenaltyChecked, driverId, checkProgress]);

  return (
    <CollapsiblePanel
      header={
        <SectionHeader
          title="DVLA check"
          completedText="Completed"
          isComplete={isComplete}
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
    >
      <div>
        <SectionHeader title="DVLA check" isComplete={isComplete} isLocked={isLocked} expanded />
        <DvlaNumber>
          <FlexLayout itemsY="center" gap={16}>
            <Text variant="body6" color={SECONDARY_PURPLE_30} weight={500}>
              {progress?.dvla_document_no}
            </Text>
            <FlexLayout itemsY="center">
              {progress?.tag_type?.map((tag: string, index: number) => (
                <Tag key={`${tag}_${index}`} color={statusColors[tag] ?? PRIMARY_PURPLE}>
                  {tag}
                </Tag>
              ))}
            </FlexLayout>
          </FlexLayout>
          <Text styled={{ marginTop: 24 }} variant="body6" color={PRIMARY_PURPLE} weight={500} block>
            DVLA points
          </Text>
          <PrimaryButton
            styled={{ width: 190, height: 40, marginTop: 8 }}
            isGreen
            onClick={() => window.open(DVSA_URL, '_blank')}
          >
            Go to DVSA platform
          </PrimaryButton>
          <FlexLayout itemsY="start" gap={16} styled={{ marginTop: 16 }}>
            <div>
              <DropDown
                multiSelect
                disabled={noPenaltyChecked}
                options={PENALTY_CODES}
                placeholder="Select code"
                defaultValue={progress?.penalty_codes?.map((code: string) => {
                  return { value: code, label: code?.toUpperCase() };
                })}
                name="penalty_codes"
                onSelect={(items) => setPenaltyCodes(items as OptionList[])}
                multiSelectCount={penaltyCodes?.length}
              />
              <SelectedPenaltyCodes>
                {penaltyCodes?.map((code: OptionList) => (
                  <Tag key={code.value} color={penaltyColors[code.value]} styled={{ margin: 4 }}>
                    <FlexLayout itemsY="center" styled={{ margin: '2px 4px' }}>
                      <BsExclamationCircle size={16} color={penaltyColors[code.value]} />
                      <CodeName>{code.label}</CodeName>
                    </FlexLayout>
                  </Tag>
                ))}
              </SelectedPenaltyCodes>
            </div>
            <FlexLayout gap={16} itemsY="center">
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoints(+e?.target?.value)}
                placeholder="Points"
                type="number"
                defaultValue={+(progress?.no_dvla_points ?? '0')}
                disabled={noPenaltyChecked}
                styled={{ minWidth: 112, width: 112 }}
              />
              <FlexLayout gap={8}>
                <Checkbox
                  onCheck={(checked: boolean) => {
                    setNoPenaltyChecked(checked);
                  }}
                  name="no_points"
                  defaultChecked={progress?.penalty_codes?.length === 0 && progress?.no_dvla_points === '0'}
                />
                <CheckboxLabel label="This driver has no penalties, points or offences" />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout itemsX="end">
            <PrimaryButton
              disabled={!noPenaltyChecked && (penaltyCodes?.length === 0 || !points)}
              styled={{ marginTop: 16, width: 186 }}
              onClick={() => onSubmit()}
              isProcessing={submitting}
            >
              Update
            </PrimaryButton>
          </FlexLayout>
        </DvlaNumber>
      </div>
    </CollapsiblePanel>
  );
};
