import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { Control, Controller, FieldError, FieldValues, Merge, Path, PathValue, RegisterOptions } from 'react-hook-form';
import { Container, MultiSelectValue, SelectError, dropDownStyles } from './dropDown.styles';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';

import { withStyledProps } from '../../../utils/colorUtils';
import { OptionList } from '../../../utils/props';

const ValueComponent = ({ ...props }) => {
  return <MultiSelectValue>{`${props.getValue().length} selected`}</MultiSelectValue>;
};

interface DropDownProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  placeholder?: string | number;
  options: OptionList[];
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  disabled?: boolean;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  name: TName;
  required?: RegisterOptions<TFieldValues>;
  control?: Control<TFieldValues, string>;
  multiSelect?: boolean;
  multiSelectCount?: number;
  value?: SingleValue<string | OptionList> | MultiValue<string | OptionList>;
  useLabel?: boolean;
  onSelect?: (value: SingleValue<string | OptionList> | MultiValue<string | OptionList>) => void;
}

export const DropDown = withStyledProps(
  <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    placeholder,
    options,
    defaultValue,
    disabled,
    error,
    name,
    required,
    control,
    multiSelect,
    multiSelectCount,
    value,
    useLabel,
    onSelect,
    ...props
  }: DropDownProps<TFieldValues, TName>) => {
    return (
      <FlexLayout vertical>
        {control ? (
          <Container>
            <Controller
              control={control}
              name={name}
              rules={required}
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value } }) => {
                const handleChange = (selected: MultiValue<string | OptionList> | SingleValue<string | OptionList>) => {
                  if (multiSelect) {
                    const options = selected as OptionList[];
                    return onChange(selected ? options?.map((sel: OptionList) => sel.value) : null);
                  }
                  const option = selected as OptionList;
                  return onChange(selected ? option?.value : null);
                };

                const getSelected = () => {
                  if (!value) {
                    return '';
                  }
                  if (useLabel && value) {
                    return options.find((option) => option.label === value);
                  }
                  if (multiSelect && value) {
                    return options.filter((option) => value.find((val: string) => option.value === val));
                  }
                  return options.find((option) => option.value === value);
                };
                return (
                  <>
                    <Select
                      {...props}
                      isMulti={multiSelect}
                      components={
                        multiSelect && (multiSelectCount ?? 0) > 0 ? { ValueContainer: ValueComponent } : undefined
                      }
                      onChange={(option) => {
                        handleChange(option);
                        onSelect?.(option);
                      }}
                      styles={dropDownStyles}
                      placeholder={placeholder}
                      options={options}
                      isDisabled={disabled}
                      value={getSelected()}
                      onBlur={onBlur}
                    />
                  </>
                );
              }}
            />
            {error && <SelectError>{error?.message}</SelectError>}
          </Container>
        ) : (
          <Select
            {...props}
            isMulti={multiSelect}
            components={multiSelect && (multiSelectCount ?? 0) > 0 ? { ValueContainer: ValueComponent } : undefined}
            onChange={(option) => {
              onSelect?.(option);
            }}
            defaultValue={defaultValue}
            styles={dropDownStyles}
            placeholder={placeholder}
            options={options}
            isDisabled={disabled}
            value={value}
          />
        )}
      </FlexLayout>
    );
  }
);
