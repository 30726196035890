import * as React from 'react';
import { Check } from 'lucide-react';

import { Command, CommandGroup, CommandItem, CommandList } from '../../../ui/command/command';
import { Command as CommandPrimitive } from 'cmdk';
import { cn } from '../../../../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

export interface Option {
  value: string;
  label: string;
  info?: string;
  node?: React.ReactNode;
}

const selectVariants = cva(
  'group flex min-h-10 w-full shadow-xs px-3.5 py-1.5 rounded-lg border border-utility-gray-300 bg-white text-base font-light placeholder:text-utility-gray-500 text-utility-gray-900 hover:placeholder:text-utility-gray-900 focus-within:outline-none',
  {
    variants: {
      variant: {
        default: 'focus-within:border-utility-brand-300 focus-within:shadow-brand-md',
        error: '!border-utility-error-300 focus-within:border-utility-error-300 focus-within:shadow-error-md',
      },
      dimension: {
        sm: 'min-h-10 px-3.5 py-1.5',
        md: '!min-h-11 px-4 py-1.5',
      },
    },
    defaultVariants: {
      variant: 'default',
      dimension: 'sm',
    },
  }
);

export interface SelectProps extends VariantProps<typeof selectVariants> {
  options: Option[];
  placeholder?: string;
  multiple?: boolean;
  className?: string;
  startAdornment?: React.ReactNode;
  defaultSelected?: Option[];
  value?: Option[];
  onSelect?: (value: Option[]) => void;
  disabled?: boolean;
}

export function Select({
  options,
  placeholder = '',
  multiple = false,
  className,
  variant,
  dimension,
  startAdornment,
  defaultSelected,
  value,
  onSelect,
  disabled,
}: SelectProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<Option[]>(value || defaultSelected || []);
  const [inputValue, setInputValue] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const handleUnselect = (option: Option) => {
    const opts = selected.filter((s) => s.value !== option.value);
    setSelected(opts);
    onSelect?.(opts);
  };

  const handleSelect = (option: Option) => {
    setSelectAll(false);
    if (selected.filter((s) => s.value === option.value).length > 0) {
      handleUnselect(option);
    } else {
      const value = [...selected, option];
      setSelected(value);
      onSelect?.(value);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]);
      setSelectAll(false);
      onSelect?.([]);
    } else {
      setSelected(options);
      setSelectAll(true);
      onSelect?.(options);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (input.value === '') {
          const newSelected = [...selected];
          newSelected.pop();
          setSelected(newSelected);
          onSelect?.(newSelected);
          return newSelected;
        }
      }
      if (e.key === 'Escape') {
        input.blur();
      }
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Command onKeyDown={handleKeyDown}>
      <div
        ref={containerRef}
        className={cn(
          selectVariants({ variant, dimension, className }),
          !multiple ? 'flex items-center justify-between' : 'flex items-center flex-wrap gap-1',
          disabled
            ? '!cursor-not-allowed !bg-utility-gray-50 !text-utility-gray-500 hover:!text-utility-gray-500 hover:placeholder:!text-utility-gray-500'
            : ''
        )}
      >
        {startAdornment && <div className="mr-2">{startAdornment}</div>}
        <CommandPrimitive.Input
          data-testid="select-input"
          ref={inputRef}
          value={inputValue}
          onValueChange={setInputValue}
          onClick={() => {
            setOpen(!open);
          }}
          placeholder={placeholder}
          className={cn('flex-1 bg-transparent outline-none text-base font-light placeholder:text-utility-gray-500')}
          disabled={disabled}
        />
      </div>
      <div className="relative">
        <CommandList data-testid="select-list">
          {open && options.length > 0 ? (
            <div className="absolute top-2 z-10 w-full rounded-md border bg-popover text-popover-foreground animate-in">
              <CommandGroup className="h-full overflow-auto">
                <CommandItem
                  data-testid={'select-all-list-item'}
                  key={'select-all'}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onSelect={() => {
                    setInputValue('');
                    handleSelectAll();
                  }}
                  className="cursor-pointer flex items-center justify-between hover:!bg-utility-gray-50"
                >
                  <div className="flex items-center gap-2">
                    <span className="text-utility-gray-900 font-medium">
                      {selectAll ? 'Unselect All' : 'Select All'}
                    </span>
                  </div>
                  {selectAll && <Check className="h-4 w-4 text-utility-brand-600" />}
                </CommandItem>
                {options.map((option, index) => (
                  <CommandItem
                    data-testid={`select-list-item-${index}`}
                    key={option.value}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onSelect={() => {
                      setInputValue('');
                      handleSelect(option);
                    }}
                    className="cursor-pointer flex items-center justify-between hover:!bg-utility-gray-50"
                  >
                    <div className="flex items-center gap-2">
                      {option.node && <div>{option.node}</div>}
                      <span className="text-utility-gray-900 font-medium">{option.label}</span>
                      {option.info && <span className="text-utility-gray-600 font-light">{option.info}</span>}
                    </div>
                    {selected?.some((s) => s.value === option.value) && (
                      <Check className="h-4 w-4 text-utility-brand-600" />
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
    </Command>
  );
}
