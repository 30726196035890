import { useState } from 'react';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../../order/order';
import { SectionHeader } from '../sectionHeader';
import { useForm, FormProvider } from 'react-hook-form';
import { MandatoryPhotos } from './mandatoryPhotos/mandatoryPhotos';
import { Form } from './styles';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { mandatoryPhotosItems } from './../../../../consts/conditionReport';
import { VehicleInformation } from './vehicleInformation/vehicleInformation';
import { useGetRecentConditionReportQuery } from '../../../../api/conditionReport/conditionReportApiSlice';
import { DamageLog } from './damageLog/damageLog';
import { SignOff } from './signOff/signOff';
import { ImageArrayType, NewConditionReport } from '../../../../models/transfer';
import { fileNameTimeStampFormat, renderNotification } from '../../../../utils/utils';
import { getFileService } from '../../../../api/cognito/file.service';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { createNewUsedAgreement } from '../../../../api/post/conditionReport.post';
import { AGREEMENTS } from '../../../../consts/routes';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const conditionReportFormSchema = z.object({
  mandatoryPhotos: z.array(
    z.object({
      name: z.string(),
      s3Folder: z.string(),
      label: z.string(),
      required: z.boolean(),
      file: z.instanceof(File, { message: 'Please upload a file' }).or(z.instanceof(Blob)),
      aditionalInfo: z.string().optional(),
    })
  ),
  oilLevel: z.coerce.number(),
  screenWashLevel: z.coerce.number(),
  fuelLevel: z.coerce.number(),
  // mileage: z.coerce.number(),
  mileage: z
    .string()
    .min(1, { message: 'Mileage is required' })
    .transform((val) => {
      const numberValue = parseFloat(val);
      if (isNaN(numberValue)) {
        throw new Error('Invalid mileage format');
      }
      return numberValue;
    }),
  checkList: z.array(z.enum(['TELEMATICS', 'LOCKING-WHEEL-NUT', 'TOOLS', 'SPARE-WHEEL-KIT'])),
  signature: z
    .string({ required_error: 'Please sign and click "Submit Signature"' })
    .base64()
    .min(1, { message: 'Please sign and click "Submit Signature"' }),
  notes: z.string().optional(),
});

export type ConditionReportFormData = z.infer<typeof conditionReportFormSchema>;

interface ConditioReportSectionProps extends SectionProps {
  conditionReportDraft?: NewConditionReport;
}

export function ConditionReportSection({
  isComplete,
  isLocked,
  progress,
  conditionReportDraft,
}: ConditioReportSectionProps) {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [submittingConditionReport, setSubmittingConditionReport] = useState<boolean>(false);
  const { data: recentConditioReportData } = useGetRecentConditionReportQuery({
    vehicleId: conditionReportDraft?.vehicle_id,
  });
  const fileService = getFileService();

  const formMethods = useForm<ConditionReportFormData>({
    resolver: zodResolver(conditionReportFormSchema),
    defaultValues: {
      mandatoryPhotos: mandatoryPhotosItems,
      oilLevel: 0,
      screenWashLevel: 0,
      fuelLevel: 0,
      checkList: [],
    },
  });

  const uploadImages = async (mandatoryPhotos: ConditionReportFormData['mandatoryPhotos']) => {
    const uploadPromises = mandatoryPhotos.map((file) => {
      const fileNameWithTimeStamp = fileNameTimeStampFormat(file.name);
      const path = `DOCUMENTS/CONDITION_REPORT/${conditionReportDraft?.vehicle_id}/${format(
        new Date(),
        'yyyy-MM-dd_HH-MM-SS'
      )}/PHOTOS/${fileNameWithTimeStamp}`;

      return fileService
        .uploadFileToStorage({
          path,
          data: file.file,
        })
        .then((result) => ({
          fileName: file.s3Folder,
          type: file.file.type,
          s3_url: result.path,
        }))
        .catch((error) => {
          renderNotification('error', 'Error', `${error.message}.`, true);
          throw error;
        });
    });

    const results = await Promise.all(uploadPromises);
    return results;
  };

  async function handleSubmitConditionReport(payload: NewConditionReport) {
    try {
      await createNewUsedAgreement(payload);
      renderNotification('success', 'Success', 'Condition Report and New Agreement were successfully created.');
      navigate(`${AGREEMENTS}/${payload.agreement_id}`, {
        state: { agreement_id: payload.agreement_id },
      });
      setSubmittingConditionReport(false);
    } catch {
      renderNotification('error', 'Error', 'There was a problem creating the new condition report.');
      setSubmittingConditionReport(false);
    }
  }

  async function submitForm(data: ConditionReportFormData) {
    if (recentConditioReportData?.data.mileage && data.mileage < recentConditioReportData?.data.mileage) {
      formMethods.setError('mileage', {
        type: 'custom',
        message: 'Mileage cannot be lower than previous transfer mileage',
      });
      setSubmittingConditionReport(false);
      return;
    }

    if (!conditionReportDraft) {
      renderNotification('error', 'Draft Not Found', 'The condition report draft is missing.', true);
      setSubmittingConditionReport(false);
      return;
    }

    setSubmittingConditionReport(true);

    let imagesArray: ImageArrayType[];
    try {
      imagesArray = await uploadImages(data.mandatoryPhotos);
    } catch (error) {
      renderNotification('error', 'Upload Failed', 'An error occurred while uploading images.', true);
      setSubmittingConditionReport(false);
      return;
    }

    imagesArray.push({
      fileName: 'SIGNATURE',
      type: 'image/png',
      contents: data.signature,
    });

    const payload: NewConditionReport = {
      id: conditionReportDraft.id,
      application_id: conditionReportDraft.application_id,
      agreement_id: progress.agreement_id,
      agreement_status: conditionReportDraft.agreement_status,
      vehicle_id: conditionReportDraft.vehicle_id,
      branch_id: conditionReportDraft.branch_id,
      vrm: conditionReportDraft.vrm,
      files: imagesArray,
      oil_level: data.oilLevel,
      screen_wash_level: data.screenWashLevel,
      fuel_level: data.fuelLevel,
      mileage: data.mileage,
      checklist: data.checkList,
      notes: data.notes,
      transfer_type: 'CHECK-OUT',
    };

    handleSubmitConditionReport(payload);
  }

  return (
    <CollapsiblePanel
      styled={{ marginTop: 16 }}
      locked={isLocked}
      header={
        <SectionHeader
          title="Condition Report"
          completedText="Completed"
          isComplete={isComplete}
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
    >
      <div>
        <SectionHeader
          title="Condition Report"
          completedText="Completed"
          isComplete={isComplete}
          isLocked={isLocked}
          expanded
        />
        <FormProvider {...formMethods}>
          <Form>
            <MandatoryPhotos />
            <VehicleInformation />
            <DamageLog
              conditionReportId={conditionReportDraft?.id}
              vehicleId={conditionReportDraft?.vehicle_id}
              prevDamageLogs={conditionReportDraft?.damage_logs}
            />
            <SignOff />
            <PrimaryButton
              disabled={submittingConditionReport}
              isProcessing={submittingConditionReport}
              onClick={formMethods.handleSubmit(submitForm)}
              styled={{ maxWidth: 'fit-content', alignSelf: 'end' }}
            >
              Make agreement live
            </PrimaryButton>
          </Form>
        </FormProvider>
      </div>
    </CollapsiblePanel>
  );
}
