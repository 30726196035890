import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { ExceptionSettingForm } from './exceptionSettingForm';
import { CHARGES_PENDING } from '../../../../consts/additionalCharges';
import { useDateRangeFilter } from '../../../../hooks/useDateRangeFilter';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { UploaderInput } from '../../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Modal } from '../../../../uiComponents/modals/modal';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Table } from '../../../../uiComponents/table/table';
import { congestionChargeColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../../uiComponents/table/tableFilters/tableFilters';
import {
  additionalChargeStatuses,
  archivedOptions,
} from '../../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
  TableTagCell,
  TableTextCell,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Checkbox } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import {
  uploadCongestionCharges,
  updatePendingCongestionChargesStatus,
} from '../../../../api/post/additionalCharges.post';
import { Congestion, SuccessResponseProps } from '../../../../models/congestion';
import { statusColors } from '../../../../common/utils';
import { APP_CONTEXT } from '../../../../utils/context';
import { OptionList } from '../../../../utils/props';
import { fileToBase64 } from '../../../../utils/utils';
import { useGetAndMergeCongestionChargesQuery } from '../../../../api/listAndMerge/listAndMergeCongestionChargesApiSlice';
import { format } from 'date-fns';

export const CongestionCharges = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [selectedStatus, setSelectedStatus] = useState<OptionList[]>([]);
  const [selectedArchived, setSelectedArchived] = useState<OptionList[]>([]);
  const [selectedCongestion, setSelectedCongestion] = useState<Congestion>();
  const [successResponse, setSuccessResponse] = useState<SuccessResponseProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalidTemplate, setIsInvalidTemplate] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [isUploadDocModalOpen, setIsUploadDocModalOpen] = useState<boolean>(false);
  const [isSettingFormModalOpen, setIsSettingFormModalOpen] = useState<boolean>(false);
  const [pendingCongestionCharges, setPendingCongestionCharges] = useState<Congestion[]>([]);
  const [isProcessModalOpen, setIsProcessModalOpen] = useState<boolean>(false);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
    setSortAscending,
  } = useTableFilters();
  const { control, handleSubmit, resetField } = useForm<Congestion>();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=transaction_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);

  const {
    data: congestionCharges,
    isLoading: isCongestionChargesLoading,
    isFetching: isCongestionChargesFetching,
    refetch,
  } = useGetAndMergeCongestionChargesQuery({ query: queryStringState, refetch: refetchData });

  const handleCheckboxChange = useCallback((checked: boolean, charge: Congestion) => {
    if (checked) {
      setPendingCongestionCharges((prevPendingCongestionCharges) => [...prevPendingCongestionCharges, charge]);
    } else {
      setPendingCongestionCharges((prevPendingCongestionCharges) =>
        prevPendingCongestionCharges.filter((selectedCharge) => selectedCharge !== charge)
      );
    }
  }, []);

  const handleGetCongChargesResponse = useCallback(
    (count: number, charges: Congestion[]) => {
      const chargeRows = charges?.map((charge: Congestion) => {
        const isChecked = pendingCongestionCharges.some((pendingCharge) => pendingCharge.id === charge.id);
        return {
          rowData: { data: charge },
          cells: [
            <TableTextCell
              value={charge?.transaction_date ? format(new Date(charge?.transaction_date), 'dd MMM yyyy') : '-'}
            />,
            <TableTextCell value={charge?.charge_type || '-'} />,
            <TableTextCell value={charge?.vrm || '-'} />,
            <TableTextCell value={charge?.driver_name || '-'} />,
            <TableTextCell value={charge?.cost_centre || '-'} />,
            <TableTextCell
              value={charge?.journey_date ? format(new Date(charge?.journey_date), 'dd MMM yyyy') : '-'}
            />,
            <TableTextCell value={charge?.transaction_id || '-'} />,
            <TableTextCell value={charge?.ordway_order_id || '-'} />,
            <TableTextCell value={charge?.ordway_invoice_id || '-'} />,
            <FlexLayout
              styled={{ cursor: 'pointer' }}
              itemsX="center"
              itemsY="center"
              gap={10}
              onClick={() => {
                setIsSettingFormModalOpen(true);
                setSelectedCongestion(charge);
              }}
            >
              <TableTagCell tags={[charge?.congestion_status]} />
              <MdKeyboardArrowDown
                style={{ marginLeft: '-35px' }}
                size={15}
                color={statusColors[charge?.congestion_status]}
              />
            </FlexLayout>,
            <FlexLayout gap={30}>
              <TableTextCell value={charge?.congestion_amount} />
              {charge?.congestion_status === CHARGES_PENDING && (
                <Checkbox
                  name="pending-congestion-charges"
                  checked={isChecked}
                  onCheck={(checked: boolean) => handleCheckboxChange(checked, charge)}
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(chargeRows);
      setTotalRows(count);
    },
    [handleCheckboxChange, setTableData, setTotalRows, pendingCongestionCharges]
  );

  const filters: FilterItem[] = [
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Journey start date range:"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <DropDownFilter
          name="status"
          placeholder="Select status"
          options={additionalChargeStatuses}
          multiValues={selectedStatus}
          title="Status:"
          onChange={(items) => setSelectedStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'archived',
      element: (
        <DropDownFilter
          name="archived"
          placeholder="Archived:"
          options={archivedOptions || []}
          multiValues={selectedArchived}
          title="Archived:"
          onChange={(items) => {
            setSelectedArchived(items as OptionList[]);
          }}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('congestionListPage');
    setPageTitle('Additional charges');
    setSortingColumn('transaction_date');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      {
        columnName: 'congestion.journey_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
      { columnName: 'congestion_status', options: selectedStatus },
      { columnName: 'archived', options: selectedArchived },
    ]);
  }, [setTableFilters, selectedStatus, selectedArchived, dateRangeFilter]);

  useEffect(() => {
    if (congestionCharges) {
      handleGetCongChargesResponse(congestionCharges.count, congestionCharges.data);
    }
  }, [congestionCharges, queryStringState, handleGetCongChargesResponse]);

  const onClearClick = useCallback(() => {
    setDateRangeFilter([]);
    setSelectedStatus([]);
    setSelectedArchived([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
    setPendingCongestionCharges([]);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  const onDownloadClick = () => {
    const fileName = 'congestionTemplate.xlsx';
    const filePath =
      process.env.CONGESTION_TEMPLATE ??
      'https://s3.eu-west-1.amazonaws.com/documents.pulse-dev.ottocar.co.uk/Template/Sample-Congestion-Template.xlsx';
    fetch(filePath)
      .then((response: Response) => {
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob: Blob) => {
        saveAs(blob, fileName);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Template has been successfully downloaded on your computer',
          isAlert: true,
        });
      })
      .catch((err: Error) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: `${err}. Failed to download the template`,
          isAlert: true,
        });
      });
  };

  const onUploadClick = useCallback(
    async (value: { document: Blob | File }) => {
      setLoading(true);
      try {
        const convertedFile = await fileToBase64(value.document);
        const { data } = await uploadCongestionCharges({ contents: convertedFile });
        if (data.success) {
          Notification({
            type: 'success',
            title: 'Success',
            message: data.message,
            isAlert: true,
          });
          refetchToFirstPage();
          setIsUploaded(true);
          setSuccessResponse(data);
        }
      } catch (err) {
        Notification({
          type: 'error',
          title: 'Error',
          message: `${err}`,
          isAlert: true,
        });
        setIsInvalidTemplate(true);
        setIsUploadDocModalOpen(false);
      } finally {
        setLoading(false);
      }
    },
    [refetchToFirstPage]
  );

  const handleClose = () => {
    setSelectedCongestion(undefined);
    setSuccessResponse(null);
    setIsInvalidTemplate(false);
    setIsUploaded(false);
    setIsUploadDocModalOpen(false);
    setIsSettingFormModalOpen(false);
    resetField('document');
  };

  const handleConfirm = useCallback(() => {
    if (pendingCongestionCharges.length > 0) {
      updatePendingCongestionChargesStatus({ congestionData: pendingCongestionCharges, status: 'APPROVED' })
        .then((response: AxiosResponse<{ success: boolean; message: string }>) => {
          if (!response.data.success) {
            throw new Error(response.data.message);
          }
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Selected pending congestion charges have been processed',
            isAlert: true,
          });
          refetchToFirstPage();
        })
        .catch((err: Error) => {
          Notification({
            type: 'error',
            title: 'Error',
            message: `${err}. Failed to process selected congestion charges`,
            isAlert: true,
          });
        });
    } else {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'No pending congestion charges were selected',
        isAlert: true,
      });
      setIsProcessModalOpen(false);
    }
    setIsProcessModalOpen(false);
  }, [pendingCongestionCharges, refetchToFirstPage]);

  const renderUploadForm = (): JSX.Element | null => {
    if (!isInvalidTemplate && !isUploaded) {
      return (
        <>
          <UploaderInput
            label="Upload congestion charge document (.xlsx extension only)"
            control={control as unknown as Control<FieldValues>}
            name="document"
            required
            isExcelFile={['.xlsx']}
          />
          <FlexLayout styled={{ marginTop: 16 }} itemsX="space-between" gap={8}>
            <SecondaryButton onClick={onDownloadClick}>Download template</SecondaryButton>
            <FlexLayout gap={4}>
              <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
              <PrimaryButton isProcessing={loading} onClick={handleSubmit(onUploadClick)}>
                Upload document
              </PrimaryButton>
            </FlexLayout>
          </FlexLayout>
        </>
      );
    }
    if (isUploaded && successResponse) {
      return (
        <FieldGrid
          numColumns={2}
          headers={[
            'Number of charges uploaded:',
            'New charges added:',
            'Duplicate charges:',
            'Number of unassigned charges:',
          ]}
          values={[
            successResponse?.totalCongestion,
            successResponse?.addedCongestion,
            successResponse?.duplicateCongestion,
            successResponse?.unassignedCongestion,
          ]}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isCongestionChargesLoading || isCongestionChargesFetching}
        header="Congestion charges"
        primaryBtnText="Download template"
        onPrimaryBtnClick={onDownloadClick}
        secondaryBtnText="Upload document"
        onSecondaryBtnClick={() => setIsUploadDocModalOpen(true)}
        tertiaryBtnText="Process charges"
        onTertiaryBtnClick={() => setIsProcessModalOpen(true)}
        disableTertiaryBtn={pendingCongestionCharges.length === 0}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={congestionChargeColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={onClearClick}
      />
      <Modal
        title={isUploadDocModalOpen ? 'Upload document' : 'Exception settings'}
        showClose
        open={isUploadDocModalOpen || isSettingFormModalOpen}
        onClose={handleClose}
        styled={{ width: `${isUploadDocModalOpen ? '75%' : '90%'}`, minWidth: 600 }}
      >
        <>
          {isUploadDocModalOpen && renderUploadForm()}
          {isSettingFormModalOpen && selectedCongestion && (
            <ExceptionSettingForm
              congestion={selectedCongestion}
              onFormSubmit={() => {
                refetchToFirstPage();
                setIsSettingFormModalOpen(false);
              }}
              onClose={handleClose}
            />
          )}
        </>
      </Modal>
      <ConfirmationModal
        title={`Are you sure you want to approve ${pendingCongestionCharges.length} selected pending charges?`}
        isOpen={isProcessModalOpen}
        onClose={() => setIsProcessModalOpen(false)}
        onConfirm={handleConfirm}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
