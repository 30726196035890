import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { VehicleInfoProps } from '../vehiclePage/vehiclePage';
import { AGREEMENTS } from '../../../consts/routes';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Table } from '../../../uiComponents/table/table';
import {
  vehicleAgreementColumns,
  vehicleAgreementColumnsAudit,
} from '../../../uiComponents/table/tableColumns/tableColumns';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../uiComponents/text/text';
import { getAllAgreements } from '../../../api/get/agreement.get';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { Agreement } from '../../../models/agreement';
import { AGREEMENT_TERMINATED } from '../../../consts/agreement';
import { AgreementAudits } from '../../agreements/agreement/auditInformation';
import { format } from 'date-fns';

export const VehicleAgreements = ({ vehicle }: VehicleInfoProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isForAudit, setIsForAudit] = useState<boolean>(false);
  const { biAnnualAudit } = useFlags();
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();
  const vehicleId: string = vehicle?.vehicle_id;

  const handleGetAgreementsResponse = useCallback(
    (count: number, agreements: Agreement[], isForAudit?: boolean) => {
      const agreementRows = agreements?.map((agreement: Agreement) => {
        const cells: JSX.Element[] = [];
        cells.push(<TableTextCell value={agreement?.driver_name} />);
        cells.push(<TableTagCell tags={[agreement?.agreement_status]} />);
        if (!isForAudit || !biAnnualAudit) {
          cells.push(<TableTextCell value={agreement?.total_due} color={PRIMARY_PURPLE} />);
        }
        cells?.push(<TableTextCell value={agreement?.agreement_type} color={PRIMARY_PURPLE} />);
        cells.push(
          <TableTextCell value={format(new Date(agreement?.start_date), 'dd MMM yyyy')} color={PRIMARY_PURPLE} />
        );
        cells.push(
          <TableTextCell
            value={
              agreement?.agreement_status === AGREEMENT_TERMINATED && agreement?.termination_date
                ? format(new Date(agreement?.termination_date), 'dd MMM yyyy')
                : agreement?.end_date
                  ? format(new Date(agreement?.end_date), 'dd MMM yyyy')
                  : '-'
            }
            color={PRIMARY_PURPLE}
          />
        );
        return {
          rowData: { data: agreement },
          cells,
        };
      });
      setTableData(agreementRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, biAnnualAudit]
  );

  const fetchAgreementList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllAgreements(queryString, controller.signal).then((response: { count: number; data: Agreement[] }) => {
        //for Mo and Ali audit purposes
        let auditAgreement = null;

        if (biAnnualAudit) {
          for (const i of response?.data) {
            const agreement = AgreementAudits?.find((a) => a?.id === i?.agreement_id);
            if (agreement != null) {
              auditAgreement = {
                ...i,
                start_date: agreement.start_date,
                end_date: agreement.end_date,
                agreement_type: 'RENTAL',
              };
              break;
            }
          }
        }
        if (auditAgreement != null) {
          handleGetAgreementsResponse(1, [auditAgreement as Agreement], true);
          setIsForAudit(true);
        } else {
          handleGetAgreementsResponse(response.count, response.data);
        }
      });
    },
    [handleGetAgreementsResponse, biAnnualAudit]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchAgreementList(queryString);
    },
    [fetchAgreementList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('start_date');
    fetchAgreementList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:ASC&filter=vehicle_id$eq=${vehicleId}`);
  }, [fetchAgreementList, setSortingColumn, vehicleId]);

  useEffect(() => {
    setTableFilters([{ columnName: 'vehicle_id', options: { value: vehicleId, label: vehicleId } }]);
  }, [setTableFilters, vehicleId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          Agreements
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          Agreements
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={isForAudit && biAnnualAudit ? vehicleAgreementColumnsAudit : vehicleAgreementColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          onSearchChange={(value: string) => {
            setSearchString(value);
            applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
          }}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
          onRowClick={(agreement: { data: Agreement }) => navigate(`${AGREEMENTS}/${agreement?.data?.agreement_id}`)}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, searchString, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
