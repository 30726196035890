import { UPCOMING_STATUS, CANCELLED_STATUS, COMPLETED_STATUS } from '../../../../../consts/status';
import { DriverPaymentFreeze, FreezeStatus } from '../../../../../models/freeze';
import { DriverPaymentHoliday } from '../../../../../models/holiday';

export const isHoliday = (data: DriverPaymentHoliday | DriverPaymentFreeze): data is DriverPaymentHoliday => {
  return 'holidays' in data;
};

export const getSummaryStatus = (data: DriverPaymentHoliday | DriverPaymentFreeze): string => {
  if (isHoliday(data)) {
    if (data.holidays?.some((holiday) => holiday.holiday_status === UPCOMING_STATUS)) {
      return UPCOMING_STATUS;
    }
    if (data.holidays?.some((holiday) => holiday.holiday_status === COMPLETED_STATUS)) {
      return COMPLETED_STATUS;
    }
  } else {
    if (data.freezes?.some((freeze) => freeze.status_code === UPCOMING_STATUS)) {
      return UPCOMING_STATUS;
    }
    if (data.freezes?.some((freeze) => freeze.status_code === COMPLETED_STATUS)) {
      return COMPLETED_STATUS;
    }
  }
  return CANCELLED_STATUS;
};

export const statusToString = (status: FreezeStatus | string): string => {
  switch (status) {
    case FreezeStatus.COMPLETED:
      return COMPLETED_STATUS;
    case FreezeStatus.CANCELLED:
      return CANCELLED_STATUS;
    case FreezeStatus.UPCOMING:
      return UPCOMING_STATUS;
    default:
      return 'Unknown';
  }
};
