import Compressor from 'compressorjs';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { BsFiletypePdf, BsPersonPlus } from 'react-icons/bs';
import { FiCamera, FiUploadCloud } from 'react-icons/fi';
import { getFileService } from '../../../api/cognito/file.service';
import {
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_PURPLE_30,
  STATUS_RED,
} from '../../../common/styles/Colors';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { withStyledProps } from '../../../utils/colorUtils';
import { renderNotification } from '../../../utils/utils';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { Text } from '../../text/text';
import { Notification } from '../../toast/toast';
import {
  FakeButton,
  IconContainer,
  Image,
  UploadBackgroundImage,
  UploadImageText,
  UploadLabel,
  UploaderContainer,
  UploaderText,
  UploaderTitle,
} from './uploaderInput.styles';
import { DocumentType } from '../../../models/document';
import { SCRIVE_CONTRACT } from '../../../consts/document';

const ignoredTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
];

const compressImage = (img: File, callback: (result: File | Blob) => void) => {
  const maxFileSize = 2 * 1024 * 1024; // 3mb

  if (img && img.size > maxFileSize && !ignoredTypes.includes(img.type)) {
    new Compressor(img, {
      quality: 0.6,
      convertTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'],
      convertSize: 2000000,
      height: 2038,
      width: 1024,
      resize: 'none',
      success(result) {
        callback(result);
      },
    });
  } else {
    callback(img);
  }
};

interface UploaderProps {
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  error?: FieldError;
  onChange: (value: File | Blob) => void;
  value?: Blob | MediaSource | string;
  label: string;
  invalid?: boolean;
  required?: boolean;
  isExcelFile?: string[];
  isProfilePicture?: boolean;
  isImageOnly?: boolean;
  hideRequiredIndicator?: boolean;
  loading?: boolean;
  aditionalInfo?: string;
  documentType?: DocumentType;
  onDrop?: (value: File[]) => void;
}

const Uploader = React.forwardRef<HTMLInputElement, UploaderProps>(
  (
    {
      defaultValue,
      disabled,
      loading,
      label,
      hideRequiredIndicator,
      name,
      error,
      onChange,
      invalid,
      value,
      required,
      isExcelFile,
      isProfilePicture,
      isImageOnly,
      aditionalInfo,
      documentType,
      onDrop,
    }: UploaderProps,
    ref
  ) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [isPdf, setIsPdf] = useState<boolean>(false);
    function isImageCompressedOnValidSize(e: File | Blob) {
      if (e.size > (documentType === SCRIVE_CONTRACT ? 9437184 : 5242880)) {
        Notification({
          type: 'error',
          title: 'Upload failed',
          message: `File too big (${documentType === SCRIVE_CONTRACT ? '9MB' : '5MB'} limit) or incorrect format`,
          isAlert: true,
        });
        return;
      }
      if (onDrop) {
        onDrop([e as File]);
      }
      if (onChange) {
        onChange(e);
      }
    }

    function handleOnDrop(e: File[]) {
      compressImage(e[0], isImageCompressedOnValidSize);
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept:
        documentType === SCRIVE_CONTRACT
          ? { 'application/pdf': ['.pdf'] }
          : isExcelFile
            ? { 'application/vnd.ms-excel': isExcelFile ?? ['.xlsx'] }
            : isImageOnly
              ? { 'image/*': ['.jpeg', '.jpg', '.png'] }
              : {
                  'image/*': ['.jpeg', '.jpg', '.png'],
                  'application/pdf': ['.pdf'],
                },
      multiple: false,
      maxSize: 26214400, // 25MB
      onDrop: handleOnDrop,
    });

    function getFileTypeFromBlob(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          const uint8Array = new Uint8Array(arrayBuffer);

          // Check for PDF (%PDF)
          const isPdf =
            uint8Array[0] === 0x25 && uint8Array[1] === 0x50 && uint8Array[2] === 0x44 && uint8Array[3] === 0x46;
          if (isPdf) {
            resolve('pdf');
            return;
          }

          // Check for JPEG (0xFF 0xD8)
          const isJpeg = uint8Array[0] === 0xff && uint8Array[1] === 0xd8;
          if (isJpeg) {
            resolve('jpeg');
            return;
          }

          // Check for PNG (0x89 0x50 0x4E 0x47)
          const isPng =
            uint8Array[0] === 0x89 && uint8Array[1] === 0x50 && uint8Array[2] === 0x4e && uint8Array[3] === 0x47;
          if (isPng) {
            resolve('png');
            return;
          }

          resolve('unknown');
        };

        reader.onerror = (err) => {
          reject('Error reading blob: ' + err);
        };

        // Read the first 4 bytes of the file to determine its type
        reader.readAsArrayBuffer(blob.slice(0, 4));
      });
    }

    useEffect(() => {
      const fetchImageUrl = async () => {
        if (value) {
          const url = await handlePreview(value);
          setImageUrl(url);
        } else {
          setImageUrl(defaultValue);
        }
      };

      fetchImageUrl();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, defaultValue]);

    async function handlePreview(file: File | Blob | string | MediaSource) {
      if (typeof file === 'string') {
        return file;
      }
      if (file instanceof File && file?.type === 'application/pdf') {
        setIsPdf(true);
      }

      if (file instanceof Blob) {
        if ((await getFileTypeFromBlob(file)) === 'pdf') {
          setIsPdf(true);
        }
      }

      return URL.createObjectURL(file);
    }

    return (
      <div>
        <UploadLabel>
          {label}
          {aditionalInfo && <span style={{ marginLeft: '5px', fontSize: '10px' }}>{aditionalInfo}</span>}
          {required && !hideRequiredIndicator && <span style={{ color: STATUS_RED, marginLeft: '5px' }}>*</span>}
        </UploadLabel>
        {isProfilePicture ? (
          <FlexLayout gap={16} vertical itemsX="center" {...getRootProps()}>
            <IconContainer
              itemsX="center"
              itemsY="center"
              styled={{ cursor: defaultValue ? 'pointer' : 'default' }}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.stopPropagation();
                defaultValue ? window.open(defaultValue, '_blank') : null;
              }}
            >
              {acceptedFiles?.length > 0 || defaultValue ? (
                <Image alt="profile-picture" src={acceptedFiles?.[0] ? imageUrl : defaultValue ?? ''} />
              ) : (
                <BsPersonPlus size={50} color={PRIMARY_WHITE} />
              )}
            </IconContainer>
            <FakeButton variant="body7" color={PRIMARY_WHITE} weight={300}>
              Upload image
            </FakeButton>
            <input type="file" ref={ref} {...getInputProps()} name={name} accept="image/*" capture="environment" />
          </FlexLayout>
        ) : isImageOnly ? (
          <UploaderContainer
            $error={invalid}
            $active={!!value || !!defaultValue}
            $disabled={disabled || loading}
            {...getRootProps()}
          >
            {!value && !defaultValue && (
              <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="start" itemsY="center">
                <FiCamera size={52} color={SECONDARY_PURPLE_30} />
                <UploadImageText>
                  <input
                    type="file"
                    ref={ref}
                    {...getInputProps()}
                    name={name}
                    accept="image/*"
                    capture="environment"
                  />
                  <UploaderTitle>Upload your image</UploaderTitle>
                  <UploaderText>Drag and drop or take a snapshot</UploaderText>
                </UploadImageText>
              </FlexLayout>
            )}
            {(value || defaultValue) && (
              <UploadBackgroundImage
                draggable="false"
                alt={isExcelFile ? 'Upload your file' : 'Upload your image'}
                width={40}
                src={value ? imageUrl : defaultValue}
                $visible={!!value ?? false}
                $active={!!value || !!defaultValue}
              />
            )}
            <input type="file" ref={ref} {...getInputProps()} name={name} accept="image/*" capture="environment" />
          </UploaderContainer>
        ) : (
          <UploaderContainer
            $error={invalid}
            $active={!!value || !!defaultValue}
            $disabled={disabled || loading}
            {...getRootProps()}
          >
            <>
              {loading ? (
                <Spinner size={35} color={PRIMARY_GREEN} styled={{ marginRight: 'auto', marginLeft: 'auto' }} />
              ) : (
                <>
                  {!value && !defaultValue && (
                    <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="center" itemsY="center">
                      <FiUploadCloud size={52} color={SECONDARY_PURPLE_30} />
                      <UploadImageText>
                        <UploaderTitle>Upload your file or image</UploaderTitle>
                        <UploaderText>Drag and drop or click to select file</UploaderText>
                      </UploadImageText>
                    </FlexLayout>
                  )}
                  {(value || defaultValue) && !isPdf && (
                    <UploadBackgroundImage
                      draggable="false"
                      alt={isExcelFile ? 'Upload your file' : 'Upload your image'}
                      width={40}
                      src={value ? imageUrl : defaultValue}
                      $visible={!!value ?? false}
                      $active={!!value || !!defaultValue}
                    />
                  )}
                  {(value || defaultValue) && isPdf && (
                    <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                      <BsFiletypePdf size={50} color={PRIMARY_PURPLE}></BsFiletypePdf>
                    </div>
                  )}
                  {defaultValue && (
                    <Text variant="body7" weight={500} color={PRIMARY_PURPLE}>
                      <a href={defaultValue} target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    </Text>
                  )}
                  <UploadImageText>
                    <input
                      type="file"
                      defaultValue={defaultValue}
                      ref={ref}
                      {...getInputProps()}
                      name={name}
                      accept="image/*"
                      capture="environment"
                    />
                    {value && !defaultValue && <UploaderText>{name}</UploaderText>}
                    {!value && defaultValue && (
                      <>
                        <UploaderText>{defaultValue}</UploaderText>
                        <UploaderText>click to replace</UploaderText>
                      </>
                    )}
                  </UploadImageText>
                </>
              )}
            </>
          </UploaderContainer>
        )}
        {error && (
          <Text variant="body7" color={STATUS_RED} weight={300}>
            {error?.message ?? ''}
          </Text>
        )}
      </div>
    );
  }
);

interface UploaderInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  disabled?: boolean;
  loading?: boolean;
  label: string;
  control: Control<TFieldValues, string>;
  name: TName;
  defaultValue?: TName;
  isExcelFile?: string[];
  isProfilePicture?: boolean;
  isImageOnly?: boolean;
  required?: boolean;
  error?: FieldError;
  hideRequiredIndicator?: boolean;
  aditionalInfo?: string;
  onDrop?: (value: File[]) => void;
  fileUrlToBlob?: string;
  documentType?: DocumentType;
}

export const UploaderInput = withStyledProps(
  <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    defaultValue,
    disabled,
    label,
    name,
    required,
    loading,
    isExcelFile,
    isProfilePicture,
    isImageOnly,
    control,
    error,
    aditionalInfo,
    onDrop,
    fileUrlToBlob,
    documentType,
    ...props
  }: UploaderInputProps<TFieldValues, TName>) => {
    const [updatedValue, setUpdatedValue] = useState<string>();
    const fileService = getFileService();

    useEffect(() => {
      const fetchImage = async () => {
        if (fileUrlToBlob) {
          try {
            const linkToStorageFile = await fileService.getFileUrlFromStorage({ path: fileUrlToBlob });

            setUpdatedValue(linkToStorageFile.href);
          } catch (error: any) {
            renderNotification('error', 'Error', `${error.message}`, true);
          }
        }
      };

      fetchImage();
    }, [fileUrlToBlob, fileService]);

    return (
      <Controller
        control={control}
        rules={{
          required: required ? 'Please upload a file' : undefined,
        }}
        name={name}
        render={({ field: { onChange, name, value }, fieldState: { invalid } }) => {
          return (
            <div {...props}>
              <Uploader
                label={label}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                loading={loading}
                error={error}
                invalid={invalid}
                isExcelFile={isExcelFile}
                isProfilePicture={isProfilePicture}
                isImageOnly={isImageOnly}
                value={fileUrlToBlob ? updatedValue : value}
                aditionalInfo={aditionalInfo}
                documentType={documentType}
                onDrop={onDrop}
                {...{ onChange, name }}
              />
            </div>
          );
        }}
      />
    );
  }
);
