import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SelectInput } from '../../../../components/inputs/select-input/select-input';
import { Option } from '../../../../components/ui/select/select';
import { DatePicker } from '../../../../components/inputs/date-pickers/date-picker/date-picker';
import { Button } from '../../../../components/ui/button/button';
import { BsArrowLeft, BsPencil, BsX } from 'react-icons/bs';
import { LocationListItem, ManufacturerListItem, ModelListItem } from '../../../../models/contract';
import { Notification } from '../../../../uiComponents/toast/toast';
import { useNavigate } from 'react-router-dom';
import { FLEET } from '../../../../consts/routes';
import {
  useCreateVehicleBatchMutation,
  useEditVehicleBatchMutation,
  useGetFinanceSourceQuery,
} from '../../../../api/vehicleBatch/vehicleBatchSliceApi';
import {
  useGetLocationListQuery,
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
} from '../../../../api/contracts/contractsDetailsApiSlice';
import { useGetColorsListQuery, useGetFuelListQuery } from '../../../../api/vehicleModel/vehicleModelApiSlice';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { SelectColorQuantity } from '../../../../components/inputs/select-color-quantity/select-color-quantity';

export interface BatchPayload {
  id?: string;
  manufacturer: string;
  vehicle_model: string;
  model_year: string;
  fuel_type: string;
  vehicle_type: string;
  color: string;
  available_on: string;
  city: string;
  finance_source: string;
  cost: number;
  vat: number;
  net: number;
}

const optionSchema = z.object({
  value: z.string().min(1, 'Value is required'),
  label: z.string().min(1, 'Label is required'),
  info: z.string().optional(),
  node: z.any().optional(),
});

const batchFormSchema = z.object({
  id: z.string().optional(),
  manufacturer: z.array(optionSchema).nonempty('Manufacturer is required'),
  vehicle_model: z.array(optionSchema).nonempty('Model is required'),
  model_year: z.number().min(-1, 'Model year is required'),
  fuel_type: z.array(optionSchema).nonempty('Fuel type is required'),
  vehicle_type: z.array(optionSchema).nonempty('Type of vehicle is required'),
  exterior_colors: z.array(optionSchema).optional(),
  available_on: z.date(),
  location: z.array(optionSchema).nonempty('City is required'),
  finance_source: z.array(optionSchema).nonempty('Finance source is required'),
  cost: z.number().min(-1, 'Cost is required'),
  vat: z.number().min(-1, 'VAT is required'),
  net: z.number().min(-1, 'Net value is required'),
});

export type BatchFormType = z.infer<typeof batchFormSchema>;

export const vehicleTypeList: Option[] = [
  { value: 'new', label: 'New' },
  { value: 'used', label: 'Used' },
];

export interface BatchFormProps {
  defaultValues?: BatchFormType;
  isEdit?: boolean;
}

export const BatchForm = ({ defaultValues, isEdit = false }: BatchFormProps) => {
  const navigate = useNavigate();
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: modelList } = useGetVehicleModelListQuery({});
  const { data: locationList } = useGetLocationListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: colorsList } = useGetColorsListQuery({});
  const { data: financeSourceList } = useGetFinanceSourceQuery({});

  const [createBatch, { isLoading: createBatchLoading }] = useCreateVehicleBatchMutation();
  const [editBatch, { isLoading: editBatchLoading }] = useEditVehicleBatchMutation();
  const isLoading = createBatchLoading || editBatchLoading;
  const [vehicleModelOptions, setVehicleModelOptions] = useState<Option[]>([]);
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<BatchFormType>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: zodResolver(batchFormSchema),
  });
  const manufacturers = watch('manufacturer');

  useEffect(() => {
    if (manufacturers && modelList?.data) {
      const manufacturersList = manufacturers?.map((el) => el.value);

      setVehicleModelOptions(
        modelList.data
          ?.filter((item: ModelListItem) => manufacturersList.includes(item.manufacturer_id))
          ?.map((item: ModelListItem) => {
            return { value: item.id, label: item.model };
          })
      );
      const vehicleModels = getValues('vehicle_model');
      if (vehicleModels) {
        const vehicleModelsList = modelList.data
          .filter((item: any) => vehicleModels?.map((el) => el.value).includes(item.id))
          ?.filter((item: any) => manufacturersList.includes(item.manufacturer_id))
          ?.map((item: any) => {
            return { value: item.id, label: item.model };
          });
        setValue('vehicle_model', vehicleModelsList, { shouldValidate: true, shouldDirty: true });
      }
    }
  }, [manufacturers, modelList?.data, getValues, setValue]);

  const handleChange =
    (field: keyof BatchFormType) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValue(field, parseFloat(event.target.value), { shouldValidate: true, shouldDirty: true });
    };

  const handleSelectChange = (field: keyof BatchFormType) => (option: Option[]) => {
    setValue(field, option as any, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<BatchFormType> = (data) => {
    createBatch({
      manufacturer_id: data.manufacturer?.[0]?.value,
      vehicle_model_id: data.vehicle_model?.[0]?.value,
      location_id: data.location?.[0]?.value,
      fuel_type_id: data.fuel_type?.[0]?.value,
      vehicle_finance_source_id: data.finance_source?.[0]?.value,
      vehicle_type: data.vehicle_type?.[0]?.value,
      model_year: data.model_year,
      cost: data.cost,
      vat: data.vat,
      net: data.net,
      available_on: data.available_on,
      exterior_colors: data.exterior_colors?.map((val) => {
        return {
          quantity: parseInt(val.info || '0'),
          id: val.value,
        };
      }),
    })
      .unwrap()
      .then(() => {
        Notification({
          type: 'success',
          title: 'Vehicle batch created',
          message: 'Your vehicle batch was created successfully',
        });
        navigate(`${FLEET}/vehicle-batches`);
      })
      .catch((error) => {
        Notification({
          type: 'error',
          title: 'Error creating vehicle batch',
          message: `${error.status}: An error occurred while trying to create vehicle batch`,
        });
      });
  };

  const onEdit: SubmitHandler<BatchFormType> = (data) => {
    editBatch({
      id: data.id,
      manufacturer_id: data.manufacturer?.[0]?.value,
      vehicle_model_id: data.vehicle_model?.[0]?.value,
      location_id: data.location?.[0]?.value,
      fuel_type_id: data.fuel_type?.[0]?.value,
      vehicle_finance_source_id: data.finance_source?.[0]?.value,
      vehicle_type: data.vehicle_type?.[0]?.value,
      model_year: data.model_year,
      cost: data.cost,
      vat: data.vat,
      net: data.net,
      available_on: data.available_on,
      exterior_colors: data.exterior_colors?.map((val) => {
        return {
          quantity: parseInt(val.info || '0'),
          id: val.value,
        };
      }),
    })
      .unwrap()
      .then(() => {
        Notification({
          type: 'success',
          title: 'Vehicle batch updated',
          message: 'Your vehicle batch was updated successfully',
        });
        navigate(`${FLEET}/vehicle-batches`);
      })
      .catch((error) => {
        Notification({
          type: 'error',
          title: 'Error updating vehicle batch',
          message: `${error.status}: An error occurred while trying to update the vehicle batch`,
        });
      });
  };

  return (
    <div className="bg-white p-8 pb-0">
      <Button
        type="button"
        color="secondary"
        data-testid="back"
        onClick={() => {
          navigate(`${FLEET}/vehicle-batches`);
        }}
      >
        <BsArrowLeft />
        Back
      </Button>

      <div className="pt-8 pb-5 mb-14 border-solid border-b border-utility-gray-200">
        {isEdit ? (
          <div className="flex justify-between items-center">
            <div>
              <span className="text-lg font-extrabold text-utility-gray-900" data-testid="title">
                Edit vehicle batch
              </span>
              <p className="text-sm font-light text-utility-gray-600" data-testid="title">
                ID: {getValues().id}
              </p>
            </div>
            <Button
              className="max-sm:hidden"
              type="button"
              color="secondary"
              data-testid="back-edit"
              onClick={() => {
                navigate(`${FLEET}/batches`);
              }}
            >
              <BsX size="24" />
              Cancel Editing
            </Button>
          </div>
        ) : (
          <span className="text-lg font-extrabold text-utility-gray-900" data-testid="title">
            Create vehicle batch
          </span>
        )}
      </div>
      <div>
        <form>
          <div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4 pb-12">
              <SelectInput
                data-testid="manufacturer"
                label="Manufacturer"
                placeholder="Select manufacturer"
                options={(manufacturerList?.data || [])?.map((item: ManufacturerListItem) => {
                  return { value: item.id, label: item.name };
                })}
                value={getValues().manufacturer}
                onSelect={handleSelectChange('manufacturer')}
                error={errors.manufacturer?.message}
                required
                disabled={isEdit}
              />
              <SelectInput
                data-testid="model"
                label="Model"
                placeholder="Select vehicle model"
                options={vehicleModelOptions}
                value={getValues().vehicle_model}
                onSelect={handleSelectChange('vehicle_model')}
                error={errors.vehicle_model?.message}
                required
                disabled={isEdit}
              />
              <TextInput
                data-testid="model_year"
                label="Model year"
                placeholder="eg. 2024"
                value={getValues().model_year}
                onChange={handleChange('model_year')}
                error={errors.model_year?.message}
                required
                type="number"
                disabled={isEdit}
              />
              <SelectInput
                data-testid="fuel_type"
                label="Fuel Type"
                placeholder="Please choose fuel type"
                options={(fuelList?.data || [])?.map((item: { id: string; name: string }) => {
                  return { value: item.id, label: item.name };
                })}
                value={getValues().fuel_type}
                onSelect={handleSelectChange('fuel_type')}
                error={errors.fuel_type?.message}
                required
                disabled={isEdit}
              />
              <SelectInput
                data-testid="type_of_vehicle"
                label="Used/New"
                placeholder="Please choose condition"
                options={vehicleTypeList}
                value={getValues().vehicle_type}
                onSelect={handleSelectChange('vehicle_type')}
                error={errors.vehicle_type?.message}
                required
                disabled={isEdit}
              />

              <DatePicker
                data-testid="available_on"
                label="Available on"
                placeholder="yyyy/mm/dd"
                value={getValues().available_on || undefined}
                onChange={(date) => setValue('available_on', date)}
                error={errors.available_on?.message}
                required
              />
              <SelectInput
                data-testid="location"
                label="City"
                placeholder="Please choose city"
                options={(locationList?.data || [])?.map((item: LocationListItem) => {
                  return { value: item.city_id, label: item.city_name };
                })}
                value={getValues().location}
                onSelect={handleSelectChange('location')}
                error={errors.location?.message}
                required
                disabled={isEdit}
              />
              <SelectInput
                data-testid="finance_source"
                label="Finance source"
                placeholder="Please choose finance source"
                options={(financeSourceList?.data || [])?.map((item: { id: string; name: string }) => {
                  return { value: item.id, label: item.name };
                })}
                value={getValues().finance_source}
                onSelect={handleSelectChange('finance_source')}
                error={errors.finance_source?.message}
                required
              />
              <TextInput
                data-testid="cost"
                label="Cost of vehicle"
                placeholder="00,000.00"
                value={getValues().cost}
                onChange={handleChange('cost')}
                error={errors.cost?.message}
                required
                type="number"
                fixedStartAdornment={<span className="h-full flex items-center">£</span>}
              />
              <TextInput
                data-testid="vat"
                label="VAT"
                placeholder="000.00"
                value={getValues().vat}
                onChange={handleChange('vat')}
                error={errors.vat?.message}
                required
                type="number"
                fixedStartAdornment={<span className="h-full flex items-center">£</span>}
              />
              <TextInput
                data-testid="net"
                label="Net value"
                placeholder="00,000.00"
                value={getValues().net}
                onChange={handleChange('net')}
                error={errors.net?.message}
                required
                type="number"
                fixedStartAdornment={<span className="h-full flex items-center">£</span>}
              />
              <SelectColorQuantity
                data-testid="exterior_colors"
                label="Colors"
                placeholder="Select"
                options={(colorsList?.data || [])?.map((item: { id: string; name: string }) => {
                  return { value: item.id, label: item.name };
                })}
                value={getValues().exterior_colors}
                onChange={handleSelectChange('exterior_colors')}
                error={errors.exterior_colors?.message}
                disabled={isEdit}
              />
            </div>
          </div>

          {isEdit ? (
            <div className="w-full items-center flex justify-end gap-3 py-5 sticky bottom-0 bg-white border-solid border-t border-utility-gray-200">
              <div>
                <Button
                  type="button"
                  data-testid="update"
                  disabled={!isDirty || !isValid || isLoading}
                  onClick={handleSubmit(onEdit)}
                  className="max-sm:w-full"
                >
                  Update
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full items-center flex justify-end gap-3 py-5 sticky bottom-0 bg-white border-solid border-t border-utility-gray-200">
              <div>
                <Button
                  className="w-full"
                  type="button"
                  data-testid="create"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty || !isValid || isLoading}
                >
                  <BsPencil size="16" />
                  Create
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
