import { Assessment, HistoricalDataArray } from '../../models/assessment';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_5 } from '../../common/styles/Colors';
import { BsPerson, BsPersonCheck } from 'react-icons/bs';
import { Text } from '../../uiComponents/text/text';
import { FieldGrid } from '../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Tag } from '../../uiComponents/customComponents/tag/tag';
import { statusColors } from '../../common/utils';
import { AssessmentContainer, NotesContainer } from './assessment.styles';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { handleDownloadFileFromStorage } from '../../utils/utils';
import { GridLayout } from '../../uiComponents/layouts/gridLayout/gridLayout';
import { format } from 'date-fns';

interface HistoricalAssessmentProps {
  historicalAssessmentDetails?: HistoricalDataArray;
  selectedAssessment?: Assessment | null;
}

export const HistoricalAssessment = ({
  historicalAssessmentDetails,
  selectedAssessment,
}: HistoricalAssessmentProps) => {
  return (
    <AssessmentContainer>
      <GridLayout template={2} gap={16} styled={{ marginTop: 32 }}>
        <div>
          <FlexLayout gap={88} itemsY="center">
            <FlexLayout itemsY="center" gap={16}>
              <BsPerson size={40} color={PRIMARY_PURPLE} />
              <div>
                <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                  Driver name
                </Text>
                <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                  {selectedAssessment?.driver_name ?? '-'}
                </Text>
              </div>
            </FlexLayout>
            <FlexLayout itemsY="center" gap={16}>
              <BsPersonCheck size={40} color={PRIMARY_PURPLE} />
              <div>
                <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                  Agent name
                </Text>
                <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                  {selectedAssessment?.assessment_type_data[0]?.agent ?? '-'}
                </Text>
              </div>
            </FlexLayout>
          </FlexLayout>
          <FieldGrid
            styled={{ marginTop: 48 }}
            numColumns={3}
            headers={[
              'DVLA:',
              'Test date:',
              'Assessment type:',
              'Assessment result:',
              'Assessment branch:',
              'Tags/flags:',
            ]}
            values={[
              selectedAssessment?.dvla ?? '-',
              selectedAssessment?.dvla_test_date
                ? format(new Date(selectedAssessment?.dvla_test_date), 'dd MMM yyyy')
                : '-',
              historicalAssessmentDetails?.evaluation_type ?? '-',
              <Tag
                color={
                  statusColors[selectedAssessment?.assessment_type_data[0]?.assessment_status ?? '-'] ?? PRIMARY_PURPLE
                }
              >
                {historicalAssessmentDetails?.assessment_status ?? '-'}
              </Tag>,
              selectedAssessment?.assessment_type_data[0]?.branch_name ?? '-',
              <Tag color={statusColors[historicalAssessmentDetails?.tag_name ?? '-'] ?? PRIMARY_PURPLE}>
                {historicalAssessmentDetails?.tag_name ?? '-'}
              </Tag>,
            ]}
          />
        </div>
        <div>
          <Text
            variant="body7"
            weight={300}
            color={PRIMARY_PURPLE}
            block
            styled={{ lineHeight: 1, backgroundColor: SECONDARY_PURPLE_5, borderRadius: 50, padding: '5px 32px' }}
          >
            Notes:
          </Text>
          <NotesContainer>
            <Text variant="body8" weight={300} color={PRIMARY_PURPLE}>
              {historicalAssessmentDetails?.notes ?? ''}
            </Text>
          </NotesContainer>
          {historicalAssessmentDetails?.s3_url && selectedAssessment?.is_practical && (
            <PrimaryButton
              onClick={() => handleDownloadFileFromStorage(historicalAssessmentDetails?.s3_url)}
              styled={{ float: 'right', marginTop: 24 }}
            >
              View report
            </PrimaryButton>
          )}
        </div>
      </GridLayout>
    </AssessmentContainer>
  );
};
