import React, { useState, useCallback } from 'react';
import { FieldError, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextArea } from '../../../uiComponents/inputs/textArea/textArea';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { Notification } from '../../../uiComponents/toast/toast';
import { updateServicing } from '../../../api/patch/servicing.patch';
import { Servicing } from '../../../models/servicing';
import { handleAPIError, ErrorType } from '../../../utils/handleAPIError';
import { validateFutureDateFormat } from '../../../utils/validations';
import { format } from 'date-fns';

interface EditSubServicingProps {
  servicing?: Servicing;
  onFormSubmit: () => void;
  onClose: () => void;
}

export const EditSubServicing = ({ servicing, onFormSubmit, onClose }: EditSubServicingProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    notes: currentNote = '',
    projected_ttr: currentProjectedTTR,
    vehicle_id = '',
    servicing_type,
    id = '',
  } = servicing || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { ...servicing, projected_ttr: format(new Date(servicing?.projected_ttr as string), 'yyyy-MM-dd') },
  });

  const onSubmit = useCallback<SubmitHandler<FieldValues>>(
    async (values: FieldValues) => {
      const { projected_ttr, notes } = values;
      setIsLoading(true);
      try {
        const payload = {
          vrm: vehicle_id,
          notes,
          projected_ttr,
          servicing_type,
          end_date: undefined,
        };
        updateServicing(id, payload).then(() => {
          setIsLoading(false);
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Subservicing has been successfully edited',
          });
          close();
        });
      } catch (err) {
        handleAPIError(err as ErrorType);
        setIsLoading(false);
      }
      onFormSubmit();
    },
    [id, onFormSubmit, servicing_type, vehicle_id]
  );

  return (
    <>
      <TextField
        {...register('projected_ttr', {
          required: 'Projected resolution date cannot be empty',
          validate: (value: string) => validateFutureDateFormat(value),
        })}
        defaultValue={currentProjectedTTR}
        error={errors['projected_ttr'] as FieldError}
        label="Projected resolution date"
        loading={isLoading}
        name="projected_ttr"
        required
        type="date"
      />
      <div style={{ marginTop: 20 }}>
        <TextFieldLabel>Notes</TextFieldLabel>
        <TextArea {...register('notes')} defaultValue={currentNote} name="notes" placeholder="Notes"></TextArea>
      </div>
      <FlexLayout styled={{ marginTop: 20 }} itemsX="end" itemsY="end">
        <SecondaryButton styled={{ marginRight: '10px' }} isProcessing={isLoading} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={isLoading} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
