import { APP_CONTEXT } from '../../utils/context';
import React, { useContext, useEffect } from 'react';
import { SideNav } from '../dashboardPanel/sideNav/sideNav';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionSubtitle,
  AccordionTitle,
  AccordionTrigger,
} from '../../components/ui/accordion/accordion';
import { Dot, SquareArrowOutUpRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useGetReportsListQuery } from '../../api/reports/reportsApiSlice';
import { EMBEDDED_REPORT } from '../../consts/routes';

interface ReportLink {
  report_title: string;
  report_id: string;
  link_url: string;
}

interface Report {
  category_tile: string;
  category_subtitle?: string;
  reports?: ReportLink[];
}

export const Reports = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { data: reportsList } = useGetReportsListQuery({});

  const navigate = useNavigate();

  useEffect(() => {
    setActiveSideNav('reportsPage');
    setPageTitle('Reports');
  }, [setActiveSideNav, setPageTitle]);

  const handleNavigate = (reportLink: string) => {
    navigate(EMBEDDED_REPORT, { state: reportLink });
  };

  return (
    <>
      <SideNav />
      <div className="ml-64 w-[768px]">
        <Accordion type="single" collapsible>
          {reportsList?.data?.map((item: Report, index: number) => {
            return (
              <AccordionItem value={index.toString()}>
                <AccordionTrigger>
                  <AccordionTitle>{item.category_tile}</AccordionTitle>
                  {item.category_subtitle && <AccordionSubtitle>{item.category_subtitle}</AccordionSubtitle>}
                </AccordionTrigger>
                <AccordionContent>
                  {item.reports && (
                    <ul className="list-disc">
                      {item.reports.map((report) => {
                        return (
                          <li className="mb-2 flex items-center cursor-pointer max-w-fit">
                            <div className="flex items-center" onClick={() => handleNavigate(report.link_url)}>
                              <Dot />
                              <span className="pr-2 text-base font-light">{report.report_title}</span>
                              <SquareArrowOutUpRight width={20} />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </>
  );
};
