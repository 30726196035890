import React, { useCallback, SyntheticEvent } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { TextField } from '../../../../../uiComponents/inputs/textField/textField';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { CustomerProfile, DuplicatePcoCheck } from '../../../../../models/driver';
import { getDriverByPCO } from '../../../../../api/get/driver.get';
import { ukPostCode } from '../../../../../utils/validations';
import { TextFieldLabel } from '../../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../../uiComponents/uiControls/dropDown/dropDown';
import { titleOptions } from '../../../../../consts/user';
import { differenceInMonths, isAfter } from 'date-fns';

interface DriverDetailsProps {
  errors: FieldErrors<CustomerProfile>;
  register: UseFormRegister<CustomerProfile>;
  setError: UseFormSetError<CustomerProfile>;
  clearErrors: UseFormClearErrors<CustomerProfile>;
  setValue: UseFormSetValue<CustomerProfile>;
  control: Control<CustomerProfile>;
  driverData: DriverData;
}
interface DriverData {
  title?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  driver_id: string;
  address_line_1?: string;
  address_line_2?: string;
  address_city?: string;
  post_code?: string;
}
export const DriverDetailsForm = ({
  errors,
  register,
  control,
  setError,
  clearErrors,
  driverData,
}: DriverDetailsProps) => {
  const validatePCOIsAvailable = useCallback(
    (pcoNo: string) => {
      if (pcoNo) {
        getDriverByPCO(driverData?.driver_id, pcoNo).then((response: { data: DuplicatePcoCheck[] }) => {
          const otherDriversWithPcoNo = response?.data?.filter((item) => item?.id !== driverData.driver_id) ?? [];
          if (otherDriversWithPcoNo?.length > 0) {
            setError('pco_no', { message: 'This PCO number is in use' });
          } else {
            clearErrors('pco_no');
          }
        });
      }
    },
    [driverData?.driver_id, setError, clearErrors]
  );

  return (
    <div style={{ marginTop: 24 }}>
      <GridLayout template={2} gap="16px 38px">
        {!driverData?.title && (
          <>
            <div>
              <TextFieldLabel $isRequired>Title</TextFieldLabel>
              <DropDown
                control={control as unknown as Control<FieldValues>}
                options={titleOptions}
                placeholder="Title"
                name="title"
                error={errors?.title}
                required={{
                  required: 'Title is a required field',
                }}
              />
            </div>
            <TextField
              {...register('first_name', {
                required: 'First name is required',
              })}
              label="First name"
              type="text"
              error={errors?.first_name}
              required
            />
            <TextField {...register('middle_name')} label="Middle name" type="text" error={errors?.middle_name} />
            <TextField
              {...register('last_name', {
                required: 'Last name is required',
              })}
              label="Last name"
              type="text"
              error={errors?.last_name}
              required
            />
          </>
        )}
        {(!driverData?.address_line_1 || !driverData?.address_city || !driverData?.post_code) && (
          <>
            <TextField
              {...register('address_line_1', {
                required: 'Address is required',
              })}
              label="Address line 1"
              type="text"
              error={errors?.address_line_1}
              required
            />
            <TextField
              {...register('address_line_2')}
              label="Address line 2"
              type="text"
              error={errors?.address_line_2}
            />
            <TextField
              {...register('address_city', {
                required: 'City is required',
              })}
              label="City"
              type="text"
              error={errors?.address_city}
              required
            />
            <TextField
              {...register('address_post_code', {
                required: 'Post code is required',
                pattern: {
                  value: ukPostCode,
                  message: 'Must be post code format e.g. PO1 3AX',
                },
              })}
              label="Post code"
              type="text"
              error={errors?.address_post_code}
              required
            />
          </>
        )}
        <TextField
          {...register('dvla_test_date', {
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) > new Date()) {
                  return 'DVLA test date should be in the past';
                }
              },
            },
          })}
          label="DVLA test date"
          type="date"
          error={errors?.dvla_test_date}
        />
        <TextField
          {...register('dvla_expiry_date', {
            required: 'DVLA expiry date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) < new Date()) {
                  return 'DVLA expiry date should be in the future';
                }
              },
            },
          })}
          required
          label="DVLA expiry date"
          type="date"
          error={errors?.dvla_expiry_date}
        />
        <TextField
          {...register('pco_no', {
            required: 'PCO badge number is a required field',
            onBlur: (e: SyntheticEvent) => validatePCOIsAvailable((e.target as HTMLInputElement).value),
          })}
          required
          label="PCO badge number"
          type="text"
          placeholder="PCO badge number"
          error={errors?.pco_no}
        />
        <TextField
          {...register('pco_expiry_date', {
            required: 'PCO expiry date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) < new Date()) {
                  return 'PCO date should be in the future';
                }
              },
            },
          })}
          required
          label="PCO badge expiry"
          type="date"
          error={errors?.pco_expiry_date}
        />
        <TextField
          {...register('pco_cert_expiry_date', {
            required: 'PCO certificate expiry date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) < new Date()) {
                  return 'PCO certificate expiry date should be in the future';
                }
              },
            },
          })}
          required
          label="PCO certificate expiry date"
          type="date"
          error={errors?.pco_cert_expiry_date}
        />
        <TextField
          {...register('poa_expiry_date', {
            required: 'Proof of address issue date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                const isFuture: boolean = isAfter(new Date(date as string), new Date());
                const olderThan3Months: boolean = differenceInMonths(new Date(), new Date(date as string)) > 3;
                if (isFuture || olderThan3Months) {
                  return 'Proof of address issue date should be within the last 3 months';
                }
              },
            },
          })}
          required
          label="Proof of address issue date"
          type="date"
          error={errors?.poa_expiry_date}
        />
      </GridLayout>
    </div>
  );
};
