import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BatchForm, BatchFormType, vehicleTypeList } from '../batchForm/batchForm';
import { LocationListItem, ManufacturerListItem, ModelListItem } from '../../../../models/contract';
import {
  useGetLocationListQuery,
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
} from '../../../../api/contracts/contractsDetailsApiSlice';
import { useGetColorsListQuery, useGetFuelListQuery } from '../../../../api/vehicleModel/vehicleModelApiSlice';
import {
  useGetFinanceSourceQuery,
  useGetVehicleBatchByIdQuery,
} from '../../../../api/vehicleBatch/vehicleBatchSliceApi';

export const EditVehicleBatch = () => {
  const { id } = useParams();
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: modelList } = useGetVehicleModelListQuery({});
  const { data: locationList } = useGetLocationListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: colorsList } = useGetColorsListQuery({});
  const { data: financeSourceList } = useGetFinanceSourceQuery({});
  const { data: vehicleBatch } = useGetVehicleBatchByIdQuery({ query: id });
  const [data, setData] = useState<BatchFormType>();

  useEffect(() => {
    if (
      manufacturerList?.data &&
      modelList?.data &&
      locationList?.data &&
      fuelList?.data &&
      colorsList?.data &&
      financeSourceList?.data &&
      vehicleBatch?.data
    ) {
      const manufacturer = manufacturerList.data
        ?.filter((item: ManufacturerListItem) => item.id === vehicleBatch.data.manufacturer_id)
        .map((opt: ManufacturerListItem) => {
          return {
            value: opt.id,
            label: opt.name,
          };
        });
      const vehicle_model = modelList.data
        ?.filter((item: ModelListItem) => item.id === vehicleBatch.data.vehicle_model_id)
        .map((opt: ModelListItem) => {
          return {
            value: opt.id,
            label: opt.model,
            info: opt.manufacturer_id,
          };
        });
      const location = locationList.data
        ?.filter((item: LocationListItem) => item.city_id === vehicleBatch.data.location_id)
        .map((opt: LocationListItem) => {
          return {
            value: opt.city_id,
            label: opt.city_name,
          };
        });
      const fuel_type = fuelList.data
        ?.filter((item: ManufacturerListItem) => item.id === vehicleBatch.data.fuel_type_id)
        .map((opt: ManufacturerListItem) => {
          return {
            value: opt.id,
            label: opt.name,
          };
        });
      const finance_source = financeSourceList.data
        ?.filter((item: ManufacturerListItem) => item.id === vehicleBatch.data.vehicle_finance_source_id)
        .map((opt: ManufacturerListItem) => {
          return {
            value: opt.id,
            label: opt.name,
          };
        });

      const exterior_colors = vehicleBatch.data.exterior_colors?.map(
        (opt: { id: string; name: string; quantity: number }) => {
          return {
            value: opt.id,
            label: opt.name,
            info: opt.quantity.toString(),
          };
        }
      );
      const vehicle_type = vehicleTypeList?.filter((item) => item.value === vehicleBatch.data.vehicle_type);
      const available_on = vehicleBatch.data.available_on ? new Date(vehicleBatch.data.available_on) : undefined;

      setData({
        ...vehicleBatch.data,
        manufacturer,
        vehicle_model,
        finance_source,
        location,
        fuel_type,
        vehicle_type,
        available_on,
        exterior_colors,
      });
    }
  }, [manufacturerList, modelList, locationList, vehicleBatch, fuelList, colorsList?.data, financeSourceList]);

  return <>{data && <BatchForm defaultValues={data} isEdit />}</>;
};
