import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { getFileService } from '../../../../api/cognito/file.service';
import { getAllTransfers } from '../../../../api/get/transfer.get';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { AgreementTableProps } from '../../../../models/agreement';
import { Transfer } from '../../../../models/transfer';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../uiComponents/table/table';
import { transferColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTextCell,
  getQueryString,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../../uiComponents/text/text';
const fileService = getFileService();

interface AgreementTransfersProps extends AgreementTableProps {
  tableHeader: string;
}

const handleView = async (url: string) => {
  const finalUrl = await fileService.getFileUrlFromStorage({ path: url });
  window.open(finalUrl);
};

export const AgreementTransfers = ({ agreementId, tableHeader }: AgreementTransfersProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const handleGetTransfersResponse = useCallback(
    (count: number, transfers: Transfer[]) => {
      const transfereRows = transfers?.map((transfer: Transfer) => {
        return {
          rowData: { data: transfer },
          cells: [
            <TableTextCell value={transfer?.transfer_type ? transfer?.transfer_type : ''} />,
            <TableTextCell value={transfer?.reason ?? '-'} />,
            <TableTextCell value={transfer?.in_date ? format(new Date(transfer?.in_date), 'dd MMM yyyy') : '-'} />,
            <TableTextCell value={transfer?.out_date ? format(new Date(transfer?.out_date), 'dd MMM yyyy') : '-'} />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => handleView(transfer?.s3_url || '')}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View condition report"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(transfereRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchTransferList = useCallback(
    (queryString: string) => {
      getAllTransfers(queryString).then((response: { count: number; data: Transfer[] }) => {
        handleGetTransfersResponse(response.count, response.data);
      });
    },
    [handleGetTransfersResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString(tableFilters, rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchTransferList(queryString);
    },
    [fetchTransferList, setTableData, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('vrm');
    fetchTransferList(
      `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC&&filter=agreement_id$eq=${agreementId}`
    );
    setTableFilters([{ columnName: 'agreement_id', options: { label: agreementId, value: agreementId } }]);
  }, [fetchTransferList, setSortingColumn, setTableFilters, agreementId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          {tableHeader}
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          {tableHeader}
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={transferColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
          }}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
