import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AgreementInfoProps } from '../agreementPage/agreementPage';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Text } from '../../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { format } from 'date-fns';

export const AgreementDetails = ({ agreement }: AgreementInfoProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { biAnnualAudit } = useFlags();

  return (
    <>
      <CollapsiblePanel
        header={
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            Agreement details
          </Text>
        }
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        expanded={!isCollapsed}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            Agreement details
          </Text>
          <FieldGrid
            numColumns={4}
            headers={
              biAnnualAudit
                ? [
                    'Agreement start date:',
                    'Agreement end date:',
                    'Payment start date:',
                    'Agreement type:',
                    'Weekly amount:',
                    'Car and charge package:',
                    'Uber clean air package:',
                  ]
                : [
                    'Agreement start date:',
                    'Agreement end date:',
                    'Projected contract end date',
                    'Termination date (only for terminated agreement)',
                    'Payment start date:',
                    'Agreement type:',
                    'Agreement length:',
                    'Weekly amount:',
                    'Car and charge package:',
                    'Uber clean air package:',
                  ]
            }
            values={
              biAnnualAudit
                ? [
                    (agreement?.start_date && format(new Date(agreement?.start_date), 'dd MMMM yyyy')) || '-',
                    (agreement?.end_date && format(new Date(agreement?.end_date), 'dd MMMM yyyy')) || '-',
                    (agreement?.payment_start_date &&
                      format(new Date(agreement?.payment_start_date), 'dd MMMM yyyy')) ||
                      '-',
                    agreement?.agreement_type,
                    agreement?.agreement_value_weeks || '-',
                    agreement?.car_and_charge ? 'Yes' : 'No',
                    agreement?.uber_clean_air_amount || '-',
                  ]
                : [
                    (agreement?.start_date && format(new Date(agreement?.start_date), 'dd MMMM yyyy')) || '-',
                    (agreement?.end_date && format(new Date(agreement?.end_date), 'dd MMMM yyyy')) || '-',
                    (agreement?.projected_contract_end_date &&
                      format(new Date(agreement?.projected_contract_end_date), 'dd MMMM yyyy')) ||
                      '-',
                    (agreement?.termination_date && format(new Date(agreement?.termination_date), 'dd MMMM yyyy')) ||
                      '-',
                    (agreement?.payment_start_date &&
                      format(new Date(agreement?.payment_start_date), 'dd MMMM yyyy')) ||
                      '-',
                    agreement?.agreement_type,
                    agreement?.agreement_length || '-',
                    agreement?.agreement_value_weeks || '-',
                    agreement?.car_and_charge ? 'Yes' : 'No',
                    agreement?.uber_clean_air_amount || '-',
                  ]
            }
          />
        </div>
      </CollapsiblePanel>
    </>
  );
};
