// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
// COMPONENTS & STYLES
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { newVehicleBatchListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { TableRowGrid } from '../../../uiComponents/table/table.styles';
import { NoResults } from '../../../uiComponents/table/noResults/noResults';
import { Text } from '../../../uiComponents/text/text';
// API CALLS & MODELS
import { getAllBatches } from '../../../api/get/batches.get';
import { BatchVehicle, NewVehicleBatch } from '../../../models/vehicle';
// HOOKS & UTILS & COMMONS
import { useTableFilters } from '../../../hooks/useTableFilters';
import { OptionList } from '../../../utils/props';
import { PRIMARY_GRAY, PRIMARY_PURPLE, SECONDARY_GRAY_20, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';
import { Modal } from '../../../uiComponents/modals/modal';
import { EditVehicleBatchForm } from './editVehicleBatchForm';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useListAndMergeNewVehicleBatchesListQuery } from '../../../api/listAndMerge/listAndMergeVehicleBatchesApiSlice';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { FLEET } from '../../../consts/routes';
import {
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
} from '../../../api/contracts/contractsDetailsApiSlice';
import { ModelListItem } from '../../../models/contract';
import {
  useGetAllVehicleModelYearsListQuery,
  useGetFuelListQuery,
} from '../../../api/vehicleModel/vehicleModelApiSlice';

interface RenderBatchVehicleListProps {
  vehicles: BatchVehicle[];
  retchBatchesList: () => void;
}
const RenderBatchVehicleList = ({ vehicles, retchBatchesList }: RenderBatchVehicleListProps) => {
  const [vehicleValue, setVehicleValue] = useState<BatchVehicle>();
  const [openUpdateVehicle, setOpenUpdateVehicle] = useState<boolean>(false);
  const editVehicle = (row: BatchVehicle) => {
    setOpenUpdateVehicle(true);
    setVehicleValue(row);
  };
  const closeModal = (action?: 'cancel' | 'submit') => {
    setOpenUpdateVehicle(false);
    if (action === 'submit') {
      retchBatchesList();
    }
  };

  return (
    <>
      <TableRowGrid $rowClickable={false} $borderColur={SECONDARY_GRAY_20} gap={8} template={4}>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          VIN
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          VRM
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Available Date
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Actions
        </Text>
      </TableRowGrid>
      {vehicles?.length > 0 ? (
        <>
          {vehicles?.map((vehicle: BatchVehicle) => (
            <TableRowGrid $borderColur={SECONDARY_GRAY_20} $rowClickable={false} key={vehicle?.id} gap={8} template={4}>
              <TableTextCell value={vehicle?.vin || '-'} />
              <TableTextCell value={vehicle?.vrm || '-'} />
              <TableTextCell
                value={vehicle?.available_on ? format(new Date(vehicle?.available_on as string), 'dd MMM yyyy') : '-'}
              />
              <ActionIcon
                onClick={() => editVehicle(vehicle)}
                icon={<BsPencil size={24} color={SECONDARY_PURPLE_70} />}
                tooltip="Edit"
              />
            </TableRowGrid>
          ))}
        </>
      ) : (
        <NoResults />
      )}
      <Modal
        styled={{ width: '60vw', minWidth: 600 }}
        title="Edit vehicle"
        open={openUpdateVehicle}
        onClose={closeModal}
        showClose
      >
        <EditVehicleBatchForm values={vehicleValue} close={closeModal} />
      </Modal>
    </>
  );
};

export const NewVehicleBatchesList = () => {
  const navigate = useNavigate();
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const [selectedModel, setSelectedModel] = useState<OptionList[]>([]);
  const [selectedModelYear, setSelectedModelYear] = useState<OptionList[]>([]);
  const [selectedFuelType, setSelectedFuelType] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: vehicleBatchesList,
    isFetching: isVehicleBatchesListFetching,
    refetch,
  } = useListAndMergeNewVehicleBatchesListQuery({ query: queryStringState, refetch: refetchData });
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: modelList } = useGetVehicleModelListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: modelYearsList } = useGetAllVehicleModelYearsListQuery({});

  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    setSortAscending,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const manufacturers: OptionList[] = manufacturerList?.data?.map((opt: { id: string; name: string }) => {
    return {
      value: opt.id,
      label: opt.name,
    };
  });

  const models: OptionList[] = modelList?.data?.map((opt: ModelListItem) => {
    return {
      value: opt.id,
      label: opt.model,
      info: opt.manufacturer_id,
    };
  });

  const fuelTypes: OptionList[] = fuelList?.data?.map((opt: { id: string; name: string }) => {
    return {
      value: opt.id,
      label: opt.name,
    };
  });

  const modelYears: OptionList[] = modelYearsList?.data?.map((value: string) => {
    return {
      value: value,
      label: value,
    };
  });

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Manufacturer"
          options={manufacturers}
          multiValues={selectedManufacturer}
          title="Manufacturer"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model',
      element: (
        <DropDownFilter
          name="model"
          placeholder="Model"
          multiValues={selectedModel}
          options={models}
          title="Model"
          onChange={(items) => setSelectedModel(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model_year',
      element: (
        <DropDownFilter
          name="model_year"
          placeholder="Model year"
          multiValues={selectedModelYear}
          options={modelYears}
          title="Model year"
          onChange={(items) => setSelectedModelYear(items as OptionList[])}
        />
      ),
    },
    {
      name: 'fuel_type',
      element: (
        <DropDownFilter
          name="fuel_type"
          placeholder="Fuel type"
          multiValues={selectedFuelType}
          options={fuelTypes}
          title="Fuel type"
          onChange={(items) => setSelectedFuelType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'available-date',
      element: (
        <DateRangeFilter
          title="Available date"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const handleGetBatchesResponse = useCallback(
    (count: number, batches: NewVehicleBatch[]) => {
      const batchRows = batches?.map((batch: NewVehicleBatch) => {
        return {
          rowData: { data: batch },
          cells: [
            <TableTextCell value={batch?.manufacturer} />,
            <TableTextCell value={batch?.vehicle_model} />,
            <TableTextCell value={batch?.model_year?.toString()} />,
            <TableTextCell
              value={batch?.exterior_colors
                ?.map((color) => {
                  return color.name;
                })
                ?.join(', ')}
            />,
            <TableTextCell value={batch?.fuel_type} />,
            <TableTextCell value={(batch?.vehicles?.length || 0)?.toString()} />,
            <TableTextCell value={format(new Date(batch?.available_on), 'dd MMM yyyy')} />,
            <ActionIcon
              icon={
                <BsPencil
                  onClick={() => navigate(`${FLEET}/vehicle-batches/edit/${batch.id}`)}
                  size={24}
                  color={PRIMARY_GRAY}
                />
              }
              tooltip="Edit"
            />,
          ],
        };
      });
      setTableData(batchRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, navigate]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setSortingColumn('updated_date');
    setSortAscending(false);
  }, [setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'manufacturer', options: selectedManufacturer },
      { columnName: 'model', options: selectedModel },
      { columnName: 'model_year', options: selectedModelYear },
      { columnName: 'fuel_type', options: selectedFuelType },
      {
        columnName: 'available_on',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, selectedManufacturer, selectedModel, selectedModelYear, selectedFuelType]);

  useEffect(() => {
    if (vehicleBatchesList) {
      handleGetBatchesResponse(vehicleBatchesList.count, vehicleBatchesList.data);
    }
  }, [vehicleBatchesList, queryStringState, handleGetBatchesResponse]);

  const onClearClick = useCallback(() => {
    setSelectedManufacturer([]);
    setSelectedModel([]);
    setSelectedModelYear([]);
    setSelectedFuelType([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isVehicleBatchesListFetching}
        header="Vehicle batch list"
        actionButtonText="Create batch"
        onActionButtonClick={() => navigate(`${FLEET}/vehicle-batches/create`)}
        disableApply={invalidDates}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        dataDownloadMethod="download"
        sortAscending={sortAscending}
        columns={newVehicleBatchListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllBatches}
        downloadName="Vehicle batches"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        getTableRowData={async (id: string) => (
          <RenderBatchVehicleList
            vehicles={vehicleBatchesList?.data?.find((batch) => batch.id === id)?.vehicles || []}
            retchBatchesList={refetchToFirstPage}
          />
        )}
      />
    </>
  );
};
