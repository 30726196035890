export enum FreezeStatus {
  COMPLETED = 2,
  CANCELLED = 8,
  UPCOMING = 9,
}

export interface DriverFreeze {
  id: string;
  freeze_batch_id: string;
  status_code: string;
  commencement_date: string;
  created_date: string;
  updated_date: string;
}

export interface DriverPaymentFreeze {
  id: string;
  driver_id: string;
  agreement_id: string;
  employee_id: string;
  country_id: string;
  freeze_length: number;
  override_reason?: string;
  commencement_date: string;
  created_date: string;
  updated_date: string;
  archived: boolean;
  agreement_type: string;
  agreement_status: string;
  vrm: string;
  employee_first_name: string;
  employee_last_name: string;
  freezes: DriverFreeze[];
}

export interface CurrentPeriodFreezeDetails {
  batches: DriverPaymentFreeze[];
  numberOfWeeksTaken: number;
  periodEndDate: string;
  periodStartDate: string;
}

export interface EditFreezePayload {
  id: string;
  freeze_length?: number;
  holday_length?: number;
  commencement_date: string | Date;
  override_reason?: string;
  agreement_id: string;
  driver_id: string;
}

export interface NewFreezePayload {
  driver_id: string;
  agreement_id: string;
  freeze_length?: number;
  holday_length?: number;
  commencement_date: string | Date;
  override_reason?: string;
}

export interface OnSubmitFreezeValues {
  freeze_length?: number;
  commencement_date: string | Date;
  override_reason?: string;
}
