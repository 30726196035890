import { useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_GRAY, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
  TableTextCell,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { VehicleInfoProps } from '../vehiclePage/vehiclePage';
import { TableColumn } from '../../../models/table';
import { useGetConditionReportsByVehicleIdQuery } from '../../../api/vehicle/vehicleSliceApi';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsDownload, BsEye, BsPencil } from 'react-icons/bs';
import { handleDownloadFileFromStorage } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { CONDITION_REPORT } from '../../../consts/routes';
import { format } from 'date-fns';

export const conditionReportVehicleColumns: TableColumn[] = [
  { id: 'created_date', name: 'Date added', sortable: true },
  { id: 'status', name: 'Report status', sortable: true },
  { id: 'reason', name: 'Report reason', sortable: true },
  { id: 'download', name: 'Download', sortable: false },
  { id: 'action', name: 'Action', sortable: false },
];

type VehicleConditionReportListType = {
  created_date: string;
  status: string;
  condition_report_id: string;
  count: number;
  reason: string;
  s3_url?: string;
};

export const ConditionReportVehicleTable = ({ vehicle }: VehicleInfoProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const vehicleId: string = vehicle?.vehicle_id;
  const [filterValue, setFilterValue] = useState(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:DESC`);
  const { refetch, currentData, isLoading } = useGetConditionReportsByVehicleIdQuery({
    id: vehicleId,
    filters: filterValue,
  });

  const navigate = useNavigate();
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
    setSortAscending,
  } = useTableFilters();

  const handleFilterChange = (
    pageNumber: number,
    rowsPerPage: number,
    searchString: string,
    sortingColumn: string | undefined,
    sortAscending: boolean
  ) => {
    setTableData(undefined);
    goToPageNumber(pageNumber);
    const queryString = getQueryString(
      tableFilters,
      rowsPerPage,
      pageNumber,
      searchString,
      sortingColumn,
      sortAscending
    );
    setFilterValue(queryString);
    () => {
      refetch();
    };
  };

  if (currentData && currentData.data.length) {
    const rowsCount = currentData.data[0].count;

    const conditionReportListRows = currentData.data.map((crRow: VehicleConditionReportListType) => {
      return {
        rowData: { data: currentData.data },
        cells: [
          <TableTextCell value={crRow?.created_date ? format(new Date(crRow?.created_date), 'yyyy/MM/dd') : ''} />,
          <TableTextCell value={crRow?.status ? crRow?.status : ''} />,
          <TableTextCell value={crRow?.reason ? crRow?.reason : ''} />,
          <ActionIcon
            disabled={!crRow?.s3_url}
            onClick={() => handleDownloadFileFromStorage(crRow.s3_url as string)}
            icon={<BsDownload size={24} color={PRIMARY_GRAY} />}
            tooltip="Download condition report"
          />,
          <ActionIcon
            onClick={() => navigate(`${CONDITION_REPORT}edit/${crRow?.condition_report_id}`)}
            icon={
              crRow?.status === 'SUBMITTED' ? (
                <BsEye size={24} color={PRIMARY_GRAY} />
              ) : (
                <BsPencil size={24} color={PRIMARY_GRAY} />
              )
            }
            tooltip={crRow?.status === 'SUBMITTED' ? 'View condition report' : 'Edit condition report'}
          />,
        ],
      };
    });

    if (!tableData?.length) {
      setTableData(conditionReportListRows);
      setTotalRows(rowsCount);
    }
  }

  useEffect(() => {
    setSortingColumn('created_date');
    setSortAscending(false);
  });

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          Condition reports
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          Condition reports
        </Text>
        <Table
          isLoading={isLoading}
          variant="compact"
          tableTheme="purple"
          embedded
          header="Condition reports"
          onColumnHeaderClick={(columnId: string) =>
            handleFilterChange(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={conditionReportVehicleColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          onSearchChange={() => {}}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            handleFilterChange(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            handleFilterChange(0, value, searchString, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
