import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../../common/styles/Colors';
import { PrimaryButton } from '../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { BsPlusCircleFill } from 'react-icons/bs';
import { Text } from '../../../../../uiComponents/text/text';
import { Modal } from '../../../../../uiComponents/modals/modal';
import { DamageReportForm } from '../../../../../pages/fleet/conditionReport/checkSections/sections/damageReportForm/damageReportForm';
import { useEffect, useState } from 'react';
import { DamageLogPayload, ImageArrayType } from '../../../../../models/transfer';
import { fileNameTimeStampFormat, renderNotification } from '../../../../../utils/utils';
import { getFileService } from '../../../../../api/cognito/file.service';
import { createDamageLog } from '../../../../../api/post/conditionReport.post';
import { DamageReportItem } from '../../../../../consts/conditionReport';
import { DamageLogList } from '../damageLogList/damageLogList';
import { format } from 'date-fns';

interface DamegeLogProps {
  conditionReportId?: string;
  vehicleId?: string;
  prevDamageLogs?: DamageLogPayload[];
}

export function DamageLog({ conditionReportId, vehicleId, prevDamageLogs }: DamegeLogProps) {
  const [openDamageLogModal, setOpenDamageLogModal] = useState<boolean>(false);
  const [submittingDamage, setSubmittingDamage] = useState<boolean>(false);
  const [damageLogs, setDamageLogs] = useState<DamageReportItem[]>(prevDamageLogs || []);

  async function uploadImages(files: Array<File | Blob | ImageArrayType> | undefined) {
    const fileService = getFileService();

    if (files) {
      const uploadPromises = files.map((file, index) => {
        const item = file as File;
        const fileNameWithTimeStamp = fileNameTimeStampFormat(item.name);
        const path = `DOCUMENTS/CONDITION_REPORT/${vehicleId}/${format(
          new Date(),
          'yyyy-MM-dd_HH-MM-SS'
        )}/DAMAGE_LOG/PHOTOS/${fileNameWithTimeStamp}`;

        return fileService
          .uploadFileToStorage({
            path,
            data: item,
          })
          .then((result) => ({
            fileName: `${item.name}_${index}`,
            type: item.type,
            s3_url: result.path,
          }))
          .catch((error) => {
            renderNotification('error', 'Error', `${error.message}.`, true);
            return null;
          });
      });
      const results = await Promise.allSettled(uploadPromises);
      return results;
    }

    return [];
  }

  async function onAddDamageLog(submitValues: DamageReportItem) {
    setSubmittingDamage(true);
    const damageLogId = new Date().getTime();
    const images = await uploadImages(submitValues.files);
    const errorsImages = images.filter((r) => r.status === 'rejected');
    const successesImages = images
      .filter(
        (r): r is PromiseFulfilledResult<{ fileName: string; type: string; s3_url: string }> => r.status === 'fulfilled'
      )
      .map((r) => r.value);

    if (successesImages === null || successesImages.length === 0) {
      renderNotification('error', 'Error', 'No images uploaded.');
      setSubmittingDamage(false);
      return;
    }
    submitValues.files = successesImages;

    createDamageLog({
      condition_report_id: conditionReportId,
      damaged_type: submitValues.damaged_type,
      damaged_area: submitValues.damaged_area,
      damage_description: submitValues.damage_description,
      damage_detail: submitValues.damage_detail,
      images_folder: damageLogId.toString(),
      files: submitValues.files,
    } as DamageLogPayload)
      .then(({ data }) => {
        if (errorsImages.length > 0) {
          renderNotification('success', 'Success', `Damage log created with ${errorsImages.length} errors on images`);
        }
        renderNotification('success', 'Success', 'Damage log created successfully.');
        submitValues.id = data.id;
        setDamageLogs((prev) => {
          return [...prev, submitValues];
        });
      })
      .catch(() => {
        renderNotification('error', 'Error', 'There was a problem adding the damage log.');
      });

    setSubmittingDamage(false);
    setOpenDamageLogModal(false);
  }

  useEffect(() => {
    if (prevDamageLogs) {
      setDamageLogs(prevDamageLogs);
    }
  }, [prevDamageLogs]);

  return (
    <div>
      <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
        Damage Log
      </Text>
      <FlexLayout styled={{ marginTop: 16 }} gap={24}>
        <PrimaryButton onClick={() => setOpenDamageLogModal(true)}>
          <FlexLayout gap={8}>
            <BsPlusCircleFill color={PRIMARY_GREEN} size={16} />
            <Text variant="body7" color={PRIMARY_WHITE} weight={500}>
              Create damage report
            </Text>
          </FlexLayout>
        </PrimaryButton>
      </FlexLayout>
      <DamageLogList damageLogs={damageLogs} />
      <Modal
        title="Damage report"
        open={openDamageLogModal}
        onClose={() => {
          setOpenDamageLogModal(false);
        }}
        showClose={true}
        styled={{ minWidth: 375 }}
      >
        <DamageReportForm
          onClose={() => {
            setOpenDamageLogModal(false);
          }}
          onSubmit={onAddDamageLog}
          submittingDamage={submittingDamage}
        />
      </Modal>
    </div>
  );
}
