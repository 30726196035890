import { apiSlice } from '../baseApi';

export const reports = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReportsList: builder.query({
      query: () => 'report',
    }),
  }),
});

export const { useGetReportsListQuery } = reports;
