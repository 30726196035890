import { Button } from '../../../../../components/ui/button/button';
import { ColumnDef } from '@tanstack/react-table';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { ContractListItem } from '../../../../../models/contract';

export const columns: ColumnDef<ContractListItem>[] = [
  {
    accessorKey: 'contract_type',
    id: 'contract_type',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Contract Type
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'vehicle_manufacturer_name',
    id: 'vehicle_manufacturer_name',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Manufacturer
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'vehicle_model_name',
    id: 'vehicle_model_name',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Model
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'exterior_color_name',
    id: 'exterior_color_name',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Color
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'fuel_type_name',
    id: 'fuel_type_name',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Fuel Type
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'vrm',
    id: 'vrm',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          VRM
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'contract_length',
    id: 'contract_length',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Contract Length
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'weekly_rent',
    id: 'weekly_rent',
    header: ({ column }) => {
      return (
        <Button variant="link" color="secondary" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Weekly Length
          {column.getIsSorted() === 'desc' ? (
            <BsArrowUp className="ml-2 h-3 w-3" />
          ) : column.getIsSorted() === 'asc' ? (
            <BsArrowDown className="ml-2 h-3 w-3" />
          ) : null}
        </Button>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: () => {
      return <></>;
    },
    cell: ({ row, table }) => {
      const handleAssign = () => {
        if (table.options.meta?.assign) {
          table.options.meta.assign(row.original);
        }
      };

      return (
        <div className="inline-flex gap-x-[4px] items-center justify-end w-[100%]">
          <Button color="secondary" onClick={handleAssign}>
            Assign
          </Button>
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
];
