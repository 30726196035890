import { BsPerson, BsPersonCheck } from 'react-icons/bs';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { useState } from 'react';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import { SectionProps } from '../order/order';
import { RadioButton } from '../../../uiComponents/uiControls/radioButton/radioButton';
import { TextArea } from '../../../uiComponents/inputs/textArea/textArea';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { SectionHeader } from './sectionHeader';
import { FieldHeader, Notes, PreviousAssessment, Reassessment } from '../order/order.styles';
import { statusColors } from '../../../common/utils';
import { useForm } from 'react-hook-form';
import { createReassessmentType } from '../../../api/post/assessmentType';
import { AssessmentStatus, DriverAssessmentResult, EvaluationType } from '../../../models/assessment';
import { ASSESSMENT_NONE, ASSESSMENT_ONLINE, ASSESSMENT_PRACTICAL } from '../../../consts/assessment';
import { assessmentNotNeeded } from '../../../api/patch/assessment.patch';
import { handleDownloadFileFromStorage } from '../../../utils/utils';
import { format } from 'date-fns';

interface AssessmentSectionProps extends SectionProps {
  assessmentLessThan3Months: boolean;
}

interface ReassessmentProps {
  evaluation_type: EvaluationType;
  assessment_status: AssessmentStatus;
  notes: string;
}

export const AssessmentSection = ({
  isComplete,
  isLocked,
  progress,
  assessmentLessThan3Months,
  checkProgress,
}: AssessmentSectionProps) => {
  const { register, watch, handleSubmit } = useForm<ReassessmentProps>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {},
  });
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [submittingAssessment, setSubmittingAssessment] = useState<boolean>(false);
  const historicalAssessment: DriverAssessmentResult | undefined =
    progress?.assessment_history?.[progress?.assessment_history?.length - 1];
  const reassessmentType: EvaluationType = watch('evaluation_type');
  const needsNewAssessment: boolean =
    !historicalAssessment?.id || (progress?.is_new_driver && !assessmentLessThan3Months);

  const onSubmit = (submitValues: ReassessmentProps) => {
    setSubmittingAssessment(true);
    if (submitValues.evaluation_type !== ASSESSMENT_NONE) {
      createReassessmentType({
        assessment_id: historicalAssessment?.assessment_id,
        evaluation_type: submitValues?.evaluation_type,
        notes: submitValues?.notes ?? '',
      }).then(() => {
        checkProgress?.();
        setSubmittingAssessment(false);
      });
    } else {
      assessmentNotNeeded(historicalAssessment?.assessment_id, { notes: submitValues?.notes ?? '' }).then(() => {
        checkProgress?.();
        setSubmittingAssessment(false);
      });
    }
  };

  return (
    <CollapsiblePanel
      styled={{ marginTop: 16 }}
      header={
        <SectionHeader
          expanded={false}
          completedText="Completed"
          title="Assessment"
          isComplete={isComplete}
          isLocked={isLocked}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div>
        <SectionHeader
          expanded
          title="Assessment"
          completedText="Completed"
          isComplete={isComplete}
          isLocked={isLocked}
        />
        {needsNewAssessment && (
          <Reassessment>
            {historicalAssessment?.id && (
              <Text variant="body8" color={STATUS_RED} weight={300} block>
                This driver profile has been inactive for more than 3 months and needs to be re-assessed before
                continuing with this order.
              </Text>
            )}
            <FlexLayout itemsY="center" gap={112} styled={{ marginTop: 32 }}>
              <div>
                <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block styled={{ marginBottom: 24 }}>
                  Re-assessment options
                </Text>
                <FlexLayout gap={16}>
                  <FlexLayout gap={8} itemsY="center">
                    <RadioButton
                      {...register('evaluation_type', {
                        required: 'Please select an assessment type',
                      })}
                      id="online"
                      value={ASSESSMENT_ONLINE}
                    />
                    <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                      Online
                    </Text>
                  </FlexLayout>
                  <FlexLayout gap={8} itemsY="center">
                    <RadioButton
                      {...register('evaluation_type', { required: 'Please select an assessment type' })}
                      id="practical"
                      value={ASSESSMENT_PRACTICAL}
                    />
                    <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                      Practical
                    </Text>
                  </FlexLayout>
                  <FlexLayout gap={8} itemsY="center">
                    <RadioButton
                      {...register('evaluation_type', { required: 'Please select an assessment type' })}
                      id="none"
                      value={ASSESSMENT_NONE}
                    />
                    <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                      None
                    </Text>
                  </FlexLayout>
                </FlexLayout>
              </div>
              <Notes>
                <TextArea label="" {...register('notes')} placeholder="Notes" styled={{ minHeight: 64, flex: 1 }} />
              </Notes>
            </FlexLayout>
            <FlexLayout itemsX="end">
              <PrimaryButton
                styled={{ width: 96, marginTop: 8 }}
                isProcessing={submittingAssessment}
                onClick={handleSubmit(onSubmit)}
                disabled={!reassessmentType}
              >
                Submit
              </PrimaryButton>
            </FlexLayout>
          </Reassessment>
        )}
        {historicalAssessment && (
          <>
            {historicalAssessment?.evaluation_type === ASSESSMENT_NONE ? (
              <PreviousAssessment>
                <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                  An assessment was not necessary for this driver.
                </Text>
                {historicalAssessment?.notes && (
                  <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block styled={{ padding: '16px 0' }}>
                    {`Notes: ${historicalAssessment?.notes}`}
                  </Text>
                )}
                <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                  {`Added by ${historicalAssessment?.agent_first_name} ${historicalAssessment?.agent_last_name} on ${format(
                    new Date(historicalAssessment?.created_date),
                    'dd MMM yyyy'
                  )}`}
                </Text>
              </PreviousAssessment>
            ) : (
              <PreviousAssessment>
                <Text variant="body4" color={PRIMARY_PURPLE} weight={800} block>
                  Previous assessment
                </Text>
                <Text variant="body8" weight={300} color={PRIMARY_PURPLE}>
                  {format(new Date(historicalAssessment?.created_date), 'dd MMM yyyy')}
                </Text>
                <GridLayout template={2} gap={16} styled={{ marginTop: 24 }}>
                  <div>
                    <FlexLayout itemsY="center" gap={84}>
                      <FlexLayout itemsY="center" gap={12}>
                        <BsPerson size={40} color={PRIMARY_PURPLE} />
                        <div>
                          <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                            Driver name
                          </Text>
                          <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                            {progress?.driver_name}
                          </Text>
                        </div>
                      </FlexLayout>
                      <FlexLayout itemsY="center" gap={12}>
                        <BsPersonCheck size={40} color={PRIMARY_PURPLE} />
                        <div>
                          <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                            Instructor name
                          </Text>
                          <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                            {`${historicalAssessment?.agent_first_name ?? ''} ${
                              historicalAssessment?.agent_last_name ?? ''
                            }`}
                          </Text>
                        </div>
                      </FlexLayout>
                    </FlexLayout>
                    <FieldGrid
                      headers={['DVLA:', 'Test date:', 'Penalties:']}
                      values={[
                        progress?.dvla_document_no,
                        format(new Date(progress?.dvla_test_date), 'dd MMM yyyy'),
                        <>
                          {progress?.penalty_codes?.map((tag: string, index: number) => (
                            <Tag key={`${tag}_${index}`} color={PRIMARY_PURPLE}>
                              {tag}
                            </Tag>
                          ))}
                        </>,
                      ]}
                      numColumns={3}
                      styled={{ marginTop: 32 }}
                    />
                    <FieldGrid
                      headers={['Assessment type:', 'Assessment result:', 'Assessment branch:']}
                      values={[
                        historicalAssessment?.evaluation_type ?? '-',
                        <Tag color={statusColors[historicalAssessment?.assessment_status ?? ''] ?? PRIMARY_PURPLE}>
                          {historicalAssessment?.assessment_status ?? '-'}
                        </Tag>,
                        historicalAssessment?.branch_name ?? '-',
                      ]}
                      numColumns={3}
                      styled={{ marginTop: 32 }}
                    />
                    <FieldGrid
                      headers={['Tags/flags:', 'Score:']}
                      values={[
                        historicalAssessment?.tag_name ? (
                          <Tag color={statusColors[historicalAssessment?.tag_name] ?? PRIMARY_PURPLE}>
                            {historicalAssessment?.tag_name}
                          </Tag>
                        ) : (
                          '-'
                        ),
                        historicalAssessment?.score?.toString() ?? '-',
                      ]}
                      numColumns={2}
                      styled={{ marginTop: 32 }}
                    />
                  </div>
                  <div>
                    {historicalAssessment?.assessment_report != null && (
                      <PrimaryButton
                        styled={{ width: 188 }}
                        onClick={() => handleDownloadFileFromStorage(historicalAssessment?.assessment_report ?? '')}
                      >
                        View report
                      </PrimaryButton>
                    )}
                    <FieldHeader styled={{ marginTop: 32 }} variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                      Notes:
                    </FieldHeader>
                    <TextArea
                      styled={{ marginTop: 8, height: 104 }}
                      disabled
                      label=""
                      placeholder="Assessment notes"
                      name="previous-assessment"
                      value={historicalAssessment?.notes}
                    />
                    <FieldHeader styled={{ marginTop: 42 }} variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                      Driver experience:
                    </FieldHeader>
                    <Text
                      styled={{ marginLeft: 32, marginTop: 8 }}
                      variant="body7"
                      weight={500}
                      color={PRIMARY_PURPLE}
                      block
                    >
                      {historicalAssessment?.driver_experience ?? '-'}
                    </Text>
                  </div>
                </GridLayout>
              </PreviousAssessment>
            )}
          </>
        )}
      </div>
    </CollapsiblePanel>
  );
};
