/**
 * This document will handle all the routes
 * All files in the pages folder can be seen as a route
 */
import React from 'react';
// Icons
import { FiPieChart, FiSettings } from 'react-icons/fi';
import { MdAccountCircle, MdOutlineGridView, MdLoop, MdShoppingCart } from 'react-icons/md';
// Dashboard
import { Dashboard } from '../pages/dashboardPanel/dashboard/dashboard';
import { AgreementPage } from '../pages/agreements/agreement/agreementPage/agreementPage';
// Driver
import { AgreementList } from '../pages/agreements/agreementList/agreementList';
import { DriverPage } from '../pages/drivers/driver/driverPage/driverPage';
import { DriverList } from '../pages/drivers/driverList/driverList';
import { ProspectiveDriverList } from '../pages/drivers/driverList/prospectDriverList';
import { CreateNewDriver } from '../pages/drivers/newDriver/createNewDriver/createNewDriver';
// Fleet
import { VehicleBatchesList } from '../pages/fleet/vehicleBatches/vehicleBatchesList';
import { VehiclePackages } from '../pages/fleet/vehicleCreation/vehiclePackage/vehiclePackage';
import { CreateVehicleType } from '../pages/fleet/vehicleCreation/vehicleType/createVehicleType';
import { VehicleTypeList } from '../pages/fleet/vehicleCreation/vehicleType/vehicleTypeList';
import { ConditionReport } from '../pages/fleet/conditionReport/conditionReport';
import { TransferList } from '../pages/fleet/transfers/transferList';
import { VehiclePage } from '../pages/fleet/vehiclePage/vehiclePage';
import { VehicleList } from '../pages/fleet/vehicle/vehicleList';
// Internal
import { CityList } from '../pages/admin/cities/cityList';
import { UserList } from '../pages/admin/users/userList';
import { BranchList } from '../pages/admin/branches/branchList';
// Sales
import { AssessmentList } from '../pages/assessment/assessmentList';
import { CongestionCharges } from '../pages/drivers/additionalCharges/congestionCharges/congestionCharges';
import { ExcessMileage as EMileage } from '../pages/drivers/additionalCharges/excessMileages/excessMileages';
import { PRIMARY_WHITE } from '../common/styles/Colors';
import { Order } from '../pages/orders/order/order';
import { CreateOrder } from '../pages/orders/createOrder/createOrder';
import { OrderList } from '../pages/orders/orderList';
import {
  ACCOUNT,
  ADMIN,
  AFTERCARE,
  AFTERCARE_CONTACT_LIST,
  AFTERCARE_LIST,
  AFTERCARE_REVIEWS,
  AFTERCARE_UPCOMING,
  AGREEMENTS,
  ASSESSMENTS,
  BRANCHES,
  CITIES,
  COURTESY_ORDER,
  CONGESTIONS_CHARGES,
  CONTRACTS,
  CREATE_ORDER,
  CREATE_TRANSFER,
  DASHBOARD,
  DRIVERS,
  EXCESS_MILEAGE,
  FLEET,
  NEW_DRIVER,
  ORDERS,
  PHV_AND_MOT,
  POLICIES,
  PROSPECTIVE_DRIVERS,
  SALES,
  SERVICING,
  SERVICING_LIST,
  TRANSFERS,
  USERS,
  VEHICLES,
  VEHICLE_BATCHES,
  VEHICLE_TYPES,
  ADDITIONAL_CHARGES,
  CREATE_CONTRACT,
  EDIT_CONTRACT,
  VIEW_CONTRACT,
  EDIT_CONDITION_REPORT,
  VEHICLE_MODELS,
  NEW_VEHICLE_BATCHES,
  REPORTS,
  EMBEDDED_REPORT,
} from '../consts/routes';
// Servicing
import { PhvMotCalendar } from '../pages/servicing/phvMotCalendar/phvMotCalendar';
import { CourtesyOrder } from '../pages/orders/courtesyOrder';
import { ServicingList } from '../pages/servicing/servicingList/servicingList';
import { AftercareList } from '../pages/aftercare/aftercareList/aftercareList';
import { UpcomingAftercareList } from '../pages/aftercare/upcomingAftercareList';
import { PoliciesList } from '../pages/insurance/policies/policyList';
import { BsUmbrella } from 'react-icons/bs';
import { ContactList } from '../pages/aftercare/contactList/contactList';
import { AftercareReviewList } from '../pages/aftercare/aftercareReview/aftercareReview';
import { ContractsList } from '../pages/contracts/contractsList';
import { Navigate } from 'react-router-dom';
import { CreateContract } from '../pages/contracts/createContract/createContract';
import { EditContract } from '../pages/contracts/editContract/editContract';
import { ViewContract } from '../pages/contracts/viewContract/viewContract';
import { CreateVehicleModel } from '../pages/fleet/vehicleCreation/vehicleModel/createVehicleModel/createVehicleModel';
import { EditVehicleModel } from '../pages/fleet/vehicleCreation/vehicleModel/editVehicleModel/editVehicleModel';
import { VehicleModelList } from '../pages/fleet/vehicleCreation/vehicleModel/vehicleModelList/vehicleModelList';
import { EditVehicleBatch } from '../pages/fleet/vehicleBatches/editVehicleBatch/editVehicleBatch';
import { CreateVehicleBatch } from '../pages/fleet/vehicleBatches/createVehicleBatch/createVehicleBatch';
import { NewVehicleBatchesList } from '../pages/fleet/vehicleBatches/newVehicleBatchesList';
import { Reports } from '../pages/reports/reports';
import { EmbeddedReport } from '../pages/reports/embedded/EmbeddedReport';
export interface PulseAdminChildRoute {
  path: string;
  title: string;
  defaultTab?: boolean;
  component?: JSX.Element;
  childRoutes?: PulseAdminChildRoute[];
  active?: string;
  pageId?: string;
  styles?: React.CSSProperties;
  redirect?: string;
}

export interface PulseAdminRoutes {
  path: string;
  icon?: JSX.Element;
  title?: string;
  permissions?: string[];
  component?: JSX.Element;
  defaultRoute?: boolean;
  childRoutes?: PulseAdminChildRoute[];
  redirect?: string;
  pageId?: string;
}

// To block a route using Feature Flag.
// Add the route to block as key, and the corresponding feature flag id as value
export const PulseRouteFeatureFlags = new Map<string, string>([
  ['/app/admin/contracts', 'contractPricing'],
  [`${FLEET}/vehicle-model`, 'vehicleModel'],
  [`${FLEET}/vehicle-model/create`, 'vehicleModel'],
  [`${FLEET}/vehicle-model/edit/:id`, 'vehicleModel'],
  [`${FLEET}/vehicle-batches`, 'vehicleBatch'],
  [`${FLEET}/vehicle-batches/create`, 'vehicleBatch'],
  [`${FLEET}/vehicle-batches/edit/:id`, 'vehicleBatch'],
]);

export const OttoPulseAdminRoutes: PulseAdminRoutes[] = [
  {
    path: DASHBOARD,
    icon: <FiPieChart size={24} color={PRIMARY_WHITE} />,
    title: 'Dashboard',
    permissions: [],
    component: <Dashboard />,
  },
  {
    path: `${AGREEMENTS}/:agreementId`,
    title: 'Agreement Single',
    component: <AgreementPage />,
  },
  {
    path: `${DRIVERS}/:driverId`,
    title: 'Driver single',
    component: <DriverPage />,
  },
  {
    path: NEW_DRIVER,
    title: 'New driver',
    pageId: 'createDriver',
    component: <CreateNewDriver />,
  },
  {
    path: ACCOUNT,
    redirect: DRIVERS,
    icon: <MdAccountCircle size={24} color={PRIMARY_WHITE} />,
    title: 'Driver',
    permissions: [],

    childRoutes: [
      {
        path: DRIVERS,
        title: 'Drivers',
        active: 'driverListPage',
        component: <DriverList />,
      },
      {
        path: AGREEMENTS,
        title: 'Agreements',
        defaultTab: true,
        component: <AgreementList />,
        active: 'agreementListPage',
      },
      {
        path: ADDITIONAL_CHARGES,
        title: 'Additional Charges',
        active: 'additionalCharges',
        redirect: CONGESTIONS_CHARGES,
        styles: {
          position: 'absolute',
          top: 192,
          backgroundColor: PRIMARY_WHITE,
          right: 54,
          paddingBottom: 0,
          paddingLeft: 0,
          boxShadow: 'none',
          zIndex: 1,
        },
        childRoutes: [
          {
            path: CONGESTIONS_CHARGES,
            title: 'Congestion charges',
            component: <CongestionCharges />,
          },
          {
            path: EXCESS_MILEAGE,
            title: 'Excess mileage',
            component: <EMileage />,
          },
        ],
      },
      {
        path: AFTERCARE,
        title: 'Aftercare',
        active: 'aftercare',
        redirect: AFTERCARE_LIST,
        styles: {
          position: 'absolute',
          top: 192,
          backgroundColor: PRIMARY_WHITE,
          right: 54,
          paddingBottom: 0,
          paddingLeft: 0,
          boxShadow: 'none',
          zIndex: 1,
        },
        childRoutes: [
          {
            path: AFTERCARE_LIST,
            title: 'Aftercare list',

            component: <AftercareList />,
          },
          {
            path: AFTERCARE_UPCOMING,
            title: 'Upcoming',

            component: <UpcomingAftercareList />,
          },
          {
            path: AFTERCARE_CONTACT_LIST,
            title: 'Contact list',

            component: <ContactList />,
          },
          {
            path: AFTERCARE_REVIEWS,
            title: 'Aftercare review',

            component: <AftercareReviewList />,
          },
        ],
      },
    ],
  },
  {
    path: CREATE_CONTRACT,
    title: 'Create contract',
    component: <CreateContract />,
  },
  {
    path: EDIT_CONTRACT,
    title: 'Edit contract',
    component: <EditContract />,
  },
  {
    path: VIEW_CONTRACT,
    title: 'View contract',
    component: <ViewContract />,
  },
  {
    path: CREATE_TRANSFER,
    title: 'Condition report',
    component: <ConditionReport />,
  },
  {
    path: EDIT_CONDITION_REPORT,
    title: 'Condition report',
    component: <ConditionReport />,
  },
  {
    path: `${FLEET}/vehicle-types/create`,
    title: 'Create Vehicle Type',
    component: <CreateVehicleType />,
  },
  {
    path: `${FLEET}/vehicle-model/create`,
    title: 'Create Vehicle Model',
    component: <CreateVehicleModel />,
  },
  {
    path: `${FLEET}/vehicle-model/edit/:id`,
    title: 'Edit Vehicle Model',
    component: <EditVehicleModel />,
  },
  {
    path: `${FLEET}/vehicle-batches/create`,
    title: 'Create Vehicle Batch',
    component: <CreateVehicleBatch />,
  },
  {
    path: `${FLEET}/vehicle-batches/edit/:id`,
    title: 'Edit Vehicle Batch',
    component: <EditVehicleBatch />,
  },
  {
    path: `${FLEET}/vehicle-types/packages/:id`,
    title: 'Vehicle Packages',
    component: <VehiclePackages />,
  },
  {
    path: `${FLEET}/transfers/create`,
    component: <ConditionReport />,
  },
  {
    path: `${VEHICLES}/:vehicleId`,
    title: 'Vehicle',
    component: <VehiclePage />,
  },
  {
    path: REPORTS,
    title: 'Reports',
    component: <Reports />,
  },
  {
    path: EMBEDDED_REPORT,
    title: 'Report',
    component: <EmbeddedReport />,
  },
  {
    path: FLEET,
    redirect: VEHICLES,
    icon: <MdOutlineGridView size={24} color={PRIMARY_WHITE} />,
    title: 'Fleet',
    permissions: [],

    childRoutes: [
      {
        path: VEHICLES,
        title: 'Vehicles',

        component: <VehicleList />,
      },
      {
        path: TRANSFERS,
        title: 'Transfers',

        active: 'transferListPage',
        component: <TransferList />,
      },
      {
        path: VEHICLE_TYPES,
        title: 'Vehicle Types',
        defaultTab: true,

        active: 'vehicleTypesListPage',
        component: <VehicleTypeList />,
      },

      {
        path: VEHICLE_MODELS,
        title: 'Vehicle Models',
        defaultTab: true,

        active: 'vehicleModelsListPage',
        component: <VehicleModelList />,
      },
      {
        path: VEHICLE_BATCHES,
        title: 'Vehicle Batches',
        defaultTab: true,

        active: 'vehicleBatchesList',
        component: <VehicleBatchesList />,
      },
      {
        path: NEW_VEHICLE_BATCHES,
        title: 'Vehicle Batches (New)',
        defaultTab: true,

        active: 'newVehicleBatchesList',
        component: <NewVehicleBatchesList />,
      },
    ],
  },

  {
    path: ADMIN,
    redirect: USERS,
    icon: <MdLoop size={24} color={PRIMARY_WHITE} />,
    title: 'Internal',
    defaultRoute: true,
    permissions: [],

    childRoutes: [
      {
        path: USERS,
        title: 'Users',

        active: 'userListPage',
        defaultTab: true,
        component: <UserList />,
      },
      {
        path: BRANCHES,
        title: 'Branches',

        active: 'branchesListPage',
        component: <BranchList />,
      },
      {
        path: CITIES,
        title: 'Cities',

        active: 'cityListPage',
        component: <CityList />,
      },
      {
        path: CONTRACTS,
        title: 'Contracts',
        defaultTab: true,
        component: <ContractsList />,
        active: 'contracts',
      },
    ],
    pageId: 'admin',
  },
  {
    path: CREATE_ORDER,
    title: 'Create Order',
    component: <CreateOrder />,
  },
  {
    path: `${ORDERS}/:applicationId`,
    title: 'Single Order Page',
    component: <Order />,
  },
  {
    path: `${COURTESY_ORDER}/:courtesyId`,
    title: 'Courtesy order',
    component: <CourtesyOrder />,
  },
  {
    path: SALES,
    icon: <MdShoppingCart size={24} color={PRIMARY_WHITE} />,
    title: 'Sales',
    redirect: ORDERS,
    defaultRoute: true,
    permissions: [],

    childRoutes: [
      {
        path: PROSPECTIVE_DRIVERS,
        title: 'Prospective drivers',

        active: 'prospectiveDriverListPage',
        component: <ProspectiveDriverList />,
      },
      {
        path: ASSESSMENTS,
        title: 'Assessments',

        component: <AssessmentList />,
      },
      {
        path: ORDERS,
        title: 'Orders',

        defaultTab: true,

        component: <OrderList />,
      },
    ],
  },
  {
    path: SERVICING,
    redirect: SERVICING_LIST,
    icon: <FiSettings size={24} color={PRIMARY_WHITE} />,
    title: 'Servicing',
    defaultRoute: true,
    permissions: [],

    childRoutes: [
      {
        path: SERVICING_LIST,
        title: 'Servicings',

        active: 'servicingListPage',
        component: <ServicingList />,
      },
      {
        path: PHV_AND_MOT,
        title: 'PHV and MOT Calendar',

        active: 'phv-mot',
        component: <PhvMotCalendar />,
      },
    ],
  },
  {
    path: POLICIES,
    icon: <BsUmbrella size={24} color={PRIMARY_WHITE} />,
    title: 'Insurance',
    defaultRoute: true,
    permissions: [],
    childRoutes: [
      {
        path: POLICIES,
        title: 'Policies',
        defaultTab: true,
        active: 'policies',
        component: <PoliciesList />,
      },
    ],
  },
  {
    path: '*',
    component: <Navigate to={DASHBOARD} />,
  },
];
