import React, { useCallback, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { BsCardText, BsPerson, BsPersonCheck } from 'react-icons/bs';
import { getFileService } from '../../api/cognito/file.service';
import { onlineAssessment } from '../../api/patch/assessment.patch';
import { GRAY_1, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../common/styles/Colors';
import { Assessment } from '../../models/assessment';
import { useAppSelector } from '../../store-hooks';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { TextArea } from '../../uiComponents/inputs/textArea/textArea';
import { TextField } from '../../uiComponents/inputs/textField/textField';
import { UploaderInput } from '../../uiComponents/inputs/uploaderInput/uploaderInput';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../uiComponents/text/text';
import { Notification } from '../../uiComponents/toast/toast';
import { ALLOWED_MIME_TYPES, fileNameTimeStampFormat, renderNotification } from '../../utils/utils';
import { scoreFormat } from '../../utils/validations';
import { ResultIndicator } from './assessment.styles';

interface OnlineAssessmentProps {
  selectedRow?: Assessment | null;
  close: () => void;
  fetchData?: () => void;
}
interface FormData {
  assessment_id?: string;
  score?: number;
  notes?: string;
  assessmentReport: File | Blob;
  s3_url?: string;
  driver_id?: string;
  document_type: string;
}
export interface OnlineAssessment extends FieldValues {
  score?: number;
  notes?: string;
}

export const OnlineAssessment = ({ selectedRow, close, fetchData }: OnlineAssessmentProps) => {
  const [currentScore, setCurrentScore] = useState<number>(0);
  const pulseUser = useAppSelector((state) => state.pulseUser);
  const PASSING_MARKS: number = 70;
  const { register, control, handleSubmit } = useForm<FormData>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const fileService = getFileService();

  const onSubmit = async (data: FormData) => {
    const { assessmentReport } = data;
    let ext: string | undefined = ALLOWED_MIME_TYPES[assessmentReport.type];
    if (!ext) {
      ext = (assessmentReport as File).name.split('.').pop() || undefined;
    }

    const fileName = 'ONLINE-ASSESSMENT';
    let fileWithExt = fileName;

    if (ext) {
      fileWithExt = `${fileWithExt}.${ext}`;
    }

    const fileNameWithTimeStamp = fileNameTimeStampFormat(fileWithExt);
    data.assessment_id = selectedRow?.id;
    data.driver_id = selectedRow?.driver_id;
    data.document_type = fileName;

    const path = `DOCUMENTS/DRIVER/${data.driver_id}/${fileName}/${fileNameWithTimeStamp}`;
    setIsProcessing(true);
    try {
      await fileService.uploadFileToStorage({
        path,
        data: assessmentReport,
      });
      data.s3_url = path;
    } catch (error: any) {
      renderNotification('error', 'Error', `${error.message}.`, true);
      setIsProcessing(false);
      return;
    }

    const res = await onlineAssessment(selectedRow?.assessment_type_data[0]?.assessment_type_id, data);
    setIsProcessing(false);
    if (res) {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Online assessment successfully added',
        isAlert: true,
      });
      if (fetchData) {
        fetchData();
      }
      close();
    }
  };
  const handleChange = (score: number) => {
    setCurrentScore(+score);
  };
  const results = useCallback((score: number) => {
    let res;
    if (score === null || score === 0) {
      res = (
        <ResultIndicator itemsX="center" itemsY="center" $result="RESULT">
          <Text variant="body7" weight={500} color={GRAY_1}>
            Result
          </Text>
        </ResultIndicator>
      );
    } else if (score >= PASSING_MARKS) {
      res = (
        <ResultIndicator itemsX="center" itemsY="center" $result="PASSED">
          <Text variant="body7" weight={500} color={PRIMARY_WHITE}>
            Passed
          </Text>
        </ResultIndicator>
      );
    } else {
      res = (
        <ResultIndicator itemsX="center" itemsY="center" $result="FAILED">
          <Text variant="body7" weight={500} color={PRIMARY_WHITE}>
            Failed
          </Text>
        </ResultIndicator>
      );
    }
    return res;
  }, []);
  const basicInfo = [
    {
      icon: <BsPerson size={40} color={PRIMARY_PURPLE} />,
      title: 'Driver name',
      content: selectedRow?.driver_name,
    },
    {
      icon: <BsCardText size={40} color={PRIMARY_PURPLE} />,
      title: 'DVLA',
      content: selectedRow?.dvla,
    },
    {
      icon: <BsPersonCheck size={40} color={PRIMARY_PURPLE} />,
      title: 'Current agent',
      content: `${pulseUser?.first_name} ${pulseUser?.last_name}`,
    },
  ];

  return (
    <div>
      <FlexLayout gap={80}>
        {basicInfo?.map((info) => (
          <FlexLayout key={info?.title} itemsY="center" gap={16}>
            {info?.icon}
            <div>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                {info?.title}
              </Text>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                {info?.content ?? '-'}
              </Text>
            </div>
          </FlexLayout>
        ))}
      </FlexLayout>
      <FlexLayout gap={16} styled={{ marginTop: 24 }}>
        <div>
          <TextField
            label="Add score"
            {...register('score', {
              required: 'score is required',
              pattern: {
                value: scoreFormat,
                message: 'Please provide a valid score',
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleChange(parseInt(e.target.value)),
            })}
            required
            type="number"
            placeholder="Driver score"
          />
          {results(currentScore ?? 0)}
        </div>
        <UploaderInput
          label="Upload or take picture of report"
          name="assessmentReport"
          required
          control={control as unknown as Control<FieldValues>}
        />
      </FlexLayout>
      <TextArea
        styled={{ height: 80, marginTop: 16 }}
        label=""
        {...register('notes')}
        name="notes"
        placeholder="Notes"
      />
      <PrimaryButton
        styled={{ float: 'right', marginTop: 24 }}
        onClick={handleSubmit(onSubmit)}
        disabled={currentScore === null || currentScore === 0}
        isProcessing={isProcessing}
      >
        Submit
      </PrimaryButton>
    </div>
  );
};
