import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VehicleForm } from '../vehicle.styles';
import {
  ARCHIVED,
  AUCTION,
  AVAILABLE,
  NO_SALE,
  ORDERED,
  SOLD,
  STAFF,
  STOLEN,
  WITH_3RD_PARTY,
  vehicleConditionOptions,
  vehicleStatusOptions,
} from '../../../../consts/vehicle';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { InputType } from '../../../../uiComponents/inputs/textInput/textInput';
import { Notification } from '../../../../uiComponents/toast/toast';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { Toggle } from '../../../../uiComponents/uiControls/toggle/toggle';
import { getAllBranches } from '../../../../api/get/branch.get';
import { verifyVinExists, getVehicleByVrmAndStatus } from '../../../../api/get/vehicle.get';
import { updateVehicle } from '../../../../api/patch/vehicle.patch';
import { UpdateVehiclePayload, Vehicle, VehicleTag } from '../../../../models/vehicle';
import { Branch } from '../../../../models/branch';
import { OptionList } from '../../../../utils/props';
import { VRMRegex, decimalRegex, numberRegex, validateDateFormat } from '../../../../utils/validations';
import { format, isSameDay } from 'date-fns';

interface EditVehicleFormInfo {
  vehicleData: Vehicle | null;
  close: (refresh?: boolean) => void;
  editType: EditType;
}

interface EditFields {
  label: string;
  name: keyof Vehicle;
  type: InputType | 'select' | 'checkbox';
  placeholder: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: (v: string | number | boolean | null | VehicleTag[] | undefined) => boolean | string;
  required?: string;
  disabled?: boolean;
}

type EditType = 'vehicleEdit' | 'adminEdit';

export const EditVehicleForm = ({ vehicleData, close, editType }: EditVehicleFormInfo) => {
  const {
    register,
    handleSubmit,
    setError,
    control,
    watch,
    formState: { errors },
  } = useForm<Vehicle>({
    defaultValues: {
      ...vehicleData,
      last_service_date:
        vehicleData?.last_service_date && format(new Date(vehicleData?.last_service_date), 'yyyy-MM-dd'),
      phv_exp: vehicleData?.phv_exp && format(new Date(vehicleData?.phv_exp), 'yyyy-MM-dd'),
      mot_exp: vehicleData?.mot_exp && format(new Date(vehicleData.mot_exp), 'yyyy-MM-dd'),
      registration_date:
        vehicleData?.registration_date && format(new Date(vehicleData.registration_date), 'yyyy-MM-dd'),
      available_on:
        (vehicleData?.available_on && format(new Date(vehicleData.available_on), 'yyyy-MM-dd')) ||
        (vehicleData?.available_on_batch && format(new Date(vehicleData.available_on_batch), 'yyyy-MM-dd')),
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const vehicleStatus = watch('vehicle_status');
  const [loading, setLoading] = useState<boolean>(false);
  const [salesBranchOptions, setSalesBranchOptions] = useState<OptionList[]>([]);
  const [isUsedChecked, setIsUsedChecked] = useState<boolean>(!!vehicleData?.used);
  const [isSecImmobChecked, setIsSecImmobChecked] = useState<boolean>(!!vehicleData?.is_sec_immob);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [specStr, setSpecStr] = useState<string>('');
  const [updatePayload, setUpdatePayload] = useState<UpdateVehiclePayload>();
  const { vehicleId } = useParams();

  const validateVrmIsAvailable = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const vrmVal = e?.target.value;
      if (vrmVal === vehicleData?.vrm) return;
      if (vrmVal.length > 0) {
        getVehicleByVrmAndStatus(vrmVal).then(({ data }) => {
          if (data) {
            setError('vrm', {
              type: 'manual',
              message: 'Vehicle with this VRM already exists',
            });
          }
        });
      }
    },
    [setError, vehicleData]
  );

  const validateVinIsAvailable = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const vinVal = e?.target.value;
      if (vinVal === vehicleData?.vin) return;
      if (vinVal.length > 5) {
        verifyVinExists(vinVal).then(({ data }) => {
          if (data >= 1) {
            setError('vin', {
              type: 'manual',
              message: 'Vehicle with this VIN already exists',
            });
          }
        });
      }
    },
    [setError, vehicleData]
  );

  const vehicleEdit = useMemo((): EditFields[] => {
    return [
      {
        label: 'MOT expiry date',
        name: 'mot_exp',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
        disabled: true,
      },
      {
        label: 'PHV expiry date',
        name: 'phv_exp',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
        disabled: true,
      },
      {
        label: 'Status',
        name: 'vehicle_status',
        type: 'select',
        placeholder: 'Select from list',
        required: 'Status is required',
        validate: (v: string | number | boolean | null | VehicleTag[] | undefined) => {
          let valid: string | boolean = true;
          if (v !== vehicleData?.vehicle_status && vehicleData?.vehicle_status) {
            valid =
              ([ORDERED, WITH_3RD_PARTY].includes(vehicleData?.vehicle_status) &&
                [AVAILABLE, WITH_3RD_PARTY].includes(v as string)) ||
              ([AVAILABLE, WITH_3RD_PARTY].includes(vehicleData.vehicle_status) &&
                [NO_SALE, ORDERED, ARCHIVED, SOLD, AUCTION, STAFF, STOLEN, WITH_3RD_PARTY].includes(v as string)) ||
              `Cant change status of vehicle with status ${vehicleData?.vehicle_status.toLowerCase()} to ${v
                ?.toString()
                .toLowerCase()}`;
          }
          return valid;
        },
      },
      {
        label: 'Key location',
        name: 'carkey',
        type: 'text',
        placeholder: 'Location',
      },
      {
        label: 'Last service date',
        name: 'last_service_date',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
      },
      {
        label: 'Last service mileage',
        name: 'last_service_mileage',
        type: 'number',
        placeholder: '0000.00',
      },
      {
        label: 'Telematics',
        name: 'telematics',
        type: 'text',
        placeholder: 'Samsara/Kasava',
      },
      {
        label: 'Secondary immobiliser',
        name: 'is_sec_immob',
        type: 'checkbox',
        placeholder: '',
      },
    ];
  }, [vehicleData?.vehicle_status]);

  const adminEdit = useMemo((): EditFields[] => {
    return [
      {
        label: 'VRM',
        name: 'vrm',
        type: 'text',
        placeholder: 'AABBCCDD1234',
        pattern: {
          value: VRMRegex,
          message: 'Invalid VRM, please use letters A-Z and numbers 0-9',
        },
        required: 'VRM cannot be an empty field',
        onBlur: validateVrmIsAvailable,
      },
      {
        label: 'VIN',
        name: 'vin',
        type: 'text',
        placeholder: 'AABBCCDD1234',
        onBlur: validateVinIsAvailable,
      },
      {
        label: 'Registration date',
        name: 'registration_date',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
      },
      {
        label: 'MOT expiry date',
        name: 'mot_exp',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
        disabled: true,
      },
      {
        label: 'PHV expiry date',
        name: 'phv_exp',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
        disabled: true,
      },
      {
        label: 'Deposit',
        name: 'deposit',
        type: 'number',
        placeholder: '0000.00',
        pattern: {
          value: decimalRegex,
          message: 'Please enter valid amount',
        },
      },
      {
        label: 'Service interval mileage',
        name: 'service_interval_mileage',
        type: 'number',
        placeholder: '0000.00',
        pattern: {
          value: numberRegex,
          message: 'Please enter valid mileage',
        },
      },
      {
        label: 'Status',
        name: 'vehicle_status',
        type: 'select',
        placeholder: 'Select from list',
        required: 'Status is required',
      },
      {
        label: 'Condition',
        name: 'condition',
        type: 'select',
        placeholder: 'Select from list',
      },
      {
        label: 'Mileage',
        name: 'mileage',
        type: 'number',
        placeholder: '0000.00',
        pattern: {
          value: numberRegex,
          message: 'Please enter valid mileage',
        },
      },
      {
        label: 'Available on',
        name: 'available_on',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
      },
      {
        label: 'Rental value',
        name: 'rental_value',
        type: 'number',
        placeholder: '0000.00',
        pattern: {
          value: decimalRegex,
          message: 'Please enter valid amount',
        },
      },
      {
        label: 'Rental deposit',
        name: 'rental_deposit',
        type: 'number',
        placeholder: '0000.00',
      },
      {
        label: 'Amount paid',
        name: 'amount_paid',
        type: 'number',
        placeholder: '0000.00',
        pattern: {
          value: decimalRegex,
          message: 'Please enter valid amount',
        },
      },
      {
        label: 'Key location',
        name: 'carkey',
        type: 'text',
        placeholder: 'Location',
      },
      {
        label: 'Last service date',
        name: 'last_service_date',
        type: 'date',
        placeholder: 'yyyy/mm/dd',
        validate: (v) => validateDateFormat(v as string),
      },
      {
        label: 'Last service mileage',
        name: 'last_service_mileage',
        type: 'number',
        placeholder: '0000.00',
        pattern: {
          value: numberRegex,
          message: 'Please use numerics only',
        },
      },
      {
        label: 'Telematics',
        name: 'telematics',
        type: 'text',
        placeholder: 'Samsara/Kasava',
      },
      {
        label: 'Policy number',
        name: 'policy_number',
        type: 'text',
        placeholder: '123KJH HGB9874635',
      },
      {
        label: 'Used vehicle',
        name: 'used',
        type: 'checkbox',
        placeholder: '',
      },
      {
        label: 'Secondary immobiliser',
        name: 'is_sec_immob',
        type: 'checkbox',
        placeholder: '',
      },
    ];
  }, [validateVinIsAvailable, validateVrmIsAvailable]);

  const getOptionType = (type: 'condition' | 'vehicle_status'): OptionList[] =>
    type === 'vehicle_status' ? vehicleStatusOptions : vehicleConditionOptions;

  const handleUsed = () => setIsUsedChecked(!isUsedChecked);
  const handleIsSecImmob = () => setIsSecImmobChecked(!isSecImmobChecked);

  const currentList = useMemo(() => {
    return editType === 'vehicleEdit' ? vehicleEdit : adminEdit;
  }, [adminEdit, editType, vehicleEdit]);

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name, has_sales: branch.has_sales };
      });
      setSalesBranchOptions(branches.filter((b) => b.has_sales));
    });
  }, []);

  const update = async (payload?: UpdateVehiclePayload): Promise<void> => {
    if (updatePayload && Object.keys(updatePayload).length === 0) {
      close(false);
      return;
    }
    setLoading(true);
    if (vehicleId) {
      await updateVehicle(vehicleId, updatePayload ? updatePayload : (payload as UpdateVehiclePayload))
        .then(() => {
          close(true);
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Vehicle has been successfully updated',
          });
        })
        .catch((err) => {
          Notification({
            type: 'error',
            title: 'Error',
            message: `${err}`,
          });
        })
        .finally(() => {
          setLoading(false);
          close(true);
        });
    }
  };

  const onSubmit = (submitValues: Vehicle): void => {
    const { phv_exp: phvExp, mot_exp: motExp } = submitValues;

    const getDifference = (vehicleInfo: Vehicle, submitValues: Vehicle) =>
      Object.fromEntries(
        Object.entries(submitValues).filter(
          ([key, val]) => key in vehicleInfo && vehicleInfo[key as keyof Vehicle] !== val
        )
      );

    if (!vehicleData) {
      return;
    }
    const payload = getDifference(vehicleData, submitValues);
    delete payload?.colors;
    delete payload?.image_s3_url;
    delete payload?.tagData;
    delete payload?.mot_exp;
    delete payload?.phv_exp;
    delete payload?.fittings;

    if (isSameDay(new Date(vehicleData?.available_on), payload.available_on)) {
      delete payload.available_on;
    }

    if (isSameDay(new Date(vehicleData?.registration_date as string), payload.registration_date)) {
      delete payload.registration_date;
    }

    if (isSameDay(new Date(vehicleData?.last_service_date as string), payload.last_service_date)) {
      delete payload.last_service_date;
    }

    if (payload?.rental_value) {
      payload.rental_value = (+payload?.rental_value)?.toFixed(2);
    }

    if (payload?.rental_deposit) {
      payload.rental_deposit = (+payload?.rental_deposit)?.toFixed(2);
    }

    if (payload?.mileage) {
      payload.mileage = +payload?.mileage;
    }

    if (payload?.last_service_mileage) {
      payload.last_service_mileage = +payload?.last_service_mileage;
    }

    if (payload?.deposit) {
      payload.deposit = (+payload?.deposit)?.toFixed(2);
    }

    if (payload?.accident_excess) {
      payload.accident_excess = (+payload?.excess)?.toFixed(2);
    }

    if (payload?.gross_value) {
      payload.gross_value = (+payload?.gross_value)?.toFixed(2);
    }

    if (payload?.net_value) {
      payload.net_value = (+payload?.net_value)?.toFixed(2);
    }

    if (payload?.vat_value) {
      payload.vat_value = (+payload?.vat_value)?.toFixed(2);
    }

    setUpdatePayload(payload);
    if (!phvExp || !motExp) {
      let specTxt;
      if (!phvExp && !motExp) {
        specTxt = 'PHV & MOT are';
      } else if (!motExp) {
        specTxt = 'MOT is';
      } else {
        specTxt = 'PHV is';
      }
      setSpecStr(specTxt);
      setIsConfirmationModalOpen(true);
    } else if (Object.keys(payload).length === 0) {
      setLoading(false);
      close(false);
      return;
    } else {
      update(payload);
    }
  };

  return (
    <>
      <VehicleForm>
        <GridLayout template={editType === 'vehicleEdit' ? 2 : 3} gap={24}>
          {currentList?.map(({ validate, ...edit }, id) => (
            <div key={id}>
              {(edit.name === 'vehicle_status' || edit.name === 'condition') && edit.type === 'select' ? (
                <>
                  <TextFieldLabel $isRequired={!!edit.required}>{edit.label}</TextFieldLabel>
                  <DropDown
                    name={edit.name}
                    error={errors[edit.name]}
                    placeholder={edit.placeholder}
                    defaultValue={vehicleData ? vehicleData[edit.name] : ''}
                    required={{
                      required: edit.required,
                      ...(validate && { validate }),
                    }}
                    options={getOptionType(edit.name)}
                    control={control as unknown as Control<FieldValues>}
                  />
                </>
              ) : (edit.name === 'used' || edit.name === 'is_sec_immob') && edit.type === 'checkbox' ? (
                <FlexLayout itemsX="start" itemsY="center" styled={{ marginTop: '30px' }}>
                  <Toggle
                    styled={{ marginRight: '10px' }}
                    defaultChecked={vehicleData ? vehicleData[edit?.name] : undefined}
                    onChecked={edit.name === 'used' ? handleUsed : handleIsSecImmob}
                    {...register(edit.name === 'used' ? 'used' : 'is_sec_immob')}
                  />
                  <TextFieldLabel>{edit.label}</TextFieldLabel>
                </FlexLayout>
              ) : (
                <TextField
                  {...register(edit?.name, {
                    required: edit.required,
                    pattern: edit.pattern,
                    onBlur: edit.onBlur,
                    ...(validate && { validate }),
                  })}
                  required={!!edit.required}
                  label={edit.label}
                  placeholder={edit.placeholder}
                  type={edit.type !== 'checkbox' && edit.type !== 'select' ? edit.type : 'text'}
                  name={edit.name}
                  error={errors[edit.name] as FieldError}
                  disabled={edit.disabled}
                  onBlur={(e) => edit?.onBlur?.(e)}
                />
              )}
            </div>
          ))}
          {vehicleData && [ORDERED].includes(vehicleData?.vehicle_status) && [AVAILABLE].includes(vehicleStatus) && (
            <div>
              <TextFieldLabel $isRequired>Physical location</TextFieldLabel>
              <DropDown
                name={'physical_branch_id'}
                error={errors.physical_branch_id}
                placeholder="Select physical location"
                required={{
                  required: 'Physical location is required',
                }}
                options={salesBranchOptions}
                control={control as unknown as Control<FieldValues>}
              />
            </div>
          )}
          {editType === 'adminEdit' && isUsedChecked && (
            <>
              <TextField
                {...register('gross_value', {
                  pattern: {
                    value: decimalRegex,
                    message: 'Please enter valid amount',
                  },
                })}
                type="number"
                label="Gross Value"
                placeholder="Gross Value"
                name="gross_value"
                error={errors.gross_value}
              />
              <TextField
                {...register('net_value', {
                  pattern: {
                    value: decimalRegex,
                    message: 'Please enter valid amount',
                  },
                })}
                type="number"
                label="Net Value"
                placeholder="Net Value"
                name="net_value"
                error={errors.net_value}
              />
              <TextField
                {...register('vat_value', {
                  pattern: {
                    value: decimalRegex,
                    message: 'Please enter valid amount',
                  },
                })}
                type="number"
                label="Vat Value"
                placeholder="Vat Value"
                name="vat_value"
                error={errors.vat_value}
              />
            </>
          )}
        </GridLayout>
        <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: 16 }}>
          <SecondaryButton disabled={loading} styled={{ marginRight: 10, padding: '0px 38px' }} onClick={() => close()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton isProcessing={loading} styled={{ padding: '0px 38px' }} onClick={handleSubmit(onSubmit)}>
            Update vehicle
          </PrimaryButton>
        </FlexLayout>
      </VehicleForm>
      <ConfirmationModal
        title={`Please ensure a valid ${specStr} in place for this vehicle. Would you like to proceed?`}
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
        preConfirm={() => {
          setLoading(true);
          return update();
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
