import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BsPlusCircleDotted, BsEye, BsCloudDownload } from 'react-icons/bs';
import { OnlineAssessment } from './onlineAssessment';
import { PracticalAssessment } from './practicalAssessment';
import { ViewAssessment } from './viewAssessment';
import {
  ASSESSMENT_ONLINE,
  ASSESSMENT_PRACTICAL,
  ASSESSMENT_STATUS_NOT_NEEDED,
  ONLINE_PASSED,
  ONLINE_PENDING,
  PRACTICAL_FAILED,
  PRACTICAL_PASSED,
  PRACTICAL_PENDING,
} from '../../consts/assessment';
import { useTableFilters } from '../../hooks/useTableFilters';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../uiComponents/modals/modal';
import { ActionIcon } from '../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../uiComponents/table/table';
import { assessmentListColumns } from '../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../uiComponents/table/tableFilters/tableFilters';
import {
  assessmentStatusOption,
  evaluationTypeOptions,
} from '../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
  TableTextCell,
} from '../../uiComponents/table/tableUtils/tableUtils';
import { Tag } from '../../uiComponents/customComponents/tag/tag';
import { Assessment } from '../../models/assessment';
import { SECONDARY_PURPLE_90, SECONDARY_PURPLE_5, PRIMARY_WHITE } from '../../common/styles/Colors';
import { OptionList } from '../../utils/props';
import { decideNewStatusColor } from '../../common/utils';
import { APP_CONTEXT } from '../../utils/context';
import { alterString, handleDownloadFileFromStorage } from '../../utils/utils';
import { getAllEmployees } from '../../api/get/employee.get';
import { Employee } from '../../models/employee';
import { useDateRangeFilter } from '../../hooks/useDateRangeFilter';
import { useListAndMergeAssessmentsListQuery } from '../../api/listAndMerge/listAndMergeAssessmentsListApiSlice';
import { getAllBranches } from '../../api/get/branch.get';
import { Branch } from '../../models/branch';
import { format } from 'date-fns';

export const AssessmentList: React.FC = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [addOnlineAssessment, setAddOnlineAssessment] = useState<boolean>(false);
  const [addPracticalAssessment, setAddPracticalAssessment] = useState<boolean>(false);
  const [viewAssessment, setViewAssessment] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Assessment>();
  const [employees, setEmployees] = useState<OptionList[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<OptionList[]>([]);
  const [statusFilters, setStatusFilters] = useState<OptionList[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<OptionList[]>([]);
  const [selectedAssessmentType, setSelectedAssessmentType] = useState<OptionList[]>([]);
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setSortAscending,
    setSortingColumn,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: assessmentsList,
    isFetching: isAssessmentsListFetching,
    refetch,
  } = useListAndMergeAssessmentsListQuery({ query: queryStringState, refetch: refetchData });

  const handleCloseModel = () => {
    setAddOnlineAssessment(false);
    setViewAssessment(false);
    setAddPracticalAssessment(false);
  };

  const currentModelTitle = () => {
    let currentTitle;
    if (addOnlineAssessment) {
      currentTitle = 'Online assessment score';
    } else if (addPracticalAssessment) {
      currentTitle = 'Practical assessment score';
    } else {
      currentTitle = 'Driver assessment view';
    }
    return currentTitle;
  };

  const handleAssessmentGetResponse = useCallback(
    (count: number, assessments: Assessment[]) => {
      const assessmentRows = assessments.map((assessment: Assessment, index: number) => {
        return {
          rowData: {
            rowColour: inactiveCondition(assessment) ? PRIMARY_WHITE : SECONDARY_PURPLE_5,
            data: assessment,
          },
          cells: [
            <TableTextCell key={`${index}-${assessment?.driver_name}`} value={assessment?.driver_name} />,
            <TableTextCell key={`${index}-${assessment?.dvla}`} value={assessment?.dvla} />,
            <TableTextCell
              key={`${index}-${assessment?.assessment_type_data?.[0]?.branch_name}`}
              value={assessment?.assessment_type_data?.[0]?.branch_name}
            />,
            <FlexLayout>
              {assessment.is_online && (
                <Tag
                  color={decideNewStatusColor(alterString(assessment?.assessment_type_data?.[0]?.assessment_status))}
                >
                  {alterString(ASSESSMENT_ONLINE)}
                </Tag>
              )}
              {assessment.is_practical && (
                <Tag
                  color={decideNewStatusColor(alterString(assessment?.assessment_type_data?.[0]?.assessment_status))}
                >
                  {alterString(ASSESSMENT_PRACTICAL)}
                </Tag>
              )}
              {!assessment.is_practical &&
                !assessment.is_online &&
                assessment?.assessment_type_data?.[0]?.assessment_status === ASSESSMENT_STATUS_NOT_NEEDED && (
                  <Tag
                    color={decideNewStatusColor(alterString(assessment?.assessment_type_data?.[0]?.assessment_status))}
                  >
                    None
                  </Tag>
                )}
            </FlexLayout>,
            <TableTextCell
              key={`${index}-${assessment?.created_date}`}
              value={format(new Date(assessment?.created_date), 'dd MMM yyyy')}
            />,
            <TableTextCell
              key={`${index}-${assessment?.assessment_type_data?.[0]?.agent ?? '-'}`}
              value={assessment?.assessment_type_data?.[0]?.agent ?? '-'}
            />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                icon={<BsPlusCircleDotted size={24} color={SECONDARY_PURPLE_90} />}
                disabled={
                  assessment?.assessment_type_data?.[0]?.assessment_status !== ONLINE_PENDING &&
                  assessment?.assessment_type_data?.[0]?.assessment_status !== PRACTICAL_PENDING
                }
                onClick={() => handleAddResults(assessment)}
                tooltip="Add assessment results"
              />
              <ActionIcon
                icon={<BsEye size={24} color={SECONDARY_PURPLE_90} />}
                tooltip="View Assessment History"
                onClick={() => handleViewAssessment(assessment)}
              />
              {assessment?.prc_s3_url && (
                <ActionIcon
                  icon={<BsCloudDownload size={24} color={SECONDARY_PURPLE_90} />}
                  tooltip="Download Driver File"
                  onClick={() => handleDownloadFileFromStorage(assessment.prc_s3_url)}
                />
              )}
            </FlexLayout>,
            <FlexLayout gap={16}>
              {assessment?.assessment_type_data?.[0]?.assessment_status && (
                <Tag
                  color={decideNewStatusColor(alterString(assessment?.assessment_type_data?.[0]?.assessment_status))}
                  styled={{ margin: '0px', maxWidth: '100px' }}
                >
                  {alterString(assessment?.assessment_type_data?.[0]?.assessment_status)}
                </Tag>
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(assessmentRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  useEffect(() => {
    getAllBranches('filter=has_sales$eq=true').then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_name, label: branch.branch_name };
      });
      setBranchOptions(branches);
    });
  }, [defaultString, setActiveSideNav, setPageTitle]);

  const handleAddResults = (row: Assessment) => {
    setSelectedRow(row);
    if (row.is_online && row?.assessment_type_data?.[0]?.assessment_status === ONLINE_PENDING) {
      setAddOnlineAssessment((pre) => !pre);
    } else if (row.is_practical && row?.assessment_type_data?.[0]?.assessment_status === PRACTICAL_PENDING) {
      setAddPracticalAssessment((pre) => !pre);
    } else {
      setAddOnlineAssessment((pre) => !pre);
    }
  };

  const handleViewAssessment = (row: Assessment) => {
    setSelectedRow(row);
    setViewAssessment(true);
  };

  const inactiveCondition = (row: Assessment) =>
    (row.is_online && row?.assessment_type_data?.[0]?.assessment_status === ONLINE_PASSED) ||
    (row.is_practical && row?.assessment_type_data?.[0]?.assessment_status === PRACTICAL_PASSED) ||
    (row.is_practical && row?.assessment_type_data?.[0]?.assessment_status === PRACTICAL_FAILED);

  const filters: FilterItem[] = [
    {
      name: 'branch',
      element: (
        <DropDownFilter
          name="branch_name"
          placeholder="Select branch"
          multiValues={selectedBranch}
          options={branchOptions}
          title="Assessment branch"
          onChange={(items) => setSelectedBranch(items as OptionList[])}
        />
      ),
    },
    {
      name: 'assessment_type',
      element: (
        <DropDownFilter
          name="assessment_type"
          placeholder="Assessment type"
          multiValues={selectedAssessmentType}
          options={evaluationTypeOptions}
          title="Assessment type"
          onChange={(items) => setSelectedAssessmentType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'instructor',
      element: (
        <DropDownFilter
          name="instructor"
          placeholder="Instructor"
          multiValues={selectedEmployees}
          options={employees}
          title="Instructor"
          onChange={(items) => setSelectedEmployees(items as OptionList[])}
        />
      ),
    },
    {
      name: 'assessment_status',
      element: (
        <DropDownFilter
          name="assessment_status"
          placeholder="Assessment status"
          options={assessmentStatusOption}
          multiValues={statusFilters}
          title="Assessment status"
          onChange={(items) => setStatusFilters(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date_created',
      element: (
        <DateRangeFilter
          title="Assessment start date"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('assessmentListPage');
    setPageTitle('Assessments');
  }, [setActiveSideNav, setPageTitle]);

  useEffect(() => {
    getAllEmployees().then((response: { count: number; data: Employee[] }) => {
      const employeeList: OptionList[] = response?.data?.map((employee: Employee) => {
        return {
          value: employee?.id,
          label: `${employee?.first_name} ${employee?.last_name}`,
        };
      });
      setEmployees(employeeList);
      setSortingColumn('updated_date');
      setSortAscending(false);
    });
    setTableFilters([
      { columnName: 'branch_name', options: selectedBranch },
      { columnName: 'evaluation_type', options: selectedAssessmentType },
      { columnName: 'agent_id', options: selectedEmployees },
      { columnName: 'assessment_status', options: statusFilters },
      {
        columnName: 'assessment.updated_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [
    setTableFilters,
    setSortingColumn,
    setSortAscending,
    selectedBranch,
    statusFilters,
    dateRangeFilter,
    selectedAssessmentType,
    selectedEmployees,
  ]);

  useEffect(() => {
    if (assessmentsList) {
      handleAssessmentGetResponse(assessmentsList.count, assessmentsList.data);
    }
  }, [assessmentsList, queryStringState, handleAssessmentGetResponse]);

  const onClearClick = useCallback(() => {
    setSelectedBranch([]);
    setSelectedEmployees([]);
    setSelectedAssessmentType([]);
    setStatusFilters([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isAssessmentsListFetching}
        header="Assessment list"
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={assessmentListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        disableApply={invalidDates}
      ></Table>
      <Modal
        title={currentModelTitle()}
        open={addOnlineAssessment}
        onClose={() => handleCloseModel()}
        showClose
        styled={{ width: '80vw', minWidth: 400, maxWidth: 730 }}
      >
        <OnlineAssessment
          selectedRow={selectedRow}
          close={() => {
            handleCloseModel();
          }}
          fetchData={() => {
            refetchToFirstPage();
          }}
        />
      </Modal>
      <Modal
        title={currentModelTitle()}
        open={viewAssessment}
        onClose={() => handleCloseModel()}
        showClose
        styled={{ width: '80vw', minWidth: 400, maxWidth: 1200 }}
      >
        <ViewAssessment selectedRow={selectedRow} />
      </Modal>
      <Modal
        title={currentModelTitle()}
        open={addPracticalAssessment}
        onClose={() => handleCloseModel()}
        showClose
        styled={{ width: '80vw', minWidth: 872, maxWidth: 1028 }}
      >
        <PracticalAssessment
          fetchData={() => {
            refetchToFirstPage();
          }}
          close={() => {
            handleCloseModel();
          }}
          selectedRow={selectedRow}
        />
      </Modal>
    </>
  );
};
