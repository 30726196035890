import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { BsCalendar4, BsCardText, BsPerson } from 'react-icons/bs';
import { ALLOWED_MIME_TYPES, fileNameTimeStampFormat, renderNotification } from '../..//utils/utils';
import { getFileService } from '../../api/cognito/file.service';
import { getAllBranches } from '../../api/get/branch.get';
import { getTagTypesByCategory } from '../../api/get/driver.get';
import { getAllEmployees } from '../../api/get/employee.get';
import { createPracticalAssessment } from '../../api/patch/assessment.patch';
import { PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_RED } from '../../common/styles/Colors';
import { Assessment } from '../../models/assessment';
import { DriverTagType } from '../../models/driver';
import { Employee } from '../../models/employee';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextArea } from '../../uiComponents/inputs/textArea/textArea';
import { TextField } from '../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../uiComponents/inputs/textField/textField.styles';
import { UploaderInput } from '../../uiComponents/inputs/uploaderInput/uploaderInput';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../uiComponents/layouts/gridLayout/gridLayout';
import { Text } from '../../uiComponents/text/text';
import { DropDown } from '../../uiComponents/uiControls/dropDown/dropDown';
import { ErrorType, handleAPIError } from '../../utils/handleAPIError';
import { OptionList } from '../../utils/props';
import { scoreFormat } from '../../utils/validations';

interface PracticalAssessmentProps {
  selectedRow?: Assessment | null;
  close: () => void;
  fetchData?: () => void;
}

interface onSubmitPayload extends FieldValues {
  branch_id: string;
  driver_experience: string;
  employee_id: string;
  image?: File;
  tag_name: string;
  notes: string;
  score: number;
}

export const PracticalAssessment = ({ selectedRow, close, fetchData }: PracticalAssessmentProps) => {
  const [branches, setBranches] = useState<OptionList[]>([]);
  const driverTagOptionsRef = useRef<OptionList[]>([]);
  const [listOfEmployees, setListOfEmployees] = useState<OptionList[]>([]);
  const [practicalResult, setPracticalResult] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const fileService = getFileService();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<onSubmitPayload>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const { image, tag_name, notes } = data as onSubmitPayload;
    if (practicalResult === '') {
      renderNotification('error', 'Error', 'Please select assessment result', false);
    }

    let ext: string | undefined = ALLOWED_MIME_TYPES[image ? image.type : ''];
    if (!ext) {
      ext = image ? image.name.split('.').pop() : undefined;
    }

    const fileName = 'PRACTICAL-ASSESSMENT';
    let fileWithExt = fileName;

    if (ext) {
      fileWithExt = `${fileWithExt}.${ext}`;
    }

    const fileNameWithTimeStamp = fileNameTimeStampFormat(fileWithExt);
    const path = `DOCUMENTS/DRIVER/${selectedRow?.driver_id}/${fileName}/${fileNameWithTimeStamp}`;

    setSubmitting(true);
    if (image) {
      try {
        await fileService.uploadFileToStorage({
          path,
          data: image,
        });
        data.s3_url = path;
      } catch (error: any) {
        renderNotification('error', 'Error', `${error.message}.`, true);
        setSubmitting(false);
        return;
      }
    }

    const payload = {
      ...data,
      id: selectedRow?.assessment_type_data[0]?.assessment_type_id,
      driver_id: selectedRow?.driver_id,
      document_type: fileName,
      driver_tag: {
        tag_type_ids: [tag_name],
        reason: notes,
      },
      result: practicalResult,
    };

    createPracticalAssessment(selectedRow?.id, payload)
      .then(() => {
        renderNotification('success', 'Success', 'Practical assessment successfully added');
        if (fetchData) {
          fetchData();
        }
        setSubmitting(false);
        close();
      })
      .catch(() => setSubmitting(false));
  };

  const driverExpOptions = [
    {
      label: 'New driver',
      value: 'NEW-DRIVER',
    },
    {
      label: 'Experienced driver',
      value: 'EXPERIENCED-DRIVER',
    },
  ];

  const allBranches = async () => {
    const { data } = await getAllBranches();
    data.forEach((br) => {
      setBranches((prev) => [...prev, { label: br.branch_name, value: br.branch_id }]);
    });
  };

  const allEmployees = async () => {
    const { data } = await getAllEmployees();
    const employeeOptions: OptionList[] = data?.map((employee: Employee) => {
      return {
        value: employee?.id,
        label: `${employee?.first_name} ${employee?.last_name}`,
      };
    });
    setListOfEmployees(employeeOptions);
  };

  const fetchTagTypes = useCallback(async () => {
    try {
      const response = await getTagTypesByCategory('DRIVER-TAG');
      if (response) {
        const fetchedTags = response.data?.singleSelectTagTypes;
        driverTagOptionsRef.current = fetchedTags?.map((tag: DriverTagType) => ({
          value: tag.id,
          label: tag.name,
        }));
      }
    } catch (err) {
      handleAPIError(err as ErrorType);
    }
  }, []);

  useEffect(() => {
    allBranches();
    allEmployees();
    fetchTagTypes();
  }, [fetchTagTypes]);

  return (
    <>
      <GridLayout template={3} gap={30}>
        <div>
          <FlexLayout itemsY="center" gap={16}>
            <BsPerson size={40} color={PRIMARY_PURPLE} />
            <div>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                Driver name
              </Text>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                {selectedRow?.driver_name ?? '-'}
              </Text>
            </div>
          </FlexLayout>
          <FlexLayout styled={{ marginTop: 56, marginBottom: 24 }} gap={16} itemsY="center">
            <BsCalendar4 size={32} color={PRIMARY_PURPLE} />
            <div>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                Assessment date
              </Text>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                {selectedRow?.created_date ? format(new Date(selectedRow?.created_date), 'dd MMM yyyy') : ''}
              </Text>
            </div>
          </FlexLayout>
          <div style={{ marginTop: 55 }}>
            <TextFieldLabel $isRequired>Assessment branch</TextFieldLabel>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              error={errors?.branch_id}
              name="branch_id"
              options={branches}
              placeholder="Assessment branch"
              required={{ required: 'Branch is required' }}
            />
          </div>
          <SecondaryButton
            styled={
              practicalResult === 'FAILED'
                ? { backgroundColor: STATUS_RED, color: 'white', marginTop: 24, borderColor: STATUS_RED, width: '100%' }
                : { border: `1px solid ${STATUS_RED}`, color: `${STATUS_RED}`, marginTop: 24, width: '100%' }
            }
            onClick={() => setPracticalResult('FAILED')}
          >
            Fail
          </SecondaryButton>
        </div>
        <div>
          <FlexLayout gap={16}>
            <BsCardText size={40} color={PRIMARY_PURPLE} />
            <div>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
                DVLA number
              </Text>
              <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                {selectedRow?.dvla ?? '-'}
              </Text>
            </div>
          </FlexLayout>
          <TextField
            {...register('score', {
              required: 'Score is required',
              pattern: {
                value: scoreFormat,
                message: 'Please provide a valid score',
              },
            })}
            error={errors?.score}
            label="Score"
            name="score"
            placeholder="Assessment score"
            required
            styled={{ marginTop: 54, marginBottom: 18 }}
            type="number"
          />
          <div style={{ marginTop: 20 }}>
            <TextFieldLabel $isRequired>Driver experience</TextFieldLabel>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              error={errors?.driver_experience}
              name="driver_experience"
              options={driverExpOptions}
              placeholder="Select driver experience"
              required={{ required: 'Experience is required' }}
            />
          </div>
          <SecondaryButton
            styled={
              practicalResult === 'PASSED'
                ? {
                    backgroundColor: PRIMARY_GREEN,
                    color: 'white',
                    borderColor: PRIMARY_GREEN,
                    marginTop: 24,
                    width: '100%',
                  }
                : {
                    border: `1px solid ${PRIMARY_GREEN}`,
                    color: `${PRIMARY_GREEN}`,
                    marginTop: 24,
                    width: '100%',
                  }
            }
            onClick={() => setPracticalResult('PASSED')}
          >
            Pass
          </SecondaryButton>
        </div>
        <div>
          <div style={{ marginBottom: 20 }}>
            <TextFieldLabel $isRequired>Agent</TextFieldLabel>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              error={errors?.employee_id}
              name="employee_id"
              options={listOfEmployees}
              placeholder="Agent"
              required={{ required: 'Agent is required' }}
            />
          </div>
          <div>
            <TextFieldLabel $isRequired>Driver tag</TextFieldLabel>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              error={errors?.tag_name}
              name="tag_name"
              options={driverTagOptionsRef.current}
              placeholder="Select driver tag"
              required={{
                required: 'Tag is required',
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextArea
              {...register?.('notes', {
                required: 'Reason is required',
              })}
              error={errors?.notes}
              label="Tag reason"
              placeholder="Supply a reason"
              required
              styled={{ height: 120 }}
            />
          </div>
        </div>
      </GridLayout>
      <FlexLayout itemsX="space-between" itemsY="end" styled={{ marginTop: 16 }}>
        <UploaderInput
          label="Upload or take picture of report"
          name="image"
          control={control as unknown as Control<FieldValues>}
        />
        <PrimaryButton isProcessing={submitting} onClick={handleSubmit(onSubmit)} styled={{ float: 'right' }}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
