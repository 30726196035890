import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Control, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue, useForm } from 'react-hook-form';
import { TransferDetails } from './transferSection/transferSection';
import { CheckSections } from './checkSections/checkSections';
import { Party } from './party';
import { Skeleton } from '../../../components/ui/skeleton/skeleton';

import {
  ASSIGN_COURTESY,
  ASSIGN_REPLACEMENT,
  AVAILABLE,
  CHECK_IN,
  CHECK_OUT,
  DROP,
  FINISH_OFF_ROAD_VEH,
  FINISH_SERVICE_AND_UNFREEZE_AGREEMENT,
  FINISH_SERVICING,
  IN_SERVICE_RENTAL,
  LEUMI,
  NEW_USED_AGREEMENT,
  REPLACEMENT_RETURN,
  REPO,
  RETURN_COURTESY,
  SWAP,
  TERMINATE_AND_SERVICE,
  TERMINATED,
  conditionReportReasons,
} from '../../../consts';
import { AGREEMENT_FROZEN } from '../../../consts/agreement';
import { ConditionReportFields, ConditionReportParams, mandatoryPhotosItems } from '../../../consts/conditionReport';
import { AGREEMENTS, CREATE_ORDER, SERVICING_LIST, TRANSFERS } from '../../../consts/routes';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../uiComponents/toast/toast';
import {
  assignCourtesy,
  createConditionReport,
  createConditionReportDraft,
  createNewUsedAgreement,
  returnCourtesy,
  terminateAgreement,
} from '../../../api/post/conditionReport.post';
import { updateOrdwayCustomerGateway } from '../../../api/post/driver.post';
import { DriverTagDropDown } from '../../../models/driver';
import { GATEWAY_LEUMI, GATEWAY_OTTO_CAR } from '../../../models/ordway';
import {
  ChecklistType,
  ImageArrayType,
  TransferReason,
  TransferType,
  ChecklistItem,
  NewConditionReport,
  TransferFields,
} from '../../../models/transfer';
import { Vehicle, VehicleStatus } from '../../../models/vehicle';
import { PageContainer } from '../../../core/coreStyles';
import { fileNameTimeStampFormat } from '../../../utils/utils';
import { uploadData } from 'aws-amplify/storage';
import { getTransferByConditionReportId } from '../../../api/get/transfer.get';
import { getDraftConditionReport } from '../../../api/get/conditionReport.get';
import { useAppDispatch, useAppSelector } from '../../../store-hooks';
import { getVehicleById } from '../../../api/get/vehicle.get';
import { updateConditionReportState } from '../../../features/condition-report/conditionReportSlice';
import { format } from 'date-fns';

interface TerminationPayload {
  id?: string;
  vehicle_id: string;
  branch_id: string;
  files: ImageArrayType[];
  oil_level: number;
  fuel_level: number;
  screen_wash_level: number;
  mileage: number;
  checklist: ChecklistItem[];
  reason: TransferReason;
  notes: string;
  application_id: string;
  agreement_id: string | null;
  agreement_status?: string;
  vehicle_status: VehicleStatus;
  driver_tag?: DriverTagDropDown;
}

export interface ChecklistProps {
  isComplete?: boolean;
  control: Control<FieldValues>;
  register?: UseFormRegister<ConditionReportFields>;
  errors: FieldErrors<ConditionReportFields>;
  setValue: UseFormSetValue<ConditionReportFields>;
}

const getIncrement = (submitValues: ConditionReportFields) => {
  let numImagesForUpload = 0;
  const { back, front, chargeCables, dashboard, driverSide, lockingNut, passengerSide, repairKitTire } = submitValues;

  if (back) {
    numImagesForUpload++;
  }
  if (front) {
    numImagesForUpload++;
  }
  if (chargeCables) {
    numImagesForUpload++;
  }
  if (dashboard) {
    numImagesForUpload++;
  }
  if (driverSide) {
    numImagesForUpload++;
  }
  if (lockingNut) {
    numImagesForUpload++;
  }
  if (passengerSide) {
    numImagesForUpload++;
  }
  if (repairKitTire) {
    numImagesForUpload++;
  }
  return Math.floor(100 / numImagesForUpload);
};

export const ConditionReport = () => {
  const navigate = useNavigate();
  const pulseUser = useAppSelector((state) => state.pulseUser);
  const { id } = useParams();

  const location = (useLocation()?.state as ConditionReportParams) ?? {};

  const {
    agreement_id,
    agreement_status,
    agreement_type,
    application_id,
    courtesy_id,
    servicing_id,
    type,
    ordway_customer_id,
    vehicle_source,
    reason,
    prevRoute,
  } = location;

  const action = location?.dynamic;
  const [vehicleDetails, setVehicleDetails] = useState<Vehicle>();
  const [transferDetails, setTransferDetails] = useState<TransferFields>();
  const [enableConditionReportUploads, setEnableConditionReportUploads] = useState<boolean>(false);
  const [submittingConditionReport, setSubmittingConditionReport] = useState<boolean>(false);
  const [savingDraft, setSavingDraft] = useState<boolean>(false);
  const [showAgreementLiveModal, setShowAgreementLiveModal] = useState<boolean>(false);
  const [showUnfreezeAgreementModal, setShowUnfreezeAgreementModal] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [previousMileage, setPreviousMileage] = useState<number>(0);
  const transferReason: string | undefined = transferDetails?.reason;
  const [draft, setDraft] = useState<NewConditionReport>();
  const checkIntoServicing: boolean | undefined = transferDetails?.checkIntoServicing;
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { draft: conditionReportStoreData, transfer: transferData } = useAppSelector((state) => state.conditionReport);
  const isEditable =
    (conditionReportStoreData?.id && conditionReportStoreData?.status_code !== 1) || transferData?.transfer_id;
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ConditionReportFields>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const findConditionReportData = async (id: string) => {
      if (!transferDetails?.vrm && !draft?.agreement_id) {
        const conditionReportData: { data: NewConditionReport } = await getDraftConditionReport(id);
        const transferData = await getTransferByConditionReportId(id);

        if (conditionReportData.data) {
          setDraft(conditionReportData.data);
          setValue('damage_logs', conditionReportData.data.damage_logs);
        }

        if (transferData.data) {
          setTransferDetails(transferData.data as unknown as TransferFields);
        }
      }

      if (draft?.vehicle_id) {
        getVehicleById(draft?.vehicle_id).then((vehicle) => {
          if (!transferDetails?.vrm) {
            setTransferDetails({
              vrm: vehicle.data.vrm,
            } as TransferFields);
          }
          setShowLoading(false);
          setVehicleDetails(vehicle.data);
          dispatch(
            updateConditionReportState({
              transfer: transferDetails as unknown as TransferFields,
              draft: draft as unknown as NewConditionReport,
              vehicle: vehicle.data,
            })
          );
        });
      }
    };

    if (id) {
      setShowLoading(true);
      findConditionReportData(id);
      if (transferData?.transfer_id) {
        setEnableConditionReportUploads(true);
      }
    } else {
      setShowLoading(false);
      dispatch(
        updateConditionReportState({
          transfer: {} as unknown as TransferFields,
          draft: {} as unknown as NewConditionReport,
          vehicle: {} as unknown as Vehicle,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionReportStoreData?.id,
    conditionReportStoreData?.vehicle_id,
    id,
    dispatch,
    setValue,
    transferDetails?.vrm,
    draft?.agreement_id,
    draft?.vehicle_id,
  ]);

  const completeService = useCallback(
    (message: string) => {
      Notification({
        type: 'success',
        title: 'Success',
        message,
      });
      navigate(-1);
    },
    [navigate]
  );

  const submittingComplete = () => {
    setSubmittingConditionReport(false);
    setSavingDraft(false);
  };

  const uploadImages = useCallback(
    async (submitValues: ConditionReportFields) => {
      const allImages: ChecklistType[] = [...mandatoryPhotosItems];
      let index = 0;

      const increment = getIncrement(submitValues);

      const imagesArray: ImageArrayType[] = [];
      try {
        // working on this
        for await (const i of allImages) {
          const item: File = submitValues[i.name as keyof ConditionReportFields] as File;
          if (!item || !item.name) {
            if (conditionReportStoreData?.s3_images_url?.length) {
              const savedImage = conditionReportStoreData?.s3_images_url.find((image) => {
                return image.fileName === i.s3Folder;
              });
              if (savedImage) {
                imagesArray.push({
                  fileName: i?.s3Folder ?? '',
                  type: item?.type,
                  s3_url: savedImage?.s3_url,
                });
              }
            }

            continue;
          }
          const fileNameWithTimeStamp = fileNameTimeStampFormat(item.name);
          const path = `DOCUMENTS/CONDITION_REPORT/${vehicleDetails?.vehicle_id}/${format(
            new Date(),
            'yyyy-MM-dd_HH-MM-SS'
          )}/PHOTOS/${fileNameWithTimeStamp}`;

          if (item) {
            imagesArray.push({
              fileName: i?.s3Folder ?? '',
              type: item?.type,
              s3_url: await uploadData({
                path,
                data: item,
              }).result.then(({ path }) => {
                index += increment;
                setUploadProgress(index);
                return path;
              }),
            });
          }
        }

        setUploadProgress(100);
        imagesArray.push({
          fileName: 'SIGNATURE',
          type: 'image/png',
          contents: submitValues?.signature as File,
        });
      } catch (err) {
        submittingComplete();
        setUploadProgress(0);
        return [];
      }
      return imagesArray;
    },
    [vehicleDetails, conditionReportStoreData?.s3_images_url]
  );

  const handleAssignCourtesy = useCallback(
    async (
      mileage: string,
      checkList: (string | undefined)[],
      fuel_level: string,
      notes: string,
      imagesArray: ImageArrayType[]
    ) => {
      const assignCourtesyPayload = {
        vehicle_id: transferDetails?.vrm ?? '',
        branch_id: transferDetails?.branch_id,
        mileage: +mileage,
        driver_id: vehicleDetails?.driver_id,
        courtesy_id: courtesy_id,
        checklist: checkList.filter((e) => e) as ChecklistItem[],
        fuel_level: +fuel_level,
        transfer_type: CHECK_OUT as TransferType,
        notes: notes ?? '',
        files: imagesArray,
        agreement_id: agreement_id ?? vehicleDetails?.agreement_id,
        reason: ASSIGN_REPLACEMENT,
      };
      await assignCourtesy(assignCourtesyPayload);
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Replacement vehicle has successfully been assigned.',
      });
      navigate(SERVICING_LIST);
    },
    [agreement_id, courtesy_id, navigate, transferDetails, vehicleDetails]
  );

  const handleReturnCourtesy = useCallback(
    async (
      mileage: string,
      checkList: (string | undefined)[],
      fuel_level: string,
      notes: string,
      imagesArray: ImageArrayType[]
    ) => {
      const returnCourtesyPayload = {
        vehicle_id: transferDetails?.vrm ?? '',
        branch_id: transferDetails?.branch_id,
        mileage: +mileage,
        checklist: checkList.filter((e) => e) as ChecklistItem[],
        fuel_level: +fuel_level,
        transfer_type: CHECK_IN as TransferType,
        notes: notes ?? '',
        files: imagesArray,
        agreement_id: agreement_id ?? vehicleDetails?.agreement_id,
        courtesy_id: courtesy_id ?? '',
        reason: REPLACEMENT_RETURN,
      };
      await returnCourtesy(returnCourtesyPayload);
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Replacement vehicle has successfully been returned.',
      });
      navigate(SERVICING_LIST);
    },
    [agreement_id, courtesy_id, navigate, transferDetails, vehicleDetails]
  );

  const handleCreateAgreement = useCallback(
    (payload: TerminationPayload, submit: boolean) => {
      if (submit) {
        createNewUsedAgreement({
          transfer_type: CHECK_OUT,
          ...payload,
          vrm: transferDetails?.vrm ?? '',
        })
          .then(() => {
            Notification({
              type: 'success',
              title: 'Success',
              message: 'Condition Report and New used Agreement were successfully created.',
            });
            const params = {
              agreement_id: agreement_id ?? vehicleDetails?.agreement_id,
            };
            navigate(`${AGREEMENTS}/${agreement_id ?? vehicleDetails?.agreement_id}`, {
              state: params,
            });
            setShowAgreementLiveModal(true);
            submittingComplete();
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem creating the new agreement.',
            });
            submittingComplete();
          });
      } else {
        createConditionReportDraft({
          transfer_type: CHECK_OUT,
          servicing_id: servicing_id,
          ...payload,
          vrm: draft?.vrm || '',
          id: draft?.id,
        })
          .then((response: { data: NewConditionReport }) => {
            setDraft(response.data);
            if (agreement_id && agreement_status === AGREEMENT_FROZEN) {
              setShowUnfreezeAgreementModal(true);
            }
            submittingComplete();
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem saving the new agreement.',
            });
            submittingComplete();
          });
      }
    },
    [draft, agreement_status, agreement_id, servicing_id, navigate, vehicleDetails, transferDetails?.vrm]
  );

  const getTerminationReason = ({
    reason,
    checkIntoServicing = false,
  }: {
    reason?: string;
    checkIntoServicing?: boolean;
  }) => {
    if (reason === SWAP) {
      return SWAP;
    }
    return checkIntoServicing ? TERMINATE_AND_SERVICE : TERMINATED;
  };

  const handleTerminateAgreement = useCallback(
    (payload: TerminationPayload, submit: boolean) => {
      if (submit) {
        const terminationConfirmation = () => {
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Condition report created and agreement successfully terminated.',
          });

          let params;
          submittingComplete();
          if (agreement_type === IN_SERVICE_RENTAL && servicing_id) {
            params = {
              servicingId: servicing_id,
            };
            navigate(SERVICING_LIST, { state: params });
          } else if (transferReason === SWAP) {
            params = {
              dvla: vehicleDetails?.dvla_no,
            };
            navigate(CREATE_ORDER, { state: params });
          } else {
            params = {
              agreement_id: agreement_id || vehicleDetails?.agreement_id,
              showOrdwayCancelSubscription: vehicleDetails?.ordway_subscription_id,
            };

            navigate(`${AGREEMENTS}/${agreement_id || vehicleDetails?.agreement_id}`, {
              state: params,
            });
          }
        };

        const reason = getTerminationReason({
          reason: transferDetails?.reason,
          checkIntoServicing: checkIntoServicing,
        });
        terminateAgreement({
          transfer_type: CHECK_IN,
          ...payload,
          available_refund_amount: vehicleDetails?.available_refund_amount,
          arrears_end_of_contract: vehicleDetails?.arrears,
          total_for_future_orders: vehicleDetails?.total_for_future_orders,
          reason: reason,
          vrm: transferDetails?.vrm ?? '',
        })
          .then(() => {
            if (ordway_customer_id && agreement_type === IN_SERVICE_RENTAL) {
              updateOrdwayCustomerGateway({
                ordway_customer_id: ordway_customer_id,
                gateway_account_name: vehicle_source === LEUMI ? GATEWAY_LEUMI : GATEWAY_OTTO_CAR,
              }).then(() => {
                terminationConfirmation();
              });
            } else {
              terminationConfirmation();
            }
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem creating the condition report.',
            });
            submittingComplete();
          });
      } else {
        createConditionReportDraft({
          transfer_type: CHECK_OUT,
          servicing_id: servicing_id,
          ...payload,
          vrm: draft?.vrm || '',
          id: draft?.id,
        })
          .then((response: { data: NewConditionReport }) => {
            setDraft(response.data);
            if (agreement_id && agreement_status === AGREEMENT_FROZEN) {
              setShowUnfreezeAgreementModal(true);
            }
            submittingComplete();
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem saving the condition report.',
            });
            submittingComplete();
          });
      }
    },
    [
      transferDetails?.reason,
      transferDetails?.vrm,
      checkIntoServicing,
      vehicleDetails?.available_refund_amount,
      vehicleDetails?.arrears,
      vehicleDetails?.total_for_future_orders,
      vehicleDetails?.dvla_no,
      vehicleDetails?.agreement_id,
      vehicleDetails?.ordway_subscription_id,
      agreement_type,
      servicing_id,
      transferReason,
      navigate,
      agreement_id,
      ordway_customer_id,
      vehicle_source,
      draft?.vrm,
      draft?.id,
      agreement_status,
    ]
  );

  const handleServicingCompletion = useCallback(
    (payload: TerminationPayload, submit: boolean) => {
      if (submit) {
        createConditionReport({
          transfer_type: CHECK_OUT,
          servicing_id: servicing_id,
          ...payload,
          vrm: draft?.vrm || '',
          is_off_road_vehicle: action === FINISH_OFF_ROAD_VEH,
        })
          .then(() => {
            if (agreement_id && agreement_status === AGREEMENT_FROZEN) {
              setShowUnfreezeAgreementModal(true);
            } else {
              completeService('Service successfully completed');
            }
            submittingComplete();
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem completing the service.',
            });
            submittingComplete();
          });
      } else {
        createConditionReportDraft({
          transfer_type: CHECK_OUT,
          servicing_id: servicing_id,
          ...payload,
          vrm: draft?.vrm || '',
          id: draft?.id,
          is_off_road_vehicle: action === FINISH_OFF_ROAD_VEH,
        })
          .then((response: { data: NewConditionReport }) => {
            setDraft(response.data);
            if (agreement_id && agreement_status === AGREEMENT_FROZEN) {
              setShowUnfreezeAgreementModal(true);
            }
            submittingComplete();
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem saving the service.',
            });
            submittingComplete();
          });
      }
    },
    [draft, agreement_id, agreement_status, servicing_id, action, completeService]
  );

  const handleCheckInConditionReport = useCallback(
    (payload: TerminationPayload, submit: boolean) => {
      if (submit) {
        createConditionReport({
          transfer_type: conditionReportReasons?.find((e) => e.value === transferDetails?.reason)?.type,
          servicing_id: servicing_id,
          is_off_road_vehicle: action === FINISH_OFF_ROAD_VEH,
          ...payload,
          vrm: draft?.vrm || '',
        })
          .then(() => {
            submittingComplete();
            Notification({
              type: 'success',
              title: 'Success',
              message: 'Condition report has been successfully created.',
            });
            return type === 'CHECK-IN' ? navigate(-1) : navigate(TRANSFERS);
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem creating the condition report.',
            });
            submittingComplete();
          });
      } else {
        createConditionReportDraft({
          transfer_type: conditionReportReasons?.find((e) => e.value === transferDetails?.reason)?.type,
          servicing_id: servicing_id,
          ...payload,
          vrm: draft?.vrm || '',
        })
          .then(() => {
            submittingComplete();
            Notification({
              type: 'success',
              title: 'Success',
              message: 'Condition report has been successfully saved.',
            });
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'Error',
              message: 'There was a problem saving the condition report.',
            });
            submittingComplete();
          });
      }
    },
    [draft, navigate, action, servicing_id, transferDetails, type]
  );

  const onSubmit = useCallback(
    async (submitValues: ConditionReportFields) => {
      if (isEditable) {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'You can not change a CR that is not a DRAFT',
        });
        return;
      }

      setSubmittingConditionReport(true);
      setUploadProgress(0);

      const {
        mileage,
        fuelLevel,
        oilLevel,
        screenWashLevel,
        lockingWheelNut,
        telematics,
        tools,
        spareWheelKit,
        notes,
        tags,
        tags_note,
      } = submitValues;

      const checkList = [
        telematics ? 'TELEMATICS' : undefined,
        lockingWheelNut ? 'LOCKING-WHEEL-NUT' : undefined,
        tools ? 'TOOLS' : undefined,
        spareWheelKit ? 'SPARE-WHEEL-KIT' : undefined,
      ];

      const imagesArray = await uploadImages(submitValues);

      if (action === ASSIGN_COURTESY) {
        await handleAssignCourtesy(mileage || '0', checkList, fuelLevel || '0', notes ?? '', imagesArray);
        return;
      } else if (action === RETURN_COURTESY) {
        await handleReturnCourtesy(mileage || '0', checkList, fuelLevel || '0', notes ?? '', imagesArray);
        return;
      }
      const payload: TerminationPayload = {
        id: draft?.id,
        vehicle_id: vehicleDetails?.vehicle_id ?? '',
        reason: transferDetails?.reason ?? TERMINATED,
        branch_id: transferDetails?.branch_id ?? '',
        mileage: mileage ? +mileage : 0,
        checklist: checkList.filter((e) => e) as ChecklistItem[],
        fuel_level: fuelLevel ? +fuelLevel : 0,
        oil_level: oilLevel ? +oilLevel : 0,
        screen_wash_level: screenWashLevel ? +screenWashLevel : 0,
        notes: notes ?? '',
        files: imagesArray,
        application_id: application_id ?? '',
        agreement_id: agreement_id ?? vehicleDetails?.agreement_id ?? null,
        agreement_status: agreement_status,
        vehicle_status: vehicleDetails?.vehicle_status ?? AVAILABLE,
        driver_tag: {
          tag_type_ids: tags,
          reason: tags_note,
        },
      };

      if (action === NEW_USED_AGREEMENT) {
        handleCreateAgreement(payload, true);
      } else if (
        transferDetails?.reason === TERMINATED ||
        transferDetails?.reason === TERMINATE_AND_SERVICE ||
        [TERMINATED, DROP, SWAP, REPO].includes(transferDetails?.reason ?? '')
      ) {
        handleTerminateAgreement(payload, true);
      } else if (
        action === FINISH_SERVICING ||
        action === FINISH_OFF_ROAD_VEH ||
        action === FINISH_SERVICE_AND_UNFREEZE_AGREEMENT
      ) {
        handleServicingCompletion(payload, true);
      } else {
        handleCheckInConditionReport(payload, true);
      }
    },
    [
      draft,
      transferDetails,
      vehicleDetails,
      agreement_id,
      agreement_status,
      application_id,
      action,
      uploadImages,
      handleAssignCourtesy,
      handleReturnCourtesy,
      handleCheckInConditionReport,
      handleCreateAgreement,
      handleServicingCompletion,
      handleTerminateAgreement,
      isEditable,
    ]
  );

  const onSave = useCallback(
    async (submitValues: ConditionReportFields, id: string) => {
      if (isEditable) {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'You can not change a CR that is not a DRAFT',
        });
        return;
      }
      setSavingDraft(true);
      setUploadProgress(0);

      const {
        mileage,
        fuelLevel,
        oilLevel,
        screenWashLevel,
        lockingWheelNut,
        telematics,
        tools,
        spareWheelKit,
        notes,
        tags,
        tags_note,
      } = submitValues;

      const checkList = [
        telematics ? 'TELEMATICS' : undefined,
        lockingWheelNut ? 'LOCKING-WHEEL-NUT' : undefined,
        tools ? 'TOOLS' : undefined,
        spareWheelKit ? 'SPARE-WHEEL-KIT' : undefined,
      ];

      const imagesArray = await uploadImages(submitValues);
      const payload: TerminationPayload = {
        id: id,
        vehicle_id: vehicleDetails?.vehicle_id ?? '',
        reason: transferDetails?.reason ?? TERMINATED,
        branch_id: transferDetails?.branch_id ?? '',
        mileage: mileage ? +mileage : 0,
        checklist: checkList.filter((e) => e) as ChecklistItem[],
        fuel_level: fuelLevel ? +fuelLevel : 0,
        oil_level: oilLevel ? +oilLevel : 0,
        screen_wash_level: screenWashLevel ? +screenWashLevel : 0,
        notes: notes ?? '',
        files: imagesArray,
        application_id: application_id ?? '',
        agreement_id: vehicleDetails?.agreement_id ?? null,
        agreement_status: agreement_status,
        vehicle_status: vehicleDetails?.vehicle_status ?? AVAILABLE,
        driver_tag: {
          tag_type_ids: tags,
          reason: tags_note,
        },
      };

      if (action === NEW_USED_AGREEMENT) {
        handleCreateAgreement(payload, false);
      } else if (
        transferDetails?.reason === TERMINATED ||
        transferDetails?.reason === TERMINATE_AND_SERVICE ||
        [TERMINATED, DROP, SWAP, REPO].includes(transferDetails?.reason ?? '')
      ) {
        handleTerminateAgreement(payload, false);
      } else if (
        action === FINISH_SERVICING ||
        action === FINISH_OFF_ROAD_VEH ||
        action === FINISH_SERVICE_AND_UNFREEZE_AGREEMENT
      ) {
        handleServicingCompletion(payload, false);
      } else {
        handleCheckInConditionReport(payload, false);
      }
    },
    [
      transferDetails,
      vehicleDetails,
      agreement_status,
      application_id,
      action,
      uploadImages,
      handleCheckInConditionReport,
      handleCreateAgreement,
      handleServicingCompletion,
      handleTerminateAgreement,
      isEditable,
    ]
  );

  const onSubmitDraft = useCallback(
    (transferDetails: TransferFields, vehicleDetails: Vehicle | undefined) => {
      if (isEditable) {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'You can not change a CR that is allready submitted',
        });
        return;
      }
      setTransferDetails(transferDetails);

      createConditionReportDraft({
        ...(draft?.id ? { id: draft?.id } : {}),
        vrm: transferDetails.vrm || '',
        vehicle_id: vehicleDetails?.vehicle_id || '',
        branch_id: transferDetails?.branch_id || '',
        agreement_id: vehicleDetails?.agreement_id || null,
      })
        .then((response: { data: NewConditionReport }) => {
          if (response.data) {
            setValue('damage_logs', response.data?.damage_logs || []);
            setDraft(response.data);
            setEnableConditionReportUploads(true);
          }
        })
        .catch(() => {
          Notification({
            type: 'error',
            title: 'Error',
            message: 'There was a problem creating the condition report.',
          });
        });
    },
    [draft?.id, setValue, setTransferDetails, setEnableConditionReportUploads, isEditable]
  );

  return (
    <>
      <PageContainer>
        <FlexLayout itemsX="space-between" itemsY="center">
          {showLoading ? (
            <FlexLayout gap={40} itemsY="center">
              <Skeleton className="h-10 w-[12rem] bg-gray-400" />
              <Skeleton className="h-10 w-[12rem] bg-gray-400" />
            </FlexLayout>
          ) : (
            <FlexLayout gap={40} itemsY="center">
              <Party isAgent={false} partyLabel="Driver name" partyName={vehicleDetails?.driver_name ?? '-'} />
              <Party
                isAgent
                partyLabel="Agent name"
                partyName={pulseUser ? `${pulseUser?.first_name} ${pulseUser?.last_name}` : '-'}
              />
            </FlexLayout>
          )}
          <SecondaryButton onClick={() => navigate(prevRoute ?? -1)}>Cancel</SecondaryButton>
        </FlexLayout>
        <FlexLayout styled={{ marginTop: 24 }} itemsY="start" gap={16}>
          {showLoading ? (
            <Skeleton className="h-[30rem] w-[25rem] bg-gray-400" />
          ) : (
            <TransferDetails
              defaultReason={location?.reason as TransferReason}
              defaultVrm={location?.vrm}
              draftCreated={enableConditionReportUploads}
              action={action}
              previousConditionReportMileage={setPreviousMileage}
              updateVehicleDetails={(vehicle: Vehicle) => setVehicleDetails(vehicle)}
              vehicleDetails={vehicleDetails}
              onConfirm={(transferDetails: TransferFields) => {
                if (transferDetails) {
                  onSubmitDraft(transferDetails, vehicleDetails);
                }
              }}
            />
          )}
          {showLoading ? (
            <div className="flex flex-col space-y-3">
              <Skeleton className="h-[6rem] w-[100rem] rounded-3xl bg-gray-400" />
              <Skeleton className="h-[6rem] w-[100rem] rounded-3xl bg-gray-400" />
              <Skeleton className="h-[6rem] w-[100rem] rounded-3xl bg-gray-400" />
              <Skeleton className="h-[10rem] w-[100rem] rounded-3xl bg-gray-400" />
            </div>
          ) : (
            <CheckSections
              control={control as unknown as Control<FieldValues>}
              disabled={!enableConditionReportUploads}
              errors={errors}
              uploadProgress={uploadProgress}
              uploadingImages={submittingConditionReport}
              reason={reason || transferDetails?.reason}
              register={register}
              watch={watch}
              previousMileage={previousMileage}
              conditionReportId={draft?.id}
              vehicle_id={vehicleDetails?.vehicle_id}
              setValue={setValue}
            />
          )}
        </FlexLayout>
        <FlexLayout itemsX="end" styled={{ marginTop: 16 }} gap={10}>
          {showLoading ? (
            <>
              <Skeleton className="h-[3rem] w-[8em] rounded-3xl bg-gray-400" />
              <Skeleton className="h-[3rem] w-[8rem] rounded-3xl bg-gray-400" />
            </>
          ) : (
            <>
              <PrimaryButton
                disabled={draft == null || submittingConditionReport || transferData?.transfer_id !== undefined}
                isProcessing={savingDraft}
                onClick={() => onSave(getValues(), draft?.id || '')}
              >
                Save Draft
              </PrimaryButton>
              <PrimaryButton
                disabled={draft == null || savingDraft || transferData?.transfer_id !== undefined}
                isProcessing={submittingConditionReport}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </PrimaryButton>
            </>
          )}
        </FlexLayout>
      </PageContainer>

      <ConfirmationModal
        confirmButtonCaption="OK"
        onConfirm={() => setShowAgreementLiveModal(false)}
        title="The condition report was created successfully and the agreement is now live!"
        isOpen={showAgreementLiveModal}
      />
      <ConfirmationModal
        confirmButtonCaption="OK"
        onConfirm={() => {
          setShowUnfreezeAgreementModal(false);
          navigate(SERVICING_LIST);
        }}
        title="The driver`s agreement will unfreeze and weekly payments will resume."
        isOpen={showUnfreezeAgreementModal}
      />
    </>
  );
};
