import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

import { cn } from '../../../lib/utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-[1px] border-utility-gray-300 rounded-xl bg-white mb-5', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'px-6 flex flex-1 items-center justify-between py-4 transition-all [&[data-state=open]>svg]:rotate-180',
        className
      )}
      {...props}
    >
      <div>{children}</div>
      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('px-4 pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

const AccordionTitle = React.forwardRef<HTMLParagraphElement, { children: React.ReactNode }>(({ children }, ref) => {
  return (
    <p ref={ref} className="text-left text-lg text-utility-gray-900 font-medium">
      {children}
    </p>
  );
});

const AccordionSubtitle = React.forwardRef<HTMLParagraphElement, { children: React.ReactNode }>(({ children }, ref) => {
  return (
    <p ref={ref} className="text-left text-sm text-utility-gray-600 font-light">
      {children}
    </p>
  );
});

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent, AccordionTitle, AccordionSubtitle };
