import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// utils
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';
import { AFTERCARE_REVIEWS, AGREEMENTS } from '../../../consts/routes';
// api
import { cancelAftercareSetup } from '../../../api/delete/aftercare.delete';
// models
import { AftercareReview } from '../../../models/aftercare';
// styles
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30, STATUS_ORANGE, STATUS_YELLOW } from '../../../common/styles/Colors';
// components
import { AftercareSetupForm } from '../aftercareSetup/aftercareSetup';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { BsCheckCircle, BsDashCircleDotted, BsEye, BsPencil } from 'react-icons/bs';
import { Table } from '../../../uiComponents/table/table';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { Modal } from '../../../uiComponents/modals/modal';
import { CompleteAftercare } from './activateAftercare/activateAftercare';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../uiComponents/toast/toast';
import { AxiosError } from 'axios';
import { aftercareServiceOptions } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { aftercareReviewColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { AGREEMENT_SIGNATURE_PENDING, AGREEMENT_SIGNED } from '../../../consts/agreement';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useGetAndMergeAftercareReviewListQuery } from '../../../api/listAndMerge/listAndMergeAftercareReviewApiSlice';
import { format } from 'date-fns';

export const AftercareReviewList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [serviceFilter, setServiceFilter] = useState<OptionList[]>([]);
  const [activeAftercare, setActiveAftercare] = useState<AftercareReview | null>(null);
  const [isAftercareSetupModalOpen, setIsAftercareSetupModalOpen] = useState<boolean>(false);
  const [isCancelAftercareModalOpen, setIsCancelAftercareModalOpen] = useState<boolean>(false);
  const [isCompleteAftercareModalOpen, setIsCompleteAftercareModalOpen] = useState<boolean>(false);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setSortingColumn,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=end_date:ASC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: aftercareReviewList,
    isLoading: isAftercareReviewListLoading,
    isFetching: isAftercareReviewListFetching,
    refetch,
  } = useGetAndMergeAftercareReviewListQuery({ query: queryStringState, refetch: refetchData });

  const handleCancelAftercare = () => {
    return cancelAftercareSetup({
      id: activeAftercare?.id ?? '',
    })
      .then(() => {
        setIsCancelAftercareModalOpen(false);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Aftercare agreement cancelled successfully',
          isAlert: true,
        });
        refetchToFirstPage();
      })
      .catch((e: AxiosError<Error>) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.message,
        });
      });
  };

  const handleCompleteAftercareModalClose = () => {
    setIsCompleteAftercareModalOpen(false);
    setActiveAftercare(null);
    refetchToFirstPage();
  };

  const handleGetAftercareReviewResponse = useCallback(
    (count: number, data: AftercareReview[]) => {
      const aftercareRows = data?.map((aftercareItem: AftercareReview) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell value={aftercareItem?.driver_mobile_phone} />,
            <TableTextCell
              value={aftercareItem?.end_date ? format(new Date(aftercareItem?.end_date), 'dd MMM yyyy') : '-'}
            />,
            <FlexLayout itemsX="start">
              <Tag key={`aftercare_agreement_status_${aftercareItem?.aftercare_type}`} color={PRIMARY_PURPLE}>
                {aftercareItem?.aftercare_type}
              </Tag>
            </FlexLayout>,
            <FlexLayout itemsX="start">
              <Tag
                key={`aftercare_agreement_status_${aftercareItem.original_agreement_id}`}
                color={aftercareItem.scrive_signed ? STATUS_YELLOW : STATUS_ORANGE}
              >
                {aftercareItem.scrive_signed ? AGREEMENT_SIGNED : AGREEMENT_SIGNATURE_PENDING}
              </Tag>
            </FlexLayout>,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => {
                  setActiveAftercare(aftercareItem);
                  setIsAftercareSetupModalOpen(true);
                }}
                icon={<BsPencil size={24} color={PRIMARY_PURPLE} />}
                tooltip="Edit aftercare setup"
              />
              <ActionIcon
                disabled={!aftercareItem.scrive_signed}
                onClick={() => {
                  setActiveAftercare(aftercareItem);
                  setIsCompleteAftercareModalOpen(true);
                }}
                icon={
                  <BsCheckCircle
                    size={24}
                    aria-disabled={!aftercareItem.scrive_signed}
                    color={aftercareItem.scrive_signed === true ? PRIMARY_PURPLE : SECONDARY_PURPLE_30}
                  />
                }
                tooltip="Go live"
              />
              <ActionIcon
                onClick={() => {
                  setActiveAftercare(aftercareItem);
                  setIsCancelAftercareModalOpen(true);
                }}
                icon={<BsDashCircleDotted size={24} color={PRIMARY_PURPLE} />}
                tooltip="Cancel agreement"
              />
              <ActionIcon
                onClick={() =>
                  navigate(`${AGREEMENTS}/${aftercareItem?.original_agreement_id}`, { state: AFTERCARE_REVIEWS })
                }
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View agreement page"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, navigate]
  );

  const filters: FilterItem[] = [
    {
      name: 'plan',
      element: (
        <DropDownFilter
          name="plan"
          placeholder="Plan selected"
          options={aftercareServiceOptions}
          multiValues={serviceFilter}
          title="Plan selected"
          onChange={(items) => setServiceFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  const AgreementListColumns = useMemo(() => [...aftercareReviewColumns], []);

  useEffect(() => {
    setActiveSideNav('aftercareListPage');
    setPageTitle('Aftercare');
    setSortingColumn('end_date');
  }, [setActiveSideNav, setPageTitle, setSortingColumn]);

  useEffect(() => {
    if (!serviceFilter) return;
    setTableFilters([
      { columnName: 'aftercare_type', options: serviceFilter },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, serviceFilter]);

  useEffect(() => {
    if (aftercareReviewList) {
      handleGetAftercareReviewResponse(aftercareReviewList.count, aftercareReviewList.data);
    }
  }, [aftercareReviewList, queryStringState, handleGetAftercareReviewResponse]);

  const onClearClick = useCallback(() => {
    setServiceFilter([]);
    setDateRangeFilter([]);
  }, [setServiceFilter, setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    goToPageNumber(0);
    onClearClick();
  }, [goToPageNumber, onClearClick, refetch, defaultString, queryStringState]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isAftercareReviewListFetching || isAftercareReviewListLoading}
        header="Aftercare review"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={AgreementListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: AftercareReview }) => {
          navigate(`${AGREEMENTS}/${data?.original_agreement_id}`, { state: AFTERCARE_REVIEWS });
        }}
      />
      {activeAftercare && (
        <>
          <Modal open={isCompleteAftercareModalOpen} showClose onClose={handleCompleteAftercareModalClose}>
            <CompleteAftercare aftercareId={activeAftercare.id} onClose={handleCompleteAftercareModalClose} />
          </Modal>
          <Modal
            title="Edit aftercare setup"
            open={isAftercareSetupModalOpen}
            showClose
            onClose={() => setIsAftercareSetupModalOpen(false)}
          >
            <AftercareSetupForm
              aftercare={activeAftercare}
              onClose={() => setIsAftercareSetupModalOpen(false)}
              isInEdit={true}
              onSubmit={() => refetchToFirstPage()}
            />
          </Modal>
          <ConfirmationModal
            title={'You are about to cancel this agreement before it has gone live.'}
            isOpen={isCancelAftercareModalOpen}
            onClose={() => setIsCancelAftercareModalOpen(false)}
            preConfirm={handleCancelAftercare}
            closeButtonCaption="Cancel"
            confirmButtonCaption="Yes"
          />
        </>
      )}
    </>
  );
};
