import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerProfile } from '../../models/driver';
import { driverDetailsApiSlice } from '../../api/driver/driverDetailsApiSlice';
import { format } from 'date-fns';

type UploadStateFields = {
  loading?: {
    field: string;
    value: boolean | string;
  };
  url?: {
    field: string;
    value: boolean | string;
  };
  type?: {
    field: string;
    value: boolean | string;
  };
};

type SpecificFieldsState = {
  [key: string]: string | boolean | number | undefined;
};

const customerProfileSlice = createSlice({
  name: 'customerProfile',
  initialState: {} as unknown as CustomerProfile,
  reducers: {
    updateCustomerDetailsState(state, action: PayloadAction<CustomerProfile>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateSpecificParametersOnState(state, action: PayloadAction<SpecificFieldsState>) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    updateUploadState(state, action: PayloadAction<UploadStateFields>) {
      if (action.payload.loading) state[action.payload.loading.field] = action.payload.loading.value;
      if (action.payload.url) state[action.payload.url.field] = action.payload.url.value;
      if (action.payload.type) state[action.payload.type.field] = action.payload.type.value;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(driverDetailsApiSlice.endpoints.getDriverByDVLA.matchFulfilled, (state, { payload }) => {
      Object.keys(payload.data).forEach((key) => {
        if (key.includes('date') && payload.data[key]) {
          state[key] = format(payload.data[key], 'yyyy-MM-dd');
        } else {
          state[key] = payload.data[key];
        }
      });
    });
  },
});

export const { updateCustomerDetailsState, updateUploadState, updateSpecificParametersOnState } =
  customerProfileSlice.actions;
export default customerProfileSlice.reducer;
