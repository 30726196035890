import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { ImStarFull, ImStarHalf, ImStarEmpty } from 'react-icons/im';
import { MdOutlinePersonPin } from 'react-icons/md';
import { Card, DvlaInputDiv, OrdwayCustomerLink, SearchButton, StatusBarBG, StatusBar } from './driverInfoCard.styles';
import { DriverTagsTable } from './driverTagsTable/DriverTagsTable';
import {
  GOOD_DRIVER,
  TAG_ALL_IN_ORDER,
  TAG_LOW_RISK,
  TAG_NO_RISK,
  TAG_STATUS_RESOLVED,
  TAG_NO_THEFT_COVER,
  ORDWAY_CUSTOMER_PATH,
  ORDWAY_SUBSCRIPTION_PATH,
} from '../../../../consts/driver';
import { DRIVERS, VEHICLES } from '../../../../consts/routes';
import { Tag } from '../../../../uiComponents/customComponents/tag/tag';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { Text } from '../../../../uiComponents/text/text';
import { TextInput } from '../../../../uiComponents/inputs/textInput/textInput';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { getDriverTagsByDriverId } from '../../../../api/get/driver.get';
import { Agreement } from '../../../../models/agreement';
import { CustomerProfile, Driver, DriverTag } from '../../../../models/driver';
import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../common/styles/Colors';
import { statusColors } from '../../../../common/utils';
import { handleAPIError, ErrorType } from '../../../../utils/handleAPIError';
import { getStars, StarType } from '../../../../utils/utils';
import { dvlaFormat } from '../../../../utils/validations';
import { IconTooltip } from '../../../../uiComponents/table/actionIcon/iconTooltip';

interface DriverInfoCardProps {
  driver: CustomerProfile | Driver | undefined | null;
  agreement?: Agreement;
  inArrears?: boolean;
  loadingDriverDetails?: boolean;
  orderProgress?: number;
  onDvlaInputBlur?: (dvlaNo: string) => void;
  setBlockOrderCreation?: (blockOrderCreationStatus: boolean) => void;
}

export const DriverInfoCard = ({
  driver,
  agreement,
  inArrears,
  orderProgress,
  loadingDriverDetails,
  onDvlaInputBlur,
  setBlockOrderCreation,
}: DriverInfoCardProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [tags, setTags] = useState<DriverTag[]>();
  const [unresolvedNegativeTags, setUnresolvedNegativeTags] = useState<DriverTag[]>([]);
  const [allTagsResolved, setAllTagsResolved] = useState<boolean>(false);
  const [noTheftCoverTag, setNoTheftCoverTag] = useState<DriverTag | undefined>();
  const [isTagModal, setIsTagModal] = useState<boolean>(false);
  const driverId = driver?.id;
  const agreementStatus = agreement?.agreement_status;
  const agreementType = agreement?.agreement_type;
  const ordwayCustomerId = driver?.ordway_customer_id;
  const subId = agreement?.ordway_subscription_id;
  const vehicleId = agreement?.vehicle_id;
  const vrm = agreement?.vrm;

  const {
    register,
    formState: { errors },
  } = useForm<{ dvla_no: string }>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { dvla_no: '' },
  });

  // Uber rating
  const uberRating: number = +(driver?.uber_rating ?? '0');
  const wholeStarRating: number = Math.floor(uberRating);
  const decimalRating: number = uberRating - wholeStarRating;
  const starRating: StarType[] = getStars(wholeStarRating, decimalRating);

  const fetchTags = useCallback(
    async (driverId: string) => {
      try {
        if (driverId) {
          getDriverTagsByDriverId(driverId).then((response: { data: DriverTag[] }) => {
            if (response?.data) {
              setLoading(false);
              setTags(response.data);
              setUnresolvedNegativeTags(
                response.data.filter(
                  (tag) =>
                    ![TAG_ALL_IN_ORDER, TAG_NO_RISK, TAG_LOW_RISK, TAG_NO_THEFT_COVER].includes(tag.tag_name) &&
                    tag.resolution_status !== TAG_STATUS_RESOLVED
                )
              );
              setAllTagsResolved(response.data.every((tag) => tag.resolution_status === TAG_STATUS_RESOLVED));
              if (setBlockOrderCreation) {
                setBlockOrderCreation(
                  response.data.filter(
                    (tag) =>
                      ![TAG_ALL_IN_ORDER, TAG_NO_RISK, TAG_LOW_RISK, TAG_NO_THEFT_COVER].includes(tag.tag_name) &&
                      tag.resolution_status !== TAG_STATUS_RESOLVED
                  ).length > 0
                );
              }
              setNoTheftCoverTag(response.data.find((tag) => tag.tag_name === TAG_NO_THEFT_COVER));
            }
          });
        }
      } catch (err) {
        handleAPIError(err as ErrorType);
      }
    },
    [setBlockOrderCreation]
  );

  useEffect(() => {
    if (driverId) {
      fetchTags(`${driverId}`);
    }
  }, [driverId, fetchTags]);

  interface FiledItem {
    label: string;
    value: JSX.Element | string;
  }

  const driverFields: FiledItem[] = useMemo(
    () => [
      {
        label: 'DVLA',
        value: driver?.dvla_no ?? '-',
      },
      {
        label: 'PCO',
        value: driver?.pco_no ?? '-',
      },
      {
        label: 'Mobile',
        value: driver?.no_area_code && driver?.mobile_phone ? `0${driver?.mobile_phone}` : '-',
      },
      {
        label: 'Ordway ID',
        value: ordwayCustomerId ? (
          <OrdwayCustomerLink href={`${ORDWAY_CUSTOMER_PATH}/${ordwayCustomerId}`} target="_blank">
            {ordwayCustomerId}
          </OrdwayCustomerLink>
        ) : (
          '-'
        ),
      },
      {
        label: '',
        value: (
          <>
            {driver?.uber_rating !== null && (
              <>
                Uber rating
                <Text styled={{ paddingLeft: 8 }} variant="body8" align="center" weight={300}>
                  {`(${uberRating})`}
                </Text>
                <FlexLayout gap={4}>
                  {starRating?.map((star: StarType, i: number) => {
                    if (star === 'full') {
                      return <ImStarFull key={`${star}${i}`} color={PRIMARY_GREEN} size={20} />;
                    } else if (star === 'half') {
                      return <ImStarHalf key={`${star}${i}`} color={PRIMARY_GREEN} size={20} />;
                    } else {
                      return <ImStarEmpty key={`${star}${i}`} color={PRIMARY_GREEN} size={20} />;
                    }
                  })}
                </FlexLayout>
              </>
            )}
          </>
        ),
      },
      {
        label: '',
        value: (
          <>
            {(orderProgress || orderProgress === 0) && (
              <div>
                <Text variant="body6" weight={300} color={PRIMARY_WHITE} block>
                  Order status
                </Text>
                <StatusBarBG>
                  <StatusBar $progress={orderProgress}>
                    <Text
                      block
                      align="right"
                      variant="body9"
                      color={PRIMARY_WHITE}
                      weight={300}
                      styled={{ lineHeight: '20px', marginRight: 8 }}
                    >{`${orderProgress?.toFixed(2)}%`}</Text>
                  </StatusBar>
                </StatusBarBG>
              </div>
            )}
          </>
        ),
      },
    ],
    [
      driver?.dvla_no,
      driver?.pco_no,
      driver?.no_area_code,
      driver?.mobile_phone,
      driver?.uber_rating,
      ordwayCustomerId,
      uberRating,
      starRating,
      orderProgress,
    ]
  );

  const agreementFields: FiledItem[] = useMemo(
    () => [
      {
        label: 'Agreement type',
        value: agreementType ? (
          <Tag key={agreementType} color={PRIMARY_GREEN}>
            {agreementType}
          </Tag>
        ) : (
          '-'
        ),
      },
      {
        label: 'Agreement status',
        value: agreementStatus ? (
          <Tag key={agreementStatus} color={statusColors[agreementStatus] ?? PRIMARY_WHITE}>
            {agreementStatus}
          </Tag>
        ) : (
          '-'
        ),
      },
      {
        label: 'Subscription ID',
        value: subId ? (
          <OrdwayCustomerLink href={`${ORDWAY_SUBSCRIPTION_PATH}/${subId}`} target="_blank">
            {subId}
          </OrdwayCustomerLink>
        ) : (
          '-'
        ),
      },
      {
        label: 'Vehicle',
        value: vrm ? (
          <div onClick={() => navigate(`${VEHICLES}/${vehicleId}`)} className="active-link">
            <Tag key={vrm} color={PRIMARY_GREEN}>
              {vrm}
            </Tag>
          </div>
        ) : (
          '-'
        ),
      },
    ],
    [agreementType, agreementStatus, subId, vrm, navigate, vehicleId]
  );

  const allFields = useMemo(
    () => (agreement ? [...driverFields.slice(0, 4), ...agreementFields, ...driverFields.slice(4)] : driverFields),
    [agreement, driverFields, agreementFields]
  );

  const RenderActionIcon = () => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    return (
      <FlexLayout
        styled={{ position: 'relative', width: 40, height: 40, cursor: 'pointer' }}
        itemsX="center"
        itemsY="center"
        onClick={() => setIsTagModal(true)}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
      >
        <BsArrowRightCircle size={24} color={PRIMARY_WHITE} />
        <IconTooltip showTooltip={showTooltip} tooltip="See all tags" styled={{ transform: 'none', top: -32 }} />
      </FlexLayout>
    );
  };

  return (
    <>
      <Card $inArrears={inArrears || unresolvedNegativeTags?.length > 0}>
        <MdOutlinePersonPin size={48} />
        {!driver && !agreement && (
          <DvlaInputDiv>
            <TextInput
              placeholder="DVLA number"
              error={errors.dvla_no}
              {...register('dvla_no', {
                required: 'DVLA is required',
                pattern: { value: dvlaFormat, message: 'Must be DVLA format e.g. AAAAA111111BB2CC' },
                onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => {
                  e.target.value = e.target.value.toUpperCase();
                  if (dvlaFormat.test(e.target.value)) {
                    onDvlaInputBlur?.(e.target.value);
                  }
                },
                onChange: (e: React.FocusEvent<HTMLInputElement, Element>) => {
                  e.target.value = e.target.value.toUpperCase();
                  dvlaFormat.test(e.target.value);
                },
              })}
              styled={{ width: '100%' }}
            />
            {loadingDriverDetails ? (
              <Spinner styled={{ position: 'absolute', right: 24, top: 13 }} size={16} color={PRIMARY_PURPLE} />
            ) : (
              <SearchButton itemsX="center" itemsY="center">
                <FiSearch color={PRIMARY_GREEN} size={24} />
              </SearchButton>
            )}
          </DvlaInputDiv>
        )}
        {(driver || agreement) && (
          <div
            onClick={() => (agreement || driver ? navigate(`${DRIVERS}/${driver?.id}`) : null)}
            className={agreement ? 'active-link' : ''}
          >
            <Text variant="h1" color={PRIMARY_WHITE} block>
              {`${driver?.title ?? ''} ${driver?.first_name ?? ''} ${driver?.middle_name ?? ''} ${driver?.last_name}`}
            </Text>
          </div>
        )}
        <GridLayout template={2} styled={{ marginTop: 24 }} gap={24}>
          {allFields.map((item, index) => (
            <div key={index}>
              <Text variant="body6" color={PRIMARY_WHITE} weight={500} block>
                {item.label}
              </Text>
              <Text variant="body6" color={PRIMARY_WHITE} weight={300} block>
                {item.value}
              </Text>
            </div>
          ))}
        </GridLayout>
        <FlexLayout styled={{ marginTop: 20 }}>
          {(() => {
            if (driver || agreement) {
              if (loading) {
                return (
                  <FlexLayout itemsX="center">
                    <Spinner size={24} color={PRIMARY_GREEN} />
                  </FlexLayout>
                );
              }
              if (tags && tags.length > 0 && !loading) {
                return (
                  <>
                    {allTagsResolved || noTheftCoverTag ? (
                      <Tag key="good-driver" color={PRIMARY_GREEN}>
                        {noTheftCoverTag ? TAG_NO_THEFT_COVER : GOOD_DRIVER}
                      </Tag>
                    ) : (
                      <Tag
                        key="latest-unres-tag"
                        color={
                          statusColors[
                            unresolvedNegativeTags[unresolvedNegativeTags.length - 1]?.tag_name.toUpperCase()
                          ]
                        }
                      >
                        {unresolvedNegativeTags[unresolvedNegativeTags.length - 1]?.tag_name.toUpperCase()}
                      </Tag>
                    )}
                    <RenderActionIcon />
                  </>
                );
              }
              return (
                <>
                  <Tag key="good-driver" color={PRIMARY_GREEN}>
                    {GOOD_DRIVER}
                  </Tag>
                  <RenderActionIcon />
                </>
              );
            }
            return null;
          })()}
        </FlexLayout>
      </Card>
      {driverId && (
        <Modal
          styled={{ width: '80vw', minWidth: 400 }}
          title="Tags"
          open={isTagModal}
          showClose
          onClose={() => setIsTagModal(false)}
        >
          <DriverTagsTable driverId={driverId} fetch={fetchTags} />
        </Modal>
      )}
    </>
  );
};
