import React, { useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { getAllBranches } from '../../../api/get/branch.get';
import { updatePhysicalLocationOnVehicle } from '../../../api/patch/vehicle.patch';
import { Branch } from '../../../models/branch';
import { UpdateVehicleLocationPayload, Vehicle } from '../../../models/vehicle';
import { OptionList } from '../../../utils/props';
import { renderNotification } from '../../../utils/utils';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { useRemovePhysicalLocationByVehicleIdMutation } from '../../../api/vehicle/vehicleSliceApi';

interface VehicleLocationFormParams {
  vehicle: Vehicle | null;
  close: () => void;
}

export const VehicleLocationForm = ({ vehicle, close }: VehicleLocationFormParams) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateVehicleLocationPayload>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    values: {
      branch_id: vehicle?.physical_branch_id ?? '',
    },
  });
  const [allBranchOptions, setAllBranchOptions] = useState<OptionList[]>([]);
  const [currentPhysicalLocation, setCurrentPhysicalLocation] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [removePhysicalLocation, { isLoading: removePhysicalLocationLoading }] =
    useRemovePhysicalLocationByVehicleIdMutation();

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name, has_sales: branch.has_sales };
      });
      setAllBranchOptions(branches);
      setCurrentPhysicalLocation(vehicle?.physical_branch_id);
    });
  }, [vehicle?.branch_id, vehicle?.physical_branch_id, vehicle?.vehicle_location]);

  const onSubmit = async (submitValues: UpdateVehicleLocationPayload) => {
    setLoading(true);
    try {
      if (vehicle?.id) {
        const data = await updatePhysicalLocationOnVehicle(vehicle?.id, submitValues);
        if (data) {
          renderNotification('success', 'Success', 'Vehicle location successfully updated');
        }
      }
    } catch (err) {
      renderNotification('error', 'Error', `Failed to update vehicle location: ${err}`, false);
    } finally {
      setLoading(false);
      close();
    }
  };

  const onRemoveLocation = async () => {
    try {
      if (vehicle?.id) {
        const data = await removePhysicalLocation({ id: vehicle.id });
        if (data) {
          renderNotification('success', 'Success', 'Vehicle location successfully removed');
        }
      }
    } catch (err) {
      renderNotification('error', 'Error', `Failed to remove vehicle location: ${err}`, false);
    } finally {
      close();
    }
  };

  return (
    <div>
      <TextFieldLabel $isRequired>Location to</TextFieldLabel>
      <DropDown
        options={allBranchOptions}
        placeholder="Location to"
        name="branch_id"
        error={errors.branch_id}
        required={{
          required: 'Location is required',
          validate: (v) => (v !== currentPhysicalLocation ? true : 'New location cannot be same as the current one'),
        }}
        control={control as unknown as Control<FieldValues>}
      />
      <FlexLayout styled={{ marginTop: 20, marginBottom: -70, gap: 16 }} itemsY="end" itemsX="end">
        <SecondaryButton
          isProcessing={removePhysicalLocationLoading}
          onClick={onRemoveLocation}
          disabled={!currentPhysicalLocation || removePhysicalLocationLoading}
        >
          Remove Location
        </SecondaryButton>
        <PrimaryButton isProcessing={loading} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </div>
  );
};
