import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Filters and headers in common folders as used in both agreement lists
import { APP_CONTEXT } from '../../utils/context';
import { OptionList } from '../../utils/props';
import { UpcomingAftercare } from '../../models/aftercare';
import { useTableFilters } from '../../hooks/useTableFilters';
import {
  TableTextCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../uiComponents/table/tableUtils/tableUtils';
import { upcomingAftercareColumns } from '../../uiComponents/table/tableColumns/tableColumns';
import { useNavigate } from 'react-router-dom';
import { ActionIcon } from '../../uiComponents/table/actionIcon/actionIcon';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_GRAY, PRIMARY_GREEN } from '../../common/styles/Colors';
import { BsCircle, BsEye } from 'react-icons/bs';
import { Table } from '../../uiComponents/table/table';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../uiComponents/table/tableFilters/tableFilters';
import { AFTERCARE_UPCOMING, AGREEMENTS } from '../../consts/routes';
import { ConfirmationModal } from '../../uiComponents/modals/confirmationModal/confirmationModal';
import { updateAgreement } from '../../api/patch/agreement.patch';
import { Notification } from '../../uiComponents/toast/toast';
import { agreementTypes } from '../../uiComponents/table/tableFilters/tableFilterOptions';
import { useDateRangeFilter } from '../../hooks/useDateRangeFilter';
import { useGetAndMergeUpcomingAgreementsQuery } from '../../api/listAndMerge/listAndMergeUpcomingAgreementsApiSlice';
import { format } from 'date-fns';

export const UpcomingAftercareList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [isAftercareEligibilityModalOpen, setIsAftercareEligibilityModalOpen] = useState(false);
  const [activeAftercare, setActiveAftercare] = useState<UpcomingAftercare>();
  const [agreementTypeFilter, setAgreementTypeFilter] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    setSortingColumn,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    filterQuery,
    setTableFilters,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=end_date:ASC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: upcomingAftercareList,
    isLoading: isUpcomingAftercareListLoading,
    isFetching: isUpcomingAftercareListFetching,
    refetch,
  } = useGetAndMergeUpcomingAgreementsQuery({ query: queryStringState, refetch: refetchData });

  const handleGetUpcomingAftercareResponse = useCallback(
    (count: number, data: UpcomingAftercare[]) => {
      const aftercareRows = data?.map((aftercareItem) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell value={format(new Date(aftercareItem?.end_date), 'dd MMM yyyy')} />,
            <TableTextCell value={aftercareItem?.agreement_type} />,
            <TableTextCell value={aftercareItem?.remaining_balance ? `£${aftercareItem?.remaining_balance}` : '-'} />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => navigate(`${AGREEMENTS}/${aftercareItem?.id}`, { state: AFTERCARE_UPCOMING })}
                icon={<BsEye size={24} color={PRIMARY_GRAY} />}
                tooltip="View agreement page"
              />
              <ActionIcon
                onClick={() => {
                  setActiveAftercare(aftercareItem);
                  setIsAftercareEligibilityModalOpen(true);
                }}
                disabled={aftercareItem.has_bad_tag}
                icon={<BsCircle size={24} color={PRIMARY_GREEN} />}
                tooltip="Aftercare eligibility"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, navigate]
  );

  const filters: FilterItem[] = [
    {
      name: 'agreement_type',
      element: (
        <DropDownFilter
          name="agreement_type"
          placeholder="Select agreement type"
          options={agreementTypes}
          multiValues={agreementTypeFilter}
          title="Plan"
          onChange={(items) => setAgreementTypeFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  const aftercareColumns = useMemo(() => [...upcomingAftercareColumns], []);

  useEffect(() => {
    setActiveSideNav('aftercareListPage');
    setPageTitle('Aftercare');
    setSortingColumn('end_date');
  }, [setActiveSideNav, setPageTitle, setSortingColumn]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'agreement_type', options: agreementTypeFilter },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, agreementTypeFilter]);

  useEffect(() => {
    if (upcomingAftercareList) {
      handleGetUpcomingAftercareResponse(upcomingAftercareList.count, upcomingAftercareList.data);
    }
  }, [upcomingAftercareList, queryStringState, handleGetUpcomingAftercareResponse]);

  const onClearClick = useCallback(() => {
    setAgreementTypeFilter([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    goToPageNumber(0);
    onClearClick();
  }, [goToPageNumber, onClearClick, refetch, defaultString, queryStringState]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isUpcomingAftercareListLoading || isUpcomingAftercareListFetching}
        header="Agreements nearing completion"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={aftercareColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: UpcomingAftercare }) =>
          navigate(`${AGREEMENTS}/${data?.id}`, { state: AFTERCARE_UPCOMING })
        }
      />
      {activeAftercare && (
        <ConfirmationModal
          title={'Are you sure you want to update eligibility?'}
          isOpen={isAftercareEligibilityModalOpen}
          onClose={() => setIsAftercareEligibilityModalOpen(false)}
          onConfirm={() => {
            updateAgreement(activeAftercare?.id, {
              aftercare_eligible: !activeAftercare?.aftercare_eligible,
            })
              .then(() => {
                Notification({
                  type: 'success',
                  title: 'Success',
                  message: 'Aftercare eligibility successfully updated.',
                  isAlert: true,
                });
                refetchToFirstPage();
              })
              .catch((e: Error) => {
                Notification({
                  type: 'error',
                  title: 'Error',
                  message: e.message,
                  isAlert: true,
                });
              });
            setIsAftercareEligibilityModalOpen(false);
          }}
          closeButtonCaption="Cancel"
          confirmButtonCaption="Confirm"
        />
      )}
    </>
  );
};
