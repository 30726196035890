import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_CONTEXT } from '../../utils/context';
import { columns } from './columns';
import { getCoreRowModel, useReactTable, SortingState, VisibilityState } from '@tanstack/react-table';
import { BsDownload, BsFilter, BsPlus, BsSearch } from 'react-icons/bs';
import { Badge } from '../../components/ui/badge/badge';
import { DashDataTable } from '../../components/tables/dash-table/dash-table';
import { Button } from '../../components/ui/button/button';
import { TextInput } from '../../components/inputs/text-input/text-input';
import { DEFAULT_NUM_ROWS_PER_PAGE, TableFilter } from '../../uiComponents/table/tableUtils/tableUtils';
import { useExportContractsMutation, useGetContractsListQuery } from '../../api/contracts/contractsDetailsApiSlice';
import { ContractListItem } from '../../models/contract';
import usePaginationHelper from '../../utils/paginationHelper/paginationHelper';
import { CREATE_CONTRACT, EDIT_CONTRACT, VIEW_CONTRACT } from '../../consts/routes';
import { renderNotification } from '../../utils/utils';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';
import { Spinner } from '../../uiComponents/uiControls/spinner/spinner';

export const ContractsList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [filters, setFilters] = React.useState<TableFilter[]>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_at:DESC`;

  useEffect(() => {
    setActiveSideNav('contracts');
    setPageTitle('Contracts');
  }, [setActiveSideNav, setPageTitle]);

  const { query, handleNext, handleSearch, handleSorting } = usePaginationHelper({
    currentPage: currentPage,
    search: search,
    sorting: sorting,
    filters: filters,
    setCurrentPage: setCurrentPage,
    setSearch: setSearch,
    setSorting: setSorting,
    setFilters: setFilters,
  });

  const {
    data: contractsList,
    isLoading: isContractsListLoading,
    isFetching: isContractsListFetching,
  } = useGetContractsListQuery({ query: query || defaultString, refetch: currentPage === 0 });

  const [exportContracts, { isLoading: isExportingContracts }] = useExportContractsMutation();

  const handleView = (data: ContractListItem) => {
    navigate(VIEW_CONTRACT.replace(':id', data.id));
  };
  const handleEdit = (data: ContractListItem) => {
    navigate(EDIT_CONTRACT.replace(':id', data.id));
  };

  const table = useReactTable({
    data: contractsList?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: handleSorting,
    manualSorting: true,
    getRowId: (originalRow) => originalRow.id,
    state: {
      rowSelection,
      columnVisibility,
      sorting,
    },
    meta: {
      editData: handleEdit,
      viewData: handleView,
    },
  });
  const selectedRows = table.getSelectedRowModel().rows;

  async function handleRowsExport() {
    let selectedRowsId: string[] = [];
    if (table.getIsSomeRowsSelected() && selectedRows.length > 0) {
      selectedRowsId = Object.keys(rowSelection);
    }

    await exportContracts({ query, body: { contractIds: selectedRowsId } })
      .unwrap()
      .then(() => renderNotification('success', 'Success', 'Export successful! The data has been emailed to you.'))
      .catch(() => renderNotification('error', 'Error', 'Failed to export the Data.'));
  }

  return (
    <div>
      <DashDataTable.HeaderContainer>
        <div className="max-sm:w-full flex items-center max-sm:justify-between">
          <DashDataTable.Title
            rowCount={contractsList?.count || 0}
            data-testid="title"
            className="max-sm:!pl-4 max-sm:!pt-5"
          >
            Contracts
          </DashDataTable.Title>
          <div className="sm:hidden">
            <DashDataTable.ColumnVisibilityDropdown table={table} />
          </div>
        </div>
        <DashDataTable.HeaderActionsContainer className="max-sm:w-full max-sm:pl-4">
          <div className="flex items-center gap-3">
            <div className="max-sm:hidden">
              <TextInput
                startAdornment={<BsSearch className="text-utility-gray-600" />}
                placeholder="Search contracts"
                value={search ?? ''}
                onChange={(event) => handleSearch(event.target.value)}
                data-testid="search-input"
                className="w-[320px]"
              />
            </div>

            <Button
              color="secondary"
              onClick={handleRowsExport}
              data-testid="download-button"
              disabled={isExportingContracts || contractsList?.data.length === 0}
            >
              {isExportingContracts ? (
                <Spinner color={PRIMARY_PURPLE} size={16} />
              ) : (
                <BsDownload className="text-base" />
              )}
              {selectedRows.length > 0 && <Badge color="gray">{selectedRows.length}</Badge>}
              Download
              {selectedRows.length === 0 && ' All'}
            </Button>
            <Button color="secondary" startAdornment={<BsFilter className="text-lg" />} data-testid="filters-button">
              Filters
            </Button>
            <Button
              startAdornment={<BsPlus className="text-xl" />}
              data-testid="create-contract-button"
              onClick={() => navigate(CREATE_CONTRACT)}
              data-name="new contract"
              className="sm:after:content-[attr(data-name)]"
            >
              Create
            </Button>
          </div>
          <div className="max-sm:hidden">
            <DashDataTable.ColumnVisibilityDropdown table={table} />
          </div>
        </DashDataTable.HeaderActionsContainer>
      </DashDataTable.HeaderContainer>
      <DashDataTable.Table
        table={table}
        columns={columns}
        isLoading={isContractsListLoading || isContractsListFetching}
        hasMore={(contractsList?.count as number) > (table.getRowModel().rows?.length as number)}
        getMore={handleNext}
        scrollToTop={currentPage === 0}
      />
    </div>
  );
};
