import * as React from 'react';
import { CarFront, Search } from 'lucide-react';

import { cn } from '../../../lib/utils';
import { Button } from '../../ui/button/button';
import { Dialog, DialogContent } from '../../ui/dialog/dialog';
import { Select } from './select/select';
import { TextInput } from '../text-input/text-input';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { Badge } from '../../ui/badge/badge';

export interface Option {
  value: string;
  label: string;
  info?: string;
  node?: React.ReactNode;
  unselected?: boolean;
}

export interface SelectColorQuantityProps {
  placeholder?: string;
  onChange?: (value: Option[]) => void;
  label?: string;
  hint?: string;
  error?: string;
  required?: boolean;
  defaultValue?: Option[];
  value?: Option[];
  'data-testid'?: string;
  disabled?: boolean;
  options: Option[];
}

export function SelectColorQuantity({
  placeholder = 'Search',
  options,
  onChange,
  label,
  hint,
  error,
  required,
  defaultValue,
  value,
  disabled,
  ...props
}: SelectColorQuantityProps) {
  const [openCloseDialog, setOpenCloseDialog] = React.useState(false);
  const [selected, setSelected] = React.useState<Option[] | undefined>(value || defaultValue);
  const [defaultSelected, setDefaultSelected] = React.useState<Option[] | undefined>(value || defaultValue);

  const handleQuantity = (index: number, value: string) => {
    const newSelected = selected?.map((item) => item);
    if (newSelected && newSelected[index]) {
      newSelected[index].info = value;
      setSelected(newSelected);
    }
  };

  const handleSelect = (index: number, value: boolean) => {
    const newSelected = selected?.map((item) => item);
    if (newSelected && newSelected[index]) {
      newSelected[index].unselected = !value;
      setSelected(newSelected);
    }
  };

  const handleChange = () => {
    if (selected && selected.length > 0) {
      const result = selected.filter((item) => !item.unselected && !!item.info && parseInt(item.info) > 0);
      setDefaultSelected(result);
      onChange?.(result);
    }
    setOpenCloseDialog(false);
  };

  return (
    <div data-testid={props['data-testid']}>
      {label && (
        <p className="text-sm font-medium text-utility-gray-700 p-0 m-0 mb-1.5">
          {label}
          {required && '*'}
        </p>
      )}
      <Button
        color={error ? 'destructive' : 'secondary'}
        variant={error ? 'outline' : 'fill'}
        className={cn(
          'w-full justify-start text-left font-normal',
          defaultSelected &&
            defaultSelected.length > 0 &&
            '!max-h-fit !min-h-fit !h-fit w-full !flex-wrap justify-start text-left font-normal',
          !selected && 'text-muted-foreground'
        )}
        disabled={disabled}
        onClick={() => setOpenCloseDialog(true)}
        type="button"
      >
        {defaultSelected && defaultSelected.length > 0 ? (
          <>
            {defaultSelected.map((option, index) => (
              <Badge data-testid={`select-value-${index}`} key={option.value} variant="fill" size="sm" shape="square">
                <span>{option.label}</span>
                <span> - </span>
                <span>{option.info}</span>
              </Badge>
            ))}
          </>
        ) : (
          <>
            <Search className="mr-2 h-4 w-4" />
            <span className="font-normal text-utility-gray-500">{placeholder}</span>
          </>
        )}
        <></>
      </Button>
      <Dialog open={openCloseDialog} onOpenChange={setOpenCloseDialog}>
        <DialogContent className="!w-[400px] !h-[590px] !rounded-md !border !bg-popover !text-popover-foreground !animate-in !shadow-dropdown-shadow p-0">
          <div>
            <div className="h-[510px] w-full px-6 pt-5">
              <p className="text-lg font-extrabold text-utility-gray-900">Colours and Quantity</p>
              <p className="text-sm font-light text-utility-gray-600">Search for colours and add quantity</p>
              <div className="w-full pt-5">
                <Select
                  startAdornment={<Search className="mr-2 h-4 w-4" />}
                  options={options}
                  value={selected}
                  placeholder="Search for colour"
                  onSelect={setSelected}
                />
              </div>
              <div className="w-full mt-7 max-h-[350px] overflow-y-auto">
                {selected?.map((element, index) => {
                  return (
                    <div key={index} className="w-full pt-5 flex items-center justify-between">
                      <div className="flex items-center">
                        <CarFront />
                        <span className="pl-4 text-base font-medium text-utility-gray-900">{element.label}</span>
                      </div>
                      <div className="flex items-center">
                        <TextInput
                          placeholder={'0'}
                          className="w-[102px] mr-5"
                          type="number"
                          onChange={(event) => handleQuantity(index, event.target.value)}
                          value={element.info}
                        />
                        <Checkbox
                          data-testid="checkbox"
                          disabled={!element.info || element.info === '0'}
                          checked={!!element.info && parseInt(element.info) > 0 && !element.unselected}
                          onCheckedChange={(value) => handleSelect(index, value as boolean)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex items-center gap-3 px-4 py-4">
              <Button className="w-full" color="secondary" onClick={() => setOpenCloseDialog(false)}>
                Cancel
              </Button>
              <Button className="w-full" onClick={handleChange} disabled={!selected || selected.length === 0}>
                Add
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {hint && !error && <p className="text-sm font-light text-utility-gray-600 p-0 m-0 mt-1.5">{hint}</p>}
      {error && <p className="text-sm font-light text-utility-error-600 p-0 m-0 mt-1.5">{error}</p>}
    </div>
  );
}
