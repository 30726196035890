import {
  GRAY_8,
  PRIMARY_GRAY,
  PRIMARY_PURPLE,
  PRIMARY_GREEN,
  STATUS_RED,
  SECONDARY_PURPLE_70,
  SECONDARY_PURPLE_30,
  STATUS_ORANGE,
  STATUS_YELLOW,
  STATUS_BLUE,
  SECONDARY_ORANGE,
  SECONDARY_GREEN_30,
  SECONDARY_RED,
  SECONDARY_GRAY_20,
} from './styles/Colors';

export const penaltyColors: Record<string, string> = {
  ac10: STATUS_RED,
  ac20: PRIMARY_GREEN,
  ac30: PRIMARY_GREEN,
  ba10: STATUS_RED,
  ba30: STATUS_RED,
  ba40: STATUS_RED,
  ba60: STATUS_RED,
  cd10: STATUS_RED,
  cd20: STATUS_RED,
  cd30: STATUS_RED,
  cd33: STATUS_RED,
  cd40: STATUS_RED,
  cd50: STATUS_RED,
  cd60: STATUS_RED,
  cd70: STATUS_RED,
  cd80: STATUS_RED,
  cd90: STATUS_RED,
  cu10: PRIMARY_GREEN,
  cu20: PRIMARY_GREEN,
  cu30: PRIMARY_GREEN,
  cu40: PRIMARY_GREEN,
  cu50: PRIMARY_GREEN,
  cu80: PRIMARY_GREEN,
  dd10: STATUS_RED,
  dd40: STATUS_RED,
  dd60: STATUS_RED,
  dd80: STATUS_RED,
  dd90: STATUS_RED,
  dr10: STATUS_RED,
  dr20: STATUS_RED,
  dr30: STATUS_RED,
  dr31: STATUS_RED,
  dr61: STATUS_RED,
  dr40: STATUS_RED,
  dr50: STATUS_RED,
  dr60: STATUS_RED,
  dr70: STATUS_RED,
  dg10: STATUS_RED,
  dg40: STATUS_RED,
  dg60: STATUS_RED,
  dg70: STATUS_RED,
  dg80: STATUS_RED,
  dg90: STATUS_RED,
  in10: STATUS_RED,
  lc20: STATUS_RED,
  lc30: STATUS_RED,
  lc40: STATUS_RED,
  lc50: STATUS_RED,
  mr09: STATUS_RED,
  mr19: STATUS_RED,
  mr29: STATUS_RED,
  mr39: STATUS_RED,
  mr49: STATUS_RED,
  mr59: STATUS_RED,
  ms10: PRIMARY_GREEN,
  ms20: PRIMARY_GREEN,
  ms30: PRIMARY_GREEN,
  ms50: STATUS_RED,
  ms60: PRIMARY_GREEN,
  ms70: STATUS_RED,
  ms80: STATUS_RED,
  ms90: STATUS_RED,
  mw10: PRIMARY_GREEN,
  pc10: PRIMARY_GREEN,
  pc20: PRIMARY_GREEN,
  pc30: PRIMARY_GREEN,
  sp10: PRIMARY_GREEN,
  sp20: PRIMARY_GREEN,
  sp30: PRIMARY_GREEN,
  sp40: PRIMARY_GREEN,
  sp50: PRIMARY_GREEN,
  ts10: PRIMARY_GREEN,
  ts20: PRIMARY_GREEN,
  ts30: PRIMARY_GREEN,
  ts40: PRIMARY_GREEN,
  ts50: PRIMARY_GREEN,
  ts60: PRIMARY_GREEN,
  ts70: PRIMARY_GREEN,
  ut50: STATUS_RED,
};

export const statusColors: Record<string, string> = {
  EXPIRED: STATUS_RED,
  ACTIVE: PRIMARY_GREEN,
  UPCOMING: STATUS_ORANGE,
  OPEN: STATUS_BLUE,
  NEW: STATUS_BLUE,
  AVAILABLE: STATUS_BLUE,
  YES: STATUS_BLUE,
  FROZEN: STATUS_BLUE,
  ORDERED: STATUS_BLUE,
  'AFTERCARE-REVIEW': GRAY_8,
  'AWAITING-EVAULATION': GRAY_8,
  'PRACTICAL-PENDING': SECONDARY_PURPLE_30,
  'PRACTICAL-PASSED': PRIMARY_GREEN,
  'PRACTICAL-FAILED': STATUS_RED,
  'ONLINE-PENDING': SECONDARY_PURPLE_30,
  'ONLINE-PASSED': PRIMARY_GREEN,
  'ONLINE-FAILED': STATUS_RED,
  PENDING: GRAY_8,
  INVOICED: GRAY_8,
  ASSIGNED: SECONDARY_PURPLE_70,
  LIVE: PRIMARY_GREEN,
  COMPLETED: PRIMARY_GREEN,
  PASS: PRIMARY_GREEN,
  NO: PRIMARY_GREEN,
  Low: PRIMARY_GREEN,
  Resolved: PRIMARY_GREEN,
  Complete: PRIMARY_GREEN,
  COMPLETE: PRIMARY_GREEN,
  INCOMPLETE: SECONDARY_PURPLE_70,
  'R2B-USED': SECONDARY_PURPLE_70,
  'R2B-USED-NATIONAL': SECONDARY_PURPLE_70,
  'R2B-USED-NATIONAL-PLUS-8': SECONDARY_PURPLE_70,
  'R2B-USED-PLUS-8': SECONDARY_PURPLE_70,
  'R2B-CLASSIC': SECONDARY_PURPLE_70,
  'R2B-NEW-NATIONAL': SECONDARY_PURPLE_70,
  'R2B-NEW-NATIONAL-PLUS-8': SECONDARY_PURPLE_70,
  'R2B-CLASSIC-PLUS-8': SECONDARY_PURPLE_70,
  'FRIENDLY-FINANCE': SECONDARY_PURPLE_70,
  RENTAL: SECONDARY_PURPLE_70,
  'IN-SERVICE-RENTAL': SECONDARY_PURPLE_70,
  'CAR-HIRE-NATIONAL': SECONDARY_PURPLE_70,
  'PCO-CAR-HIRE-FLEX': SECONDARY_PURPLE_70,
  'AFTERCARE-FULL': SECONDARY_PURPLE_70,
  'AFTERCARE-INSURANCE': SECONDARY_PURPLE_70,
  CANCELLED: STATUS_RED,
  TERMINATED: STATUS_RED,
  FAIL: STATUS_RED,
  Urgent: STATUS_RED,
  Open: STATUS_RED,
  'INVALID-ACCOUNT': STATUS_RED,
  'INVALID-DRIVER': STATUS_RED,
  'INVALID-VRM': STATUS_RED,
  High: STATUS_ORANGE,
  Pending: STATUS_ORANGE,
  Medium: STATUS_YELLOW,
  Closed: STATUS_YELLOW,
  'PART-EXCHANGE': PRIMARY_PURPLE,
  'CAREER-BOOST': STATUS_BLUE,
  'OPTED-FOR-CAREER-BOOST': STATUS_ORANGE,
  Paid: PRIMARY_GREEN,
  Unpaid: STATUS_RED,
  Active: PRIMARY_GREEN,
  Cancelled: STATUS_RED,
  PRACTICAL: PRIMARY_PURPLE,
  ONLINE: PRIMARY_PURPLE,
  DROPPED: STATUS_RED,
  'High risk': STATUS_RED,
  'Low risk': STATUS_ORANGE,
  'No risk': STATUS_BLUE,
  'CHECK-OUT': PRIMARY_GREEN,
  'CHECK-IN': PRIMARY_PURPLE,
  RESOLVED: PRIMARY_GREEN,
  UNRESOLVED: STATUS_RED,
  'ALL IN ORDER': PRIMARY_GREEN,
  ABANDONED: STATUS_RED,
  REPO: STATUS_RED,
  'BAD DEBT': STATUS_RED,
  'DAMAGED CAR': STATUS_RED,
  'DANGEROUS DRIVING': STATUS_RED,
  'DO NOT RENT': STATUS_RED,
  'FAILED DRIVING ASSESSMENT': STATUS_RED,
  'SWITCHED OFF': STATUS_RED,
  'TAMPERED WITH SYSTEM': STATUS_RED,
  'UNREPORTED ACCIDENT/CLAIM': STATUS_RED,
  'UNPAID EXCESS': STATUS_RED,
  'HIGH RISK': STATUS_RED,
  'LOW RISK': PRIMARY_GREEN,
  'NO RISK': PRIMARY_GREEN,
  APPROVED: PRIMARY_GREEN,
  REJECTED: STATUS_RED,
  UNINSURED: STATUS_RED,
  STOLEN: STATUS_RED,
  'INSURANCE POLICY BREACH': STATUS_RED,
  'MORE THAN 6 POINTS ON LICENSE': STATUS_RED,
  'DO NOT INSURE / AFTERCARE': STATUS_RED,
  'R2B-USED-BURST': SECONDARY_PURPLE_70,
  'R2B-NEW-BURST': SECONDARY_PURPLE_70,
  'NO THEFT COVER': PRIMARY_GREEN,
};

export const decideNewStatusColor = (status: string) => {
  switch (status) {
    case 'Online-passed':
    case 'Practical-passed':
    case 'Not-needed':
    case 'Live':
      return PRIMARY_GREEN;
    case 'Online-pending':
      return PRIMARY_GREEN;
    case 'Online-failed':
    case 'Practical-failed':
      return STATUS_RED;
    case 'Practical-pending':
    case 'None':
      return SECONDARY_PURPLE_30;
    default:
      return SECONDARY_PURPLE_70;
  }
};

export const decideInsuranceTagColor = (tag: string) => {
  switch (tag.toUpperCase()) {
    case 'REMOVED-INSURANCE':
    case 'NO-INSURANCE':
      return STATUS_RED;
    case 'OTTOCAR-INSURANCE':
      return PRIMARY_GREEN;
    case 'ALTERNATIVE-INSURANCE':
      return SECONDARY_PURPLE_70;
    default:
      return PRIMARY_GRAY;
  }
};

export const decideToastColor = (toastType: string) => {
  switch (toastType) {
    case 'error':
      return STATUS_RED;
    case 'success':
      return PRIMARY_GREEN;
    case 'warn':
      return STATUS_ORANGE;
    case 'info':
      return PRIMARY_PURPLE;
    default:
      return PRIMARY_GRAY;
  }
};

export const decideToastBackgroundColor = (toastType: string) => {
  switch (toastType) {
    case 'error':
      return SECONDARY_RED;
    case 'success':
      return SECONDARY_GREEN_30;
    case 'warn':
      return SECONDARY_ORANGE;
    case 'info':
      return SECONDARY_PURPLE_30;
    default:
      return SECONDARY_GRAY_20;
  }
};
