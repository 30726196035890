import { useParams } from 'react-router-dom';
import { ContractForm, ContractFormType, profitTypeList, vehicleTypeList } from '../contractForm/contractForm';
import {
  useGetContractByIdQuery,
  useGetContractTypeListQuery,
  useGetLocationListQuery,
  useGetReferenceDataListQuery,
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
} from '../../../api/contracts/contractsDetailsApiSlice';
import { useEffect, useState } from 'react';
import { ContractTypeListItem, LocationListItem, ManufacturerListItem, ModelListItem } from '../../../models/contract';

export const ViewContract = () => {
  const { id } = useParams();
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: modelList } = useGetVehicleModelListQuery({});
  const { data: contractTypeList } = useGetContractTypeListQuery({});
  const { data: locationList } = useGetLocationListQuery({});
  const { data: referenceData } = useGetReferenceDataListQuery({});
  const { data: contract } = useGetContractByIdQuery({ query: id, refetch: true });
  const [data, setData] = useState<ContractFormType>();

  useEffect(() => {
    if (
      manufacturerList?.data &&
      modelList?.data &&
      contractTypeList?.data &&
      locationList?.data &&
      referenceData?.data &&
      contract?.data
    ) {
      const contract_type_id = contractTypeList.data
        ?.filter((item: ContractTypeListItem) => item.id === contract.data.contract_type_id)
        .map((opt: ContractTypeListItem) => {
          return {
            value: opt.id,
            label: opt.name,
          };
        });
      const locations = contract.data.location?.map((val: { id: string; name: string }) => val.id);
      const location = locationList.data
        ?.filter((item: LocationListItem) => locations?.includes(item.city_id))
        .map((opt: LocationListItem) => {
          return {
            value: opt.city_id,
            label: opt.city_name,
          };
        });
      const manufacturers = contract.data.manufacturer?.map((val: { id: string; name: string }) => val.id);
      const manufacturer = manufacturerList.data
        ?.filter((item: ManufacturerListItem) => manufacturers?.includes(item.id))
        .map((opt: ManufacturerListItem) => {
          return {
            value: opt.id,
            label: opt.name,
          };
        });
      const vehicle_models = contract.data.vehicle_model?.map((val: { id: string; name: string }) => val.id);
      const vehicle_model = modelList.data
        ?.filter((item: ModelListItem) => vehicle_models?.includes(item.id))
        .map((opt: ModelListItem) => {
          return {
            value: opt.id,
            label: opt.model,
            info: opt.manufacturer_id,
          };
        });
      const model_year = [
        {
          value: contract.data.model_year?.toString(),
          label: contract.data.model_year?.toString(),
        },
      ];
      const vehicle_type = vehicleTypeList?.filter((item) => item.value === contract.data.vehicle_type);
      const profit_type = profitTypeList?.filter((item) => item.value === contract.data.profit_type);
      const available_from = contract.data.available_from ? new Date(contract.data.available_from) : undefined;

      setData({
        ...contract.data,
        contract_type_id,
        location,
        manufacturer,
        vehicle_model,
        model_year,
        vehicle_type,
        profit_type,
        available_from,
      });
    }
  }, [manufacturerList, modelList, contractTypeList, locationList, referenceData, contract]);

  return <>{data && <ContractForm defaultValues={data} isViewOnly />}</>;
};
