import { TableColumn } from '../../../models/table';

export const agreementListColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vrm', name: 'Vehicle', sortable: true },
  { id: 'agreement_type', name: 'Agreement type', sortable: true },
  { id: 'ordway_subscription_id', name: 'Subscription ID', sortable: true },
  { id: 'branch_name', name: 'Branch', sortable: true },
  { id: 'agreement_status', name: 'Status', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const driverListColumns: TableColumn[] = [
  { id: 'first_name', name: 'First name', sortable: true },
  { id: 'last_name', name: 'Last name', sortable: true },
  { id: 'email', name: 'Email address', sortable: true },
  { id: 'agreement_type', name: 'Agreement type', sortable: true },
  { id: 'pco_no', name: 'PCO number', sortable: true },
  { id: 'dvla_no', name: 'DVLA number', sortable: true },
  { id: 'address_city', name: 'City', sortable: true },
  { id: 'tag_type', name: 'Tags', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const propectiveDriverListColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'driver_profile_status', name: 'Profile status', sortable: true },
  { id: 'source', name: 'Source', sortable: false },
  { id: 'plan_type', name: 'Plan type', sortable: true },
  { id: 'branch_name', name: 'Sales branch', sortable: true },
  { id: 'latest_assessment_status', name: 'Assessment status', sortable: true },
  { id: 'tag_type', name: 'Driver tags', sortable: true },
  { id: 'created_date', name: 'Date created', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const vehicleListColumns: TableColumn[] = [
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'manufacturer', name: 'Manufacturer', sortable: true },
  { id: 'color', name: 'Colour', sortable: true },
  { id: 'physical_branch_id', name: 'Physical location', sortable: false },
  { id: 'vehicle_status', name: 'Status', sortable: true },
  { id: 'current_tag', name: 'Insurance tag', sortable: true },
  { id: 'mot_exp', name: 'MOT expiry', sortable: true },
  { id: 'phv_exp', name: 'PHV expiry', sortable: true },
  { id: 'is_sec_immob', name: 'Immobiliser', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const vehicleBatchListColumns: TableColumn[] = [
  { id: 'manufacturer', name: 'Manufacturer', sortable: true },
  { id: 'model', name: 'Model', sortable: true },
  { id: 'spec', name: 'Spec', sortable: true },
  { id: 'model_year', name: 'Year', sortable: true },
  { id: 'colour', name: 'Colour', sortable: false },
  { id: 'fuel_type', name: 'Fuel type', sortable: true },
  { id: 'amount', name: 'Quantity', sortable: true },
  { id: 'available_on', name: 'Available on', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const newVehicleBatchListColumns: TableColumn[] = [
  { id: 'manufacturer', name: 'Manufacturer', sortable: true },
  { id: 'vehicle_model', name: 'Model', sortable: true },
  { id: 'model_year', name: 'Year', sortable: true },
  { id: 'exterior_colors', name: 'Colours', sortable: false },
  { id: 'fuel_type', name: 'Fuel type', sortable: true },
  { id: 'amount', name: 'Quantity', sortable: true },
  { id: 'available_on', name: 'Available on', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const branchColumns: TableColumn[] = [
  { id: 'branch_name', name: 'Branch name', sortable: true },
  { id: 'city_name', name: 'City', sortable: true },
  { id: 'country_name', name: 'Country', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const userColumns: TableColumn[] = [
  { id: 'first_name', name: 'First name', sortable: true },
  { id: 'last_name', name: 'Last name', sortable: true },
  { id: 'email', name: 'Email', sortable: true },
  { id: 'contact_number', name: 'Mobile number', sortable: false },
  { id: 'department', name: 'Department', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const cityColumns: TableColumn[] = [
  { id: 'city_name', name: 'City name', sortable: true },
  { id: 'country_name', name: 'Country name', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const vehicleModelColumns: TableColumn[] = [
  { id: 'manufacturer', name: 'Manufacturer', sortable: true },
  { id: 'model', name: 'Model', sortable: true },
  { id: 'spec', name: 'Spec', sortable: true },
  { id: 'model_year', name: 'Year', sortable: true },
  { id: 'colors', name: 'Colours', sortable: true },
  { id: 'fuel_type', name: 'Fuel Type', sortable: true },
  { id: 'actions', name: '', sortable: false },
];

export const vehicleColumns: TableColumn[] = [
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'manufacturer', name: 'Manufacturer', sortable: true },
  { id: 'model', name: 'Model', sortable: true },
  { id: 'spec', name: 'Spec', sortable: true },
  { id: 'model_year', name: 'Year', sortable: true },
  { id: 'color', name: 'Colour', sortable: true },
  { id: 'vehicle_source', name: 'Vehicle source', sortable: true },
  { id: 'actions', name: '', sortable: false },
];

export const orderListColumns: TableColumn[] = [
  {
    id: 'driver_name',
    name: 'Driver name',
    sortable: true,
  },
  {
    id: 'agreement_type',
    name: 'Agreement type',
    sortable: true,
  },
  {
    id: 'city_name',
    name: 'City',
    sortable: true,
  },
  {
    id: 'branch_name',
    name: 'Branch name',
    sortable: true,
  },
  {
    id: 'vehicle_type',
    name: 'Vehicle',
    sortable: true,
  },
  {
    id: 'vrm',
    name: 'VRM  ',
    sortable: true,
  },
  {
    id: 'created_date',
    name: 'Created date',
    sortable: true,
  },
  {
    id: 'application_status',
    name: 'Status',
    sortable: true,
  },
  {
    id: 'actions',
    name: 'Actions',
    sortable: false,
  },
];

export const documentTableColumns: TableColumn[] = [
  { id: 'document_type', name: 'Document type', sortable: true },
  { id: 'created_date', name: 'Upload date', sortable: true },
  { id: 'expiry_date', name: 'Expiry date', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const internalTransferTableColumns: TableColumn[] = [
  { id: 'transfer_type', name: 'Transfer type', sortable: false },
  { id: 'from_branch_name', name: 'From branch', sortable: false },
  { id: 'to_branch_name', name: 'To branch', sortable: false },
  { id: 'internal_transfer_created_date', name: 'Date created', sortable: false },
  { id: 'in_date', name: 'Date in', sortable: false },
  { id: 'out_date', name: 'Date out', sortable: false },
];

export const driverVehicleColumns: TableColumn[] = [
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'vehicle_make_model', name: 'Vehicle type', sortable: true },
  { id: 'spec', name: 'Spec', sortable: true },
  { id: 'accident_excess', name: 'Accident excess', sortable: true },
  { id: 'vehicle_status', name: 'Status', sortable: true },
];

export const driverAgreementColumns: TableColumn[] = [
  { id: 'agreement_type', name: 'Agreement type', sortable: true },
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'total_due', name: 'Total value', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: true },
  { id: 'agreement_status', name: 'Status', sortable: true },
];

// columns for Shawbrook audit
export const driverAgreementColumnsForAudit: TableColumn[] = [
  { id: 'agreement_type', name: 'Agreement type', sortable: true },
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: true },
  { id: 'agreement_status', name: 'Status', sortable: true },
];

export const driverApplicationColumns: TableColumn[] = [
  { id: 'application_type', name: 'Application type', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'updated_date', name: 'Last change', sortable: true },
  { id: 'application_status', name: 'Status', sortable: true },
];

export const driverInvoiceColumns: TableColumn[] = [
  { id: 'invoice_date', name: 'Date', sortable: false },
  { id: 'invoice_amount', name: 'Amount', sortable: false },
  { id: 'product_name', name: 'Product name', sortable: false },
  { id: 'status', name: 'Status', sortable: false },
  { id: 'invoice_pdf_url', name: 'Ordway invoice ID', sortable: false },
];

export const driverActivePlanColumns: TableColumn[] = [
  { id: 'subId', name: 'Subscription ID', sortable: false },
  { id: 'name', name: 'Plan name', sortable: false },
  { id: 'tcv', name: 'Total amount', sortable: false },
  { id: 'outstandingBalance', name: 'Outstanding amount', sortable: false },
  { id: 'subStatus', name: 'Subscription status', sortable: false },
];

export const driverMileageColumns: TableColumn[] = [
  { id: 'date_range', name: 'Week', sortable: false },
  { id: 'holidays', name: 'Holiday flag', sortable: false },
  { id: 'is_courtesy', name: 'Courtesy', sortable: false },
  { id: 'main_vehicle_vrm', name: 'Main VRM', sortable: false },
  { id: 'courtesy_vehicle_vrm', name: 'Courtesy VRM', sortable: false },
  { id: 'main_vehicle_mileage', name: 'Main mileage', sortable: false },
  { id: 'courtesy_vehicle_mileage', name: 'Courtesy mileage', sortable: false },
  { id: 'total_mileage', name: 'Total miles travelled', sortable: false },
  { id: 'excess_miles', name: 'Excess miles', sortable: false },
  { id: 'excess_mileage_charge', name: 'Excess mileage charge', sortable: false },
];

export const driverFreshdeskColumns: TableColumn[] = [
  { id: 'id', name: 'Ticket ID', sortable: false },
  { id: 'subject', name: 'Subject', sortable: false },
  { id: 'group_name', name: 'Group', sortable: false },
  { id: 'agent_name', name: 'Agent', sortable: false },
  { id: 'created_at', name: 'Date created', sortable: false },
  { id: 'type', name: 'Ticket type', sortable: false },
  { id: 'tags', name: 'Tags', sortable: false },
  { id: 'status', name: 'Status', sortable: false },
  { id: 'priority', name: 'Priority', sortable: false },
];

export const driverTagColumns: TableColumn[] = [
  { id: 'tag_name', name: 'Tag', sortable: false },
  { id: 'created_date', name: 'Date added', sortable: false },
  { id: 'date_resolved', name: 'Date resolved', sortable: false },
  { id: 'tag_reason', name: 'Reason', sortable: false },
  { id: 'created_by', name: 'Created by', sortable: false },
  { id: 'resolved_by', name: 'Resolved by', sortable: false },
  { id: 'resolution_status', name: 'Status', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const vehicleTypeColumns: TableColumn[] = [
  { id: 'manufacturer', name: 'Manufacturers', sortable: true },
  { id: 'model', name: 'Model', sortable: true },
  { id: 'spec', name: 'Spec', sortable: true },
  { id: 'model_year', name: 'Year', sortable: true },
  { id: 'colors', name: 'Colour', sortable: true },
  { id: 'fuel_type', name: 'Fuel type', sortable: true },
  { id: 'actions', name: 'Manage pricing', sortable: false },
];

export const vehiclePackageColumns: TableColumn[] = [
  { id: 'package_name', name: 'Package name', sortable: false },
  { id: 'total_pricing', name: 'Total Pricing', sortable: false },
  { id: 'total_weeks', name: 'Total Weeks', sortable: false },
  { id: 'package_type', name: 'Package Type', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const vehicleAgreementColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'agreement_status', name: 'Status', sortable: true },
  { id: 'total_due', name: 'Total value', sortable: true },
  { id: 'agreement_type', name: 'Type', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: true },
];

// for shawbrook audit
export const vehicleAgreementColumnsAudit: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'agreement_status', name: 'Status', sortable: true },
  { id: 'agreement_type', name: 'Type', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: true },
];

export const vehicleServicingsColumns: TableColumn[] = [
  { id: 'branch_name', name: 'Branch', sortable: true },
  { id: 'servicing_type', name: 'Type', sortable: true },
  { id: 'start_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: true },
];

export const transferListColumns: TableColumn[] = [
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vehicle_type', name: 'Vehicle Type', sortable: true },
  { id: 'agreement_type', name: 'Agreement Type', sortable: true },
  { id: 'mileage', name: 'Mileage', sortable: false },
  { id: 'previous_branch_name', name: 'Previous location', sortable: false },
  { id: 'current_branch_name', name: 'Physical location', sortable: false },
  { id: 'check_in_branch_name', name: 'Checked-in location', sortable: true },
  { id: 'reason', name: 'Reason', sortable: true },
  { id: 'transfer_type', name: 'Type', sortable: true },
  { id: 'transfer_created_date', name: 'Date', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const transferColumns: TableColumn[] = [
  { id: 'transfer_type', name: 'Transfer type', sortable: true },
  { id: 'reason', name: 'Reason', sortable: true },
  { id: 'in_date', name: 'Date in', sortable: true },
  { id: 'out_date', name: 'Date out', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const phvMotVehicleColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver', sortable: true },
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'mot_exp', name: 'MOT expiry', sortable: true },
  { id: 'phv_exp', name: 'PHV expiry', sortable: true },
  { id: 'vehicle_status', name: 'Status', sortable: true },
  { id: 'driver_email', name: 'Email', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const assessmentListColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'dvla', name: 'DVLA', sortable: true },
  { id: 'branch_name', name: 'Assessment branch', sortable: true },
  { id: 'evaluation_type', name: 'Assessment type', sortable: true },
  { id: 'created_date', name: 'Started date', sortable: true },
  { id: 'agent', name: 'Instructor', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
  { id: 'assessment_status', name: 'Assessment status', sortable: true },
];

export const servicingListColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'vehicle_type', name: 'Model', sortable: true },
  { id: 'vehicle_color', name: 'Color', sortable: true },
  { id: 'days_off_road', name: 'Days since transfer', sortable: true },
  { id: 'branch_name', name: 'Checked-in location', sortable: true },
  { id: 'interim_vehicle_vrm', name: 'Interim vehicle', sortable: true },
  { id: 'servicing_type', name: 'Open services', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const servicingListForOneVehicleColumns: TableColumn[] = [
  { id: 'start_date', name: 'Start date', sortable: false },
  { id: 'projected_ttr', name: 'Projected resolution date', sortable: false },
  { id: 'end_date', name: 'End date', sortable: false },
  { id: 'servicing_type', name: 'Type', sortable: false },
  { id: 'branch_name', name: 'Location', sortable: false },
  { id: 'employee name', name: 'Employee', sortable: false },
  { id: 'servicing_status', name: 'Status', sortable: false },
  { id: 'notes', name: 'Notes', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const paymentToolTableColumns: TableColumn[] = [
  { id: 'agreement', name: 'Agreement', sortable: false },
  { id: 'commencement_date', name: 'Start date', sortable: true },
  { id: 'end_date', name: 'End date', sortable: false },
  { id: 'length', name: 'Batch length', sortable: false },
  { id: 'status', name: 'Status', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const congestionChargeColumns: TableColumn[] = [
  { id: 'transaction_date', name: 'Transaction date', sortable: true },
  { id: 'charge_type', name: 'Charge type', sortable: true },
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'cost_centre', name: 'Cost centre', sortable: true },
  { id: 'journey_date', name: 'Journey date', sortable: true },
  { id: 'transaction_id', name: 'Transaction ID', sortable: false },
  { id: 'ordway_order_id', name: 'Ordway order ID', sortable: true },
  { id: 'ordway_invoice_id', name: 'Ordway invoice ID', sortable: true },
  { id: 'congestion_status', name: 'Status', sortable: true },
  { id: 'congestion_amount', name: 'Amount', sortable: true },
];

export const upcomingAftercareColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vehicle', name: 'Vehicle', sortable: true },
  { id: 'vrm', name: 'Registration', sortable: true },
  { id: 'end_date', name: 'Agreement complete', sortable: true },
  { id: 'agreement_type', name: 'Plan', sortable: true },
  { id: 'remaining_balance', name: 'Remaining balance', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const aftercareContactListColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vehicle', name: 'Vehicle', sortable: false },
  { id: 'registration', name: 'Registration', sortable: false },
  { id: 'mobile_number', name: 'Mobile number', sortable: false },
  { id: 'end_date', name: 'Agreement end date', sortable: true },
  { id: 'contact_request_exists', name: 'Contact status', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const aftercareReviewColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vehicle', name: 'Vehicle', sortable: true },
  { id: 'vrm', name: 'Registration', sortable: true },
  { id: 'mobile_number', name: 'Mobile number', sortable: false },
  { id: 'end_date', name: 'Agreement end date', sortable: true },
  { id: 'service', name: 'Service selected', sortable: false },
  { id: 'scrive_signed', name: 'Status', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const aftercareListColumns: TableColumn[] = [
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'vehicle', name: 'Vehicle', sortable: true },
  { id: 'vrm', name: 'Registration', sortable: true },
  { id: 'end_date', name: 'Agreement complete', sortable: true },
  { id: 'service', name: 'Service', sortable: false },
  { id: 'agreement_status', name: 'Status', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const insurancePolicyColumns: TableColumn[] = [
  { id: 'policy_number', name: 'Policy number', sortable: true },
  { id: 'insurance_company', name: 'Insurance company', sortable: true },
  { id: 'insurance_policy_status', name: 'Policy status', sortable: false },
  { id: 'start_date', name: 'Policy start date', sortable: true },
  { id: 'end_date', name: 'Policy end date', sortable: true },
  { id: 'vrm_number_range', name: 'Number plate range', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const excessMileageColumns: TableColumn[] = [
  { id: 'week_commencing', name: 'Week commencing', sortable: true },
  { id: 'total_miles', name: 'Total miles', sortable: true },
  { id: 'excess_miles', name: 'Excess miles', sortable: true },
  { id: 'vrm', name: 'VRM', sortable: true },
  { id: 'driver_name', name: 'Driver name', sortable: true },
  { id: 'contract_type', name: 'Contract type', sortable: true },
  { id: 'billed_on', name: 'Billed on', sortable: true },
  { id: 'status', name: 'Status', sortable: true },
  { id: 'amount', name: 'Amount', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const policyVehiclesColumns: TableColumn[] = [
  { id: 'vrm', name: 'VRM', sortable: false },
  { id: 'current_insurance', name: 'Current insurance', sortable: false },
  { id: 'driver_name', name: 'Driver name', sortable: false },
  { id: 'plan', name: 'Plan', sortable: false },
  { id: 'model', name: 'Vehicle', sortable: false },
  { id: 'vehicle_status', name: 'Vehicle status', sortable: false },
  { id: 'actions', name: 'Actions', sortable: false },
];

export const vehicleModelsColumns: TableColumn[] = [
  { id: 'manufacturer_id', name: 'Manufacturers', sortable: true },
  { id: 'model', name: 'Model', sortable: true },
  { id: 'spec', name: 'Spec', sortable: true },
  { id: 'exterior_colors', name: 'Colour', sortable: true },
  { id: 'fuel_types', name: 'Fuel type', sortable: true },
  { id: 'actions', name: 'Actions', sortable: false },
];
