import {
  addDays,
  addWeeks,
  differenceInMilliseconds,
  format,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  startOfDay,
  toDate,
} from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { IoIosWarning } from 'react-icons/io';
import { getOrdwaySubscriptionDetails } from '../../../api/get/driver.get';
import { updateFreezeBatchWithFreezes, updateHolidayBatchWithHolidays } from '../../../api/patch/driver.patch';
import { createFreezeBatchWithFreezes, createHolidayBatchWithHolidays } from '../../../api/post/driver.post';
import { PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_YELLOW } from '../../../common/styles/Colors';
import { isBurst, isPlus8 } from '../../../consts/plans';
import { COMPLETED_STATUS } from '../../../consts/status';
import { DriverPaymentFreeze, EditFreezePayload, NewFreezePayload, OnSubmitFreezeValues } from '../../../models/freeze';
import {
  BookedHolidayDetails,
  DriverHoliday,
  DriverHolidayDetails,
  DriverPaymentHoliday,
  EditHolidayPayload,
  NewHolidayPayload,
  OnSubmitHolidayValues,
  OrdwayHolidaySubscriptionDetails,
} from '../../../models/holiday';
import { OptionList } from '../../../utils/props';
import { isTimeAfterLondon } from '../../../utils/validations';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { TextArea } from '../../inputs/textArea/textArea';
import { TextFieldLabel } from '../../inputs/textField/textField.styles';
import { FieldGrid } from '../../layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { Text } from '../../text/text';
import { DropDown } from '../../uiControls/dropDown/dropDown';
import { Spinner } from '../../uiControls/spinner/spinner';
import { DatePickerComponent } from '../datePickerComponent/datePickerComponent';
import {
  ConfirmationCircle,
  DropdownContainer,
  Eligibility,
  EligibilityText,
  Errors,
  ReasonContainer,
  SpinnerContainer,
  ValidationText,
  ViolationText,
} from './paymentToolSetUp.styles';
import {
  driver12WeekRule,
  driver4WeekRule,
  driver8WeekRule,
  getUpcomingThursday,
  holidayLengthsNew,
  holidayLengthsOld,
  noHolidayText,
  skipPaymentLength,
} from './utils';

export interface PaymentToolSetUpProps {
  itemToEdit?: DriverPaymentHoliday | DriverPaymentFreeze;
  driverDetails: DriverHolidayDetails;
  bookedDates: BookedHolidayDetails[];
  isHolidayTool?: boolean;
}

export const PaymentToolSetUp = ({
  driverDetails,
  bookedDates,
  itemToEdit,
  isHolidayTool,
}: PaymentToolSetUpProps): JSX.Element => {
  const [subscriptionDetails, setSubscriptionDetails] = useState<OrdwayHolidaySubscriptionDetails>();
  const [subscriptionDetailsLoading, setSubscriptionDetailsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [availablePaymentStartDates, setAvailablePaymentStartDates] = useState<Date[]>([]);
  const [dateSelectedBeforeNextAvailable, setDateSelectedBeforeNextAvailable] = useState<boolean>(false);
  const [nextAvailableDateFromLastCompleted, setNextAvailableDateFromLastCompleted] = useState<Date>();
  const [dateAlreadyBooked, setDateAlreadyBooked] = useState<boolean>(false);
  const [submittingPaymentSetUp, setSubmittingPaymentSetUp] = useState<boolean>(false);
  const [paymentSetUpComfirmed, setPaymentSetUpComfirmed] = useState<
    EditHolidayPayload | NewHolidayPayload | EditFreezePayload | NewFreezePayload
  >();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditHolidayPayload | NewHolidayPayload>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: itemToEdit && {
      ...itemToEdit,
      commencement_date: toDate(new Date(itemToEdit.commencement_date)),
      override_reason:
        itemToEdit?.override_reason
          ?.split('\n')
          ?.find((r) => r.includes('Reason: '))
          ?.split(': ')?.[1] ?? '',
    },
  });

  const commencementDate: string | Date = watch('commencement_date');
  const overrideReason: string | undefined = watch('override_reason');
  const holidayLength: number | undefined = watch('holiday_length');
  const freezeLength: number | undefined = watch('freeze_length');

  const onNewPlan: boolean =
    isPlus8(driverDetails?.agreement_type?.toUpperCase()) || isBurst(driverDetails?.agreement_type?.toUpperCase());

  const holidayLengthOptions: OptionList[] = onNewPlan ? holidayLengthsNew : holidayLengthsOld;

  const totalHolidayAvailable: number =
    (subscriptionDetails?.holiday_eligibility?.rollover ?? 0) +
    (subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining ?? 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstAvailableHolidayDate: Date | undefined =
    subscriptionDetails && new Date(subscriptionDetails?.holiday_eligibility?.firstAvailableHolidayDate);
  const nextAvailableHolidayDate: Date | undefined = getUpcomingThursday();

  const isFirstDateInFuture: boolean | undefined =
    firstAvailableHolidayDate && isAfter(firstAvailableHolidayDate, getUpcomingThursday());
  const isNextDateInFuture: boolean | undefined =
    nextAvailableHolidayDate && isAfter(nextAvailableHolidayDate, getUpcomingThursday());

  const arrearsValue: number = subscriptionDetails ? +subscriptionDetails.arrears : 0;

  const noHolidayAvailable: boolean = (holidayLength ?? 0) > totalHolidayAvailable;

  const inEligibleFor4WeekBreak: boolean =
    holidayLength === 4 && !subscriptionDetails?.holiday_eligibility?.eligibleFor4Weeks;

  const approvalNeeded: boolean =
    inEligibleFor4WeekBreak || noHolidayAvailable || dateSelectedBeforeNextAvailable || arrearsValue > 0;

  const getAvailableStartDates = useCallback(
    ({ subscriptions, billing_schedule_items, holiday_eligibility }: OrdwayHolidaySubscriptionDetails): void => {
      const termEndDate: Date = new Date(
        subscriptions?.current_term_end_date ?? subscriptions?.contract_effective_date
      );
      const availableStartDates: Date[] = [getUpcomingThursday()];

      // Get all available Thursdays up until week before end of agreement end date
      for (let index = 1; index <= billing_schedule_items?.remaining_total_weeks; index += 1) {
        const nextThursday: string = formatISO(addDays(availableStartDates?.at(-1) as Date, 7));
        const daysTillEnd: number = differenceInMilliseconds(termEndDate, new Date(nextThursday));
        const dateForCalendar: Date = new Date(nextThursday);

        if (Math.round(daysTillEnd / (1000 * 60 * 60 * 24)) >= 6) {
          availableStartDates.push(dateForCalendar);
        }
      }

      let bookedDate: BookedHolidayDetails | undefined;
      for (let index = availableStartDates.length - 1; index >= 0; index -= 1) {
        bookedDate = bookedDates?.find(
          (date) =>
            isEqual(
              new Date(date.commencement_date),
              new Date(`${format(new Date(availableStartDates[index]), "yyyy-MM-dd'T'00:00:00.000")}Z`)
            ) && date?.holiday_batch_id !== itemToEdit?.id
        );

        if (bookedDate) {
          availableStartDates.splice(index, 1);
        }
      }

      setAvailablePaymentStartDates(availableStartDates);

      const firstAvailableDate: Date = new Date(holiday_eligibility?.firstAvailableHolidayDate);
      const nextAvailableDate: Date = new Date(holiday_eligibility?.nextAvailableHolidayDate);

      const firstAvailableIndex: number = isHolidayTool
        ? availableStartDates?.findIndex((date) => isSameDay(date, firstAvailableDate))
        : 0;

      const nextAvailableIndex: number = isHolidayTool
        ? availableStartDates?.findIndex((date) => isSameDay(date, nextAvailableDate))
        : 0;

      if (!itemToEdit) {
        if (!onNewPlan) {
          // If driver on old R2B plan
          setValue('commencement_date', availableStartDates?.[0]);
          // New plans
        } else if (isAfter(nextAvailableDate, firstAvailableDate)) {
          setValue('commencement_date', availableStartDates?.[nextAvailableIndex < 0 ? 0 : nextAvailableIndex]);
        } else {
          setValue('commencement_date', availableStartDates?.[firstAvailableIndex < 0 ? 0 : firstAvailableIndex]);
        }
      }

      const completed: DriverHoliday[] = [];
      holiday_eligibility?.currentPeriodHoliday?.batches?.forEach((batch) => {
        batch?.holidays?.forEach((hol) => {
          if (hol.holiday_status === COMPLETED_STATUS) {
            completed.push(hol);
          }
        });
      });

      if (completed.length > 0) {
        setNextAvailableDateFromLastCompleted(addWeeks(new Date(completed.at(-1)?.commencement_date as string), 8));
      } else {
        setNextAvailableDateFromLastCompleted(new Date(holiday_eligibility?.firstAvailableHolidayDate));
      }
    },
    [bookedDates, itemToEdit, onNewPlan, setValue, isHolidayTool]
  );

  const getReasonString = useCallback((): string => {
    let text: string = '';
    if (arrearsValue > 0) {
      text += `* Driver is in arrears of £${arrearsValue.toFixed(2)}\n`;
    }
    if (dateSelectedBeforeNextAvailable) {
      if (isBefore(startOfDay(commencementDate as Date), startOfDay(firstAvailableHolidayDate as Date))) {
        text += `${driver12WeekRule}\n`;
      } else {
        text += `${driver8WeekRule}\n`;
      }
    }
    if (inEligibleFor4WeekBreak) {
      text += `${driver4WeekRule}\n`;
    }
    if (noHolidayAvailable) {
      text += noHolidayText;
    }
    return text;
  }, [
    arrearsValue,
    inEligibleFor4WeekBreak,
    dateSelectedBeforeNextAvailable,
    firstAvailableHolidayDate,
    noHolidayAvailable,
    commencementDate,
  ]);

  // Type Guards
  const isHolidayValues = (values: OnSubmitHolidayValues | OnSubmitFreezeValues): values is OnSubmitHolidayValues => {
    return (values as OnSubmitHolidayValues).holiday_length !== undefined;
  };

  const onSubmitClick = useCallback(
    (values: OnSubmitHolidayValues | OnSubmitFreezeValues): void => {
      let reason: string | undefined;
      if (!isHolidayTool) {
        reason = `Reason: ${values.override_reason}`;
      } else if (approvalNeeded && values.override_reason) {
        reason = `${getReasonString()}\nReason: ${values.override_reason}`;
      }
      const newPaymentToolParams: NewHolidayPayload | NewFreezePayload = {
        driver_id: driverDetails?.id,
        agreement_id: driverDetails?.agreement_id,
        [isHolidayTool ? 'holiday_length' : 'freeze_length']: isHolidayValues(values)
          ? Number(values.holiday_length)
          : Number(values.freeze_length),
        commencement_date: format(values.commencement_date as Date, 'yyyy-MM-dd'),
        override_reason: reason,
      };
      setSubmittingPaymentSetUp(true);
      if (itemToEdit) {
        const updatePaymentToolParams: EditHolidayPayload | EditFreezePayload = {
          id: itemToEdit?.id,
          agreement_id: driverDetails?.agreement_id,
          driver_id: driverDetails?.id,
          [isHolidayTool ? 'holiday_length' : 'freeze_length']: isHolidayValues(values)
            ? Number(values.holiday_length)
            : Number(values.freeze_length),
          commencement_date: format(values.commencement_date as Date, 'yyyy-MM-dd'),
          override_reason: reason,
        };
        const apiUpdate = isHolidayTool ? updateHolidayBatchWithHolidays : updateFreezeBatchWithFreezes;
        apiUpdate(updatePaymentToolParams)
          .then(() => {
            setSubmittingPaymentSetUp(false);
            setPaymentSetUpComfirmed(updatePaymentToolParams);
          })
          .catch(() => setSubmittingPaymentSetUp(false));
      } else {
        const apiCreate = isHolidayTool ? createHolidayBatchWithHolidays : createFreezeBatchWithFreezes;
        apiCreate(newPaymentToolParams)
          .then(() => {
            setSubmittingPaymentSetUp(false);
            setPaymentSetUpComfirmed(newPaymentToolParams);
          })
          .catch(() => setSubmittingPaymentSetUp(false));
      }
    },
    [driverDetails, itemToEdit, approvalNeeded, getReasonString, isHolidayTool]
  );

  useEffect(() => {
    const commencementDates: string[] = [];
    if (holidayLength && commencementDate) {
      for (let index = 0; index < holidayLength; index += 1) {
        const nextDate: Date = addDays(new Date(commencementDate), 7 * index);
        commencementDates.push(`${format(nextDate, 'yyyy-MM-ddT00:00:00.000')}Z`);
      }

      setDateAlreadyBooked(
        commencementDates.some((comDate) =>
          bookedDates?.find((date) => date.commencement_date === comDate && date.holiday_batch_id !== itemToEdit?.id)
        )
      );
    }
  }, [holidayLength, bookedDates, commencementDate, itemToEdit]);

  useEffect(() => {
    // Check if the date selected is before the first available date according to new rules
    // Can only take a break after 12 weeks into agreement
    // There should be an 8 week gap between holidays
    if (onNewPlan && commencementDate) {
      const isBeforeFirstAvailableHoliday = isBefore(
        startOfDay(new Date(commencementDate)),
        startOfDay(firstAvailableHolidayDate as Date)
      );

      // Check if commencementDate is before the next available date based on condition
      const isBeforeNextAvailableDate = isBefore(
        startOfDay(new Date(commencementDate)),
        startOfDay(
          new Date(itemToEdit ? (nextAvailableDateFromLastCompleted as Date) : (nextAvailableHolidayDate as Date))
        )
      );
      if (isBeforeFirstAvailableHoliday || isBeforeNextAvailableDate) {
        setDateSelectedBeforeNextAvailable(true);
      } else {
        setDateSelectedBeforeNextAvailable(false);
      }
    }
  }, [
    commencementDate,
    onNewPlan,
    firstAvailableHolidayDate,
    nextAvailableHolidayDate,
    itemToEdit,
    nextAvailableDateFromLastCompleted,
  ]);

  useEffect(() => {
    if (driverDetails.ordway_customer_id && driverDetails.ordway_subscription_id && !subscriptionDetails) {
      setSubscriptionDetailsLoading(true);
      getOrdwaySubscriptionDetails(driverDetails?.ordway_customer_id, driverDetails?.ordway_subscription_id).then(
        ({ data }) => {
          setSubscriptionDetails(data);
          if (!selectedPlan) {
            setSelectedPlan(data?.subscriptions?.plans[0]?.effective_price);
            getAvailableStartDates(data);
          }
          setSubscriptionDetailsLoading(false);
        }
      );
    }
  }, [
    driverDetails.ordway_customer_id,
    driverDetails.ordway_subscription_id,
    selectedPlan,
    subscriptionDetails,
    getAvailableStartDates,
  ]);

  if (subscriptionDetailsLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={PRIMARY_PURPLE} size={24} />
      </SpinnerContainer>
    );
  }

  if (paymentSetUpComfirmed) {
    return (
      <>
        <Eligibility>
          <ConfirmationCircle size={32} color={PRIMARY_GREEN} />
          <EligibilityText>
            {itemToEdit
              ? `${isHolidayTool ? 'Holiday' : 'Payment skip'} successfully updated`
              : `${isHolidayTool ? 'Holiday' : 'Payment skip'} successfully created`}
          </EligibilityText>
        </Eligibility>
        <FieldGrid
          numColumns={3}
          headers={['Driver:', 'Commencement date:', 'Length:']}
          values={[
            driverDetails.driver_name,
            format(new Date(paymentSetUpComfirmed.commencement_date as string), 'dd MMM yyyy'),
            isHolidayValues(paymentSetUpComfirmed)
              ? paymentSetUpComfirmed.holiday_length ?? 'N/A'
              : paymentSetUpComfirmed.freeze_length ?? 'N/A',
          ]}
        />
      </>
    );
  }

  return (
    <>
      {subscriptionDetails && (
        <>
          <Text variant="body5" color={PRIMARY_PURPLE} weight={800}>
            Ordway summary
          </Text>
          <FieldGrid
            styled={{ paddingTop: 16 }}
            numColumns={3}
            headers={['Subscription ID:', 'Customer ID:', 'Status:']}
            values={[
              driverDetails?.ordway_subscription_id ?? '-',
              driverDetails?.ordway_customer_id ?? '-',
              subscriptionDetails?.subscriptions?.status,
            ]}
          />
          <FieldGrid
            numColumns={3}
            headers={['Renewal term:', 'Customer name:', 'Contract term:']}
            values={[
              subscriptionDetails?.subscriptions?.renewal_term,
              driverDetails.driver_name,
              subscriptionDetails?.subscriptions?.contract_term,
            ]}
          />
          <FieldGrid
            numColumns={3}
            headers={['Effective price:', 'Current term start date:', 'Current term end date:']}
            values={[
              `£${selectedPlan}`,
              subscriptionDetails?.subscriptions?.current_term_start_date
                ? format(subscriptionDetails?.subscriptions?.current_term_start_date, 'dd MMM yyyy')
                : format(subscriptionDetails?.subscriptions?.contract_effective_date, 'dd MMM yyyy'),
              subscriptionDetails?.subscriptions?.current_term_end_date
                ? format(subscriptionDetails?.subscriptions?.current_term_end_date, 'dd MMM yyyy')
                : 'N/A',
            ]}
          />
          <FieldGrid
            numColumns={1}
            headers={['Remaining contract balance:']}
            values={[`£${subscriptionDetails?.billing_schedule_items?.unbilled_subscription_amount ?? '0.00'}`]}
          />

          {isHolidayTool && (
            <>
              <Text variant="body5" color={PRIMARY_PURPLE} weight={800}>
                Holiday summary
              </Text>
              <FieldGrid
                styled={{ paddingTop: 16 }}
                numColumns={2}
                headers={['Annual holiday allowance:', 'Current unpaid balance:']}
                values={[
                  subscriptionDetails?.holiday_eligibility?.totalHolidayAllowance,
                  `£${subscriptionDetails?.arrears}`,
                ]}
              />

              {subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday ? (
                <FieldGrid
                  numColumns={1}
                  headers={[
                    `Holiday weeks taken in previous period: ${format(
                      new Date(subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.periodStartDate),
                      'dd MMM yyyy'
                    )} 
          to ${format(new Date(subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.periodEndDate), 'dd MMM yyyy')}`,
                  ]}
                  values={[subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.numberOfWeeksTaken]}
                />
              ) : (
                <FieldGrid
                  numColumns={1}
                  headers={[
                    `Holiday weeks taken in this period (including upcoming): ${format(
                      new Date(subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodStartDate),
                      'dd MMM yyyy'
                    )} 
            to ${format(new Date(subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodEndDate), 'dd MMM yyyy')}`,
                  ]}
                  values={[subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.numberOfWeeksTaken]}
                />
              )}

              <FieldGrid
                numColumns={1}
                headers={[
                  onNewPlan
                    ? 'Holiday remaining:'
                    : `Holiday accrued this period: ${format(
                        new Date(subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodStartDate),
                        'dd MMM yyyy'
                      )} 
        to today`,
                ]}
                values={[
                  subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining < 0
                    ? 0
                    : subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining,
                ]}
              />

              {!onNewPlan && (
                <>
                  <FieldGrid
                    numColumns={subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday ? 2 : 1}
                    headers={
                      subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday
                        ? ['Rollover weeks available:', 'Total holiday available:']
                        : ['Total holiday available:']
                    }
                    values={
                      subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday
                        ? [
                            subscriptionDetails?.holiday_eligibility?.rollover,
                            totalHolidayAvailable < 0 ? 0 : totalHolidayAvailable,
                          ]
                        : [totalHolidayAvailable < 0 ? 0 : totalHolidayAvailable]
                    }
                  />
                </>
              )}
            </>
          )}

          {isHolidayTool && onNewPlan && (isFirstDateInFuture || isNextDateInFuture) && (
            <ValidationText>
              * A valid reason is needed if selecting a date before&nbsp;
              {itemToEdit ? (
                format(nextAvailableDateFromLastCompleted as Date, 'dd MMM yyyy')
              ) : (
                <>
                  {nextAvailableHolidayDate && isAfter(nextAvailableHolidayDate, firstAvailableHolidayDate as Date)
                    ? format(nextAvailableHolidayDate, 'dd MMM yyyy')
                    : format(firstAvailableHolidayDate as Date, 'dd MMM yyyy')}
                </>
              )}
            </ValidationText>
          )}

          <Text variant="body5" color={PRIMARY_PURPLE} weight={800}>
            {itemToEdit ? 'Edit' : 'New'} {isHolidayTool ? 'holiday' : 'payment skip'}
          </Text>
          <FlexLayout styled={{ width: '100%', margin: '16px 0 30px' }} gap={20} itemsX="space-between">
            <DatePickerComponent
              control={control}
              name="commencement_date"
              label={`${isHolidayTool ? 'Holiday' : 'Payment skip'} commencement date:`}
              includedDates={availablePaymentStartDates}
              rules={{
                required: true,
              }}
              errors={errors}
              dayClassName={(date) => {
                const dateStartOfDay = startOfDay(new Date(date));
                if (itemToEdit) {
                  if (isBefore(dateStartOfDay, startOfDay(nextAvailableDateFromLastCompleted as Date))) {
                    return 'needsApproval';
                  }
                  return 'approved';
                }

                if (
                  isBefore(dateStartOfDay, startOfDay(new Date(firstAvailableHolidayDate as Date))) ||
                  isBefore(dateStartOfDay, startOfDay(new Date(nextAvailableHolidayDate)))
                ) {
                  return 'needsApproval';
                }

                return 'approved';
              }}
            />
            <DropdownContainer>
              <TextFieldLabel $isRequired>Number of weeks:</TextFieldLabel>
              <DropDown
                name="holiday_length"
                options={isHolidayTool ? holidayLengthOptions : skipPaymentLength}
                placeholder={holidayLength || freezeLength || 'Length'}
                control={control as unknown as Control<FieldValues>}
                required={{
                  required: true,
                }}
                error={errors.holiday_length}
              />
            </DropdownContainer>
          </FlexLayout>

          {approvalNeeded && !!isHolidayTool && (
            <ReasonContainer>
              <Eligibility>
                <IoIosWarning size={24} color={STATUS_YELLOW} />
                <ViolationText>
                  The driver does not meet all the criteria for a holiday, so a reason needs to be provided why an
                  exception was made.
                </ViolationText>
              </Eligibility>

              <TextArea
                label="Override reason"
                {...register('override_reason')}
                name="override_reason"
                placeholder="Override reason"
                required={approvalNeeded}
                error={errors?.override_reason}
              />
            </ReasonContainer>
          )}

          {!isHolidayTool && (
            <ReasonContainer>
              <TextArea
                label="Notes"
                {...register('override_reason')}
                name="override_reason"
                placeholder="Notes"
                required={true}
                error={errors?.override_reason}
              />
            </ReasonContainer>
          )}

          {!!isHolidayTool && (
            <Errors>
              {(isFirstDateInFuture || isNextDateInFuture) && onNewPlan && dateAlreadyBooked && (
                <ViolationText>
                  The holiday dates selected conflict with an already booked holiday. Please choose a different
                  commencement date or change the holiday length.
                </ViolationText>
              )}
              {dateSelectedBeforeNextAvailable && commencementDate && (
                <ViolationText>
                  {isBefore(startOfDay(new Date(commencementDate)), startOfDay(firstAvailableHolidayDate as Date))
                    ? driver12WeekRule
                    : driver8WeekRule}
                </ViolationText>
              )}
              {noHolidayAvailable && <ViolationText>{noHolidayText}</ViolationText>}
              {arrearsValue > 0 && (
                <ViolationText>{`* Driver's account is in arrears of £${subscriptionDetails?.arrears}`}</ViolationText>
              )}
              {inEligibleFor4WeekBreak && <ViolationText>{driver4WeekRule}</ViolationText>}
            </Errors>
          )}

          <PrimaryButton
            onClick={handleSubmit(onSubmitClick)}
            isProcessing={submittingPaymentSetUp}
            styled={{ padding: '0px 38px' }}
            disabled={
              (isHolidayTool && approvalNeeded && !overrideReason) ||
              !commencementDate ||
              dateAlreadyBooked ||
              (isTimeAfterLondon(15, 30) &&
                isSameDay(startOfDay(new Date(commencementDate)), startOfDay(addDays(new Date(), 1))))
            }
          >
            {itemToEdit ? 'Update' : 'Apply'}
          </PrimaryButton>
        </>
      )}
    </>
  );
};
