import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useCallback, useContext, useEffect, useState } from 'react';
import { BsArrowRepeat, BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { useGetAndMergeExcessMileageChargesQuery } from '../../../../api/listAndMerge/listAndMergeExcessMileageChargesApiSlice';
import {
  ReassignExcessMileagePayload,
  reassignExcessMileagesCharges,
  rejectPendingExcessMileagesCharges,
} from '../../../../api/patch/additionalCharges.patch';
import { processPendingExcessMileagesCharges } from '../../../../api/post/additionalCharges.post';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../common/styles/Colors';
import { CHARGES_PENDING } from '../../../../consts/additionalCharges';
import { useDateRangeFilter } from '../../../../hooks/useDateRangeFilter';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { Excess } from '../../../../models/excessMileage';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../uiComponents/table/table';
import { excessMileageColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { additionalChargeStatuses } from '../../../../uiComponents/table/tableFilters/tableFilterOptions';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../../uiComponents/table/tableFilters/tableFilters';
import { FilterInput } from '../../../../uiComponents/table/tableFilters/tableFilters.styles';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
  TableTagCell,
  TableTextCell,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../../uiComponents/text/text';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Checkbox } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import { APP_CONTEXT } from '../../../../utils/context';
import { OptionList } from '../../../../utils/props';
import { ReassignForm } from './reassignForm/reassignForm';

export const ExcessMileage = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [selectedStatus, setSelectedStatus] = useState<OptionList[]>([]);
  const [selectedArchived, setSelectedArchived] = useState<OptionList[]>([]);
  const [disableProcessBtn, setDisableProcessBtn] = useState<boolean>(false);
  const [openReassignPopup, setOpenReassignPopup] = useState<boolean>(false);
  const [selectedExcessMileage, setSelectedExcessMileage] = useState<Excess>();
  const [excessListData, setExcessListData] = useState<Excess[]>([]);
  const [billedon, setBilledon] = useState<string>('');

  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
    setSortAscending,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=weeklymileage.created_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);

  const {
    data: excessMileageCharges,
    isLoading: isExcessMileageChargesLoading,
    isFetching: isExcessMileageChargesFetching,
    refetch,
  } = useGetAndMergeExcessMileageChargesQuery({ query: queryStringState, refetch: refetchData });

  const onSelect = (charges: Excess[], index: number, checked: boolean) => {
    const data = charges;
    data[index].selected = checked;
    setExcessListData(data);
    handleGetExcessMileageResponse(data.length, data);
  };

  const processCharge = (excess: Excess) => {
    if (excess?.charge_status !== CHARGES_PENDING) return;
    const chargesIds = [excess.charge_id];
    processPendingExcessMileagesCharges({ chargesIds: chargesIds })
      .then((response: AxiosResponse<{ success: boolean; message: string }>) => {
        if (!response.data.success) {
          throw new Error(response.data.message);
        }
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Selected pending excess mileage charge have been processed',
          isAlert: true,
        });
        refetchToFirstPage();
      })
      .catch((err: Error) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: `${err}. Failed to process selected excess mileage charge`,
          isAlert: true,
        });
      });
  };

  const rejectCharge = (excess: Excess) => {
    if (excess?.charge_status !== CHARGES_PENDING) return;
    const chargesIds = [excess.charge_id];
    rejectPendingExcessMileagesCharges({ chargesIds: chargesIds })
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Selected pending excess mileage charge have been rejected',
          isAlert: true,
        });
        refetchToFirstPage();
      })
      .catch((err: Error) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: `${err}. Failed to reject selected excess mileage charges`,
          isAlert: true,
        });
      });
  };

  const reassignCharge = (submitValues: ReassignExcessMileagePayload) => {
    reassignExcessMileagesCharges(submitValues)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Selected pending excess mileage charge have been reassigned',
          isAlert: true,
        });
        refetchToFirstPage();
        setOpenReassignPopup(false);
      })
      .catch((err: Error) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: `${err}. Failed to reassign selected excess mileage charges`,
          isAlert: true,
        });
        setOpenReassignPopup(false);
      });
  };

  const processCharges = () => {
    const chargesIds: string[] = [];
    excessListData?.forEach((element: Excess) => {
      if (element.selected) {
        chargesIds.push(element.charge_id);
      }
    });
    if (chargesIds.length > 0) {
      processPendingExcessMileagesCharges({ chargesIds: chargesIds })
        .then(() => {
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Selected pending excess mileage charges have been processed',
            isAlert: true,
          });
          refetchToFirstPage();
        })
        .catch((err: Error) => {
          Notification({
            type: 'error',
            title: 'Error',
            message: `${err}. Failed to process selected excess mileage charges`,
            isAlert: true,
          });
        });
    } else {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'No pending excess mileage charges were selected',
        isAlert: true,
      });
    }
  };

  const rejectCharges = () => {
    const chargesIds: string[] = [];
    excessListData?.forEach((element: Excess) => {
      if (element.selected) {
        chargesIds.push(element.charge_id);
      }
    });
    if (chargesIds.length > 0) {
      rejectPendingExcessMileagesCharges({ chargesIds: chargesIds })
        .then((response: AxiosResponse<{ success: boolean; message: string }>) => {
          if (!response.data.success) {
            throw new Error(response.data.message);
          }
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Selected pending excess mileage charges have been rejected',
            isAlert: true,
          });
          refetchToFirstPage();
        })
        .catch((err: Error) => {
          Notification({
            type: 'error',
            title: 'Error',
            message: `${err}. Failed to reject selected excess mileage charges`,
            isAlert: true,
          });
        });
    } else {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'No pending excess mileage charges were selected',
        isAlert: true,
      });
    }
  };

  const handleGetExcessMileageResponse = useCallback(
    (count: number, charges: Excess[]) => {
      const excessRows = charges?.map((excess: Excess, index: number) => {
        return {
          rowData: { data: excess },
          cells: [
            <Checkbox
              checked={excess?.selected}
              name="select"
              onCheck={(checked) => onSelect(charges, index, checked)}
              disabled={excess?.charge_status !== CHARGES_PENDING}
            />,
            <TableTextCell value={excess?.week_commencing || '-'} />,
            <TableTextCell value={excess?.total_miles || '-'} />,
            <TableTextCell value={excess?.excess_miles || '-'} />,
            <TableTextCell value={excess?.vrm || '-'} />,
            <TableTextCell value={excess?.driver_name || '-'} />,
            <TableTextCell value={excess?.contract_type || '-'} />,
            <TableTextCell value={excess?.billed_on ? format(new Date(excess?.billed_on), 'dd MMM yyyy') : '-'} />,
            <FlexLayout itemsX="start">
              <TableTagCell tags={[excess?.charge_status]} />
            </FlexLayout>,
            <TableTextCell value={excess?.amount || '-'} />,
            <FlexLayout gap={16}>
              <ActionIcon
                disabled={excess?.charge_status !== CHARGES_PENDING}
                onClick={() => processCharge(excess)}
                icon={<BsCheckCircle size={24} color={PRIMARY_PURPLE} />}
                tooltip="Process charge"
              />
              <ActionIcon
                disabled={excess?.charge_status !== CHARGES_PENDING}
                onClick={() => rejectCharge(excess)}
                icon={<BsXCircle size={24} color={PRIMARY_PURPLE} />}
                tooltip="Reject charge"
              />
              <ActionIcon
                icon={
                  <BsArrowRepeat
                    onClick={() => {
                      setSelectedExcessMileage(excess);
                      setOpenReassignPopup(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Reassign charge"
                disabled={excess?.charge_status !== CHARGES_PENDING}
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(excessRows);
      setExcessListData(charges);
      setTotalRows(count);
      setDisableProcessBtn(charges?.filter((charge) => charge.selected).length === 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTableData, setTotalRows]
  );

  const filters: FilterItem[] = [
    {
      name: 'week-commencing-date-range',
      element: (
        <DateRangeFilter
          title="Week commencing date range:"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
    {
      name: 'billed-on',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Billed on:
          </Text>
          <FilterInput type="date" onChange={(e) => setBilledon(e.target.value)} />
        </div>
      ),
    },
    {
      name: 'charge_status',
      element: (
        <DropDownFilter
          name="status"
          placeholder="Select status"
          options={additionalChargeStatuses}
          multiValues={selectedStatus}
          title="Status:"
          onChange={(items) => setSelectedStatus(items as OptionList[])}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);

      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('excessMileagePage');
    setPageTitle('Excess Mileage');
    setSortingColumn('weeklymileage.created_date');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      {
        columnName: 'start_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
      {
        columnName: 'billedon',
        options: { value: billedon ?? '', label: billedon ?? '' },
      },
      { columnName: 'charge_status', options: selectedStatus },
    ]);
  }, [setTableFilters, selectedStatus, selectedArchived, dateRangeFilter, billedon]);

  useEffect(() => {
    if (excessMileageCharges) {
      const data = excessMileageCharges.data.map((item) => {
        return { ...item, selected: false };
      });
      setExcessListData(data);
      handleGetExcessMileageResponse(excessMileageCharges.count, data);
    }
  }, [excessMileageCharges, queryStringState, handleGetExcessMileageResponse]);

  const onClearClick = useCallback(() => {
    setDateRangeFilter([]);
    setSelectedStatus([]);
    setSelectedArchived([]);
    setBilledon('');
  }, [setDateRangeFilter]);

  const onSelectAllClick = (checked: boolean) => {
    const allSelected = excessListData.map((excess: Excess) => {
      excess.selected = checked && excess.charge_status === CHARGES_PENDING;
      return excess;
    });
    setExcessListData(allSelected);
    handleGetExcessMileageResponse(allSelected.length, allSelected);
  };

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    goToPageNumber(0);
    onClearClick();
  }, [goToPageNumber, onClearClick, refetch, defaultString, queryStringState]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isExcessMileageChargesFetching || isExcessMileageChargesLoading}
        header="Excess mileage"
        secondaryBtnText="Reject charges"
        onSecondaryBtnClick={() => rejectCharges()}
        disableSecondaryBtn={disableProcessBtn}
        tertiaryBtnText="Process charges"
        onTertiaryBtnClick={() => processCharges()}
        disableTertiaryBtn={disableProcessBtn}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={excessMileageColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={onClearClick}
        selectable
        onSelectAllClick={onSelectAllClick}
      />

      <Modal
        title="Reassign"
        open={openReassignPopup}
        onClose={() => {
          setOpenReassignPopup(false);
        }}
        showClose={true}
        styled="60vw"
      >
        <ReassignForm
          excess={selectedExcessMileage}
          onClose={() => {
            setOpenReassignPopup(false);
          }}
          onSubmit={reassignCharge}
        />
      </Modal>
    </>
  );
};
