import React, { useState, useCallback } from 'react';
import { GiCancel } from 'react-icons/gi';
import { isHoliday } from './utils';
import { UPCOMING_STATUS } from '../../../../../consts/status';
import { ConfirmationModal } from '../../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { ActionIcon } from '../../../../../uiComponents/table/actionIcon/actionIcon';
import { NoResults } from '../../../../../uiComponents/table/noResults/noResults';
import { TableRowGrid } from '../../../../../uiComponents/table/table.styles';
import { TableTagCell, TableTextCell } from '../../../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../../../uiComponents/text/text';
import { DriverHoliday, DriverPaymentHoliday } from '../../../../../models/holiday';
import { PRIMARY_PURPLE, SECONDARY_GRAY_20, SECONDARY_PURPLE_70 } from '../../../../../common/styles/Colors';
import { renderNotification } from '../../../../../utils/utils';
import { DriverFreeze, DriverPaymentFreeze } from '../../../../../models/freeze';
import { format } from 'date-fns';

const ActionRow = <T extends DriverHoliday | DriverFreeze>({
  action,
  onClick,
  statusKey,
  statusCode,
}: {
  action: T;
  onClick: (e: React.MouseEvent) => void;
  statusKey: keyof T;
  statusCode: string;
}) => (
  <TableRowGrid $rowClickable={false} $borderColur={SECONDARY_GRAY_20} key={action?.id} gap={8} template={4}>
    <TableTagCell tags={[action[statusKey] as string]} />
    <TableTextCell value={format(new Date(action.commencement_date), 'dd MMM yyyy')} />
    {action[statusKey] === statusCode && (
      <ActionIcon
        onClick={onClick}
        icon={<GiCancel size={24} color={SECONDARY_PURPLE_70} />}
        tooltip={`Cancel individual ${statusKey === 'holiday_status' ? 'holiday' : 'freeze'}`}
      />
    )}
  </TableRowGrid>
);

interface RendeActionListProps<T extends DriverPaymentHoliday | DriverPaymentFreeze> {
  actionsData: T;
  getDriverActionSummary: () => void;
  fetchData: () => void;
  apiCancelBatchItem: (actionId: string, itemId: string) => Promise<void>;
  isHolidayAction?: boolean;
}

export const RenderActionList = <T extends DriverPaymentHoliday | DriverPaymentFreeze>({
  actionsData,
  getDriverActionSummary,
  fetchData,
  apiCancelBatchItem,
  isHolidayAction,
}: RendeActionListProps<T>): JSX.Element => {
  const [isIndividualModal, setIsIndividualModal] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<DriverHoliday | DriverFreeze | null>(null);

  const cancelIndividualAction = useCallback(
    async (action: DriverHoliday | DriverFreeze) => {
      try {
        await apiCancelBatchItem(actionsData?.id, action?.id);
        fetchData();
        getDriverActionSummary();
        renderNotification('success', 'Success', `Successfully cancelled ${isHolidayAction ? 'holiday' : 'freeze'}`);
        return true;
      } catch (err) {
        renderNotification(
          'error',
          'Error',
          `Failed to cancel ${isHolidayAction ? 'holiday' : 'freeze'}: ${err}`,
          false
        );
      }
    },
    [actionsData, fetchData, getDriverActionSummary, apiCancelBatchItem, isHolidayAction]
  );

  return (
    <>
      <TableRowGrid $rowClickable={false} $borderColur={SECONDARY_GRAY_20} gap={8} template={4}>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Status
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Commencement date
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Actions
        </Text>
      </TableRowGrid>
      {isHoliday(actionsData) ? (
        actionsData.holidays?.length > 0 ? (
          actionsData.holidays?.map((holiday: DriverHoliday) => (
            <ActionRow
              key={holiday.id}
              action={holiday}
              onClick={(e) => {
                e.stopPropagation();
                setIsIndividualModal(true);
                setSelectedAction(holiday);
              }}
              statusKey="holiday_status"
              statusCode={UPCOMING_STATUS}
            />
          ))
        ) : (
          <NoResults />
        )
      ) : actionsData.freezes?.length > 0 ? (
        actionsData.freezes?.map((freeze: DriverFreeze) => (
          <ActionRow
            key={freeze.id}
            action={freeze}
            onClick={(e) => {
              e.stopPropagation();
              setIsIndividualModal(true);
              setSelectedAction(freeze);
            }}
            statusKey="status_code"
            statusCode={UPCOMING_STATUS}
          />
        ))
      ) : (
        <NoResults />
      )}
      {selectedAction && (
        <ConfirmationModal
          title={`Are you sure you want to cancel this individual ${isHolidayAction ? 'holiday' : 'freeze'}?`}
          isOpen={isIndividualModal}
          onClose={() => {
            setIsIndividualModal(false);
            setSelectedAction(null);
          }}
          onConfirm={() => {
            cancelIndividualAction(selectedAction);
            setIsIndividualModal(false);
          }}
          confirmButtonCaption={'Yes'}
          closeButtonCaption={'No'}
        />
      )}
    </>
  );
};
