import { apiSlice } from '../../api/baseApi';

export const vehicleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFinanceSource: builder.query({
      query: () => 'vehicle-finance-source/list',
    }),
    getVehicleBatchById: builder.query({
      query: ({ query }) => `vehicle-batch/${query}`,
      keepUnusedDataFor: 0.0001,
    }),
    createVehicleBatch: builder.mutation({
      query: (body) => ({
        url: 'vehicle-batch',
        method: 'POST',
        body,
      }),
    }),
    editVehicleBatch: builder.mutation({
      query: (body) => ({
        url: 'vehicle-batch',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetFinanceSourceQuery,
  useGetVehicleBatchByIdQuery,
  useCreateVehicleBatchMutation,
  useEditVehicleBatchMutation,
} = vehicleApiSlice;
