import React, { useCallback, useEffect } from 'react';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { Table } from '../../../../uiComponents/table/table';
import { driverFreshdeskColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { TableTextCell, TableTagCell } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { getFreshdeskDetails } from '../../../../api/get/driver.get';
import { FreshdeskTicket } from '../../../../models/driver';
import { format } from 'date-fns';

export interface DriverFreshdeskTicketsProps {
  email: string;
}

export const DriverFreshdeskTickets = ({ email }: DriverFreshdeskTicketsProps) => {
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetFreshdeskDadtaResponse = useCallback(
    (tickets: FreshdeskTicket[]) => {
      const ticketRows = tickets?.map((ticket: FreshdeskTicket) => {
        return {
          rowData: { data: ticket },
          cells: [
            <TableTextCell value={ticket?.id || '-'} />,
            <TableTextCell value={ticket?.subject || '-'} />,
            <TableTextCell value={ticket?.group_name || '-'} />,
            <TableTextCell value={ticket?.agent_name || '-'} />,
            <TableTextCell value={ticket?.created_at ? format(new Date(ticket?.created_at), 'dd MMM yyyy') : '-'} />,
            <TableTextCell value={ticket?.type || '-'} />,
            // Show the first tag only
            ticket?.tags.length && ticket?.tags.length > 0 ? (
              <TableTagCell tags={[ticket?.tags[0]]} />
            ) : (
              <TableTextCell value="-" />
            ),
            ticket?.status ? <TableTagCell tags={[ticket?.status]} /> : <TableTextCell value="-" />,
            ticket?.priority ? <TableTagCell tags={[ticket?.priority]} /> : <TableTextCell value="-" />,
          ],
        };
      });
      setTableData(ticketRows);
      setTotalRows(ticketRows?.length);
    },
    [setTotalRows, setTableData]
  );

  const fetchFreshdeskData = useCallback(() => {
    if (email) {
      getFreshdeskDetails(email, 'limit=10').then((response: { data: FreshdeskTicket[] }) => {
        handleGetFreshdeskDadtaResponse(response.data);
      });
    }
  }, [email, handleGetFreshdeskDadtaResponse]);

  const applyFilters = useCallback(() => {
    setTableData(undefined);
    fetchFreshdeskData();
  }, [fetchFreshdeskData, setTableData]);

  useEffect(() => {
    fetchFreshdeskData();
  }, [setTableFilters, fetchFreshdeskData]);

  return (
    <Table
      variant="compact"
      tableTheme="purple"
      embedded
      onColumnHeaderClick={() => applyFilters()}
      sortAscending={sortAscending}
      columns={driverFreshdeskColumns}
      rows={tableData}
      totalRows={totalRows}
      rowsPerPage={numRowsPerPage}
      currentPageNumber={pageNumber}
      sortingColumn={sortingColumn}
      filters={[]}
      filterQuery={filterQuery}
      goToPage={(pageNumber: number) => {
        goToPageNumber(pageNumber);
        applyFilters();
      }}
      onNumRowsPerPageChange={(value: number) => {
        setNumRowsPerPage(value);
        goToPageNumber(0);
        applyFilters();
      }}
    />
  );
};
