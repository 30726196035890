import api from '../../utils/api';
import {
  AssignCourtesy,
  ChecklistItem,
  DamageLogPayload,
  ImageArrayType,
  NewConditionReport,
  ReturnCourtesy,
  TransferReason,
} from '../../models/transfer';
import { DriverTagDropDown } from '../../models/driver';
import { VehicleStatus } from '../../models/vehicle';
/**
 * Submit condition reports
 * @category API/POST
 * @param {object} payload - Data for submitting a condition report
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * vehicle_id,
 * branch_id,
 * reason,
 * mileage,
 * fuel,
 * checkList,
 * files
 * }
 * createConditionReportDraft(body).then((res) => {
 * // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createConditionReportDraft = (payload: NewConditionReport) => api.post('condition-report/draft', payload);

/**
 * Submit condition reports
 * @category API/POST
 * @param {object} payload - Data for submitting a condition report
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * vehicle_id,
 * branch_id,
 * reason,
 * mileage,
 * fuel,
 * checkList,
 * files
 * }
 * createConditionReport(body).then((res) => {
 * // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createConditionReport = (payload: NewConditionReport) => api.post('condition-report', payload);

/**
 * Submit condition reports and create new used agreement
 * @category API/POST
 * @param {object} payload - Data for submitting a condition report
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * vehicle_id,
 * branch_id,
 * reason,
 * mileage,
 * fuel,
 * checkList,
 * files
 * }
 * createNewUsedAgreement(body).then((res) => {
 * // Promise resolved
 * });
 */
export const createNewUsedAgreement = (payload: NewConditionReport) =>
  api.post('condition-report/new-used-agreement', payload);

export const terminateAgreement = (payload: {
  reason: string;
  notes: string;
  fuel_level: number;
  screen_wash_level: number;
  checklist: ChecklistItem[];
  available_refund_amount: string | null | undefined;
  application_id: string;
  vrm: string;
  oil_level: number;
  arrears_end_of_contract: string | null | undefined;
  driver_tag?: DriverTagDropDown;
  total_for_future_orders: string | null | undefined;
  agreement_id: string | null;
  branch_id: string;
  agreement_status?: string;
  files: ImageArrayType[];
  vehicle_status: VehicleStatus;
  transfer_type: string;
  id?: string;
  vehicle_id: string;
  mileage: number;
}) => api.post('condition-report/terminate-agreement', payload);

/**
 * use previous condition report instead of redo everything
 * @category API/POST
 * @param {object} payload - Data for submitting a condition report
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * reason,
 * }
 * usePreviousCR(id, body).then((res) => {
 * // Promise resolved
 * });
 */
export const createUsingPreviousCR = (vehicleId: string, payload: { reason: TransferReason; agreement_id: string }) =>
  api.post(`condition-report/use-previous-report/${vehicleId}`, payload);

/**
 * Submit condition report for assigning courtesy car
 * @category API/POST
 * @param {object} payload - Data for submitting a condition report
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * vehicle_id,
 * branch_id,
 * reason,
 * mileage,
 * fuel,
 * checkList,
 * files
 * }
 * assignCourtesy(body).then((res) => {
 * // Promise resolved
 * });
 */
export const assignCourtesy = (payload: AssignCourtesy) => api.post('condition-report/assign-courtesy', payload);

/**
 * Submit condition report for returning a courtesy car
 * @category API/POST
 * @param {object} payload - Data for submitting a condition report
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * vehicle_id,
 * branch_id,
 * reason,
 * mileage,
 * fuel,
 * checkList,
 * files
 * }
 * returnCourtesy(body).then((res) => {
 * // Promise resolved
 * });
 */
export const returnCourtesy = (payload: ReturnCourtesy) => api.post('condition-report/return-courtesy', payload);

/**
 * Submit create damage log
 * @category API/POST
 * @param {object} payload - Data for submitting a damage log
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * condition_report_id,
 * damaged_type,
 * damaged_area,
 * damage_description,
 * damage_detail,
 * files
 * }
 * createDamageLog(body).then((res) => {
 * // Promise resolved
 * });
 */
export const createDamageLog = (payload: DamageLogPayload): Promise<{ data: { id: string } }> =>
  api.post('damage-log', payload);
