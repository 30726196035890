import React, { useContext, useState, useCallback, useEffect } from 'react';
import { BsEye } from 'react-icons/bs';
import { FiMinusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { CREATE_ORDER, ORDERS } from '../../consts/routes';
import { useDateRangeFilter } from '../../hooks/useDateRangeFilter';
import { useTableFilters } from '../../hooks/useTableFilters';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../uiComponents/modals/confirmationModal/confirmationModal';
import { ActionIcon } from '../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../uiComponents/table/table';
import { orderListColumns } from '../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../uiComponents/table/tableFilters/tableFilters';
import { orderStatusOptions, agreementTypes } from '../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../uiComponents/table/tableUtils/tableUtils';
import { getAllApplications } from '../../api/get/application.get';
import { getAllBranches } from '../../api/get/branch.get';
import { getAllCities } from '../../api/get/city.get';
import { cancelApplication } from '../../api/delete/application.delete';
import { ApplicationStatus, Application } from '../../models/application';
import { Branch } from '../../models/branch';
import { City } from '../../models/city';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';
import { APP_CONTEXT } from '../../utils/context';
import { OptionList } from '../../utils/props';
import { useListAndMergeOrdersListQuery } from '../../api/listAndMerge/listAndMergeOrderListApiSlice';
import { format } from 'date-fns';

export const OrderList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const [selectedCity, setSelectedCity] = useState<OptionList[]>([]);
  const [selectedAgreementBranch, setSelectedAgreementBranch] = useState<OptionList[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<OptionList[]>([]);
  const [selectedAgreementType, setSelectedAgreementType] = useState<OptionList[]>([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState<OptionList[]>([]);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState<boolean>(false);
  const [orderToCancel, setOrderToCancel] = useState<string>();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: ordersList,
    isFetching: isOrdersListFetching,
    refetch,
  } = useListAndMergeOrdersListQuery({ query: queryStringState, refetch: refetchData });

  const filters: FilterItem[] = [
    {
      name: 'agreement_type',
      element: (
        <DropDownFilter
          name="agreement_type"
          placeholder="Select an agreement type"
          options={agreementTypes}
          multiValues={selectedAgreementType}
          title="Agreement type:"
          onChange={(items) => setSelectedAgreementType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'agreement_branch',
      element: (
        <DropDownFilter
          name="branch"
          placeholder="Agreement branch"
          options={branchOptions}
          multiValues={selectedAgreementBranch}
          title="Agreement branch:"
          onChange={(items) => setSelectedAgreementBranch(items as OptionList[])}
        />
      ),
    },
    {
      name: 'sales_location',
      element: (
        <DropDownFilter
          name="sales_location"
          placeholder="Sales location"
          options={cityOptions}
          multiValues={selectedCity}
          title="Sales location"
          onChange={(items) => setSelectedCity(items as OptionList[])}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <DropDownFilter
          name="status"
          placeholder="Status"
          options={orderStatusOptions}
          multiValues={selectedStatus}
          title="Status:"
          onChange={(items) => setSelectedStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Date created"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const handleGetOrdersResponse = useCallback(
    (count: number, orders: Application[]) => {
      const cancelStatuses: ApplicationStatus[] = ['DECLARATION-COMPLETED', 'SCRIVE-SENT', 'COMPLETED'];
      const orderRows = orders?.map((order: Application) => {
        return {
          rowData: { data: order },
          cells: [
            <TableTextCell value={order?.driver_name} />,
            order?.application_type ? <TableTagCell tags={[order?.application_type ?? '-']} /> : <div></div>,
            <TableTextCell value={order?.city_name} />,
            <TableTextCell value={order?.branch_name} />,
            <TableTextCell value={order?.vehicle_type} />,
            <TableTextCell value={order?.vrm ?? '-'} />,
            <TableTextCell value={format(new Date(order?.created_date), 'dd MMM yyyy')} />,
            order?.application_status ? <TableTagCell tags={[order?.application_status]} /> : <></>,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => navigate(`${ORDERS}/${order?.application_id}`, { state: { prevRoute: ORDERS } })}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View order"
              />
              {!cancelStatuses.includes(order?.application_status) && (
                <ActionIcon
                  onClick={() => {
                    setOrderToCancel(order?.application_id);
                    setShowCancelOrderModal(true);
                  }}
                  icon={<FiMinusCircle size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Cancel order"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(orderRows);
      setTotalRows(count);
    },
    [setTotalRows, navigate, setTableData]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('orderListPage');
    setPageTitle('Orders');
    getAllCities().then((results: { count: number; data: City[] }) => {
      const items: OptionList[] = results.data.map((city: City) => {
        return { value: city?.city_id, label: city.city_name.toUpperCase() };
      });
      setCityOptions(items);
    });
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response?.data?.map((branch: Branch) => {
        return {
          value: branch?.branch_id,
          label: branch?.branch_name,
        };
      });
      setBranchOptions(branches);
    });
    setSortingColumn('created_date');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'application_status', options: selectedStatus },
      { columnName: 'application_type', options: selectedAgreementType },
      {
        columnName: 'application.created_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
      { columnName: 'city.id', options: selectedCity },
      { columnName: 'branch.id', options: selectedAgreementBranch },
      { columnName: 'vehicle_type.id', options: selectedVehicleType },
    ]);
  }, [
    setTableFilters,
    dateRangeFilter,
    selectedAgreementBranch,
    selectedCity,
    selectedVehicleType,
    selectedStatus,
    selectedAgreementType,
  ]);

  useEffect(() => {
    if (ordersList) {
      handleGetOrdersResponse(ordersList.count, ordersList.data);
    }
  }, [ordersList, queryStringState, handleGetOrdersResponse]);

  const onClearClick = useCallback(() => {
    setSelectedStatus([]);
    setSelectedCity([]);
    setSelectedAgreementType([]);
    setDateRangeFilter([]);
    setSelectedCity([]);
    setSelectedAgreementBranch([]);
    setSelectedVehicleType([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isOrdersListFetching}
        header="Order list"
        actionButtonText="Create order"
        onActionButtonClick={() => navigate(CREATE_ORDER, { state: { prevRoute: ORDERS } })}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        dataDownloadMethod="download"
        downloadName="Orders"
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={orderListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllApplications}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={(data: { data: Application }) => {
          navigate(`${ORDERS}/${data?.data?.application_id}`, { state: { prevRoute: ORDERS } });
        }}
      />
      <ConfirmationModal
        isOpen={showCancelOrderModal}
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        title="Are you sure you want to cancel this order?"
        onClose={() => setShowCancelOrderModal(false)}
        preConfirm={
          orderToCancel
            ? () =>
                cancelApplication(orderToCancel).then(() => {
                  setShowCancelOrderModal(false);
                  setOrderToCancel(undefined);
                  refetchToFirstPage();
                })
            : undefined
        }
      />
    </>
  );
};
