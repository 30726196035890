/* eslint-disable no-console */
import { PutObjectCommandInput, S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { getUrl, uploadData } from 'aws-amplify/storage';
import { getAuthService } from './auth.service';

interface UploadFileToStorageProps {
  path: string;
  data: Blob | File;
}

interface GetFileUrlFromStorage {
  path: string;
}

const authService = getAuthService();

let s3Client: S3Client | null = null;
let lastS3ClientCreated = Date.now();

class FileService {
  private getS3Url(path: string) {
    return `https://s3.${process.env.REACT_APP_COGNITO_REGION || 'eu-west-1'}.amazonaws.com/${process.env.REACT_APP_ASSETS_BUCKET_NAME || ''}/${path}`;
  }

  private getS3Prefix() {
    return `https://s3.${process.env.REACT_APP_COGNITO_REGION || 'eu-west-1'}.amazonaws.com/${process.env.REACT_APP_ASSETS_BUCKET_NAME || ''}/`;
  }

  private async uploadFileToStorageReturningFullUrl({ data, path }: UploadFileToStorageProps) {
    if (!s3Client || Date.now() - lastS3ClientCreated > 1000 * 60 * 15) {
      const session = await authService.fetchCurrentAuthSession();
      s3Client = new S3Client({
        region: process.env.REACT_APP_COGNITO_REGION || 'eu-west-1',
        credentials: {
          accessKeyId: session?.credentials?.accessKeyId || '',
          secretAccessKey: session?.credentials?.secretAccessKey || '',
          sessionToken: session?.credentials?.sessionToken || '',
        },
      });
      lastS3ClientCreated = Date.now();
    }
    const params: PutObjectCommandInput = {
      Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
      Key: path,
      Body: data,
      ContentType: data.type,
    };

    const currentUpload = new Upload({
      client: s3Client,
      params,
    });

    try {
      await currentUpload.done();
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error(`Failed to upload file: ${(error as Error).message}`);
    }

    return { path: this.getS3Url(path) };
  }

  private async uploadFileToStorageWithPrivateRead({ data, path }: UploadFileToStorageProps) {
    try {
      const result = await uploadData({
        path,
        data,
      }).result;

      return result;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error uploading file:', error);
        throw new Error(`Failed to upload file: ${error.message}`);
      } else {
        console.error('Unknown error uploading file:', error);
        throw new Error('Failed to upload file: Unknown error');
      }
    }
  }

  async uploadFileToStorage({ data, path }: UploadFileToStorageProps) {
    if (process.env.REACT_APP_STAGE === 'prod') {
      return this.uploadFileToStorageReturningFullUrl({ data, path });
    }

    return this.uploadFileToStorageWithPrivateRead({ data, path });
  }

  async getFileUrlFromStorage({ path }: GetFileUrlFromStorage) {
    if (path.startsWith('https://')) {
      const key = decodeURIComponent(
        new URL(path).pathname.replace(`/${process.env.REACT_APP_ASSETS_BUCKET_NAME as string}/`, '')
      );
      path = key;
    }
    try {
      const linkToStorageFile = await getUrl({
        path,
      });

      return linkToStorageFile.url;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error dowloading the file:', error);
        throw new Error(`Failed to dowload file: ${error.message}`);
      } else {
        console.error('Unknown error uploading file:', error);
        throw new Error('Failed to dowload file: Unknown error');
      }
    }
  }
}

let fileService: FileService | null = null;

export function getFileService() {
  if (!fileService) {
    fileService = new FileService();
  }
  return fileService;
}
