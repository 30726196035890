import { useCallback, useContext, useEffect, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { EventClickArg } from '@fullcalendar/core';
import { v4 as uuidv4 } from 'uuid';
import { CalendarContainer, PhvMotContainer, StateContainer } from './phvMotCalendar.styles';
import { DRIVERS, VEHICLES } from '../../../consts/routes';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { Calendar } from '../../../uiComponents/customComponents/calendar/calendar';
import { MultiActionTooltip } from '../../../uiComponents/customComponents/multiActionTooltip/multiActionTooltip';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Table } from '../../../uiComponents/table/table';
import { phvMotVehicleColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { TableHeader } from '../../../uiComponents/table/tableHeader/tableHeader';
import { TableTagCell, TableTextCell, getQueryString } from '../../../uiComponents/table/tableUtils/tableUtils';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { getPhvAndMotExpiry } from '../../../api/get/servicing.get';
import { getAllVehiclesForPHVAndMOT } from '../../../api/get/vehicle.get';
import { Vehicle } from '../../../models/vehicle';
import { PhvMotDatesState, CalendarEvents } from '../../../models/servicing';
import { PRIMARY_GRAY, PRIMARY_PURPLE, STATUS_BLUE, STATUS_ORANGE } from '../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../utils/context';
import { format } from 'date-fns';

interface SelectedDate {
  id: string;
  startStr: string;
}

export const PhvMotCalendar = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [dateSelected, setSelectedDates] = useState<SelectedDate>();
  const [dates, setDates] = useState<PhvMotDatesState>({ phv_exp: [], mot_exp: [] });
  const [phvMotLoading, setPhvMotLoading] = useState<boolean>(false);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  useEffect(() => {
    setActiveSideNav('phv-mot');
    setPageTitle('PHV & MOT');
    setPhvMotLoading(true);
    getPhvAndMotExpiry()
      .then(({ data }) => {
        setDates(data.phvMotDateList);
        setTableData([]);
      })
      .catch(() => {
        setPhvMotLoading(false);
      })
      .finally(() => {
        setPhvMotLoading(false);
      });
  }, [setActiveSideNav, setPageTitle, setTableData]);

  const handleTempEvents = useCallback(() => {
    const events: CalendarEvents[] = [];
    const phvDates: string[] = Array.from(new Set(dates.phv_exp));
    const phvEvents: CalendarEvents[] = phvDates.map((item) => {
      return {
        id: `phv_exp-${uuidv4()}`,
        start: item,
        color: STATUS_BLUE,
      };
    });
    const motDates: string[] = Array.from(new Set(dates.mot_exp));
    const motEvents: CalendarEvents[] = motDates.map((item) => {
      return {
        id: `mot_exp-${uuidv4()}`,
        start: item,
        color: STATUS_ORANGE,
      };
    });
    events.push(...phvEvents, ...motEvents);
    return events;
  }, [dates]);

  const handleDateClick = ({ event }: EventClickArg) => {
    if (!event) return;
    const { id, startStr } = event;
    setSelectedDates({ id, startStr });
  };

  const handleGetVehiclesResponse = useCallback(
    (count: number, vehicles: Vehicle[]) => {
      const vehicleRows = vehicles?.map((vehicle: Vehicle) => {
        return {
          rowData: { data: vehicle },
          cells: [
            <TableTextCell value={vehicle?.driver_name || 'N/A'} />,
            <TableTextCell value={vehicle?.vrm || 'N/A'} />,
            <TableTextCell
              value={vehicle?.mot_exp ? format(new Date(vehicle?.mot_exp), 'dd/MM/yyyy') : 'No MOT Expiry'}
            />,
            <TableTextCell
              value={vehicle?.phv_exp ? format(new Date(vehicle?.phv_exp), 'dd/MM/yyyy') : 'No PHV Expiry'}
            />,
            <TableTagCell tags={[vehicle?.vehicle_status]} />,
            <TableTextCell value={vehicle?.driver_email || 'N/A'} />,
            <FlexLayout>
              <MultiActionTooltip
                icon={<BsEye size={24} color={PRIMARY_GRAY} />}
                tooltips={[
                  {
                    id: 1,
                    handleClick: () => navigate(`${DRIVERS}/${vehicle.driver_id}`),
                    tooltip: 'View driver',
                    disabled: !!vehicle?.driver_id,
                  },
                  {
                    id: 2,
                    handleClick: () => navigate(`${VEHICLES}/${vehicle.vehicle_id}`),
                    tooltip: 'View vehicle',
                    disabled: !!vehicle.vehicle_id,
                  },
                ]}
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehicleRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, navigate]
  );

  const fetchVehiclesList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      setTableData(undefined);
      getAllVehiclesForPHVAndMOT(queryString, controller.signal).then(
        (response: { count: number; data: Vehicle[] }) => {
          handleGetVehiclesResponse(response.count, response.data);
        }
      );
    },
    [handleGetVehiclesResponse, setTableData]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      if (dateSelected) {
        const subString = dateSelected?.id.split('-');
        const queryString = getQueryString([], rowsPerPage, pageNumber, searchString, sortingColumn, sortAscending);
        setSortingColumn('vrm');
        fetchVehiclesList(`filter=${subString[0]}$eq=${dateSelected?.startStr}&${queryString}`);
      }
    },
    [fetchVehiclesList, setTableData, dateSelected, setSortingColumn]
  );

  useEffect(() => {
    if (dateSelected) {
      const subString: string[] = dateSelected.id.split('-');
      fetchVehiclesList(`filter=${subString[0]}$eq=${dateSelected.startStr}`);
    }
  }, [fetchVehiclesList, dateSelected]);

  if (phvMotLoading) {
    return (
      <StateContainer>
        <Spinner size={40} color={PRIMARY_PURPLE} />
      </StateContainer>
    );
  }

  return (
    <FlexLayout>
      {dates && (
        <>
          <CalendarContainer>
            <Calendar
              events={handleTempEvents()}
              eventClick={(e: EventClickArg) => {
                handleDateClick(e);
              }}
              phvMotDataCount={tableData?.length}
              clearExpiryDates={() => {
                setTableData([]);
                setSelectedDates(undefined);
              }}
            />
          </CalendarContainer>
          <PhvMotContainer>
            <Table
              header={<TableHeader tableHeaderTitle="PHV MOT list" />}
              onColumnHeaderClick={(columnId: string) =>
                applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
              }
              sortAscending={sortAscending}
              columns={phvMotVehicleColumns}
              rows={tableData}
              totalRows={totalRows}
              rowsPerPage={numRowsPerPage}
              currentPageNumber={pageNumber}
              sortingColumn={sortingColumn}
              filters={[]}
              onSearchChange={(value: string) => {
                setSearchString(value);
                applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
              }}
              downloadApi={getAllVehiclesForPHVAndMOT}
              downloadName="Phv Mot Vehicles"
              dataDownloadMethod="download"
              filterQuery={filterQuery}
              goToPage={(pageNumber: number) => {
                goToPageNumber(pageNumber);
                applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
              }}
              onApplyClick={() => applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending)}
              onNumRowsPerPageChange={(value: number) => {
                setNumRowsPerPage(value);
                goToPageNumber(0);
                applyFilters(0, value, searchString, sortingColumn, sortAscending);
              }}
            />
          </PhvMotContainer>
        </>
      )}
    </FlexLayout>
  );
};
