import { useState } from 'react';
import { isValid, isBefore } from 'date-fns';
import { OptionList } from '../utils/props';

export const useDateRangeFilter = () => {
  const [dateRangeFilter, setDateRangeFilter] = useState<OptionList[]>([]);

  const updateDateRangeFilter = (value: string, dateIndex: number) => {
    const time: string = dateIndex === 0 ? 'T00:00:00.000Z' : 'T23:59:59.000Z';
    const dateRange = dateRangeFilter;
    dateRange[dateIndex] = { value: `${value}${time}`, label: value };
    setDateRangeFilter([...dateRange]);
  };

  const startDateValid = dateRangeFilter?.[0] && isValid(dateRangeFilter?.[0]?.value);
  const endDateValid = dateRangeFilter?.[1] && isValid(dateRangeFilter?.[1]?.value);

  const invalidDates =
    (startDateValid && !endDateValid) ||
    (!startDateValid && endDateValid) ||
    isBefore(new Date(dateRangeFilter?.[1]?.value), new Date(dateRangeFilter?.[0]?.value));

  return { updateDateRangeFilter, invalidDates, dateRangeFilter, setDateRangeFilter };
};
